import { gql } from '@apollo/client';

export const CREATE_COLLECTION = gql`
  mutation createCollection($userId: Int!, $name: String!, $publicationIds: [Int]!) {
    createCollection(userId: $userId, name: $name, publicationIds: $publicationIds) {
      id
      name
      real_estate_publications {
        id
      }
      user_id
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($collectionIds: [Int]!) {
    deleteCollection(collectionIds: $collectionIds)
  }
`;

export const ADD_PUBLICATIONS_TO_COLLECTION = gql`
  mutation addPublicationsToCollection($collectionId: Int!, $publicationIds: [Int]!) {
    addPublicationsToCollection(collectionId: $collectionId, publicationIds: $publicationIds) {
      id
      real_estate_asset {
        property_type
        parking_lots
        floors
        total_area
        covered_area
        rooms
        full_bathrooms
        has_swimming_pool
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      source
      external_source_id
      unique_external_id
      external_source_url
      description
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
    }
  }
`;

export const REMOVE_PUBLICATIONS_FROM_COLLECTION = gql`
  mutation removePublicationsFromCollection($collectionId: Int!, $publicationIds: [Int]!) {
    removePublicationsFromCollection(collectionId: $collectionId, publicationIds: $publicationIds)
  }
`;

import React, { useState, useEffect } from 'react';
// import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { noPictureAvailable } from '../../../assets';
import { HeartFilled, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import './style.less';
//import PublicationHistory from '../../RenderViews/PublicationHistory';

function PropertyPreviewCardCover({
  property,
  isSelected,
  handleSelect,
  handleLike,
  isFavorite,
  layout,
}) {
  const [imgIndex, setImgIndex] = useState(0);
  const [fit, setfit] = useState(false);

  function handleLikeEvent(clickEvent) {
    clickEvent.stopPropagation();
    handleLike();
    // DummyData
  }

  function handleImgIndexChange(clickEvent, value) {
    clickEvent.stopPropagation();
    const index = imgIndex + value;
    if (index < 0) {
      setImgIndex(property.pictures?.length - 1);
    } else if (index >= property.pictures?.length) {
      setImgIndex(0);
    } else {
      setImgIndex(index);
    }
  }

  useEffect(() => {
    const img = new Image();
    img.src = property.pictures ? property.pictures[imgIndex] : '';
    if (property.pictures?.length === 0) {
      setfit(false); // setfit(false);
    } else if (img.width >= img.height - 100) {
      setfit(false); // setfit(true);
    } else {
      setfit(false); // setfit(false);
    }
  }, [imgIndex]);

  return (
    <div className={`propertyPreviewCardCoverContainer ${layout}`}>
      <div
        className={fit ? 'cardCover' : 'cardCover'}
        style={{
          backgroundImage: `url(${
            property.pictures ? property.pictures[imgIndex] : noPictureAvailable
          })`,
        }}
      >
        <div className="cardCoverIcons">
          <div className="coverIcon">
            <Checkbox
              className="disableParentSelection checkboxSelector"
              checked={isSelected}
              onClick={(e) => {
                // only click this object, not the parent
                //e.preventDefault();
                e.stopPropagation();
                handleSelect(property.id, isSelected);
              }}
            ></Checkbox>
          </div>
          <div className="coverIcon">
            {isFavorite ? (
              <HeartFilled style={{ color: '#8186F6' }} onClick={handleLike} />
            ) : (
              <HeartFilled
                style={{ fontSize: '16px' }}
                className="outlinedIcon"
                onClick={handleLike}
              />
            )}
          </div>
        </div>
        <div className="cardCoverImageArrows">
          <div
            className="arrowSelector"
            onClick={(e) => {
              handleImgIndexChange(e, -1);
            }}
          >
            <LeftOutlined />
          </div>
          <div
            className="arrowSelector"
            onClick={(e) => {
              handleImgIndexChange(e, 1);
            }}
          >
            <RightOutlined />
          </div>
        </div>
        {!property.isActive && <div className="isNotAvailable noselect">No disponible</div>}
      </div>
    </div>
  );
}

PropertyPreviewCardCover.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    pictures: PropTypes.arrayOf(PropTypes.string),
    isActive: PropTypes.bool,
  }).isRequired,
  isSelected: PropTypes.bool,
  handleSelect: PropTypes.func,
  layout: PropTypes.oneOf(['vertical', 'horizontal', 'simple']),
};

PropertyPreviewCardCover.defaultProps = {
  isSelected: false,
  handleSelect: () => {},
  layout: 'vertical',
};

export default PropertyPreviewCardCover;

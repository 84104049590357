import { setPriceInUFtoUFandCLP, setPriceInUFtoFloatUFandCLP } from '../formatters';

export function formatGetAppraisalsForAppraisalIndex(data) {
  return {
    ...data,
    id: data.id,
    estimatedSellPrice: setPriceInUFtoUFandCLP(data.sellPrice),
    estimatedSellPricePerArea: setPriceInUFtoUFandCLP(data.sellPricePerArea),
    estimatedSellPriceMin: setPriceInUFtoUFandCLP(data.lowerBoundSellCI),
    estimatedSellPriceMinPerArea: setPriceInUFtoUFandCLP(data.lowerBoundSellCIPerArea),
    estimatedSellPriceMax: setPriceInUFtoUFandCLP(data.upperBoundSellCI),
    estimatedSellPriceMaxPerArea: setPriceInUFtoUFandCLP(data.upperBoundSellCIPerArea),
    estimatedRentPrice: setPriceInUFtoUFandCLP(data.rentPrice),
    estimatedRentPricePerArea: setPriceInUFtoFloatUFandCLP(data.rentPricePerArea),
    estimatedRentPriceMin: setPriceInUFtoUFandCLP(data.lowerBoundRentCI),
    estimatedRentPriceMinPerArea: setPriceInUFtoFloatUFandCLP(data.lowerBoundRentCIPerArea),
    estimatedRentPriceMax: setPriceInUFtoUFandCLP(data.upperBoundRentCI),
    estimatedRentPriceMaxPerArea: setPriceInUFtoFloatUFandCLP(data.upperBoundRentCIPerArea),
    estimatedYield: (data.yield * 100).toFixed(1),
    estimatedRentPriceMaxNumericValue: {
      UF: data.upperBoundRentCI,
      CLP: data.upperBoundRentCI * 30000,
    },
    estimatedMaxYield: (data.upperBoundYieldCI * 100).toFixed(2),
    estimatedMinYield: (data.lowerBoundYieldCI * 100).toFixed(2),
    latitude: data.latitude,
    longitude: data.longitude,
    swimmingPool: data.hasSwimmingPool,
    parkings: data.parkingLots,
    propertyType: data.propertyType === 'house' ? 'Casa' : 'Depto',
    floors: data.floors,
    totalAreaText: `${data.coveredArea} m² útiles`,
    coveredAreaText: `${data.totalArea} m² totales`,
    totalAreaTextSmall: `${data.totalArea} m²`,
    coveredAreaTextSmall: `${data.coveredArea} m²`,
    coveredArea: data.coveredArea,
    totalArea: data.totalArea,
    fullBathrooms: data.bathrooms,
    bedRooms: data.bedrooms,
    address: data.address,
    propertyTypeDB: data.propertyType,
    quality: data.quality,
  };
}

export function formatGetAppraisalsForInvestmentSimulator(data) {
  const interest = 0.05;
  const duration = 20;
  const expectedSellPrice = data.sellPrice;
  const initialDepositValue = (data.sellPrice * 0.2).toFixed(2);
  const rm = Math.pow(1 + interest, 1 / 12) - 1;
  const estimatedDividend = (
    (expectedSellPrice - initialDepositValue) *
    rm *
    (Math.pow(1 + rm, 12 * duration) / (Math.pow(1 + rm, 12 * duration) - 1))
  ).toFixed(2);
  return {
    propertyPrice: expectedSellPrice,
    initialDepositValue: initialDepositValue,
    initialDepositPercentage: 20,
    duration,
    interest: interest * 100,
    rentProbability: 0,
    estimatedDividend,
    diference: (data.rentPrice - estimatedDividend).toFixed(2),
    ...data,
  };
}

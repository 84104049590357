import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { IconClose, IconEyeOpened, IconEyeClosed } from '../../assets';
import { CREATE_USER } from '../../graphql/mutations/users';
import { Modal, Form, Input, Button, Select } from 'antd';
import FloatLabel from './FloatLabel';
import validator from 'validator';
import Icon from '@ant-design/icons';

const { Option } = Select;

export default function RegisterUser(props) {
  RegisterUser.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    roles: PropTypes.objectOf(PropTypes.any),
  };

  const [form] = Form.useForm();

  const [firstNameFormState, setFirstNameFormState] = useState('');
  const [lastNameFormState, setLastNameFormState] = useState('');
  const [phoneFormState, setPhoneFormState] = useState('');
  const [emailFormState, setEmailFormState] = useState('');
  const [roleFormState, setRoleFormState] = useState('5');
  const [passwordFormState, setPasswordFormState] = useState('');
  const [passwordConfirmationFormState, setPasswordConfirmationFormState] = useState('');

  const { visible, onCancel, roles } = props;

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted: () => {
      setFirstNameFormState('');
      setLastNameFormState('');
      setPhoneFormState('');
      setEmailFormState('');
      setRoleFormState('');
      setPasswordFormState('');
      setPasswordConfirmationFormState('');
      form.resetFields();
      onCancel(false);
    },
    onError: (error) => {
      if (error.graphQLErrors) {
        error.graphQLErrors.map((err) => {
          if (err.extensions.code === 'BAD_USER_INPUT') {
            err.extensions.params.forEach((x) => {
              form.setFields([
                {
                  name: x.name,
                  errors: [x.message.es],
                },
              ]);
            });
          }
        });
      }
    },
  });

  const onFinish = async (values) => {
    const { firstName, lastName, email, role, password, passwordConfirmation, phone } = values;
    createUser({
      variables: {
        roleId: parseInt(role, 10),
        planId: 1,
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
        phone,
      },
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => onCancel(false)}
      footer={false}
      closeIcon={<Icon component={IconClose} />}
      width="65vw"
      bodyStyle={{
        borderRadius: '12px',
        backgroundColor: 'rgb(250,250,250)',
      }}
    >
      <div className="adminRegisterModal">
        <h1>Registrar Usuario Nuevo</h1>
        <Form onFinish={onFinish} layout={'vertical'} form={form}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '66.5%',
              }}
            >
              <div className="dualRow">
                <Form.Item>
                  <FloatLabel label="Nombres" actualValue={firstNameFormState}>
                    <Form.Item
                      noStyle
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa un nombre.',
                        },
                      ]}
                    >
                      <Input
                        size={'large'}
                        value={firstNameFormState}
                        onChange={(x) => {
                          setFirstNameFormState(x.target.value);
                        }}
                      />
                    </Form.Item>
                  </FloatLabel>
                </Form.Item>
                <Form.Item>
                  <FloatLabel label="Apellidos" actualValue={lastNameFormState}>
                    <Form.Item
                      noStyle
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: 'Ingresa un apellido.',
                        },
                      ]}
                    >
                      <Input
                        size={'large'}
                        value={lastNameFormState}
                        onChange={(x) => {
                          setLastNameFormState(x.target.value);
                        }}
                      />
                    </Form.Item>
                  </FloatLabel>
                </Form.Item>
              </div>
              <Form.Item>
                <FloatLabel label="Correo electrónico" actualValue={emailFormState}>
                  <Form.Item
                    noStyle
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Ingresa un correo eletrónico valido.',
                      },
                      {
                        required: true,
                        message: 'Ingresa un email.',
                      },
                    ]}
                  >
                    <Input
                      size={'large'}
                      value={emailFormState}
                      onChange={(x) => {
                        setEmailFormState(x.target.value);
                      }}
                    ></Input>
                  </Form.Item>
                </FloatLabel>
              </Form.Item>
            </div>
            <div className="columnRow">
              <Form.Item>
                <FloatLabel label="Teléfono móvil" actualValue={phoneFormState}>
                  <Form.Item
                    noStyle
                    name="phone"
                    rules={[
                      {
                        validator: (_, value) =>
                          validator.isMobilePhone(value, 'es-CL', {
                            strictMode: true,
                          })
                            ? Promise.resolve()
                            : Promise.reject(
                                'Ingresa un número de teléfono móvil incluyendo el código de área (+569).',
                              ),
                      },
                    ]}
                  >
                    <Input
                      size={'large'}
                      value={phoneFormState}
                      onChange={(x) => {
                        setPhoneFormState(x.target.value);
                      }}
                    />
                  </Form.Item>
                </FloatLabel>
              </Form.Item>
              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa un rol.',
                  },
                ]}
              >
                <Select
                  value={roleFormState}
                  placeholder="Rol Usuario"
                  size={'large'}
                  onChange={(x) => setRoleFormState(x)}
                >
                  {roles.getRoles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.spanishName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="formRowThree">
            <Form.Item>
              <FloatLabel label="Escribe una contraseña" actualValue={passwordFormState}>
                <Form.Item
                  noStyle
                  name="password"
                  rules={[
                    {
                      pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
                      message:
                        'Mínimo 8 caracteres incluyendo números, minusculas, mayúsculas y símbolos.',
                    },
                    {
                      required: true,
                      message: 'Ingresa una contraseña',
                    },
                  ]}
                >
                  <Input.Password
                    size={'large'}
                    value={passwordFormState}
                    onChange={(x) => {
                      setPasswordFormState(x.target.value);
                    }}
                    iconRender={(visible) =>
                      visible ? (
                        <span>
                          Ocultar
                          <Icon component={IconEyeOpened} />
                        </span>
                      ) : (
                        <span>
                          Mostrar
                          <Icon component={IconEyeClosed} />
                        </span>
                      )
                    }
                  />
                </Form.Item>
              </FloatLabel>
            </Form.Item>
            <Form.Item
              hasFeedback
              validateStatus={
                passwordConfirmationFormState === passwordFormState
                  ? passwordFormState === ''
                    ? ''
                    : 'success'
                  : 'error'
              }
            >
              <FloatLabel label="Repetir la contraseña" actualValue={passwordConfirmationFormState}>
                <Form.Item
                  name="passwordConfirmation"
                  dependencies={['password']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: 'Porfavor confirma la contraseña.',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Las contraseñas no coinciden.');
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size={'large'}
                    value={passwordConfirmationFormState}
                    onChange={(x) => {
                      setPasswordConfirmationFormState(x.target.value);
                    }}
                    iconRender={(visible) =>
                      visible ? (
                        <span>
                          Ocultar
                          <Icon component={IconEyeOpened} />
                        </span>
                      ) : (
                        <span>
                          Mostrar
                          <Icon component={IconEyeClosed} />
                        </span>
                      )
                    }
                  />
                </Form.Item>
              </FloatLabel>
            </Form.Item>
          </div>
          <div className="formSubmitButton">
            <Button type="primary" htmlType="submit" size={'large'} loading={loading}>
              Registrar Usuario
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';
import './style.less';
import Icon from '@ant-design/icons';
import { categoryPointers } from '../../pointOfInteresCategories';

const PointOfInterest = (props) => {
  PointOfInterest.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
  };

  const { latitude, longitude, category } = props;

  return (
    <Marker latitude={latitude} longitude={longitude}>
      <div className="map-pin-2">
        <Icon component={categoryPointers[category]} />
      </div>
    </Marker>
  );
};

export default PointOfInterest;

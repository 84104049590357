import React from 'react';
import { Mix } from '@ant-design/charts';
import PropTypes from 'prop-types';
//import { GET_METRIC_TIME } from '../../graphql/queries';
//import { useLazyQuery } from '@apollo/client';

export default function DemoMix({ dataLine, dataArea }) {
  var config = {
    syncViewPadding: true,
    height: 150,
    legend: {
      layout: 'horizontal',
      position: 'right',
      labels: {
        color: 'rgb(255, 99, 132)',
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      showCrosshairs: true,
      offsetY: -50,
    },
    views: [
      {
        ///data: data?.area || [],
        data: dataArea || [],
        axes: {},
        meta: {
          time: {
            type: 'time',
            mask: 'MM/YYYY',
            tickCount: 28,
          },
          temperature: {
            nice: true,
            sync: true,
            alias: 'UF/m2',
          },
        },

        // view1: prepare a area plot, mapping position to `time*temperature`
        geometries: [
          {
            type: 'area',
            xField: 'time',
            yField: 'temperature',
            mapping: {},
            colorField: '#C5C5FF',
            styleField: {
              opacity: 1,
            },
          },
        ],
      },
      {
        data: dataLine || [],
        axes: false,
        meta: {
          time: {
            type: 'time',
            mask: 'MM/YYYY',
            label: 'jol',
            //nice: true,
            //tickInterval: 28,
          },
          temperature: {
            sync: 'temperature',
            alias: 'UF/m2',
          },
        },
        // view2: prepare a line plot and point plot, mapping position to `time*temperature` (share data)
        geometries: [
          {
            type: 'line',
            xField: 'time',
            yField: 'temperature',
            mapping: {},
            colorField: '#8186f7',
          },
        ],
      },
    ],
  };
  return <Mix {...config} />;
}

DemoMix.propTypes = {
  dataLine: PropTypes.array,
  dataArea: PropTypes.array,
};

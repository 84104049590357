import React, { useState } from 'react';
import { formValuesControl } from './functions';
import PropTypes from 'prop-types';
import { useUpdateEffect } from '../../../hooks';
import { Form, Input, Select } from 'antd';
import ToggleFilter from '../../Filters/Toggle';
//import { EstimationCard } from '../Fragments';
import './style.less';

function InvestmentSimulator({ data }) {
  const [form] = Form.useForm();
  const [unitTogle, setUnitTogle] = useState('UF');
  const [diferencedColor, setDiferenceColor] = useState(data.diference > 0);

  useUpdateEffect(() => {
    if (unitTogle === 'CLP') {
      const initialDepositValue = (30000 * form.getFieldsValue(true).initialDepositValue).toFixed(
        2,
      );
      form.setFieldsValue({ initialDepositValue });
      const propertyPrice = (form.getFieldsValue(true).propertyPrice * 30000).toFixed(2);
      form.setFieldsValue({ propertyPrice });
      const estimatedDividend = (form.getFieldsValue(true).estimatedDividend * 30000).toFixed(2);
      form.setFieldsValue({ estimatedDividend });
      const diference = (form.getFieldsValue(true).diference * 30000).toFixed(2);
      form.setFieldsValue({ diference });
      const simulatedRent = (form.getFieldsValue(true).simulatedRent * 30000).toFixed(2);
      form.setFieldsValue({ simulatedRent });
    }
    if (unitTogle === 'UF') {
      const initialDepositValue = (form.getFieldsValue(true).initialDepositValue / 30000).toFixed(
        2,
      );
      form.setFieldsValue({ initialDepositValue });
      const propertyPrice = (form.getFieldsValue(true).propertyPrice / 30000).toFixed(2);
      form.setFieldsValue({ propertyPrice });
      const estimatedDividend = (form.getFieldsValue(true).estimatedDividend / 30000).toFixed(2);
      form.setFieldsValue({ estimatedDividend });
      const diference = (form.getFieldsValue(true).diference / 30000).toFixed(2);
      form.setFieldsValue({ diference });
      const simulatedRent = (form.getFieldsValue(true).simulatedRent / 30000).toFixed(2);
      form.setFieldsValue({ simulatedRent });
    }
  }, [unitTogle]);

  return (
    <>
      <div className="InvestmentSimulatorContainer">
        <Form
          initialValues={{
            ...data,
            ['simulatedRent']: data.estimatedRentPrice[unitTogle],
          }}
          layout="vertical"
          form={form}
          onValuesChange={(param) => formValuesControl(param, form, setDiferenceColor)}
          className="InvestmentSimulatorFormContainer"
        >
          <div className="InvestmentSimulatorForm">
            <ToggleFilter
              defaultValue={'UF'}
              options={[
                { label: 'UF', value: 'UF' },
                { label: 'CLP', value: 'CLP' },
              ]}
              callback={setUnitTogle}
            />

            <Form.Item
              name="propertyPrice"
              label="Valor de la propiedad"
              rules={[{ required: true }]}
            >
              <Input suffix={<span>{unitTogle}</span>} size="large" type="number" />
            </Form.Item>
            <div className="doubleRow">
              <Form.Item
                name="initialDepositValue"
                label="Depósito inicial"
                rules={[{ required: true }]}
              >
                <Input suffix={<span>{unitTogle}</span>} size="large" type="number" />
              </Form.Item>
              <Form.Item name="initialDepositPercentage" label=" ">
                <Input suffix={<span>%</span>} size="large" type="number" />
              </Form.Item>
            </div>
            <div className="doubleRow">
              <Form.Item name="duration" label="Plazo">
                <Select size="large">
                  <Select.Option value={5}>5 años</Select.Option>
                  <Select.Option value={10}>10 años</Select.Option>
                  <Select.Option value={15}>15 años</Select.Option>
                  <Select.Option value={20}>20 años</Select.Option>
                  <Select.Option value={25}>25 años</Select.Option>
                  <Select.Option value={30}>30 años</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="interest" label="Interés" rules={[{ required: true }]}>
                <Input suffix={<span>%</span>} size="large" type="number" />
              </Form.Item>
            </div>
            <Form.Item
              name="simulatedRent"
              label="Arriendo simulado"
              rules={[
                { required: true },
                {
                  validator: async (rule, value) => {
                    if (
                      parseInt(value, 10) <
                      parseInt(data.estimatedRentPriceMaxNumericValue[unitTogle], 10)
                    ) {
                      return true;
                    }
                    throw new Error();
                  },
                  message: 'El precio esta sobre el máximo estimado',
                },
              ]}
            >
              <Input suffix={<span>{unitTogle}</span>} size="large" type="number" />
            </Form.Item>
            {/* <Form.Item name="rentProbability" label="Probabilidad de arrendarse">
                <Input suffix={<span>%</span>} />
              </Form.Item> */}
          </div>

          <div className="InvestmentSimulatorResult">
            <div className="simulationResults">
              <div className="estimatedBox">
                <h3>Dividendo</h3>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  {unitTogle === 'UF' ? 'UF' : '$'}
                  <Form.Item name="estimatedDividend" noStyle>
                    <Input size="large" className="simulationResult" disabled />
                  </Form.Item>
                </p>
              </div>

              <div className={`diferenceBox-${diferencedColor ? 'positive' : 'negative'}`}>
                <h3>Diferencia</h3>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  {unitTogle === 'UF' ? 'UF' : '$'}
                  <Form.Item name="diference" noStyle>
                    <Input size="large" className="simulationResult" disabled />
                  </Form.Item>
                </p>
              </div>
            </div>
            <div>
              {/*
                data.estimatedRentPrice && (
                  <EstimationCard
                    title={'Valor estimado de arriendo:'}
                    values={{
                      main: data.estimatedRentPrice[unitTogle],
                      mainPerM2: data.estimatedRentPricePerArea[unitTogle],
                      min: data.estimatedRentPriceMin[unitTogle],
                      minPerM2: data.estimatedRentPriceMinPerArea[unitTogle],
                      max: data.estimatedRentPriceMax[unitTogle],
                      maxPerM2: data.estimatedRentPriceMaxPerArea[unitTogle],
                    }}
                    unit={unitTogle}
                  />
                )
                */}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

const auxPricePropType = PropTypes.shape({
  UF: PropTypes.string,
  CLP: PropTypes.string,
});

InvestmentSimulator.propTypes = {
  data: PropTypes.shape({
    propertyPrice: PropTypes.number,
    initialDepositValue: PropTypes.number,
    initialDepositPercentage: PropTypes.number,
    duration: PropTypes.number,
    interest: PropTypes.number,
    simulatedRent: PropTypes.number,
    rentProbability: PropTypes.number,
    estimatedRentPrice: auxPricePropType,
    estimatedRentPricePerArea: auxPricePropType,
    estimatedRentPriceMin: auxPricePropType,
    estimatedRentPriceMinPerArea: auxPricePropType,
    estimatedRentPriceMax: auxPricePropType,
    estimatedRentPriceMaxPerArea: auxPricePropType,
    estimatedRentPriceMaxNumericValue: PropTypes.shape({
      UF: PropTypes.number,
      CLP: PropTypes.number,
    }),
    estimatedYield: PropTypes.number,
    estimatedMinYield: PropTypes.number,
    estimatedMaxYield: PropTypes.number,
    diference: PropTypes.number,
    estimatedDividend: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export default InvestmentSimulator;

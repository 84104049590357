import { UF_CLP } from '../constants';

export function getPropertyType(op, type) {
  if (op === 'sell' && type === 'house') return 'Casa en venta';
  if (op === 'sell' && type === 'apartment') return 'Depto en venta';
  if ((op === 'rental') | (op === 'rent') && type === 'house') return 'Casa en arriendo';
  if ((op === 'rental') | (op === 'rent') && type === 'apartment') return 'Depto en arriendo';
}

export function getOperationEstimatedText(op) {
  if (op === 'sell') return 'Precio de venta';
  if ((op === 'rental') | (op === 'rent')) return 'Precio de arriendo';
}

export function parseNumbersToPrice(number, operation, text = false, unit = 'UF') {
  if (!operation) return number ? parseInt(number).toLocaleString('de-DE') : undefined;

  if (operation === 'sell')
    return `${unit} ${number ? parseInt(number).toLocaleString('de-DE') : '-'}`;

  if ((operation === 'rental') | (operation === 'rent'))
    if (unit === 'UF')
      return `${unit} ${number ? number.toFixed(2).toLocaleString('de-DE') : '-'}${
        !text ? '' : '' // ' / mes'
      }`;
    else
      return `${unit} ${number ? parseInt(number).toLocaleString('de-DE') : '-'}${
        !text ? '' : '' // ' / mes'
      }`;
}

export function parseNumberToFloatPrice(number, operation, text = false, unit = 'UF') {
  if (!operation) return number ? number.toFixed(2).toLocaleString('de-DE') : undefined;
  if (operation === 'sell')
    return `${unit} ${number ? number.toFixed(2).toLocaleString('de-DE') : '-'}`;

  if ((operation === 'rental') | (operation === 'rent'))
    return `${unit} ${number ? number.toFixed(2).toLocaleString('de-DE') : '-'}${
      !text ? '' : '' // ' / mes'
    }`;
}

export function setPriceInUFtoUFandCLP(price) {
  return {
    UF: parseNumbersToPrice(price),
    CLP: parseNumbersToPrice(price * UF_CLP),
  };
}

export function setPriceInUFtoFloatUFandCLP(price) {
  return {
    UF: parseNumberToFloatPrice(price),
    CLP: parseNumbersToPrice(price * UF_CLP),
  };
}

export const operationFormatter = { sell: 'venta', rental: 'arriendo' };

export const propertyTypeFormatter = { house: 'Casa', apartment: 'Departamento' };

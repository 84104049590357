import { gql } from '@apollo/client';

export const LOG_IN = gql`
  mutation logIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      role {
        id
        name
      }
      firstName
      lastName
      email
      id
    }
  }
`;

export const LOG_OUT = gql`
  mutation {
    logOut
  }
`;

export const SIGN_UP = gql`
  mutation signUp(
    $firstName: NonEmptyString!
    $lastName: NonEmptyString!
    $email: EmailAddress!
    $password: NonEmptyString!
    $passwordConfirmation: NonEmptyString!
    $phone: NonEmptyString
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

export const GOOGLE_AUTH = gql`
  mutation googleAuth($googleToken: String!) {
    googleAuth(googleToken: $googleToken) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

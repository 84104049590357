import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PropertyInfo from '../../RenderViews/PropertyInfo';

function SummaryPropertyInfo({ publicationId, publicationData }) {
  /*
  const [propertyData, setPropertyData] = useState();

  const { loading } = useQuery(GET_PUBLICATION_DETAILS, {
    variables: { id: propertyId },
    onCompleted: (data) => {
      setPropertyData(parsePropertyDetail(data.getPublicationAttributesView));
    },
  });
  */

  if (!publicationData) return <div className="componentTitle"></div>;

  return <PropertyInfo data={publicationData}></PropertyInfo>;
}

SummaryPropertyInfo.propTypes = {
  publicationId: PropTypes.number,
  editionMode: PropTypes.bool,
};

SummaryPropertyInfo.defaultProps = {
  userRole: '',
};

export default SummaryPropertyInfo;

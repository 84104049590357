import React from 'react';
import PropTypes from 'prop-types';
import './style.less';

export default function SkeletonCard({ layout }) {
  return (
    <div className={`skeletonCard ${layout}`}>
      <div className="cardCover skeleton" />
      <div className="skeletonCardData">
        <div className="skeletonContainerRow">
          <div className="small skeleton" />
          <div className="small skeleton" />
        </div>
        <div className="skeletonContainerColumn">
          <div className="large skeleton" />
          <div className="small skeleton" />
          <div className="small skeleton" />
          {layout !== 'simple' && <div className="small skeleton" />}
        </div>
        <div className="skeletonContainerRow">
          <div className="short skeleton" />
          <div className="short skeleton" />
          <div className="short skeleton" />
        </div>
      </div>
    </div>
  );
}

SkeletonCard.propTypes = {
  layout: PropTypes.oneOf(['vertical', 'horizontal', 'simple']),
};

SkeletonCard.defaultProps = {
  layout: 'vertical',
};

import React, { useEffect, useState } from 'react';
import { Form, Alert, Button, Input, Row, Col } from 'antd';
import GoogleButton from 'react-google-button';
import { useUserAuth } from '../../context/AuthContext';
import { createUser, getUser, updateUser } from '../../actions/users';

const Login = () => {

  const { user, googleSignIn } = useUserAuth();




  /*
  useEffect(() => {
    const navigateToUserWorkspace = async () => {
      const workspace = await getWorkspaceFromUser({userId: user.uid});
      if (workspace && workspace.id){
        navigate('/workspace?workspaceId=' + workspace.id);
      } else {
        const workspaceRef = await createWorkspace({ 
          name: user.email, 
          userId: user.uid 
        });
        const workspaceId = workspaceRef.id;
        navigate('/workspace?workspaceId=' + workspaceId);
      }
    };
    const upgradeUserToPro = async () => {
      const newUser = await updateUser({uid: user.uid, pro: true});
      return newUser
    };
    if (user) {
      if (key == 'd018cbf2c5e788bf2c70f388c6fc7feb'){
        upgradeUserToPro();
        navigateToUserWorkspace();
      } else {
        navigateToUserWorkspace();
      }
      
    };
  }, [user]);
  */

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    const { user } = await googleSignIn();
    console.log(user);
    if (!user){
      return;
    }
    const userRef = await getUser({'uid': user.uid});
    console.log(userRef);
    if (!userRef){
      await createUser({
        'uid': user.uid,
        'email': user.email,
        'name': user.displayName || ''
      })
    }
  };

  return (
    <>
      <Row  justify='space-around' align='middle' style={{'height':'100vh'}}>
        <GoogleButton
          onClick={handleGoogleSignIn}
          className='g-btn'
          type='red'
        />
      </Row>
    </>
  );
};
export default Login;
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "antd";
import Icon from "@ant-design/icons";

import { IconEyeClosed, IconEyeOpened } from "../../assets";
import "./style.less";

const InputPassword = (props) => {
  const [visible, setVisible] = useState(false);

  const inputType = visible ? "text" : "password";

  return (
    <Fragment>
      <Input {...props} type={inputType} />
      <Button
        onClick={() => setVisible(!visible)}
        className="input-password-toggle-visible"
        type="text"
      >
        {visible ? "Ocultar" : "Mostrar"}
        <Icon component={visible ? IconEyeOpened : IconEyeClosed} />
      </Button>
    </Fragment>
  );
};

InputPassword.propTypes = {
  size: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputPassword;

import { RENDER_STATE } from "react-map-gl-draw";
//import OnHoverCircle from "../../assets/OnHoverCircle.svg";
export function getFeatureStyleDefault({ feature, state }) {
  if (feature.geometry.type == "Polygon") {
    switch (state) {
      case RENDER_STATE.SELECTED:
      case RENDER_STATE.HOVERED:
      case RENDER_STATE.UNCOMMITTED:
      case RENDER_STATE.CLOSING:
        return {
          stroke: "var(--primary-darker)",
          strokeWidth: 2,
          fillOpacity: 0,
          strokeDasharray: 6,
        };
      case RENDER_STATE.INACTIVE:
        return {
          stroke: "var(--primary-darker)",
          strokeWidth: 2,
          fill: "var(--primary-lavander)",
          fillOpacity: 0.6,
        };
    }
  }
  if (feature.geometry.type == "LineString") {
    return {
      stroke: "var(--primary-darker)",
      strokeWidth: 2,
      fillOpacity: 0,
    };
  }
}
export function getFeatureStyleSatelital({ feature, state }) {
  if (feature.geometry.type == "Polygon") {
    switch (state) {
      case RENDER_STATE.SELECTED:
      case RENDER_STATE.HOVERED:
      case RENDER_STATE.UNCOMMITTED:
      case RENDER_STATE.CLOSING:
        return {
          stroke: "var(--primary-lavander)",
          strokeWidth: 5,
          fill: "var(--primary-slim)",
          fillOpacity: 0,
          strokeDasharray: 6,
        };

      default:
        return {
          fillRule: "nonzero",
          stroke: "var(--primary-lavander)",
          strokeWidth: 5,
          fill: "var(--primary-slim)",
          fillOpacity: 0.6,
        };
    }
  }
  if (feature.geometry.type == "LineString") {
    return {
      stroke: "var(--primary-lavander)",
      strokeWidth: 5,
      fillOpacity: 0,
    };
  }
}

export function getEditHandleStyleSatelital({ feature }) {
  if (feature.properties.positionIndexes == 0) {
    return {
      cursor: "pointer",
      r: "6",
      stroke: "var(--primary-slim)",
      fill: "rgb(255,255,255)",
    };
  } else {
    return {
      r: "6",
      stroke: "var(--primary-slim)",
      fill: "var(--primary-slim)",
    };
  }
}

export function getEditHandleStyleDefault({ feature }) {
  if (feature.properties.positionIndexes == 0) {
    return {
      cursor: "pointer",
      r: "4",
      stroke: "var(--primary-darker)",
      fill: "rgb(255,255,255)",
    };
  } else {
    return {
      r: "4",
      stroke: "var(--primary-darker)",
      fill: "var(--primary-darker)",
    };
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LOG_IN } from '../../graphql/mutations/session';
import FloatLabel from './FloatLabel';
import { Form, Input, Button } from 'antd';

import { userRoleVar } from '../../graphql/cache';
import InputPassword from '../Input/Password';
import './style.less';

export default function LogIn({ closeModal }) {
  const [form] = Form.useForm();

  // Mutations
  const [login] = useMutation(LOG_IN, {
    onCompleted: (data) => {
      userRoleVar(data.logIn.role.name);
      closeModal();
    },
    onError: (error) => {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err) => {
          if (err.extensions.code === 'UNAUTHENTICATED' && err.message === 'Wrong credentials') {
            form.setFields([
              {
                name: 'email',
                errors: ['Credenciales incorrectas, email o contraseña invalidos.'],
              },
              { name: 'password', errors: [''] },
            ]);
          }
        });
      }
    },
  });

  // Functions
  const onFormFinish = (values) => {
    const { email, password } = values;
    login({
      variables: {
        email,
        password,
      },
    });
  };

  const handleFormChange = (field) => (event) => {
    const { value } = event.target;
    const prevValues = form.getFieldsValue();
    form.setFieldsValue({ ...prevValues, [field]: value });
  };

  return (
    <div className="auth-modal-content">
      <h1>¡Bienvenido de vuelta! Ingresa a tu cuenta de TassApp</h1>
      <Form onFinish={onFormFinish} className="auth-form" form={form}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Ingresa tu correo electrónico',
            },
          ]}
        >
          <FloatLabel label="Escribe tu correo electrónico">
            <Input size="large" onChange={handleFormChange('email')} />
          </FloatLabel>
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Ingresa tu contraseña',
            },
          ]}
        >
          <FloatLabel label="Escribe tu contraseña">
            <InputPassword size="large" onChange={handleFormChange('password')} />
          </FloatLabel>
        </Form.Item>
        <div className="auth-forgot-container dummy">
          <a>Olvidé mi correo electrónico</a>
          <a>Olvidé mi contraseña</a>
        </div>
      </Form>
      <Button type="primary" size="large" onClick={form.submit}>
        Iniciar Sesión
      </Button>
    </div>
  );
}

LogIn.propTypes = {
  closeModal: PropTypes.func,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PublicationList from '../../RenderViews/PublicationList';

import { parsePublicationAPI } from '../services';
import './style.less';
import { fetchDuplicatedPublications } from './backend';

function DuplicatedPublications({ publicationData, userId, openFullScreen = false }) {
  const [duplicatedPublicationsData, setDuplicatedPublicationsData] = useState([]);

  useEffect(() => {
    const publicationId = publicationData ? publicationData.id : null;
    if (publicationId) {
      fetchDuplicatedPublications({ publicationId, userId }).then((publications) => {
        setDuplicatedPublicationsData(
          publications.map((publication) => parsePublicationAPI(publication)),
        );
      });
    }
  }, [publicationData]);

  const shouldNotRenderView =
    !publicationData ||
    !publicationData.id ||
    !duplicatedPublicationsData ||
    (duplicatedPublicationsData && duplicatedPublicationsData.length == 0);
  if (shouldNotRenderView) return <></>;

  return (
    <>
      <div className="componentDivider" />
      <PublicationList
        publications={duplicatedPublicationsData}
        referencePublication={publicationData}
        openFullScreen={openFullScreen}
        title={'Otras publicaciones de la misma propiedad'}
        alternative={'No hay otras publicaciones de la misma propiedad'}
        currentUserId={userId}
        mode={'row'}
      ></PublicationList>
    </>
  );
}

DuplicatedPublications.propTypes = {
  openFullScreen: PropTypes.bool,
};

export default DuplicatedPublications;

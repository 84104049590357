// get date 3 month ago from today as string YYYY-MM-DD
let today = new Date();

export const propertyTypeFilterValues = [
  { label: 'Casa', value: 'house' },
  { label: 'Departamento', value: 'apartment' },
];

export const operationTypeFilterValues = [
  { label: 'Venta', value: 'sell' },
  { label: 'Arriendo', value: 'rental' },
];

export const disponibilityFilterValues = [
  { label: 'Disponibles', value: 'active' },
  { label: 'Todas', value: 'all' },
];

export const availabilityIncludeUnpublished = [
  { label: 'no', value: 'active' },
  { label: 'sí', value: 'all' },
];

export const priceType = [
  { label: 'UF', value: 'UF' },
  { label: 'CLP', value: 'CLP' },
];

export const conditionType = [
  { label: 'Usadas', value: 'used' },
  { label: 'Nuevas', value: 'new' },
];

export const operationType = [
  { label: 'Venta', value: 'sell' },
  { label: 'Arriendo', value: 'rental' },
];

export const publishedDateFilterValues = [
  {
    label: 'Últimos 3 meses',
    value: new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
      .toISOString()
      .split('T')[0],
  },
  {
    label: 'Últimos 6 meses',
    value: new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
      .toISOString()
      .split('T')[0],
  },
  {
    label: 'Últimos 12 meses',
    value: new Date(today.getFullYear(), today.getMonth() - 12, today.getDate())
      .toISOString()
      .split('T')[0],
  },
  {
    label: 'Todas',
    value: null,
  },
];

export const operationFormatter = { sell: 'venta', rental: 'arriendo' };

export const propertyTypeFormatter = { house: 'Casa', apartment: 'Departamento' };

export const sortFilterValues = [
  [
    {
      label: 'Más reciente',
      value: '-published_date',
    },
    {
      label: 'Más antiguo',
      value: '+published_date',
    },
  ],
  [
    {
      label: 'Menor precio',
      value: '+published_price',
    },
    {
      label: 'Mayor precio',
      value: '-published_price',
    },
  ],
  [
    {
      label: 'Menor área útil',
      value: '+covered_area',
    },
    {
      label: 'Mayor área útil',
      value: '-covered_area',
    },
  ],
  [
    {
      label: 'Menor área total',
      value: '+total_area',
    },
    {
      label: 'Mayor área total',
      value: '-total_area',
    },
  ],
];

export const sortFilterLabels = {
  '-published_date': 'Más reciente',
  '+published_date': 'Más antiguo',
  '+published_price': 'Menor precio',
  '-published_price': 'Mayor precio',
  '+covered_area': 'Menor área útil',
  '-covered_area': 'Mayor área útil',
  '+total_area': 'Menor área total',
  '-total_area': 'Mayor área total',
};

export const movilActionValues = [
  {
    label: 'Guardar en colección',
    value: 'save_property',
  },
  {
    label: 'Agregar a favoritos',
    value: 'fav_property',
  },
  // {
  //   label: 'Descargar Exel',
  //   value: 'Download_porps',
  // },
  {
    label: 'Deseleccionar',
    value: 'errase_selection',
  },
];

export const movilActionValuesAdmin = [
  {
    label: 'Guardar en colección',
    value: 'save_property',
  },
  {
    label: 'Agregar a favoritos',
    value: 'fav_property',
  },
  // {
  //   label: 'Descargar Exel',
  //   value: 'Download_porps',
  // },
  {
    label: 'Ocultar',
    value: 'hide_properties',
  },
  {
    label: 'Mostrar',
    value: 'show_properties',
  },
  {
    label: 'Deseleccionar',
    value: 'errase_selection',
  },
];

export const filtersInit = (query) => ({
  propertyTypeFilter: query.get('propertyTypeParam') ? query.get('propertyTypeParam') : 'house',
  operationTypeFilter: query.get('propertyOperationType')
    ? query.get('propertyOperationType')
    : 'sell',
  conditionFilter: query.get('conditionType') ? query.get('conditionType') : 'used',
  publishedPriceFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  roomsFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  bathroomsFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  coveredAreaFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  totalAreaFilter: {
    minValue: undefined,
    maxValue: undefined,
  },
  publishedDateFilter: null,
  sortFilter: '-published_date',
  disponibilityFilter: 'active',
});

export const convertNumber = (number) => {
  return number ? Math.min(Number(number.toFixed(3)), 999999).toLocaleString('de-DE') : 0;
};

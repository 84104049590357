import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $firstName: NonEmptyString!
    $lastName: NonEmptyString!
    $password: NonEmptyString!
    $passwordConfirmation: NonEmptyString!
    $email: EmailAddress!
    $phone: NonEmptyString!
    $roleId: Int!
    $planId: Int!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      password: $password
      planId: $planId
      passwordConfirmation: $passwordConfirmation
      email: $email
      phone: $phone
      roleId: $roleId
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const DISABLE_USER = gql`
  mutation disableUser($userId: Int!) {
    disableUser(userId: $userId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ENABLE_USER = gql`
  mutation enableUser($userId: Int!) {
    enableUser(userId: $userId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $roleId: Int) {
    updateUser(userId: $userId, roleId: $roleId) {
      id
      firstName
      lastName
      email
      role {
        id
        name
      }
    }
  }
`;

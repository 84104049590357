import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { userTokenVar, restoreUserToken } from '../../graphql/cache';
import { GET_APPRAISALS } from '../../graphql/queries/appraisal';
import { GET_KEY_FOR_SHARE_APPRAISAL, GET_REPORT_URL } from '../../graphql/mutations/appraisals';
import Loading from '../Loading';
import AppraisalPropertyInfo from './AppraisalComponents/AppraisalPropertyInfo';
import TassAppPropertyEstimations from './AppraisalComponents/AppraisalTassappEstimations';
import AppraisalInvestmentSimulator from './AppraisalComponents/AppriasalInvestmentSimulator';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { IconDownload, IconLink } from '../../assets';
import { formatGetAppraisalsForAppraisalIndex } from '../_gqlFormatters/appraisal';
import './style.less';
import AppraisalSimilarProperties from './AppraisalComponents/AppraisalSimilarProperties';

function Appraisal({ location, match }) {
  const userToken = useReactiveVar(userTokenVar);
  useEffect(() => {
    restoreUserToken();
  }, []);

  let params = new URLSearchParams(location.search);

  const shareToken = params.get('shareToken');
  const appraisalId = parseInt(match.params.id, 10);

  return userToken || shareToken ? (
    <AppraisalView appraisalId={appraisalId} shareToken={shareToken} />
  ) : (
    <></>
  );
}

Appraisal.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }),
  location: PropTypes.shape({ search: PropTypes.string, pathname: PropTypes.string }),
};

function AppraisalView({ appraisalId, shareToken }) {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [appraisal, setAppraisal] = useState();

  const [createdPDF, { loading: pdfLoading }] = useMutation(GET_REPORT_URL, {
    onCompleted: (data) => {
      window.open(data.getReportUrl);
    },
  });

  const [createShareLink, { data: isLink, loading: loadingShareLink }] = useMutation(
    GET_KEY_FOR_SHARE_APPRAISAL,
    {
      onCompleted: (data) => {
        navigator.clipboard.writeText(
          `${window.location.href}?shareToken=${data.shareValorization}`,
        );
      },
    },
  );

  useQuery(GET_APPRAISALS, {
    variables: { appraisalId, shareToken },
    onCompleted: (data) => {
      setAppraisal(formatGetAppraisalsForAppraisalIndex(data.getValorization));
    },
  });

  const copyLink = () => {
    if (shareToken) {
      navigator.clipboard.writeText(window.location.href);
    } else {
      createShareLink({
        variables: {
          id: appraisalId,
        },
      });
    }
  };

  if (!appraisal) return <Loading />;

  return (
    <div className="appraisalContainer">
      <div className="appraisalTitle">
        <h2>Detalle de Valorización</h2>
        <h3>{appraisal.address}</h3>
        <div className="appraisalButtonActions">
          <Button
            type="primary"
            icon={<Icon component={IconDownload} />}
            loading={pdfLoading}
            onClick={() =>
              createdPDF({
                variables: {
                  valorizationId: appraisalId,
                  shareToken: shareToken,
                },
              })
            }
          >
            {isDesktop ? 'Descargar PDF' : 'Descargar'}
          </Button>
          <Button
            type="primary"
            icon={<Icon component={IconLink} />}
            loading={loadingShareLink}
            onClick={() => copyLink()}
          >
            {isDesktop
              ? isLink && !loadingShareLink
                ? 'Enlace copiado!'
                : 'Copiar Enlace'
              : isLink && !loadingShareLink
              ? 'Copiado!'
              : 'Copiar'}
          </Button>
        </div>
      </div>
      <div className="appraisalInfo">
        <AppraisalPropertyInfo appraisalData={appraisal} />
        <TassAppPropertyEstimations appraisalData={appraisal} />
      </div>
      <div className="inversion">
        <AppraisalInvestmentSimulator appraisalData={appraisal} />
      </div>
      <div className="appraisalSimilarProperties">
        <AppraisalSimilarProperties appraisalData={appraisal}></AppraisalSimilarProperties>
      </div>
    </div>
  );
}

AppraisalView.propTypes = {
  appraisalId: PropTypes.number.isRequired,
  shareToken: PropTypes.string,
};

export default Appraisal;

import { gql } from '@apollo/client';

export const CREATE_APPAISAL = gql`
  mutation createValorization($createValorizationInput: ValorizationInput!) {
    createValorization(input: $createValorizationInput) {
      id
    }
  }
`;

export const CREATE_MASSIVE_APPRAISAL = gql`
  mutation createMassiveValorization($input: [ValorizationInput]!, $name: NonEmptyString) {
    createMassiveValorization(input: $input, name: $name) {
      id
    }
  }
`;

export const GET_KEY_FOR_SHARE_APPRAISAL = gql`
  mutation share($id: Int!) {
    shareValorization(id: $id)
  }
`;

export const GET_REPORT_URL = gql`
  mutation getReportUrl($valorizationId: Int!, $shareToken: String) {
    getReportUrl(id: $valorizationId, shareToken: $shareToken)
  }
`;

export const UPDATE_APPRAISAL_QUALITY = gql`
  mutation updateAppraisalAssetQuality($appraisalId: Int!, $quality: Int!) {
    updateAppraisalAssetQuality(id: $appraisalId, quality: $quality) {
      id
      quality
    }
  }
`;

export const DELETE_APPRAISAL = gql`
  mutation deleteValorization($appraisalId: Int!) {
    deleteValorization(id: $appraisalId)
  }
`;

export const DELETE_MASSIVE_APPRAISAL = gql`
  mutation deleteMassiveValorizations($massiveAppraisals: [Int]) {
    deleteMassiveValorizations(ids: $massiveAppraisals)
  }
`;

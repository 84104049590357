import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import PublicationHistory from '../../RenderViews/PublicationHistory';

function PropertyHistory({ publicationData }) {
  if (!publicationData)
    return (
      <div className="componentTitle">
        <h1>Historial de publicación</h1>
        <Spin />
      </div>
    );

  return (
    <>
      <div className="componentDivider" />
      <PublicationHistory
        daysOnMarket={publicationData.daysOnMarket}
        timeSeries={publicationData.pricingHistory}
      />
    </>
  );
}

PropertyHistory.propTypes = {
  //propertyId: PropTypes.number.isRequired,
};

export default PropertyHistory;
//

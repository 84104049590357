import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Table, Switch, Select, Input, DatePicker, Button } from 'antd';
import Icon from '@ant-design/icons';

import './style.less';
import moment from 'moment';
import 'moment/locale/es';
import { GET_USERS, GET_ROLES } from '../../graphql/queries';
import { iconPlusBlack, IconSearchWhite } from '../../assets';
import RegisterUser from '../Modals/RegisterUser';
import { UPDATE_USER, ENABLE_USER, DISABLE_USER } from '../../graphql/mutations/users';

const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function UsersManagement() {
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [textSearchValue, setTextSearchValue] = useState();
  const [roleSearchValue, setRoleSearchValue] = useState(undefined);
  const [toDateSearchValue, setToDateSearchalue] = useState(null);
  const [fromDateSearchValue, setFromDateSearchValue] = useState(null);
  const history = useHistory();

  const [getUsers, { data, loading }] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      getUsers({
        variables: {
          textSearch: textSearchValue ? textSearchValue : undefined,
          roleId: roleSearchValue ? parseInt(roleSearchValue, 10) : undefined,
          fromDate: fromDateSearchValue ? fromDateSearchValue : undefined,
          toDate: toDateSearchValue ? toDateSearchValue : undefined,
        },
      });
    },
  });

  const [enableUser] = useMutation(ENABLE_USER, {
    onCompleted: () => {
      getUsers({
        variables: {
          textSearch: textSearchValue ? textSearchValue : undefined,
          roleId: roleSearchValue ? parseInt(roleSearchValue, 10) : undefined,
          fromDate: fromDateSearchValue ? fromDateSearchValue : undefined,
          toDate: toDateSearchValue ? toDateSearchValue : undefined,
        },
      });
    },
  });

  const [disableUser] = useMutation(DISABLE_USER, {
    onCompleted: () => {
      getUsers({
        variables: {
          textSearch: textSearchValue ? textSearchValue : undefined,
          roleId: roleSearchValue ? parseInt(roleSearchValue, 10) : undefined,
          fromDate: fromDateSearchValue ? fromDateSearchValue : undefined,
          toDate: toDateSearchValue ? toDateSearchValue : undefined,
        },
      });
    },
  });

  const { data: roles, loading: rolesLoading } = useQuery(GET_ROLES, {
    variables: { active: true },
  });

  const onClickRow = (record) => {
    return {
      onClick: () => history.push(`/users/${record.id}/profile`),
    };
  };

  useEffect(() => {
    getUsers();
  }, [registerModalVisible]);

  if (rolesLoading) {
    return <div></div>;
  } else if (roles) {
    return (
      <>
        <RegisterUser
          visible={registerModalVisible}
          onCancel={setRegisterModalVisible}
          roles={roles}
        ></RegisterUser>
        <div className="userManagementTable">
          <div className="userManagementFilterHeaders">
            <Input
              placeholder="Búsqueda de usuario"
              style={{ width: '25%' }}
              value={textSearchValue}
              onChange={(x) => setTextSearchValue(x.target.value)}
              addonBefore={<Icon component={IconSearchWhite} />}
              onPressEnter={() => {
                getUsers({
                  variables: {
                    textSearch: textSearchValue ? textSearchValue : undefined,
                    roleId: roleSearchValue ? parseInt(roleSearchValue, 10) : undefined,
                    fromDate: fromDateSearchValue ? fromDateSearchValue : undefined,
                    toDate: toDateSearchValue ? toDateSearchValue : undefined,
                  },
                });
              }}
            ></Input>
            <div className="userRoleSelect">
              <Select
                placeholder="Todos los usuarios"
                dropdownStyle={{ minWidth: 150 }}
                style={{ width: 250 }}
                value={roleSearchValue}
                onChange={(x) => {
                  setRoleSearchValue(x);
                  getUsers({
                    variables: {
                      textSearch: textSearchValue ? textSearchValue : undefined,
                      roleId: x ? parseInt(x, 10) : undefined,
                      fromDate: fromDateSearchValue ? fromDateSearchValue : undefined,
                      toDate: toDateSearchValue ? toDateSearchValue : undefined,
                    },
                  });
                }}
              >
                <Option value={undefined}>Todos los Usuarios</Option>
                {roles.getRoles.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.spanishName}
                  </Option>
                ))}
              </Select>
              <RangePicker
                allowEmpty={[true, true]}
                style={{ maxWidth: 250 }}
                onChange={(x) => {
                  setFromDateSearchValue(x && x[0] ? x[0].toDate().toISOString() : undefined);
                  setToDateSearchalue(x && x[1] ? x[1].toDate().toISOString() : undefined);
                }}
                value={[
                  fromDateSearchValue ? moment(fromDateSearchValue) : undefined,
                  toDateSearchValue ? moment(toDateSearchValue) : undefined,
                ]}
                onOpenChange={(x) => {
                  if (!x) {
                    getUsers({
                      variables: {
                        textSearch: textSearchValue ? textSearchValue : undefined,
                        roleId: roleSearchValue ? parseInt(roleSearchValue, 10) : undefined,
                        fromDate: fromDateSearchValue ? fromDateSearchValue : undefined,
                        toDate: toDateSearchValue ? toDateSearchValue : undefined,
                      },
                    });
                  }
                }}
                onCalendarChange={(x) => {
                  if (!x) {
                    setFromDateSearchValue(undefined);
                    setToDateSearchalue(undefined);
                    getUsers({
                      variables: {
                        textSearch: textSearchValue ? textSearchValue : undefined,
                        roleId: roleSearchValue ? parseInt(roleSearchValue, 10) : undefined,
                      },
                    });
                  }
                }}
              ></RangePicker>
              <Button onClick={() => setRegisterModalVisible(true)}>
                <img src={iconPlusBlack} />
              </Button>
            </div>
          </div>
          {loading || data ? (
            <Table
              dataSource={data ? data.getUsers : undefined}
              pagination={{ position: ['bottomRight'], pageSize: 9 }}
              loading={loading}
              rowKey="id"
              rowClassName={(record) => (record.active ? 'activeRow' : 'disabledRow')}
              onRow={onClickRow}
            >
              <Column
                className="userManagementTableColumn"
                align="center"
                title="ID Usuario"
                dataIndex="id"
                key="id"
              />
              <Column
                className="userManagementTableColumn"
                title="Mail Usuario"
                dataIndex="email"
                key="email"
              />
              <Column
                className="userManagementTableColumn"
                title="Nombre Usuario"
                key="name"
                render={(data) => <div>{`${data.firstName} ${data.lastName}`}</div>}
              />
              <Column
                className="userManagementTableColumn"
                title="Celular Usuario"
                dataIndex="phone"
                key="phone"
              />
              <Column
                className="userManagementTableColumn"
                title="Perfil Usuario"
                key="role"
                render={(data) => (
                  <Select
                    bordered={false}
                    value={`${data.role.id}`}
                    dropdownStyle={{ minWidth: 150 }}
                    style={{ minWidth: '80%' }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(value) => {
                      updateUser({
                        variables: {
                          userId: parseInt(data.id, 10),
                          roleId: parseInt(value, 10),
                        },
                      });
                    }}
                  >
                    {roles.getRoles.map((role) => (
                      <Option key={role.id} value={role.id}>
                        {role.spanishName}
                      </Option>
                    ))}
                  </Select>
                )}
              />
              <Column
                className="userManagementTableColumn"
                title="Fecha Ingreso"
                key="createdAt"
                dataIndex="createdAt"
                align="center"
                render={(x) => <div>{moment(new Date(x)).format('DD/MM/YYYY')}</div>}
              />
              <Column
                className="userManagementTableColumn"
                title="Estado"
                key="state"
                align="center"
                render={(data) => (
                  <Switch
                    size="medium"
                    checked={data.active}
                    onChange={(checked, event) => {
                      if (checked) {
                        enableUser({
                          variables: { userId: parseInt(data.id, 10) },
                        });
                      } else {
                        disableUser({
                          variables: { userId: parseInt(data.id, 10) },
                        });
                      }
                      event.stopPropagation();
                    }}
                  ></Switch>
                )}
              />
            </Table>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import grantAccess from '../../roleAccess';
import Loading from '../Loading';
import { AccessDenied } from '../Access';
import { useUserAuth } from '../../context/AuthContext';
import { getUser } from '../../actions/users';

const PrivateRoute = ({ component: Component, permissions, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState('free');
  const { user: userAuth } = useUserAuth();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser({ uid: userAuth.uid });
      console.log(user);
      if (user.admin) {
        setUserRole('admin');
      }
      if (user.pro) {
        setUserRole('pro');
      }
    }

    fetchUser();
  }, []);
  // Without this the user will fall into /404 on every F5 b
  // because userToken variable isn't getting instantly.
  useEffect(async () => {
    new Promise(() => setTimeout(() => setLoading(false), 50));
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            grantAccess(userRole, permissions) ? <Component {...props} /> : <AccessDenied />
          }
        />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  permissions: PropTypes.string,
};

export default PrivateRoute;

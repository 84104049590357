import React from 'react';
import PropTypes from 'prop-types';
import { Area } from '@ant-design/charts';
import './style.less';

const config = {
  xField: 'time',
  yField: 'value',
  height: 300,
  autoFit: 'width',
  xAxis: {
    type: 'time',
    tickCount: 5,
    title: {},
  },
  yAxis: {
    title: {},
  },
  areaStyle: () => {
    return {
      fill: 'l(270) 0:#ffffff 1:#3e7bfa',
    };
  },
  meta: {
    value: {
      alias: 'Valor',
    },
    time: {
      alias: 'Fecha',
    },
  },
  point: {
    size: 3,
    shape: 'circle',
    style: {
      fill: 'white',
      stroke: '#7ec2f3',
      lineWidth: 2,
    },
  },
};

function PublicationHistory({ timeSeries, daysOnMarket }) {
  return (
    <div className="propertyHistory">
      <div className="componentTitle">
        <h1>Historial de precio publicado</h1>
      </div>
      <div>
        <Area {...config} data={timeSeries} />
      </div>
      <div className="propertyHistoryData">
        <div className="propertySellHistory">
          <div className="propertySellHistoryInner">{'Total días publicado:'}</div>
          <div className="propertySellHistoryInnerValue">{daysOnMarket}</div>
        </div>
        {/* 
        <div className="propertySellHistory">
          <div className="propertySellHistoryInner">
            Promedio de tiempo que llevan propiedades similares en el sector
          </div>
          <div className="propertySellHistoryInnerValue">{timeInMarket.similarTimeInMarket}</div>
        </div>
        */}
      </div>
    </div>
  );
}

PublicationHistory.propTypes = {
  timeSeries: PropTypes.any,
  daysOnMarket: PropTypes.any,
};

export default PublicationHistory;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cover from './PropertyPreviewCardCover';
import { Tooltip } from 'antd';
import { IconSquareMeters, IconBedroom, IconBathroom } from '../../../assets';
import Icon from '@ant-design/icons';

function PropertyPreviewCard({
  property,
  layout,
  onClick,
  onMouseEnter,
  onMouseLeave,
  handleSelect,
  handleLike,
  isFavorite,
  isSelected,
}) {
  // like effect
  const [like, setLike] = useState(isFavorite);
  useEffect(() => {
    setLike(isFavorite);
  }, [isFavorite]);

  let tooltipTitle = ``;
  tooltipTitle +=
    property && property.bedRooms
      ? `${property && property.bedRooms} ${
          property && property.bedRooms > 1 ? 'dormitorios' : 'dormitorio'
        }`
      : '';
  tooltipTitle +=
    property && property.fullBathrooms
      ? `, ${property && property.fullBathrooms} ${
          property && property.fullBathrooms > 1 ? 'baños' : 'baño'
        }`
      : '';
  tooltipTitle +=
    property && property.coveredArea
      ? `, ${property && property.coveredArea.toFixed(2)} m² útiles`
      : '';
  tooltipTitle +=
    property && property.totalArea
      ? `, ${property && property.totalArea.toFixed(2)} m² totales`
      : '';
  return (
    <div
      className={`propertyPreviewCardContainer ${layout} ${property.isHidden ? ' hidden' : ''}`}
      onClick={(e) => {
        // TODO: refactor, this part is very unreliable to depend on classNames string match
        if (e.target.className.includes === undefined) {
          // heart behaviour
          return;
        }
        if (
          e.currentTarget != e.target &&
          e.target.className.includes('ant-checkbox-inner') |
            e.target.className.includes('ant-checkbox-wrapper')
        ) {
          // if we selected a checkbox or a checkbox wrapper we don't trigger the click event
          return;
        }
        onClick(e);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Cover
        property={property}
        layout={layout}
        handleSelect={handleSelect}
        handleLike={() => {
          // this section only changes the UI of the heart button
          if (like) {
            setLike(false);
          } else {
            setLike(true);
          }
          // this function actually change the value in the db of the like state
          handleLike();
        }}
        isSelected={isSelected}
        isFavorite={like}
      ></Cover>
      <div className="cardDataContainer">
        <div className="cardPublicationData">
          <span className="cardPropertyType">{property.typeText}</span>
          <span className="cardPropertyDate">{property.dateText}</span>
        </div>

        <div className="cardPriceSection">
          <span className="cardPrice">{property.priceText}</span>
        </div>

        <p className="cardAddress">{property.title == null ? property.address : property.title}</p>

        <Tooltip placement="top" title={tooltipTitle}>
          <div className="cardFooter">
            <span className="cardFooterData">
              {<Icon component={IconBedroom} />}
              {property.bedRooms}
            </span>
            <span className="cardFooterData">
              {<Icon component={IconBathroom} />}
              {property.fullBathrooms}
            </span>
            <span className="cardFooterData dataArea">
              {<Icon component={IconSquareMeters} />}
              {`${(property && property.coveredArea.toFixed(0)) || '- '} m², ${
                (property && property.totalArea.toFixed(0)) || '- '
              } m²`}
            </span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

PropertyPreviewCard.propTypes = {
  property: PropTypes.shape({
    typeText: PropTypes.string,
    dateText: PropTypes.string,
    priceText: PropTypes.string,
    operation: PropTypes.string,
    address: PropTypes.string,
    title: PropTypes.string,
    bedRooms: PropTypes.number,
    fullBathrooms: PropTypes.number,
    coveredArea: PropTypes.number,
    totalArea: PropTypes.number,
    pictures: PropTypes.arrayOf(PropTypes.string),
    isHidden: PropTypes.bool,
  }).isRequired,
  layout: PropTypes.oneOf(['vertical', 'horizontal', 'simple']),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  handleSelect: PropTypes.func,
  isSelected: PropTypes.bool,
};

PropertyPreviewCard.defaultProps = {
  layout: 'vertical',
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  handleSelect: () => {},
  isSelected: false,
};

export default PropertyPreviewCard;

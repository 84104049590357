import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import { getDistanceFromLatLonInKm } from './services';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
  { label: 'Propiedad', value: 'row', style: { alignment: { wrapText: true } } },
  { label: 'ID', value: 'id', style: { alignment: { wrapText: true } } },
  { label: 'Fuente', value: 'source' },
  { label: 'Código', value: 'externalSourceId' },
  { label: 'Fecha', value: 'createdTime', style: { alignment: { wrapText: true } } },
  { label: 'Operación', value: (x) => (x.operation === 'sell' ? 'Venta' : 'Arriendo') },
  { label: 'Tipo Propiedad', value: (x) => (x.propertyType === 'house' ? 'Casa' : 'Departamento') },
  { label: 'Dirección', value: 'address' },
  { label: 'Precio publicado', value: 'publishedPriceAmount' },
  { label: 'Moneda', value: 'unit' },
  { label: 'Área Util', value: 'coveredArea' },
  { label: 'Área Total', value: 'totalArea' },
  { label: 'Dormitorios', value: 'bedRooms' },
  { label: 'Baños', value: 'fullBathrooms' },
  // { label: 'Estacionamientos', value: 'parkingLots' },
  { label: 'Bodegas', value: 'warehouses' },
  { label: 'Año Construccion', value: 'constructionYear' },
  { label: 'Latitud', value: 'latitude' },
  { label: 'Longitud', value: 'longitude' },
  { label: 'Comentario', value: 'description' },
  { label: 'Url', value: 'externalSourceUrl' },
  { label: 'Status', value: (x) => (x.isActive ? 'Disponible' : 'No disponible') },
  { label: 'Fecha Inical Publicacion', value: 'firstTimeFound' },
  { label: 'Gasto Comun', value: 'maintenanceCost' },
  {
    label: 'Distancia a dirección (m)',
    value: (x) => (x.distance !== null ? Math.round(x.distance * 1000, 0) : 'Faltan datos'),
  },
  { label: 'Foto', value: (x) => (x.pictures.length > 0 ? x.pictures[0] : '') },
  { label: 'Link', value: (x) => 'https://tassapp.com/properties/' + x.id + '/full' },
];

function PropertiesExcelDownload({ filename, sheetName, render, data, referenceLocation = null }) {
  let rowIndex = 1;
  return (
    <ExcelFile filename={filename} element={render ? render : null}>
      {data && columns ? (
        <ExcelSheet
          data={data.map((x) => ({
            ...x,
            row: rowIndex++,
            distance: referenceLocation
              ? getDistanceFromLatLonInKm(
                  referenceLocation.latitude,
                  referenceLocation.longitude,
                  x.latitude,
                  x.longitude,
                )
              : null,
          }))}
          name={sheetName}
        >
          {columns.map((column) => (
            <ExcelColumn key={column.label} label={column.label} value={column.value} />
          ))}
        </ExcelSheet>
      ) : (
        <></>
      )}
    </ExcelFile>
  );
}

PropertiesExcelDownload.propTypes = {
  filename: PropTypes.string,
  sheetName: PropTypes.string,
  render: PropTypes.element.isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  referenceLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

PropertiesExcelDownload.defaultProps = {
  data: [],
  filename: 'TassApp-DownloadFile',
  sheetName: 'Sheet 1',
};

export default PropertiesExcelDownload;

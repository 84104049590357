export const sectorActionValues = [
  {
    label: 'Región',
    value: 'Region',
  },
  {
    label: 'Comuna',
    value: 'Municipality',
  },
  {
    label: 'Distrito',
    value: 'District',
  },
  {
    label: 'Barrio',
    value: 'ZoneOrLocality',
  },
];

export const propertyTipeActionValues = [
  {
    label: 'Casa',
    value: 'house',
  },
  {
    label: 'Departamento',
    value: 'apartment',
  },
];

export const regionIdValues = [
  {
    label: 'Arica y Parinacota',
    value: 1,
    short_code: 'CL-AP',
  },
  {
    label: 'Tarapacá',
    value: 2,
    short_code: 'CL-TA',
  },
  {
    label: 'Antofagasta',
    value: 3,
    short_code: 'CL-AN',
  },
  {
    label: 'Atacama',
    value: 4,
    short_code: 'CL-AT',
  },
  {
    label: 'Coquimbo',
    value: 5,
    short_code: 'CL-CO',
  },
  {
    label: 'Valparaíso',
    value: 6,
    short_code: 'CL-VS',
  },
  {
    label: 'Metropolitana de Santiago',
    value: 7,
    short_code: 'CL-RM',
  },
  {
    label: 'Libertador General Bernardo O’Higgins',
    value: 8,
    short_code: 'CL-LI',
  },
  {
    label: 'Maule',
    value: 9,
    short_code: 'CL-ML',
  },
  {
    label: 'Ñuble',
    value: 10,
    short_code: 'CL-NB',
  },
  {
    label: 'Biobío',
    value: 11,
    short_code: 'CL-BI',
  },
  {
    label: 'La Araucanía',
    value: 12,
    short_code: 'CL-AR',
  },
  {
    label: 'Los Ríos',
    value: 13,
    short_code: 'CL-LR',
  },
  {
    label: 'Los Lagos',
    value: 14,
    short_code: 'CL-LL',
  },
  {
    label: 'Aysén del General Carlos Ibáñez del Campo',
    value: 15,
    short_code: 'CL-AI',
  },
  {
    label: 'Magallanes y la Antártica Chilena',
    value: 16,
    short_code: 'CL-MA',
  },
];

/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { Button } from 'antd';
import { Modal, Input } from 'antd';
import Icon from '@ant-design/icons';
import { IconPlusNew, IconReload } from '../../assets';
import backend from '../../backend';
import { fakeCollections } from '../../fakeData';
import Loading from '../Loading';
import './style.less';

const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};

export default function AddCollectionModal(props) {
  const {
    collectionModalIsVisible,
    selectedProperties,
    setCollectionModalIsVisible,
    currentUser,
  } = props;

  const [addedCollectionTooltip, setAddedCollectionTooltip] = useState(false);
  const [newCollectionModalIsVisible, setNewCollectionModalIsVisible] = useState(false);
  const [loadingCollections, setLoadingCollections] = useState(true);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [userCollections, setUserCollections] = useState([]);
  const undoAdd = useRef(true);

  /*
  const [getUserCollections, { loading: loadingCollections }] = useLazyQuery(GET_USER_COLLECTIONS, {
    notifyOnNetworkStatusChange: true,
    variables: { userId: currentUser },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUserCollections(data.getUserCollections);
    },
  });
  */

  const getUserCollections = async function ({ userId }) {
    const response = await backend.post(
      '/real_estate_market/fetch_publications_in_user_collections/',
      {
        user_id: userId,
        fields: {
          id: true,
          last_published_price: true,
          operation: true,
          title: true,
          address_line: true,
          created_time: true,
          first_time_found: true,
          last_time_found: true,
          pictures: true,
        },
      },
    );
    return response.data.response;
  };
  const addPubllicationsToCollection = async function ({ userId, publicationIds, collectionId }) {
    const response = await backend.post('/real_estate_market/add_publications_to_collection/', {
      user_id: userId,
      publication_ids: publicationIds,
      collection_id: collectionId,
      fields: {
        id: true,
        last_published_price: true,
        operation: true,
        title: true,
        address_line: true,
        created_time: true,
        first_time_found: true,
        last_time_found: true,
        pictures: true,
      },
    });
    return response.data.response;
  };
  const createPubllicationsCollection = async function ({ userId, publicationIds, name }) {
    const response = await backend.post(
      '/real_estate_market/create_user_collection_publications/',
      {
        user_id: userId,
        publication_ids: publicationIds,
        name: name,
      },
    );
    return response.data.response;
  };

  useEffect(() => {
    if (currentUser) {
      getUserCollections({ userId: currentUser }).then((collections) => {
        setUserCollections(
          collections.map((collection) => ({
            id: collection.id,
            name: collection.name,
            createdAt: null, // we don't have this info
            elementCount: collection.publications.length,
            // check if real_estate_publications is not empty, if the first publication picture is not empty and then use it
            picture: collection.picture,
            handleClick: () => window.location.assign(`/user/collection/${collection.id}`),
          })),
        );
        setLoadingCollections(false);
      });
    }
  }, [currentUser, collectionModalIsVisible]);

  const showNewCollectionModal = () => {
    setNewCollectionModalIsVisible(true);
  };

  const handleInputChange = (e) => {
    setNewCollectionName(e.target.value);
  };
  const undoAddCollections = () => {
    setAddedCollectionTooltip({ ...addedCollectionTooltip, show: false });
    undoAdd.current = false;
  };

  return (
    <div>
      <Modal
        visible={collectionModalIsVisible && selectedProperties.size > 0}
        onCancel={() => setCollectionModalIsVisible(false)}
        loading={loadingCollections}
        title={[
          <div key="1" className="text">
            Guardar en colección:{' '}
          </div>,
          <div key="2" className="plus-button">
            <Button
              icon={<Icon component={IconPlusNew} />}
              type="link"
              onClick={() => {
                setCollectionModalIsVisible(false);
                showNewCollectionModal();
              }}
            />
          </div>,
        ]}
        footer={[
          <Button
            key="back"
            className="footer-button"
            onClick={() => setCollectionModalIsVisible(false)}
          >
            Cancelar
          </Button>,
        ]}
        closable={false}
        className="collection-modal"
        width={785}
        height={338}
        destroyOnClose
        centered
      >
        <div className="row-display modal-scrollable">
          {loadingCollections ? (
            <Loading />
          ) : (
            <>
              {userCollections.map((collection) => (
                <div key={collection.id} className="display-element">
                  <Button
                    className="display-element-img"
                    onClick={() => {
                      setCollectionModalIsVisible(false);
                      setAddedCollectionTooltip({
                        show: true,
                        number: selectedProperties.size,
                        name: collection.name,
                      });
                      //navigate("../success", { replace: true });
                      addPubllicationsToCollection({
                        collectionId: collection.id,
                        publicationIds: Array.from(selectedProperties),
                        userId: currentUser,
                      });
                      collection.handleClick();
                      setTimeout(() => {
                        setAddedCollectionTooltip({ ...addedCollectionTooltip, show: false });
                        if (undoAdd.current) {
                          /*
                          addPublicationsToCollection({
                            variables: {
                              collectionId: collection.id,
                              publicationIds: Array.from(selectedProperties),
                            },
                          });
                        */
                        }
                        undoAdd.current = true;
                      }, 7000);
                    }}
                  >
                    {collection.picture ? (
                      <>
                        <img src={collection.picture} />
                        <p className="collection-name">
                          {collection.name} {collection.elementCount}
                        </p>
                      </>
                    ) : (
                      <p>{collection.name}</p>
                    )}
                  </Button>
                </div>
              ))}
              {
                <div key={-1} className="display-element">
                  <Button
                    className="display-element-img"
                    icon={<Icon component={IconPlusNew} />}
                    onClick={() => {
                      setCollectionModalIsVisible(false);
                      showNewCollectionModal();
                    }}
                  />
                </div>
              }
            </>
          )}
        </div>
      </Modal>

      <Modal
        visible={newCollectionModalIsVisible}
        onCancel={() => setNewCollectionModalIsVisible(false)}
        title={[
          <div key="1" className="text">
            Nueva colección
          </div>,
        ]}
        footer={[
          <div key="1" className="footer-button">
            {newCollectionName === '' ? (
              <Button
                className="footer-button"
                key="back"
                onClick={() => setNewCollectionModalIsVisible(false)}
              >
                Cancelar
              </Button>
            ) : (
              <Button
                className="footer-button"
                key="submit"
                onClick={() => {
                  const name = newCollectionName;
                  setNewCollectionName('');
                  setNewCollectionModalIsVisible(false);
                  setAddedCollectionTooltip({
                    show: true,
                    number: selectedProperties.size,
                    name: name,
                  });
                  createPubllicationsCollection({
                    userId: currentUser,
                    name: name,
                    publicationIds: Array.from(selectedProperties),
                  }).then(({ data }) => {
                    if (data.response) {
                      window.location.assign(`/user/collection/${data.response.id}`);
                    }
                  });
                  setTimeout(() => {
                    setAddedCollectionTooltip({ ...addedCollectionTooltip, show: false });
                    if (undoAdd.current) {
                      /*
                      createNewCollection({
                        variables: {
                          userId: currentUser,
                          name: name,
                          publicationIds: Array.from(selectedProperties),
                        },
                      });
                      */
                    }
                  }, 7000);
                }}
              >
                Listo
              </Button>
            )}
          </div>,
        ]}
        closable={false}
        className="collection-modal"
        width={785}
        height={334}
        destroyOnClose
        centered
      >
        <div className="new-collection-body">
          <div className="display-element">
            <div className="display-element-img">
              <img src={`${fakeCollections[0].img}`} alt="foto" />
            </div>
            <div className="text">
              <Input className="new-input" bordered={false} onChange={handleInputChange} />
            </div>
          </div>
        </div>
      </Modal>

      {addedCollectionTooltip.show && (
        <div className="add-collections-popup">
          <div className="add-popup-text">
            {`${addedCollectionTooltip.number} propiedades han sido guardadas en la colección "${addedCollectionTooltip.name}"`}
          </div>
          <div className="add-popup-undo" onClick={() => undoAddCollections()}>
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </div>
        </div>
      )}
    </div>
  );
}

AddCollectionModal.propTypes = {
  collectionModalIsVisible: PropTypes.bool,
  selectedProperties: PropTypes.object,
  setCollectionModalIsVisible: PropTypes.func,
  currentUser: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
};

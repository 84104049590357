import React from 'react';
import PropTypes from 'prop-types';

import TassAppEstimations from '../../RenderViews/TassAppEstimations';

function TassAppPropertyEstimations({ appraisalData }) {
  return <TassAppEstimations data={appraisalData} />;
}

const auxPricePropType = PropTypes.shape({
  UF: PropTypes.string,
  CLP: PropTypes.string,
});

TassAppPropertyEstimations.propTypes = {
  appraisalData: PropTypes.shape({
    estimatedSellPrice: auxPricePropType,
    estimatedSellPricePerArea: auxPricePropType,
    estimatedSellPriceMin: auxPricePropType,
    estimatedSellPriceMinPerArea: auxPricePropType,
    estimatedSellPriceMax: auxPricePropType,
    estimatedSellPriceMaxPerArea: auxPricePropType,
    estimatedRentPrice: auxPricePropType,
    estimatedRentPricePerArea: auxPricePropType,
    estimatedRentPriceMin: auxPricePropType,
    estimatedRentPriceMinPerArea: auxPricePropType,
    estimatedRentPriceMax: auxPricePropType,
    estimatedRentPriceMaxPerArea: auxPricePropType,
    estimatedYield: PropTypes.number,
    estimatedMinYield: PropTypes.number,
    estimatedMaxYield: PropTypes.number,
  }),
};

export default TassAppPropertyEstimations;

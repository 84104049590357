import { message } from 'antd';
import './style.less';

export const openMessage = (content, duration, key) => {
  message.loading({ content, key, duration });
};

export const updateErrorMessage = (content, duration, key) => {
  message.error({ content, key, duration });
};

export const updateSuccessMessage = (content, duration, key) => {
  message.success({
    content,
    key,
    duration,
  });
};

export const closeMessage = (key) => {
  message.destroy(key);
};

export default function getAlerts(key = 'default') {
  return {
    openMessage: (content = 'Cargando...', duration = 0) => openMessage(content, duration, key),
    closeMessage: () => closeMessage(key),
    updateSuccessMessage: (content = 'Operación completada exitosamente', duration = 4) =>
      updateSuccessMessage(content, duration, key),
    updateErrorMessage: (content = 'Ha ocurrido un error', duration = 2) =>
      updateErrorMessage(content, duration, key),
  };
}

/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Form, Input, Button, Checkbox, Steps } from 'antd';
import validator from 'validator';

import { SIGN_UP } from '../../graphql/mutations/session';
import { userRoleVar } from '../../graphql/cache';
import FloatLabel from './FloatLabel';
import InputPassword from '../Input/Password';
import './style.less';

const { Step } = Steps;

export default function SignUp({ closeModal }) {
  const [firstStepValues, setFirstStepValues] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();

  // Mutations
  const [signup] = useMutation(SIGN_UP, {
    onCompleted: (data) => {
      userRoleVar(data.signUp.role.name);
      closeModal();
    },
    onError: (error) => {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach((err) => {
          err.extensions.params.forEach((x) => {
            form.setFields([
              {
                name: x.name,
                errors: [x.message.es],
              },
            ]);
          });
        });
      }
    },
  });

  // Functions
  const handleStepClick = (step) => {
    step === 0 ? setCurrentStep(0) : form.submit();
  };

  const handleFormChange = (field) => (event) => {
    const { value } = event.target;
    const prevValues = form.getFieldsValue(true);
    form.setFieldsValue({ ...prevValues, [field]: value });
  };

  const handleFormFinish = () => {
    if (currentStep === 0) {
      setFirstStepValues(form.getFieldsValue(true));
      setCurrentStep(1);
    } else {
      signup({
        variables: { ...firstStepValues, ...form.getFieldsValue(true) },
      });
    }
  };

  return (
    <div className="auth-modal-content">
      <h1>¡Regístrate y valoriza propiedades con TassApp!</h1>
      <Steps size="small" current={currentStep} onChange={handleStepClick}>
        <Step title="Primer Paso" />
        <Step title="Segundo Paso" />
      </Steps>
      <Form onFinish={handleFormFinish} className="auth-form" form={form}>
        {currentStep === 0 ? (
          <Fragment>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Ingresa tu nombre.',
                },
              ]}
            >
              <FloatLabel label="Nombres">
                <Input
                  size="large"
                  defaultValue={form.getFieldValue('firstName')}
                  onChange={handleFormChange('firstName')}
                />
              </FloatLabel>
            </Form.Item>

            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Ingresa tu apellido.',
                },
              ]}
            >
              <FloatLabel label="Apellidos">
                <Input
                  size="large"
                  defaultValue={form.getFieldValue('lastName')}
                  onChange={handleFormChange('lastName')}
                />
              </FloatLabel>
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      validator.isMobilePhone(value, 'es-CL', {
                        strictMode: true,
                      })
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        'Ingresa tu número de teléfono móvil en formato +569XXXXXXXX.',
                      );
                    }
                  },
                },
              ]}
            >
              <FloatLabel label="Teléfono móvil ">
                <Input size="large" defaultValue={'+569'} onChange={handleFormChange('phone')} />
              </FloatLabel>
            </Form.Item>
          </Fragment>
        ) : (
          <Fragment>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Ingresa tu email.',
                },
              ]}
            >
              <FloatLabel label="Correo electrónico">
                <Input
                  size="large"
                  defaultValue={form.getFieldValue('email')}
                  onChange={handleFormChange('email')}
                ></Input>
              </FloatLabel>
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
                  message:
                    'Mínimo 8 caracteres incluyendo números, minusculas, mayúsculas y símbolos.',
                },
                {
                  required: true,
                  message: 'Ingresa una contraseña',
                },
              ]}
            >
              <FloatLabel label="Escribe tu contraseña">
                <InputPassword
                  size="large"
                  defaultValue={form.getFieldValue('password')}
                  onChange={handleFormChange('password')}
                />
              </FloatLabel>
            </Form.Item>

            <Form.Item
              name="passwordConfirmation"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Porfavor confirma tu contraseña.',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Las contraseñas no coinciden.');
                  },
                }),
              ]}
            >
              <FloatLabel label="Repetir la contraseña">
                <InputPassword
                  size="large"
                  defaultValue={form.getFieldValue('passwordConfirmation')}
                  onChange={handleFormChange('passwordConfirmation')}
                />
              </FloatLabel>
            </Form.Item>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject('Debes aceptar las condiciones de uso para registrarte.'),
                },
              ]}
            >
              <Checkbox>
                Acepto los <a>términos y condiciones</a> de uso.
              </Checkbox>
            </Form.Item>
          </Fragment>
        )}
      </Form>
      <Button type="primary" size="large" onClick={form.submit}>
        {currentStep < 1 ? 'Siguiente' : '¡Registrarme!'}
      </Button>
    </div>
  );
}

SignUp.propTypes = {
  closeModal: PropTypes.func,
};

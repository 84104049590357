import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import { Upload, Button } from 'antd';
import { iconDropHere, iconFirstStep, iconSecondStep, iconThirdStep } from '../../assets';
import { Row, Col } from 'antd';
import { useMutation } from '@apollo/client';
import { CREATE_MASSIVE_APPRAISAL } from '../../graphql/mutations/appraisals';
import { houseSchema, apartmentSchema } from './schemas';
import './form.less';

const { Dragger } = Upload;

export default function MassiveAppraisalForm() {
  const [housesData, setHousesData] = useState({});
  const [apartmentData, setApartmentData] = useState({});
  const [valorizationCreated, setValorizationCreated] = useState(false);
  const [massiveValorizationButton, setMassiveAppraisalButton] = useState(true);
  const [massiveValorizationDataErrors, setMassiveAppraisalDataErrors] = useState({});
  const [createValorization, { data, loading }] = useMutation(CREATE_MASSIVE_APPRAISAL, {
    onCompleted: () => {
      setValorizationCreated(true);
    },
  });

  function onMassiveAppraisal() {
    var housesDataArray = [];
    Object.keys(housesData).map(function (key) {
      housesDataArray = [...housesData[key], ...housesDataArray];
    });
    var apartmentDataArray = [];
    Object.keys(apartmentData).map(function (key) {
      apartmentDataArray = [...apartmentData[key], ...apartmentDataArray];
    });
    if (housesDataArray.length > 0 && apartmentDataArray.length > 0) {
      createValorization({
        variables: {
          input: housesDataArray.concat(apartmentDataArray),
        },
      });
    } else if (housesDataArray.length > 0) {
      createValorization({
        variables: {
          input: housesDataArray,
        },
      });
    } else if (apartmentDataArray.length > 0) {
      createValorization({
        variables: {
          input: apartmentDataArray,
        },
      });
    }
  }
  useEffect(() => {
    if (
      Object.keys(massiveValorizationDataErrors).length === 0 &&
      (Object.keys(housesData).length > 0 || Object.keys(apartmentData).length > 0)
    ) {
      setMassiveAppraisalButton(false);
    } else {
      setMassiveAppraisalButton(true);
    }
  }, [massiveValorizationDataErrors, apartmentData, housesData]);
  return (
    <>
      {!valorizationCreated ? (
        <div className="massive-appraisalContainer">
          <Row style={{ height: '100%' }}>
            <Col className="massive-appraisalGuide" span={10}>
              <div className="massive-appraisalTitle">
                <h3>Valorización Masiva</h3>
              </div>
              <div className="massive-appraisalHint">
                <h4>Valoriza todas las propiedades que quieras ¡Rápido y fácil!</h4>
              </div>
              <div className="massive-appraisalFooter ">
                <div className="massive-appraisalSteps">
                  <h3>¡En solo 3 pasos!</h3>
                  <div>
                    <div className="massive-appraisalStep">
                      <img src={iconFirstStep} alt="" />
                      <h4>Descarga tu plantilla para rellenar datos de las propiedades aquí:</h4>
                    </div>
                    <div className="massive-appraisalExampleButton">
                      <Button type="primary">
                        <a href="https://tassapp-public-assets.s3.amazonaws.com/Plantilla-Valorizaci%C3%B3n-Masiva.xlsx">
                          Descargar plantilla para XLSX
                        </a>
                      </Button>
                    </div>
                  </div>
                  <div className="massive-appraisalStep">
                    <div>
                      <img src={iconSecondStep} alt="" />
                    </div>

                    <div>
                      <h4>Guarda tu archivo con los datos ingresados en formato. XLSX.</h4>
                    </div>
                  </div>
                  <div className="massive-appraisalStep">
                    <div>
                      <img src={iconThirdStep} alt="" />
                    </div>

                    <div>
                      <h4>Arrastra el archivo o seleccionalo en el recuadro de la derecha.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={14}>
              <div className="massive-appraisal">
                <div className="massiveValorizationDragger">
                  <Dragger
                    onRemove={(file) => {
                      const newHouseData = Object.fromEntries(
                        Object.entries(housesData).filter(([key]) => key !== file.uid),
                      );
                      const newMassiveAppraisalDataErrors = Object.fromEntries(
                        Object.entries(massiveValorizationDataErrors).filter(
                          ([key]) => key !== file.uid,
                        ),
                      );
                      const newApartmentData = Object.fromEntries(
                        Object.entries(apartmentData).filter(([key]) => key !== file.uid),
                      );
                      setHousesData(newHouseData);
                      setMassiveAppraisalDataErrors(newMassiveAppraisalDataErrors);
                      setApartmentData(newApartmentData);
                    }}
                    customRequest={({ onSuccess, onError, file }) => {
                      readXlsxFile(file, { schema: houseSchema, sheet: 2 }).then((rows) => {
                        if (rows.errors.length) {
                          setMassiveAppraisalDataErrors((prevState) => ({
                            ...prevState,
                            [file.uid]: rows.errors,
                          }));
                          onError();
                        } else {
                          const newHouses = rows.rows.map((x) => {
                            return { ...x, propertyType: 'house' };
                          });
                          setHousesData((prevState) => ({
                            ...prevState,
                            [file.uid]: newHouses,
                          }));
                        }
                      });
                      readXlsxFile(file, {
                        schema: apartmentSchema,
                        sheet: 1,
                      }).then((rows) => {
                        if (rows.errors.length) {
                          setMassiveAppraisalDataErrors((prevState) => ({
                            ...prevState,
                            [file.uid]: rows.errors,
                          }));
                          onError();
                        }
                        const newApartment = rows.rows.map((x) => {
                          return { ...x, propertyType: 'apartment' };
                        });
                        setApartmentData((prevState) => ({
                          ...prevState,
                          [file.uid]: newApartment,
                        }));
                      });
                      onSuccess(null, file);
                    }}
                    type="file"
                    accept=".xlsx, .xls"
                  >
                    <img src={iconDropHere} alt="" />
                    <p className="ant-upload-text">
                      Seleccionar archivos XLSX clickeando aquí o arrastra y sueltalo aquí.
                    </p>
                    <p className="ant-upload-hint">
                      Sólo archivos de excel en formato .XLSX que sigan el formato de la plantilla y
                      ejemplo.
                    </p>
                  </Dragger>
                </div>

                {massiveValorizationButton ? (
                  ''
                ) : (
                  <Button
                    loading={loading}
                    type="primary"
                    disabled={massiveValorizationButton}
                    onClick={() => onMassiveAppraisal()}
                  >
                    {!loading && <img alt="" />} Realizar valorización masiva
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <Redirect to={`/massive-appraisals/${data.createMassiveValorization.id}`} />
      )}
    </>
  );
}

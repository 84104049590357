import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Popover, Button as AntButton, Input } from 'antd';
import Icon from '@ant-design/icons';

import Button from '../Buttons';
import { IconArrowDown, iconHyphen } from '../../assets';
import './style.less';

export default function RangeFilter({ title, label, prefix, suffix, callback }) {
  const isWideScreen = useMediaQuery({ minWidth: 1650 });
  const antButtonSize = isWideScreen ? 'large' : 'middle';

  return (
    <div className="filterPopover">
      <Popover
        content={
          <RangeFilterContent
            callback={callback}
            title={title}
            prefix={prefix}
            suffix={suffix}
          ></RangeFilterContent>
        }
        trigger="hover"
        placement={'bottom'}
      >
        <AntButton type="default" size={antButtonSize}>
          {label}
          <Icon component={IconArrowDown} />
        </AntButton>
      </Popover>
    </div>
  );
}

RangeFilter.propTypes = {
  ...RangeFilterContent.propTypes,
  label: PropTypes.string.isRequired,
};
RangeFilter.defaultProps = {
  callback: undefined,
};

export function RangeFilterContent(props) {
  const { callback, title, prefix, suffix } = props;
  const [range, setRange] = useState({
    minValue: undefined,
    maxValue: undefined,
  });

  return (
    <div className="rangeFilterContent">
      <h3>{title}</h3>
      <RangeFilterInput
        prefix={prefix}
        suffix={suffix}
        minValue={range.minValue}
        maxValue={range.maxValue}
        onChange={setRange}
      />
      <div className="button">
        <Button type="second" label={'Aplicar'} callback={callback} params={range} />
      </div>
    </div>
  );
}

RangeFilterContent.propTypes = {
  title: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  callback: PropTypes.func,
};

export const RangeFilterInput = ({ prefix, suffix, minValue, maxValue, onChange }) => {
  const [inputAlign, setInputAlign] = useState('alignLeft');
  useEffect(() => {
    if (suffix && !prefix) {
      setInputAlign('alignRight');
    }
  }, [prefix, suffix]);

  const handleMinChange = (e) => onChange({ minValue: parseFloat(e.target.value), maxValue });
  const handleMaxChange = (e) => onChange({ minValue, maxValue: parseFloat(e.target.value) });

  return (
    <div className="range-filter-input-container">
      <Input
        className={inputAlign}
        min={0}
        max={99}
        prefix={prefix}
        suffix={suffix}
        type={'number'}
        placeholder={'mín'}
        value={minValue}
        onChange={handleMinChange}
      />
      <img src={iconHyphen} alt="-" width={'10px'} />
      <Input
        className={inputAlign}
        min={0}
        max={99}
        prefix={prefix}
        suffix={suffix}
        type={'number'}
        placeholder={'máx'}
        value={maxValue}
        onChange={handleMaxChange}
      />
    </div>
  );
};

RangeFilterInput.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func,
};
RangeFilterInput.defaultProps = {
  prefix: '',
  suffix: '',
};

import React, { useState } from 'react';
//import { useRef } from 'react';
import PropTypes from 'prop-types';
//import { useMutation } from '@apollo/client';
import Map from '../../Map';
import PropertiesExcelDownload from '../../Properties/ExcelDownload';
import { Button, Tooltip } from 'antd';
import { IconClose, IconDownload, IconSaved } from '../../../assets';
//import { IconDislike } from '../../../assets';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Card from '../../Cards/PropertyPreviewCard';
import AddCollectionModal from '../../Modals/AddCollection';
import SkeletonCard from '../../Cards/SkeletonCard';
import getAlerts from '../../Alerts/Alert';
import { removePublicationsFromFavorites, addPublicationsToFavorites } from './backend';

//import { DELETE_USER_FAVORITE_PUBLICATIONS } from '../../../graphql/mutations/publications';
import { GET_CURRENT_USER_DATA } from '../../../graphql/queries';
import './style.less';

const onCardClick = (property, openFullScreen, history) => {
  history.push(`/properties/${property.id}` + (openFullScreen ? '/full' : ''));
};

onCardClick.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    propertyType: PropTypes.string,
    operation: PropTypes.string,
    price: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  openFullScreen: PropTypes.bool,
};

function PublicationList({
  currentUserId,
  publications,
  referencePublication,
  loading,
  title,
  alternative,
  openFullScreen = false,
  mode = 'full',
}) {
  const history = useHistory();
  const isMobile = window.innerWidth < 768;
  //const allListPublicationsAreFavorite = false;

  //const [favorites, setFavorites] = useState([]);
  const [selectedPublicationIds, setSelectedPublicationIds] = useState(new Set());
  const [collectionModalIsVisible, setCollectionModalIsVisible] = useState(false);
  //const undoDelete = useRef(true);

  const showCollectionModal = () => setCollectionModalIsVisible(true);

  const handleSelectPublicationIds = (selectedPublicationId, isSelected) => {
    const newSet = new Set(selectedPublicationIds);
    if (!isSelected) {
      newSet.add(selectedPublicationId);
    } else {
      newSet.delete(selectedPublicationId);
      //setselectedPublicationIdsPreviewData(selectedPublicationIdsPreviewData.filter((x) => x.id != id));
    }
    setSelectedPublicationIds(newSet);
  };

  const resetSelectedPublicationIds = () => {
    setSelectedPublicationIds(new Set());
  };

  // mutations
  // favorites
  const { openMessage, closeMessage, updateErrorMessage, updateSuccessMessage } = getAlerts(
    'favorite',
  );

  const handleUserLikePublications = ({ publicationIds }) => {
    setTimeout(() => {
      openMessage(<p>Cargando ...</p>);
      addPublicationsToFavorites({
        userId: currentUserId,
        publicationIds: publicationIds,
      }).then((response) => {
        updateSuccessMessage(
          <>
            <p>Propiedad guardada exitosamente en favoritos</p>
          </>,
          5,
        );
      });
    }, 50);
  };

  const handleUserRemoveLikePublications = ({ publicationIds }) => {
    setTimeout(() => {
      openMessage(<p>Cargando ...</p>);
      removePublicationsFromFavorites({
        userId: currentUserId,
        publicationIds: publicationIds,
      }).then((response) => {
        console.log(response);
        updateSuccessMessage(
          <>
            <p>Propiedad borrada de favoritos</p>
          </>,
          5,
        );
      });
    }, 50);
  };

  // add every property in PublicationList to collection if no property is selected
  return (
    <div className="publicationListContainer">
      <AddCollectionModal
        setCollectionModalIsVisible={setCollectionModalIsVisible}
        collectionModalIsVisible={collectionModalIsVisible}
        selectedProperties={
          selectedPublicationIds.length > 0
            ? selectedPublicationIds
            : new Set(publications.map((x) => x.id))
        }
        currentUser={currentUserId}
      />
      {title && (
        <div className="componentTitle">
          <h1>{title}</h1>
        </div>
      )}
      {/*
      <PropertiesExcelDownload
        render={
          <Button type="primary" size="large">
            Descargar propiedades
          </Button>
        }
        data={PublicationListData ? PublicationListData : []}
      ></PropertiesExcelDownload>
      */}
      <div className="data-view-header-options">
        <div className="text">
          {`${selectedPublicationIds.size} ${
            !isMobile ? 'propiedades seleccionadas' : 'seleccionados'
          }`}
        </div>
        <div className="actions">
          <span className="header-tools-first">
            <Tooltip title="Agregar a colección">
              <Button
                icon={<Icon component={IconSaved} />}
                type="link"
                onClick={() => showCollectionModal()}
              />
            </Tooltip>
          </span>
          {/*
          <span className="header-tools">
            <Tooltip title={allListPublicationsAreFavorite?"Eliminar de favoritos":"Agregar a favoritos"}>
              <Button
                icon={<Icon component={IconDislike} />}
                type="link"
                onClick={allListPublicationsAreFavorite?unlikeselectedPublicationIds:likeselectedPublicationIds}
              />
            </Tooltip>
          </span>
          */}
          <span className="header-tools">
            <PropertiesExcelDownload
              render={
                <Tooltip title="Descargar Excel">
                  <Button
                    icon={<Icon component={IconDownload} />}
                    type="link"
                    onClick={() => resetSelectedPublicationIds()}
                  />
                </Tooltip>
              }
              filename={title ? title : 'TassApp Resumen de Propiedades'}
              sheetName="Publicaciones"
              referenceLocation={
                referencePublication
                  ? {
                      latitude: referencePublication.latitude,
                      longitude: referencePublication.longitude,
                    }
                  : undefined
              }
              data={
                selectedPublicationIds.size > 0
                  ? publications.filter((publication) => selectedPublicationIds.has(publication.id))
                  : publications
              }
            ></PropertiesExcelDownload>
          </span>
          {selectedPublicationIds.size > 0 && (
            <span className="header-tools">
              <Tooltip title="Deseleccionar" placement="topRight">
                <Button
                  icon={<Icon component={IconClose} />}
                  type="link"
                  onClick={() => resetSelectedPublicationIds()}
                />
              </Tooltip>
            </span>
          )}
        </div>
      </div>
      {!isMobile && mode == 'full' && (
        <div className="publicationListMap">
          <Map
            address={
              referencePublication
                ? {
                    value: referencePublication.address,
                    coords: {
                      latitude: referencePublication.latitude,
                      longitude: referencePublication.longitude,
                    },
                  }
                : undefined
            }
            config={{
              searchbar: true,
              polygon: false,
              markerLocation: false,
              ubication: false,
              zoomButtons: true,
              satelitalControl: true,
              propertiesAlerts: false,
              calculateArea: false,
              zoomWithWheel: false,
            }}
            withSearchBar={false}
            markersData={publications ? publications : []}
            onCardClick={(property) => onCardClick(property, openFullScreen, history)}
            handleSelectProperty={handleSelectPublicationIds}
            selectedProperties={selectedPublicationIds}
          />
        </div>
      )}
      <div className="publicationList">
        <PublicationListCardContainer
          publications={publications}
          loading={publications ? false : true}
          alternative={alternative}
          selectedPublicationIds={selectedPublicationIds}
          handleSelectPublicationIds={handleSelectPublicationIds}
          handleUserLikePublications={handleUserLikePublications}
          handleUserRemoveLikePublications={handleUserRemoveLikePublications}
          onCardClick={(property) => onCardClick(property, openFullScreen, history)}
        ></PublicationListCardContainer>
      </div>
    </div>
  );
}

PublicationList.propTypes = {
  /*
  PublicationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      propertyType: PropTypes.string,
      operation: PropTypes.string,
      price: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  PublicationListData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      isHidden: PropTypes.bool,
      operation: PropTypes.string,
      price: PropTypes.number,
    }),
  ),
  property: PropTypes.shape({
    address: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  */
  loading: PropTypes.bool,
  title: PropTypes.string,
  alternative: PropTypes.string,
  openFullScreen: PropTypes.bool,
  currentUserId: PropTypes.number,
};

function PublicationListCardContainer({
  publications,
  selectedPublicationIds,
  handleSelectPublicationIds,
  handleUserLikePublications,
  handleUserRemoveLikePublications,
  loading,
  alternative,
  onCardClick,
}) {
  if (loading || !publications)
    return (
      <div className="publicationListCardContainer">
        {Array.from(Array(12).keys()).map((property) => (
          <SkeletonCard key={property} layout="vertical" />
        ))}
      </div>
    );

  if (!loading && publications.length === 0)
    return (
      <div className="publicationListCardContainer">
        <p>{alternative}</p>
      </div>
    );

  return (
    <div className="publicationListCardContainer">
      {publications.map((publication) => (
        <Card
          key={publication.id}
          property={publication}
          layout="vertical"
          handleSelect={handleSelectPublicationIds}
          handleLike={() => {
            if (publication.isFavorite) {
              handleUserRemoveLikePublications({ publicationIds: [publication.id] });
            } else {
              handleUserLikePublications({ publicationIds: [publication.id] });
            }
          }}
          isSelected={selectedPublicationIds.has(publication.id)}
          isFavorite={publication.isFavorite}
          onClick={() => onCardClick(publication)}
        />
      ))}
    </div>
  );
}

PublicationListCardContainer.propTypes = {
  loading: PropTypes.bool,
  alternative: PropTypes.string,
  onCardClick: PropTypes.func,
  handleSelectPublicationIds: PropTypes.func,
  /*
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      isHidden: PropTypes.bool,
      operation: PropTypes.string,
      price: PropTypes.number,
    }),
  ),
  */
  selectedPublicationIds: PropTypes.object,
};

export default PublicationList;

import backend from '../../backend';

// Publications API
export const searchPublications = async function (params) {
  const response = await backend.post('/real_estate_market/search_publications/', params);
  return response.data.response;
};

export const fetchPublications = async function (params) {
  const response = await backend.post('/real_estate_market/fetch_publications_from_ids/', params);
  return response.data.response;
};

//Mutations

export const addPublicationsToFavorites = async function ({ userId, publicationIds }) {
  const response = await backend.post('/real_estate_market/add_publications_to_user_favorites/', {
    user_id: userId,
    publication_ids: publicationIds,
    fields: {
      id: true,
      last_published_price: true,
      operation: true,
      title: true,
      address_line: true,
      created_time: true,
      first_time_found: true,
      last_time_found: true,
      is_favorite: true,
    },
  });
  return response.data.response;
};

export const removePublicationsFromFavorites = async function ({ userId, publicationIds }) {
  const response = await backend.post(
    '/real_estate_market/remove_publications_from_user_favorites/',
    {
      user_id: userId,
      publication_ids: publicationIds,
      fields: {
        id: true,
        last_published_price: true,
        operation: true,
        title: true,
        address_line: true,
        created_time: true,
        first_time_found: true,
        last_time_found: true,
        is_favorite: true,
      },
    },
  );
  return response.data.response;
};

import React, { useState } from 'react';
//import { useRef } from 'react';
import PropTypes from 'prop-types';
//import { useMutation } from '@apollo/client';
import Map from '../../Map';
import PropertiesExcelDownload from '../../Properties/ExcelDownload';
import { Button, Tooltip } from 'antd';
import { IconClose, IconDownload, IconSaved } from '../../../assets';
//import { IconDislike } from '../../../assets';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Card from '../../Cards/PropertyPreviewCard';
import AddCollectionModal from '../../Modals/AddCollection';
import SkeletonCard from '../../Cards/SkeletonCard';
//import _ from 'lodash';
//import { DELETE_USER_FAVORITE_PUBLICATIONS } from '../../../graphql/mutations/publications';
import './style.less';

const onCardClick = (property, openFullScreen, history) => {
  history.push(`/properties/${property.id}` + (openFullScreen ? '/full' : ''));
};

onCardClick.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    propertyType: PropTypes.string,
    operation: PropTypes.string,
    price: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  openFullScreen: PropTypes.bool,
};

function ListProperties({
  listProperties,
  listPropertiesData,
  property,
  loading,
  title,
  alternative,
  currentUserId,
  openFullScreen = false,
}) {
  const history = useHistory();
  const isMobile = window.innerWidth < 768;
  //const allListPublicationsAreFavorite = false;

  //const [favorites, setFavorites] = useState([]);
  const [selectedPublications, SetSelectedPublications] = useState(new Set());
  const [selectedPublicationsPreviewData, setSelectedPublicationsPreviewData] = useState([]);
  const [collectionModalIsVisible, setCollectionModalIsVisible] = useState(false);
  //const undoDelete = useRef(true);

  const showCollectionModal = () => {
    setCollectionModalIsVisible(true);
  };

  const handleSelectedPublications = (id, isSelected, publicationPreviewData = null) => {
    const newSet = new Set(selectedPublications);
    if (!isSelected) {
      newSet.add(id);
      setSelectedPublicationsPreviewData([
        ...selectedPublicationsPreviewData,
        publicationPreviewData
          ? publicationPreviewData
          : listPropertiesData.find((x) => x.id === id),
      ]);
    } else {
      newSet.delete(id);
      setSelectedPublicationsPreviewData(selectedPublicationsPreviewData.filter((x) => x.id != id));
    }
    SetSelectedPublications(newSet);
  };

  const resetSelectedPublications = () => {
    SetSelectedPublications(new Set());
    setSelectedPublicationsPreviewData([]);
  };

  /*
  const unlikePublication = () => {
    const newFavorites = favorites.filter((favorite) => !selectedPublications.has(favorite.id));
    const deleted = _.differenceBy(favorites, newFavorites, 'id');
    setFavorites(newFavorites);
    setDeletedFavoriteTooltip({
      show: true,
      deleted,
    });
    setTimeout(() => {
      setDeletedFavoriteTooltip({ ...deletedFavoriteTooltip, show: false });
      if (undoDelete.current) {
        removeFavoritePublications({
          variables: { userId, publicationIds: Array.from(selectedPublications) },
        });
      }
      undoDelete.current = true;
    }, 7000);
    resetSelectedPublications();
  };

  const likePublication = () => {
    // add selected publications to favorites only if not already in favorites
    const newFavorites = [...favorites, ...selectedPublications.values()];
    //favorites.filter((favorite) => !selectedPublications.has(favorite.id));
    const deleted = _.differenceBy(favorites, newFavorites, 'id');
    setFavorites(newFavorites);
    setDeletedFavoriteTooltip({
      show: true,
      deleted,
    });
    setTimeout(() => {
      setAddedFavoriteTooltip({ ...addedFavoriteTooltip, show: false });
      if (undoDelete.current) {
        removeFavoritePublications({
          variables: { userId, publicationIds: Array.from(selectedPublications) },
        });
      }
      undoDelete.current = true;
    }, 7000);
    resetSelectedPublications();
  };

  //Mutations
  const [removeFavoritePublications, { loading: loadingDeletion }] = useMutation(
    DELETE_USER_FAVORITE_PUBLICATIONS,
  );
  */

  // add every property in listProperties to collection if no property is selected
  return (
    <div className="listPropertiesContainer">
      <AddCollectionModal
        setCollectionModalIsVisible={setCollectionModalIsVisible}
        collectionModalIsVisible={collectionModalIsVisible}
        selectedProperties={
          selectedPublications.length > 0
            ? selectedPublications
            : new Set(listProperties.map((x) => x.id))
        }
        currentUser={currentUserId}
      />
      <div className="componentTitle">
        <h1>{title}</h1>
      </div>
      {/*
      <PropertiesExcelDownload
        render={
          <Button type="primary" size="large">
            Descargar propiedades
          </Button>
        }
        data={listPropertiesData ? listPropertiesData : []}
      ></PropertiesExcelDownload>
      */}
      <div className="data-view-header-options">
        <div className="text">
          {`${selectedPublications.size} ${
            !isMobile ? 'propiedades seleccionadas' : 'seleccionados'
          }`}
        </div>
        <div className="actions">
          <span className="header-tools-first">
            <Tooltip title="Agregar a colección">
              <Button
                icon={<Icon component={IconSaved} />}
                type="link"
                onClick={() => showCollectionModal()}
              />
            </Tooltip>
          </span>
          {/*
          <span className="header-tools">
            <Tooltip title={allListPublicationsAreFavorite?"Eliminar de favoritos":"Agregar a favoritos"}>
              <Button
                icon={<Icon component={IconDislike} />}
                type="link"
                onClick={allListPublicationsAreFavorite?unlikeSelectedPublications:likeSelectedPublications}
              />
            </Tooltip>
          </span>
          */}
          <span className="header-tools">
            <PropertiesExcelDownload
              render={
                <Tooltip title="Descargar Excel">
                  <Button
                    icon={<Icon component={IconDownload} />}
                    type="link"
                    onClick={() => resetSelectedPublications()}
                  />
                </Tooltip>
              }
              filename={title ? title : 'TassApp Resumen de Propiedades'}
              sheetName="Publicaciones"
              referenceLocation={
                property
                  ? {
                      latitude: property.latitude,
                      longitude: property.longitude,
                    }
                  : undefined
              }
              data={
                selectedPublications.size > 0 ? selectedPublicationsPreviewData : listPropertiesData
              }
            ></PropertiesExcelDownload>
          </span>
          {selectedPublications.size > 0 && (
            <span className="header-tools">
              <Tooltip title="Deseleccionar" placement="topRight">
                <Button
                  icon={<Icon component={IconClose} />}
                  type="link"
                  onClick={() => resetSelectedPublications()}
                />
              </Tooltip>
            </span>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className="listPropertiesMap">
          <Map
            address={
              property
                ? {
                    value: property.address,
                    coords: {
                      latitude: property.latitude,
                      longitude: property.longitude,
                    },
                  }
                : undefined
            }
            config={{
              searchbar: false,
              polygon: false,
              markerLocation: true,
              ubication: false,
              zoomButtons: true,
              satelitalControl: true,
              propertiesAlerts: false,
              calculateArea: false,
              zoomWithWheel: false,
            }}
            withSearchBar={false}
            markersData={listProperties ? listProperties : []}
            onCardClick={(property) => onCardClick(property, openFullScreen, history)}
            handleSelectProperty={handleSelectedPublications}
            selectedProperties={selectedPublications}
          />
        </div>
      )}
      <div className="listProperties">
        <ListPropertiesCardContainer
          properties={listPropertiesData}
          loading={loading}
          alternative={alternative}
          selectedPublications={selectedPublications}
          handleSelectedPublications={handleSelectedPublications}
          onCardClick={(property) => onCardClick(property, openFullScreen, history)}
        ></ListPropertiesCardContainer>
      </div>
    </div>
  );
}

ListProperties.propTypes = {
  listProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      propertyType: PropTypes.string,
      operation: PropTypes.string,
      price: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  listPropertiesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      isHidden: PropTypes.bool,
      operation: PropTypes.string,
      price: PropTypes.number,
    }),
  ),
  property: PropTypes.shape({
    address: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  loading: PropTypes.bool,
  title: PropTypes.string,
  alternative: PropTypes.string,
  openFullScreen: PropTypes.bool,
  currentUserId: PropTypes.number,
};

function ListPropertiesCardContainer({
  properties,
  selectedPublications,
  handleSelectedPublications,
  loading,
  alternative,
  onCardClick,
}) {
  if (loading || !properties)
    return (
      <div className="listPropertiesCardContainer">
        {Array.from(Array(12).keys()).map((property) => (
          <SkeletonCard key={property} layout="vertical" />
        ))}
      </div>
    );

  if (!loading && properties.length === 0)
    return (
      <div className="listPropertiesCardContainer">
        <p>{alternative}</p>
      </div>
    );

  return (
    <div className="listPropertiesCardContainer">
      {properties.map((property) => (
        <Card
          key={property.id}
          property={property}
          layout="vertical"
          handleSelect={handleSelectedPublications}
          isSelected={selectedPublications.has(property.id)}
          onClick={() => onCardClick(property)}
        />
      ))}
    </div>
  );
}

ListPropertiesCardContainer.propTypes = {
  loading: PropTypes.bool,
  alternative: PropTypes.string,
  onCardClick: PropTypes.func,
  handleSelectedPublications: PropTypes.func,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      isHidden: PropTypes.bool,
      operation: PropTypes.string,
      price: PropTypes.number,
    }),
  ),
  selectedPublications: PropTypes.object,
};

export default ListProperties;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_PUBLICATION_VALORIZATION_DETAILS } from '../../../graphql/queries';
import TassAppEstimations from '../../RenderViews/TassAppEstimations';
import './style.less';
import { parseValorizationDetails } from '../services';

function TassAppPropertyEstimations({ publicationId, publicationData }) {
  /*
  const [propertyData, setPropertyData] = useState();
  useQuery(GET_PUBLICATION_VALORIZATION_DETAILS, {
    variables: { id: propertyId },
    onCompleted: (data) => {
      setPropertyData(parseValorizationDetails(data.getPublicationAppraisalView));
    },
  });
  */

  return publicationData ? (
    <>
      <div className="componentDivider" />
      <TassAppEstimations data={publicationData} />
    </>
  ) : (
    <></>
  );
}

TassAppPropertyEstimations.propTypes = {
  publicationId: PropTypes.number,
};

export default TassAppPropertyEstimations;

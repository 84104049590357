import { UF_CLP } from '../constants';
import {
  getPropertyType,
  parseNumbersToPrice,
  setPriceInUFtoFloatUFandCLP,
  propertyTypeFormatter,
  getOperationEstimatedText,
} from './formatters';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

export const parsePublicationPreviewsAPI = (publications) => {
  // NEW API
  const parsedPublicationPreviews = publications.map((x) => ({
    id: x.id,
    isFavorite: x.is_favorite,
    operation: x.operation,
    condition: x.condition,
    propertyType: x.property_type,
    source: x.source,
    externalSourceId: x.external_source_id,
    externalSourceUrl: x.external_source_url,
    typeText: getPropertyType(x.operation, x.property_type),
    address: x.address_line,
    description: x.description,
    title: x.title,
    createdTime: x.first_time_found,
    firstTimeFound: x.first_time_found,
    dateText: moment(x.first_time_found).fromNow(),
    isActive: x.is_active,
    isHidden: x.is_hidden,
    latitude: x.location.latitude,
    longitude: x.location.longitude,
    unit: x.last_published_price.units,
    publishedPriceAmount: parseInt(x.last_published_price.value),
    publishedPriceUnit: x.last_published_price.units,
    priceText: `${parseNumbersToPrice(x.last_published_price.value, x.operation)}`,
    prices: {
      UF: `${parseNumbersToPrice(x.last_published_price.value, x.operation)}`,
      CLP: `${parseNumbersToPrice(
        x.last_published_price.value * UF_CLP,
        x.operation,
        false,
        'CLP',
      )}`,
    },
    coveredArea: x.covered_area,
    totalArea: x.total_area,
    bedRooms: x.bedrooms,
    rooms: x.rooms,
    fullBathrooms: x.full_bathrooms,
    pictures: x.pictures_minimized,
    largePictures: x.pictures,
    warehouses: x.warehouses ? 'Si' : 'No',
    maintenanceCost: x.maintenance_cost ? x.maintenance_cost.value : 'No hay datos',
    constructionYear: x.construction_year ? x.construction_year : 'No hay datos',
  }));
  return parsedPublicationPreviews;
};

export const parsePublicationAPI = (data) => {
  const parsedPublication = {
    id: data.id,
    operation: data.operation,
    condition: data.condition,
    propertyType: propertyTypeFormatter[data.property_type],
    projectId: data.external_source_id,
    typeText: getPropertyType(data.operation, data.property_type),
    operationText: getOperationEstimatedText(data.operation),
    address: data.address_line,
    title: data.title,
    createdTime: data.first_time_found,
    firstTimeFound: data.first_time_found,
    dateText: moment(data.first_time_found).fromNow(),
    isActive: data.is_active,
    isFavorite: data.is_favorite,
    isHidden: data.is_hidden,
    latitude: data.location.latitude,
    longitude: data.location.longitude,
    publishedPriceAmount: parseInt(data.last_published_price.value),
    publishedPriceUnit: data.last_published_price.units,
    priceText: `${parseNumbersToPrice(data.last_published_price.value, data.operation)}`,
    coveredArea: data.covered_area,
    coveredAreaText: `${data.covered_area} m² útiles`,
    coveredAreaTextSmall: `${data.covered_area} m²`,
    totalArea: data.total_area,
    totalAreaText: `${data.total_area} m² totales`,
    totalAreaTextSmall: `${data.total_area} m²`,
    bedRooms: data.bedrooms,
    rooms: data.rooms,
    fullBathrooms: data.full_bathrooms,
    pictures: data.pictures,
    externalSourceUrl: data.external_source_url,
    floors: data.floors,
    parkings: data.parking_lots ? `${data.parking_lots} autos` : 'Sin estacionamiento',
    description: data.description,
    pricingHistory: data.pricing_history ? data.pricing_history : [],
    daysOnMarket: moment().diff(data.first_time_found, 'days'),
    estimatedSellPrice: !data.estimated_sale_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_sale_price.value),
    estimatedSellPricePerArea: !data.estimated_sale_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_sale_price_per_total_area.value),
    estimatedSellPriceMin: !data.estimated_min_sale_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_sale_price.value),
    estimatedSellPriceMinPerArea: !data.estimated_min_sale_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_sale_price_per_total_area.value),
    estimatedSellPriceMax: !data.estimated_max_sale_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_sale_price.value),
    estimatedSellPriceMaxPerArea: !data.estimated_max_sale_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_sale_price_per_total_area.value),
    estimatedRentPrice: !data.estimated_rental_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_rental_price.value),
    estimatedRentPricePerArea: !data.estimated_rental_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_rental_price_per_total_area.value),
    estimatedRentPriceMin: !data.estimated_min_rental_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_rental_price.value),
    estimatedRentPriceMinPerArea: !data.estimated_min_rental_price_per_total_are
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_rental_price_per_total_are.value),
    estimatedRentPriceMax: !data.estimated_max_rental_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_rental_price.value),
    estimatedRentPriceMaxPerArea: !data.estimated_max_rental_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_rental_price_per_total_area.value),
    estimatedYield: (data.estimated_yield * 100).toFixed(2),
    estimatedMinYield: (data.estimated_min_yield * 100).toFixed(2),
    estimatedMaxYield: (data.estimated_max_yield * 100).toFixed(2),
  };
  return parsedPublication;
};

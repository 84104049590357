import React from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import Icon from '@ant-design/icons';

import { IconHide, IconSaved, IconShow, IconLike, IconClose, IconDownload } from '../../assets';
import './style.less';

export default function ToggleFilter(props) {
  const { defaultValue, options, callback } = props;
  const isWideScreen = useMediaQuery({ minWidth: 1650 });
  const antButtonSize = isWideScreen ? 'large' : 'middle';

  const handleChange = (value) => {
    if (callback) {
      callback(value.target.value);
    }
  };

  return (
    <div className="filterToggle">
      <Radio.Group
        onChange={handleChange}
        size={antButtonSize}
        options={options}
        optionType="button"
        defaultValue={defaultValue}
      />
    </div>
  );
}

ToggleFilter.propTypes = {
  ...ToggleFilterInput.propTypes,
};

export function ToggleFilterInput({ value, options, onChange }) {
  const getItemClasses = (itemValue) => {
    const baseClasses = 'toggleFilterItem';
    return value === itemValue ? baseClasses + ' active' : baseClasses;
  };

  return (
    <div className="toggleFilterInput">
      {options.map(({ label, value }, idx) => (
        <span
          className={getItemClasses(value)}
          key={`${idx}-${value}`}
          value={value}
          onClick={() => onChange(value)}
        >
          {label}
          {value === 'save_property' && <Icon component={IconSaved} />}
          {value === 'fav_property' && <Icon component={IconLike} />}
          {value === 'Download_porps' && <Icon component={IconDownload} />}
          {value === 'hide_properties' && <Icon component={IconHide} />}
          {value === 'show_properties' && <Icon component={IconShow} />}
          {value === 'errase_selection' && <Icon component={IconClose} />}
        </span>
      ))}
    </div>
  );
}

ToggleFilterInput.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  onClick: PropTypes.func,
};

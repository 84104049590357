import backend from '../../../backend';

export const getUserCollections = async function ({ userId }) {
  const response = await backend.post('/real_estate_market/fetch_publications_in_user_collections/', {
    user_id: userId,
    fields: {
      id: true,
      last_published_price: true,
      operation: true,
      title: true,
      address_line: true,
      created_time: true,
      first_time_found: true,
      last_time_found: true,
      pictures: true,
    },
  });
  return response.data.response;
};

export const deleteCollection = async function ({ collectionId, userId }) {
  const response = await backend.post('/real_estate_market/delete_user_collections/', {
    user_id: userId,
    collection_ids: [collectionId],
  });
  return response.data.response;
};

export const fetchPublications = async function (params) {
  const response = await backend.post('/real_estate_market/fetch_publications_from_ids/', params);
  return response.data.response;
};

import {
  IconEducation,
  IconHealth,
  IconFinances,
  IconParking,
  IconRetail,
  IconCommerce,
  IconGovernment,
  IconGraveyard,
  IconCulture,
  IconSports,
  IconMail,
  IconPark,
  IconAlojamiento,
  IconTransport,
  IconGastronomy,
  IconReligion,
  IconSecurity,
  IconPolice,
  IconPDI,
  IconEntertainment,
  IconFire,
  IconPointerEducation,
  IconPointerHealth,
  IconPointerFinances,
  IconPointerParking,
  IconPointerRetail,
  IconPointerCommerce,
  IconPointerGovernment,
  IconPointerGraveyard,
  IconPointerCulture,
  IconPointerSports,
  IconPointerMail,
  IconPointerPark,
  IconPointerAlojamiento,
  IconPointerTransport,
  IconPointerGastronomy,
  IconPointerEntertainment,
  IconPointerFire,
  IconPointerPdi,
  IconPointerPolice,
  IconPointerReligion,
  IconPointerSecurity,
} from './assets';

export const categoryPointers = {
  'AIRE LIBRE': IconPointerPark,
  ALIMENTACION: IconPointerGastronomy,
  ALOJAMIENTO: IconPointerAlojamiento,
  BANCO: IconPointerFinances,
  BOMBEROS: IconPointerFire,
  CARABINEROS: IconPointerPolice,
  CEMENTERIOS: IconPointerGraveyard,
  COMERCIO: IconPointerCommerce,
  CORREOS: IconPointerMail,
  CULTURA: IconPointerCulture,
  DEPORTE: IconPointerSports,
  EDUCACION: IconPointerEducation,
  ENTRETENCION: IconPointerEntertainment,
  ESTACIONAMIENTOS: IconPointerParking,
  'INSTITUCIONES DE SEGURIDAD': IconPointerSecurity,
  ORGANIZACIONES: IconPointerHealth,
  'ORGS. GUBERNAMENTALES': IconPointerGovernment,
  OTROS: IconPointerHealth,
  PDI: IconPointerPdi,
  RELIGION: IconPointerReligion,
  RETAIL: IconPointerRetail,
  SALUD: IconPointerHealth,
  SERVICIOS: IconPointerRetail,
  'SISTEMA PREVISIONAL': IconPointerHealth,
  TRANSPORTE: IconPointerTransport,
};

export const categoryIcons = {
  'AIRE LIBRE': IconPark,
  GASTRONOMIA: IconGastronomy,
  ALIMENTACION: IconGastronomy,
  ALOJAMIENTO: IconAlojamiento,
  'INSTITUCIONES FINANCIERAS': IconFinances,
  BANCO: IconFinances,
  BOMBEROS: IconFire,
  CARABINEROS: IconPolice,
  CEMENTERIOS: IconGraveyard,
  COMERCIO: IconCommerce,
  CORREOS: IconMail,
  CULTURA: IconCulture,
  DEPORTE: IconSports,
  EDUCACION: IconEducation,
  ENTRETENCION: IconEntertainment,
  ESTACIONAMIENTOS: IconParking,
  'INSTITUCIONES DE SEGURIDAD': IconSecurity,
  ORGANIZACIONES: IconHealth,
  'ORGS. GUBERNAMENTALES': IconGovernment,
  OTROS: IconHealth,
  PDI: IconPDI,
  RELIGION: IconReligion,
  RETAIL: IconRetail,
  SALUD: IconHealth,
  SERVICIOS: IconRetail,
  'SISTEMA PREVISIONAL': IconHealth,
  TRANSPORTE: IconTransport,
};

export const filteredCategories = {
  'AIRE LIBRE': 'AIRE LIBRE',
  ALIMENTACION: 'GASTRONOMIA',
  GASTRONOMIA: 'GASTRONOMIA',
  ALOJAMIENTO: 'ALOJAMIENTO',
  BANCO: 'INSTITUCIONES FINANCIERAS',
  BOMBEROS: 'BOMBEROS',
  CARABINEROS: 'CARABINEROS',
  CEMENTERIOS: 'CEMENTERIOS',
  COMERCIO: 'COMERCIO',
  CORREOS: 'CORREOS',
  CULTURA: 'CULTURA',
  DEPORTE: 'DEPORTE',
  EDUCACION: 'EDUCACION',
  ENTRETENCION: 'ENTRETENCION',
  ESTACIONAMIENTOS: 'ESTACIONAMIENTOS',
  'INSTITUCIONES DE SEGURIDAD': 'INSTITUCIONES DE SEGURIDAD',
  'INSTITUCIONES FINANCIERAS': 'INSTITUCIONES FINANCIERAS',
  'ORGS. GUBERNAMENTALES': 'ORGS. GUBERNAMENTALES',
  OTROS: 'OTROS',
  PDI: 'PDI',
  RELIGION: 'RELIGION',
  RETAIL: 'COMERCIO',
  SALUD: 'SALUD',
  SERVICIOS: 'SERVICIOS',
  TRANSPORTE: 'TRANSPORTE',
};

export const categoryToQuery = {
  'AIRE LIBRE': 'AIRE LIBRE',
  GASTRONOMIA: ['ALIMENTACION', 'GASTRONOMIA'],
  ALOJAMIENTO: 'ALOJAMIENTO',
  'INSTITUCIONES FINANCIERAS': ['BANCO', 'INSTITUCIONES FINANCIERAS'],
  BOMBEROS: 'BOMBEROS',
  CARABINEROS: 'CARABINEROS',
  CEMENTERIOS: 'CEMENTERIOS',
  COMERCIO: ['COMERCIO', 'RETAIL'],
  CORREOS: 'CORREOS',
  CULTURA: 'CULTURA',
  DEPORTE: 'DEPORTE',
  EDUCACION: 'EDUCACION',
  ENTRETENCION: 'ENTRETENCION',
  ESTACIONAMIENTOS: 'ESTACIONAMIENTOS',
  'INSTITUCIONES DE SEGURIDAD': 'INSTITUCIONES DE SEGURIDAD',
  'ORGS. GUBERNAMENTALES': 'ORGS. GUBERNAMENTALES',
  OTROS: 'OTROS',
  PDI: 'PDI',
  RELIGION: 'RELIGION',
  SALUD: 'SALUD',
  SERVICIOS: 'SERVICIOS',
  TRANSPORTE: 'TRANSPORTE',
};

export default { categoryIcons, categoryPointers, filteredCategories, categoryToQuery };

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useQuery, useLazyQuery } from '@apollo/client';
import EmptyView from './EmptyView';
import Loading from '../Loading';
import PublicationList from '../RenderViews/PublicationList';
//import { DELETE_USER_FAVORITE_PUBLICATIONS } from '../../graphql/mutations/publications';
import Constants from '../../constants';
import { fetchCollectionPublications } from './backend';
import { parsePublicationAPI } from '../parsers';

const { IS_MOBILE } = Constants;

export const CollectionPublicationsView = ({ userId }) => {
  const [collectionPublicationsData, setCollectionPublicationsData] = useState();
  const isMobile = useMediaQuery(IS_MOBILE);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // collection id is only available in the URL
  const { id } = useParams();
  const currentCollectionId = Number(id);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    try {
      if (userId && currentCollectionId) {
        fetchCollectionPublications({ userId: userId, collectionId: currentCollectionId }).then(
          (publications) => {
            setCollectionPublicationsData(
              publications.map((publication) => parsePublicationAPI(publication)),
            );
            setIsLoading(false);
          },
        );
      }
    } catch (error) {
      setHasError(true);
    }
  }, [userId, currentCollectionId]);

  if (!collectionPublicationsData || !userId || !currentCollectionId)
    return (
      <div className="componentTitle">
        <Loading />
      </div>
    );

  return (
    <div className="favorites-data-view">
      {isMobile ? (
        <div className="favorites-tag">
          <div className="text"> Coleccion {currentCollectionId}</div>
        </div>
      ) : (
        ''
      )}
      {collectionPublicationsData.length ? (
        <>
          <div className="componentDivider" />
          <PublicationList
            publications={collectionPublicationsData}
            openFullScreen={true}
            currentUserId={userId}
          ></PublicationList>
        </>
      ) : (
        <EmptyView viewName={'propiedades en coleccion'} typeOf={'property'} />
      )}
    </div>
  );
};

CollectionPublicationsView.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default CollectionPublicationsView;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftWhite, ArrowRightWhite } from '../../../assets';
import { useHistory } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { Marker, Popup } from 'react-map-gl';
import { useLazyQuery } from '@apollo/client';
import { PROPERTIES_PREVIEW } from '../../../graphql/queries';
import PropertyPreviewCard from '../../Cards/PropertyPreviewCard';
import SkeletonCard from '../../Cards/SkeletonCard';
import { parsePropertyPreview } from '../../Properties/services';
import { parsePublicationAPI } from '../../parsers';
import backend from '../../../backend';
import getAlerts from '../../Alerts/Alert';

import './style.less';

function ClusterMapMarker({
  latitude,
  longitude,
  sizeWeighting,
  className,
  publicationIds,
  creatingPolygone,
  pointCount,
  handleSelectProperty,
  isSelected,
  currentUserId,
}) {
  const history = useHistory();
  const [isBeingHovered, setIsBeingHovered] = useState(false);
  const [cardIndex, setCardIndex] = useState(0);
  const [clusterPublicationsData, setClusterPublicationsData] = useState();

  const fetchPublications = async function ({ publicationIds, userId }) {
    const params = {
      publication_ids: publicationIds,
      user_id: userId,
      fields: {
        id: true,
        title: true,
        address_line: true,
        condition: true,
        operation: true,
        property_type: true,
        construction_year: true,
        total_area: true,
        covered_area: true,
        created_time: true,
        description: true,
        external_source_id: true,
        external_source_url: true,
        first_time_found: true,
        full_bathrooms: true,
        rooms: true,
        bedrooms: true,
        warehouses: true,
        last_published_price: true,
        last_published_price_per_total_area: true,
        last_published_price_per_covered_area: true,
        last_time_found: true,
        location: true,
        maintenance_cost: true,
        pictures: true,
        source: true,
        region: true,
        country: true,
        region: true,
        province: true,
        municipality: true,
        district: true,
        zone_or_locality: true,
        block_or_entity: true,
        is_active: true,
        is_hidden: true,
        estimated_sale_price: true,
        estimated_rental_price: true,
        estimated_yield: true,
      },
    };
    const response = await backend.post('/real_estate_market/fetch_publications_from_ids/', params);
    return response.data.response.map((publication) => parsePublicationAPI(publication));
  };

  // favorites
  const { openMessage, closeMessage, updateErrorMessage, updateSuccessMessage } = getAlerts(
    'favorite',
  );

  const addPublicationsToFavorites = async function ({ userId, publicationIds }) {
    const response = await backend.post('/real_estate_market/add_publications_to_user_favorites/', {
      user_id: userId,
      publication_ids: publicationIds,
      fields: {
        id: true,
        last_published_price: true,
        operation: true,
        title: true,
        address_line: true,
        created_time: true,
        first_time_found: true,
        last_time_found: true,
        is_favorite: true,
      },
    });
    return response.data.response;
  };

  const removePublicationsFromFavorites = async function ({ userId, publicationIds }) {
    const response = await backend.post(
      '/real_estate_market/remove_publications_from_user_favorites/',
      {
        user_id: userId,
        publication_ids: publicationIds,
        fields: {
          id: true,
          last_published_price: true,
          operation: true,
          title: true,
          address_line: true,
          created_time: true,
          first_time_found: true,
          last_time_found: true,
          is_favorite: true,
        },
      },
    );
    return response.data.response;
  };

  const handleUserLikePublications = ({ publicationIds }) => {
    setTimeout(() => {
      openMessage(<p>Cargando ...</p>);
      addPublicationsToFavorites({
        userId: currentUserId,
        publicationIds: publicationIds,
      }).then((response) => {
        updateSuccessMessage(
          <>
            <p>Propiedad guardada exitosamente en favoritos</p>
          </>,
          5,
        );
      });
    }, 50);
  };

  const handleUserRemoveLikePublications = ({ publicationIds }) => {
    setTimeout(() => {
      openMessage(<p>Cargando ...</p>);
      removePublicationsFromFavorites({
        userId: currentUserId,
        publicationIds: publicationIds,
      }).then((response) => {
        console.log(response);
        updateSuccessMessage(
          <>
            <p>Propiedad borrada de favoritos</p>
          </>,
          5,
        );
      });
    }, 50);
  };
  //

  function handleIndex(oldValue, change) {
    const newValue = oldValue + change;
    if (newValue < 0) return pointCount - 1;
    if (newValue >= pointCount) return 0;
    return newValue;
  }

  return (
    <div
      style={{ position: 'relative' }}
      onMouseEnter={() => {
        setIsBeingHovered(true);
        fetchPublications({
          publicationIds: publicationIds,
          userId: currentUserId,
        }).then((parsedPublications) => setClusterPublicationsData(parsedPublications));
      }}
      onMouseLeave={() => {
        setIsBeingHovered(false);
      }}
    >
      {isBeingHovered && !creatingPolygone ? (
        <Popup
          latitude={latitude}
          longitude={longitude}
          closeButton={false}
          closeOnClick={false}
          onClose={() => this.setState({ showPopup: false })}
          dynamicPosition={false}
          anchor="top"
        >
          <div className="clusterMapMarker">
            <div onClick={() => setCardIndex((oldValue) => handleIndex(oldValue, -1))}>
              <Icon
                component={ArrowLeftWhite}
                style={{ fontSize: '16px' }}
                className="arrowIcon changeCardArrow"
              />
            </div>
            <div>
              {clusterPublicationsData && clusterPublicationsData.lenght !== 0 ? (
                <PropertyPreviewCard
                  property={clusterPublicationsData[cardIndex]}
                  handleSelect={() =>
                    handleSelectProperty(
                      clusterPublicationsData[cardIndex].id,
                      isSelected(clusterPublicationsData[cardIndex].id),
                      clusterPublicationsData[cardIndex],
                    )
                  }
                  layout="simple"
                  onClick={() =>
                    history.push(`/properties/${clusterPublicationsData[cardIndex].id}`)
                  }
                  isSelected={isSelected(clusterPublicationsData[cardIndex].id)}
                  handleLike={() => {
                    if (clusterPublicationsData[cardIndex].isFavorite) {
                      handleUserRemoveLikePublications({
                        publicationIds: [clusterPublicationsData[cardIndex].id],
                      });
                    } else {
                      handleUserLikePublications({
                        publicationIds: [clusterPublicationsData[cardIndex].id],
                      });
                    }
                  }}
                  isFavorite={clusterPublicationsData[cardIndex].isFavorite}
                />
              ) : (
                <SkeletonCard layout="simple" />
              )}
            </div>
            <div onClick={() => setCardIndex((oldValue) => handleIndex(oldValue, 1))}>
              <Icon
                component={ArrowRightWhite}
                style={{ fontSize: '16px', transform: 'rotate(180deg)' }}
                className="arrowIcon changeCardArrow"
              />
            </div>
          </div>
        </Popup>
      ) : (
        <></>
      )}
      <Marker
        latitude={latitude}
        longitude={longitude}
        offsetLeft={sizeWeighting}
        offsetTop={sizeWeighting}
        onHover={isBeingHovered}
        setHover={setIsBeingHovered}
      >
        <div className={className}>{pointCount}</div>
      </Marker>
    </div>
  );
}

ClusterMapMarker.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  sizeWeighting: PropTypes.number,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  publicationIds: PropTypes.arrayOf(PropTypes.number),
  creatingPolygone: PropTypes.bool,
  pointCount: PropTypes.number,
  onCardClick: PropTypes.func,
  handleSelectProperty: PropTypes.func,
  isSelected: PropTypes.func,
};

ClusterMapMarker.defaultProps = {
  onCardClick: () => {},
};

export default ClusterMapMarker;

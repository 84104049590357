/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from 'react';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import './style.less';
// IconDownload from assets
import { IconDownload } from '../../assets';
import { Button, Tooltip } from 'antd';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// this function generates an excel file with data and columns as params
function ExcelFileButton({ filename, sheetName, fetchData, columns, callback }) {
  // filename: name of the file
  // sheetName: name of the sheet
  // render: function that returns the component you want to render
  // fetchData: function that returns the data to download
  // columns: array of columns to render, each element is an object with label and value properties
  console.log(typeof fetchData);
  const [clicked, setClicked] = useState(false);
  const [data, setData] = useState([]);
  // data is fetched when the button is clicked using useEffect
  useEffect(async () => {
    if (clicked) {
      console.log('clicked');
      console.log(data);
      //setData(fetchData());
      setData(fetchData());
      console.log(data);
      callback();
      setClicked(false);
    }
  }, [clicked]);

  let rowIndex = 1;
  // render is an empty div
  const render = (
    <Tooltip title="Descargar Excel">
      <Button
        icon={<Icon component={IconDownload} />}
        type="link"
        onClick={(e) => {
          e.stopPropagation();
          console.log('download button clicked');
          setClicked(true);
        }}
      />
    </Tooltip>
  );
  return (
    <ExcelFile filename={filename} element={render}>
      {data && data.length && columns ? (
        <ExcelSheet data={data.map((x) => ({ ...x, row: rowIndex++ }))} name={sheetName}>
          {columns.map((column) => (
            <ExcelColumn key={column.label} label={column.label} value={column.value} />
          ))}
        </ExcelSheet>
      ) : (
        <></>
      )}
    </ExcelFile>
  );
}
ExcelFileButton.propTypes = {
  filename: PropTypes.string,
  sheetName: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  callback: PropTypes.func,
};

ExcelFileButton.defaultProps = {
  fetchData: () => [],
  columns: [],
  filename: 'TassAppFile',
  sheetName: 'Sheet 1',
  callback: () => {},
};

export default ExcelFileButton;

import React from "react";
import { Link } from "react-router-dom";
import { TassAppLogo } from "../../assets";
import "./style.less";

export function Error401() {
  return (
    <div className="error401">
      <img src={TassAppLogo} alt="" />
      <h1>Autentificación requerida</h1>
      <h4>Debes iniciar sesión para continuar</h4>
    </div>
  );
}

export function Error403() {
  return (
    <div className="error403">
      <img src={TassAppLogo} alt="" />
      <h1>Función restringida</h1>
      <h4>No tienes autorización para acceder</h4>
      <h4>
        Puedes volver a la página principal{" "}
        <Link to="/">
          <span>haciendo click aquí.</span>
        </Link>
      </h4>
    </div>
  );
}

export function Error404() {
  return (
    <div className="error404">
      <img src={TassAppLogo} alt="" />
      <h1>Página no encontrada</h1>
      <h4>Ups, lo sentimos mucho, hubo un error.</h4>
      <h4>
        Puedes volver a la página principal{" "}
        <Link to="/">
          <span>haciendo click aquí.</span>
        </Link>
      </h4>
    </div>
  );
}

export function Error500() {
  return (
    <div className="error500">
      <img src={TassAppLogo} alt="" />
      <h1>Hubo un error</h1>
      <h4>Ups, lo sentimos mucho, hubo un error.</h4>
      <h4>
        Puedes volver a la página principal{" "}
        <Link to="/">
          <span>haciendo click aquí.</span>
        </Link>
      </h4>
    </div>
  );
}

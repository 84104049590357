import React from 'react';
import InvestmentSimulator from '../../RenderViews/InvestmentSimulator';
import PropTypes from 'prop-types';
import { formatGetAppraisalsForInvestmentSimulator } from '../../_gqlFormatters/appraisal';

function AppraisalInvestmentSimulator({ appraisalData }) {
  return (
    appraisalData && (
      <>
        <div className="appraisalTitle">
          <h2>Simulación de inversión</h2>
        </div>
        <InvestmentSimulator data={formatGetAppraisalsForInvestmentSimulator(appraisalData)} />
      </>
    )
  );
}
const auxPricePropType = PropTypes.shape({
  UF: PropTypes.string,
  CLP: PropTypes.string,
});

AppraisalInvestmentSimulator.propTypes = {
  appraisalData: PropTypes.shape({
    id: PropTypes.number,
    estimatedSellPrice: auxPricePropType,
    estimatedSellPricePerArea: auxPricePropType,
    estimatedSellPriceMin: auxPricePropType,
    estimatedSellPriceMinPerArea: auxPricePropType,
    estimatedSellPriceMax: auxPricePropType,
    estimatedSellPriceMaxPerArea: auxPricePropType,
    estimatedRentPrice: auxPricePropType,
    estimatedRentPricePerArea: auxPricePropType,
    estimatedRentPriceMin: auxPricePropType,
    estimatedRentPriceMinPerArea: auxPricePropType,
    estimatedRentPriceMax: auxPricePropType,
    estimatedRentPriceMaxPerArea: auxPricePropType,
    estimatedYield: PropTypes.number,
    estimatedMinYield: PropTypes.number,
    estimatedMaxYield: PropTypes.number,
  }),
};

export default AppraisalInvestmentSimulator;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, message, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';
import { GET_MASSIVE_VALORIZATION_DETAILS } from '../../../graphql/queries';
import { DELETE_MASSIVE_APPRAISAL } from '../../../graphql/mutations/appraisals';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  IconTrash,
  IconDownload,
  selectSelected,
  selectSelectedLavander,
  selectUnselected,
  selectUnselectedLavander,
} from '../../../assets';

import PropTypes from 'prop-types';
import './style.less';

moment.locale('es');

function MassiveAppraisalList({ handleCheck, massiveAppraisals, selected, onDownload }) {
  return (
    <table className="massiveAppraisalsTable">
      <tbody>
        {massiveAppraisals.map((massiveAppraisal) => (
          <MassiveAppraisalListRow
            key={massiveAppraisal.id}
            massiveAppraisal={massiveAppraisal}
            handleCheck={handleCheck}
            selected={selected}
            onDownload={onDownload}
          />
        ))}
      </tbody>
    </table>
  );
}

MassiveAppraisalList.propTypes = {
  handleCheck: PropTypes.func,
  massiveAppraisals: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.object,
  onDownload: PropTypes.func,
};
MassiveAppraisalList.defaultProps = {
  handleCheck: () => {},
  onDownload: () => {},
  massiveAppraisals: [],
  selected: new Set(),
};

export default MassiveAppraisalList;

function MassiveAppraisalListRow({ massiveAppraisal, handleCheck, onDownload, selected }) {
  const [getExcelData] = useLazyQuery(GET_MASSIVE_VALORIZATION_DETAILS, {
    onCompleted: (data) => {
      onDownload([...data.getMassiveValorizationDetail], massiveAppraisal.id);
    },
  });
  const [deleteMassiveAppraisal] = useMutation(DELETE_MASSIVE_APPRAISAL, {
    variables: { massiveAppraisals: [massiveAppraisal.id] },
    onCompleted: () => {
      const htmlAppraisalElement = document.getElementById(`massive-${massiveAppraisal.id}`);
      htmlAppraisalElement.classList.add('deleted');
      setTimeout(() => {
        htmlAppraisalElement.remove();
      }, 1200);
      message.destroy(`massive-${massiveAppraisal.id}-delete`);
    },
  });
  return (
    <tr
      id={`massive-${massiveAppraisal.id}`}
      onClick={() => window.location.assign(`/massive-appraisals/${massiveAppraisal.id}`)}
    >
      {/* <CheckTableCell
        handleCheck={(action) => handleCheck(massiveAppraisal.id, action)}
        isSelected={selected.has(massiveAppraisal.id)}
      /> */}
      <td className="nameCell">{massiveAppraisal.name}</td>
      <td className="dateCell">{moment(massiveAppraisal.createdAt).fromNow()}</td>
      <td className="quantityCell">
        {massiveAppraisal.valorizationCount > 1 || massiveAppraisal.valorizationCount === 0
          ? `${massiveAppraisal.valorizationCount} propiedades`
          : '1 propiedad'}
      </td>
      <td className="actionCell">
        <Tooltip title="Descargar Excel">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              message.loading({
                content: `Generando Excel de ${massiveAppraisal.name}`,
                key: `massive-${massiveAppraisal.id}-excel`,
              });
              getExcelData({ variables: { id: massiveAppraisal.id, limit: 0, offset: 0 } });
            }}
            icon={<Icon component={IconDownload} />}
          ></Button>
        </Tooltip>
      </td>
      <td className="actionCell">
        <Tooltip title="Borrar valorización">
          <Button
            icon={
              <Icon
                component={IconTrash}
                onClick={(e) => {
                  e.stopPropagation();
                  const htmlAppraisalElement = document.getElementById(
                    `massive-${massiveAppraisal.id}`,
                  );
                  htmlAppraisalElement.classList.add('removing');
                  message.loading({
                    content: `Eliminando ${massiveAppraisal.name}`,
                    key: `massive-${massiveAppraisal.id}-delete`,
                  });
                  deleteMassiveAppraisal();
                }}
              />
            }
          ></Button>
        </Tooltip>
      </td>
    </tr>
  );
}

MassiveAppraisalListRow.propTypes = {
  handleCheck: PropTypes.func,
  massiveAppraisal: PropTypes.object,
  selected: PropTypes.object,
  onDownload: PropTypes.func,
};

function CheckTableCell({ handleCheck, isSelected }) {
  // const [isSelected, setIsSelected] = useState(false);

  return (
    <td className="checkCell">
      <div className="checkContainer">
        {isSelected ? (
          <img
            src={selectSelectedLavander}
            alt="select"
            onClick={(e) => {
              e.stopPropagation();
              handleCheck(false);
            }}
          />
        ) : (
          <img
            src={selectUnselectedLavander}
            alt="select"
            onClick={(e) => {
              e.stopPropagation();
              handleCheck(true);
            }}
          />
        )}
      </div>
    </td>
  );
}
CheckTableCell.propTypes = {
  handleCheck: PropTypes.func,
  isSelected: PropTypes.bool,
  setIsSelected: PropTypes.func,
};

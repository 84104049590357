import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Button as AntButton, Popover } from 'antd';
import Icon from '@ant-design/icons';

import { IconArrowDown } from '../../assets';
import './style.less';

export default function SortFilter({ label, defaultValue, values, callback }) {
  const largeButton = useMediaQuery({ minWidth: 1650 });
  const antButtonSize = largeButton ? 'large' : 'middle';

  return (
    <div className="filterPopover">
      <Popover
        content={
          <SortFilterContent
            defaultValue={defaultValue}
            values={values}
            callback={callback}
          ></SortFilterContent>
        }
        trigger="hover"
        placement={'bottom'}
      >
        <AntButton type="default" size={antButtonSize}>
          {label}
          <Icon component={IconArrowDown} />
        </AntButton>
      </Popover>
    </div>
  );
}

SortFilter.propTypes = {
  ...SortFilterContent.propTypes,
  label: PropTypes.string.isRequired,
};
SortFilter.defaultProps = {
  callback: undefined,
};

export function SortFilterContent({ defaultValue, values, callback }) {
  const [active, setActive] = useState(defaultValue);

  const handleClick = (value) => {
    setActive(value);
    if (callback) {
      callback(value);
    }
  };

  const getItemClasses = (value) => {
    const baseClasses = 'sortFilterItem';
    return value === active ? baseClasses + ' active' : baseClasses;
  };

  return (
    <div className="sortFilterContent">
      {values.map((category, categoryIdx) => (
        <div className="sortFilterCategory" key={`category-${categoryIdx}`}>
          {category.map(({ label, value }, valueIdx) => (
            <span
              onClick={() => handleClick(value)}
              className={getItemClasses(value)}
              key={`sortFilterItem${categoryIdx}-${valueIdx}`}
            >
              {label}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
}

SortFilterContent.propTypes = {
  defaultValue: PropTypes.any,
  values: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ),
  ).isRequired,
  callback: PropTypes.func,
};
SortFilterContent.defaultProps = {
  defaultValue: undefined,
  callback: undefined,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { GET_POINTS_OF_INTEREST_CATEGORIES } from '../../graphql/queries';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { categoryIcons } from '../../pointOfInteresCategories';
import './style.less';

function PointofInterest({ selected, handleSelected }) {
  const [categories, setCategories] = useState({});
  const [active, setActive] = useState(0);
  const isMobile = useMediaQuery({ minWidth: 767 });

  useQuery(GET_POINTS_OF_INTEREST_CATEGORIES, {
    onCompleted: (data) => {
      const desktop = [];
      for (let i = 0; i < data.getPointOfInterestCategories.length; i += 3) {
        const chunk = data.getPointOfInterestCategories.slice(i, i + 3);
        desktop.push(chunk);
      }
      const mobile = [];
      for (let i = 0; i < data.getPointOfInterestCategories.length; i += 2) {
        const chunk = data.getPointOfInterestCategories.slice(i, i + 2);
        mobile.push(chunk);
      }
      const sizes = { mobile, desktop };
      setCategories(sizes);
    },
  });

  return (
    <div className="pointsOfInterestContainers">
      <Button
        type="link"
        onClick={() => {
          active - 1 >= 0
            ? setActive(active - 1)
            : setActive(categories[!isMobile ? 'mobile' : 'desktop'].length - 1);
        }}
      >
        <LeftOutlined />
      </Button>
      {categories[!isMobile ? 'mobile' : 'desktop'] ? (
        categories[!isMobile ? 'mobile' : 'desktop'].map((x, i) => {
          return (
            <div key={i} className={active === i ? 'active' : 'hidden'}>
              {x.map((c) => {
                return (
                  <Button
                    key={c}
                    type="link"
                    onClick={() => handleSelected(c)}
                    icon={
                      <Icon
                        style={selected.has(c) ? { color: '#4124B5' } : { color: 'transparent' }}
                        component={categoryIcons[c]}
                      />
                    }
                  >
                    {c}
                  </Button>
                );
              })}
            </div>
          );
        })
      ) : (
        <></>
      )}
      <Button
        type="link"
        onClick={() => {
          active + 1 <= categories[!isMobile ? 'mobile' : 'desktop'].length - 1
            ? setActive(active + 1)
            : setActive(0);
        }}
      >
        <RightOutlined />
      </Button>
    </div>
  );
}
PointofInterest.propTypes = {
  selected: PropTypes.object,
  handleSelected: PropTypes.func,
};

export default PointofInterest;

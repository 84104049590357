/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Tooltip, Popconfirm } from 'antd';
import { propertyStatus } from './constants';
import './fragments.less';
import { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

function EstimationCard({ title, values, unit }) {
  return (
    <div className="estimationCardMain">
      <div className="estimationCard">
        <span className="estimationCardTitle">{title}</span>
        <span className="estimationCardPrice">
          {unit} {values.main}
        </span>
        <span className="estimationCardPricePerM2">
          {unit} {values.mainPerM2}/m²
        </span>
      </div>
      <div className="estimationCardSub">
        <div>Mínimo estimado</div>
        <div className="divider"></div>
        <div>Máximo estimado</div>
      </div>
      <div className="gradientBar">
        <Progress
          strokeColor={{ '0%': '#3E7BFA', '100%': '#6600CC' }}
          strokeWidth={15}
          percent={100}
          showInfo={false}
        />
      </div>
      <div className="estimationCardSub">
        <div className="limitEstimations">
          <div className="estimation">
            {unit} {values.min}
          </div>
          <div className="estimationPerM2">
            {unit} {values.minPerM2}/m²
          </div>
        </div>
        <div className="limitEstimations">
          <div className="estimation">
            {unit} {values.max}
          </div>
          <div className="estimationPerM2">
            {unit} {values.maxPerM2}/m²
          </div>
        </div>
      </div>
    </div>
  );
}

EstimationCard.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.shape({
    main: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mainPerM2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxPerM2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minPerM2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  unit: PropTypes.string,
};

EstimationCard.defaultProps = {
  unit: 'UF',
};

function EstimationCircle({ title, values, unit }) {
  return (
    <div className="estimationCircleMain">
      <h6>{title}</h6>
      <div className="estimationCircleSub">
        <div className="limitEstimations">
          <div className="estimationTitle">Mínimo estimado</div>
          <div className="estimationValue">
            {unit} {values.min}%
          </div>
        </div>
        <div className="estimationCircle">
          <div className="estimationCircleInner">{values.main}%</div>
        </div>
        <div className="limitEstimations">
          <div className="estimationTitle">Máximo estimado</div>
          <div className="estimationValue">
            {unit} {values.max}%
          </div>
        </div>
      </div>
    </div>
  );
}

EstimationCircle.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.shape({
    main: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  unit: PropTypes.string,
};

EstimationCircle.defaultProps = {
  unit: 'UF',
};

function StatusBox({ status, active, callback }) {
  return (
    <Popconfirm
      title="¿Cambiar la calidad de la propiedad?"
      okText="Si"
      cancelText="No"
      onConfirm={() => callback()}
      onCancel={() => {}}
    >
      <div className={`statusBox ${active && 'active'}`}>
        <div className={`statusState ${status}`} />
        <h1>{propertyStatus[status].title}</h1>
        <p>{propertyStatus[status].text}</p>
      </div>
    </Popconfirm>
  );
}

StatusBox.propTypes = {
  status: PropTypes.oneOf(['new', 'good', 'used', 'bad', 'awful']),
  active: PropTypes.bool,
  callback: PropTypes.func,
};

StatusBox.propTypes = {
  status: 'used',
  callback: () => {},
};

function PropertyStatusBoxes({ defaultActive, handleQualityChange }) {
  const [active, setActive] = useState(defaultActive);
  return (
    <div className="propertyStatusBox">
      <p className="statusBoxTitle">
        <span>
          <Tooltip
            placement="bottom"
            title={
              'Para poder calcularse mejor el valor de una propiedad, se le pide al usuario que seleccione una de estas alternativas, para poder ver en qué estado está dicha propiedad.'
            }
          >
            <InfoCircleOutlined />{' '}
          </Tooltip>
        </span>
        Selecciona la condición en la que se encuentra la propiedad:{' '}
      </p>
      <div className="propertyStatus">
        <StatusBox
          status="awful"
          active={active === 1 ? true : false}
          callback={() => {
            handleQualityChange(1);
            setActive(1);
          }}
        />
        <StatusBox
          status="bad"
          active={active === 2 ? true : false}
          callback={() => {
            handleQualityChange(2);
            setActive(2);
          }}
        />
        <StatusBox
          status="used"
          active={active === 3 ? true : false}
          callback={() => {
            handleQualityChange(3);
            setActive(3);
          }}
        />
        <StatusBox
          status="good"
          active={active === 4 ? true : false}
          callback={() => {
            handleQualityChange(4);
            setActive(4);
          }}
        />
        <StatusBox
          status="new"
          active={active === 5 ? true : false}
          callback={() => {
            handleQualityChange(5);
            setActive(5);
          }}
        />
      </div>
    </div>
  );
}

PropertyStatusBoxes.propTypes = {
  defaultActive: PropTypes.oneOf([1, 2, 3, 4, 5]),
  handleQualityChange: PropTypes.func,
};

PropertyStatusBoxes.defaultProps = {
  defaultActive: 3,
};

export { EstimationCard, EstimationCircle, PropertyStatusBoxes };

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_PUBLICATION_DETAILS } from '../../../graphql/queries';
import { Button, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { IconEditWhite, IconCheck } from '../../../assets';
import { parsePropertyDetail, parsePublicationAPI } from '../services';
import { WarningFilled } from '@ant-design/icons';

function PageHeader({
  publicationId,
  publicationData,
  userRole,
  editMode,
  setEditMode,
  fullScreenOption,
}) {
  const history = useHistory();

  if (!publicationData)
    return (
      <div className="componentTitle">
        <Spin />
      </div>
    );

  return (
    <div className="PageHeaderContainer">
      <div className="propertyHeaderContainer">
        <h2>{`${publicationData.typeText}`}</h2>
        {(userRole === 'admin' || userRole === 'superAdmin') && (
          <Button
            className={editMode ? 'btnSaveProp' : 'btnEditProp'}
            onClick={() => setEditMode(editMode ? false : true)}
            icon={<Icon component={editMode ? IconCheck : IconEditWhite} />}
          >
            {editMode ? 'Guardar edición' : 'Editar información'}
          </Button>
        )}
        {fullScreenOption ? (
          <Button
            className={'btnSaveProp'}
            onClick={() => history.push('/properties/' + publicationId + '/full')}
            icon={<></>}
          >
            {'Pantalla completa'}
          </Button>
        ) : (
          <></>
        )}
        <Button
          className={'btnSaveProp'}
          onClick={() => window.open(publicationData.externalSourceUrl, '_blank')}
          disabled={!publicationData.isActive}
          icon={publicationData.isActive ? <></> : <WarningFilled />}
        >
          {publicationData.isActive ? 'Ir al original' : 'Publicación no disponible'}
        </Button>
      </div>
      <div className="propertySubHeaderContainer">
        <h3>{publicationData.title}</h3>
        <div className="propertyPublishedPriceNumber">
          <div className="priceLabel">
            <h4>{publicationData.priceText}</h4>
          </div>
          <p>{publicationData.operationText}</p>
        </div>
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  publicationId: PropTypes.number,
  userRole: PropTypes.string,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  fullScreenOption: PropTypes.bool,
};

PageHeader.defaultProps = {
  editMode: false,
  fullScreenOption: true,
};

export default PageHeader;

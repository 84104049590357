import { makeVar } from "@apollo/client";

const makePersistentVar = (initialValue, config) => {
  const rv = makeVar(initialValue);
  const rvFn = function (newValue) {
    if (arguments.length > 0) {
      try {
        config.storage.setItem(config.storageKey, newValue);
        return rv(newValue);
      } catch {
        /**/
      }
    } else {
      return rv();
    }
  };
  const restore = async () => {
    try {
      const previousValue = await config.storage.getItem(config.storageKey);
      if (previousValue) {
        rv(previousValue);
      }
    } catch {
      /**/
    }
  };
  rvFn.onNextChange = rv.onNextChange;
  return [rvFn, restore];
};

export default makePersistentVar;

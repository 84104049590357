import backend from '../../backend';

export const fetchCollectionPublications = async function ({ userId, collectionId }) {
  const params = {
    user_id: userId,
    collection_id: collectionId,
    fields: {
      id: true,
      title: true,
      address_line: true,
      condition: true,
      operation: true,
      property_type: true,
      construction_year: true,
      total_area: true,
      covered_area: true,
      created_time: true,
      description: true,
      external_source_id: true,
      external_source_url: true,
      first_time_found: true,
      full_bathrooms: true,
      rooms: true,
      bedrooms: true,
      warehouses: true,
      last_published_price: true,
      last_published_price_per_total_area: true,
      last_published_price_per_covered_area: true,
      last_time_found: true,
      location: true,
      maintenance_cost: true,
      pictures: true,
      source: true,
      region: true,
      country: true,
      region: true,
      province: true,
      municipality: true,
      district: true,
      zone_or_locality: true,
      block_or_entity: true,
      is_active: true,
      is_hidden: true,
      estimated_sale_price: true,
      estimated_rental_price: true,
      estimated_yield: true,
    },
  };
  const response = await backend.post(
    '/real_estate_market/fetch_publications_in_collections/',
    params,
  );
  return response.data.response.publications;
};

export const fetchFavoritePublicationIds = async function (currentUserId) {
  const params = {
    user_id: currentUserId,
    fields: {
      id: true,
    },
  };
  const response = await backend.post(
    '/real_estate_market/fetch_user_favorite_publications/',
    params,
  );
  return response.data.response.map((x) => x.id);
};

export const fetchPublications = async function ({ publicationIds, userId }) {
  const params = {
    publication_ids: publicationIds,
    user_id: userId,
    fields: {
      id: true,
      title: true,
      address_line: true,
      condition: true,
      operation: true,
      property_type: true,
      construction_year: true,
      total_area: true,
      covered_area: true,
      created_time: true,
      description: true,
      external_source_id: true,
      external_source_url: true,
      first_time_found: true,
      full_bathrooms: true,
      rooms: true,
      bedrooms: true,
      warehouses: true,
      last_published_price: true,
      last_published_price_per_total_area: true,
      last_published_price_per_covered_area: true,
      last_time_found: true,
      location: true,
      maintenance_cost: true,
      pictures: true,
      source: true,
      region: true,
      country: true,
      region: true,
      province: true,
      municipality: true,
      district: true,
      zone_or_locality: true,
      block_or_entity: true,
      is_active: true,
      is_hidden: true,
      estimated_sale_price: true,
      estimated_rental_price: true,
      estimated_yield: true,
    },
  };
  const response = await backend.post('/real_estate_market/fetch_publications_from_ids/', params);
  return response.data.response;
};

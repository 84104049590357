import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_VALORIZATIONS } from '../../../graphql/queries';
import './style.less';
import Loading from '../../Loading/index.jsx';
import AppraisalList from '../../Appraisal/AppraisalList';
import EmptyView from '../EmptyView';

function UserProfileAppraisals() {
  const [appraisals, setAppraisals] = useState([]);
  const [appraisalIndex, setAppraisalIndex] = useState(0);
  const [getMoreData, setGetMoreData] = useState(true);

  function handleScroll(e) {
    const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
    if (bottom && getMoreData) {
      refetch({ limit: 10, offset: (appraisalIndex + 1) * 10 });
      setAppraisalIndex((old) => old + 1);
    }
  }

  const { loading, refetch } = useQuery(GET_CURRENT_USER_VALORIZATIONS, {
    variables: { limit: 10, offset: appraisalIndex * 10 },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.getMyValorizations.length === 0) {
        setGetMoreData(false);
      }
      setAppraisals((old) => [...old, ...data.getMyValorizations]);
    },
  });

  return (
    <div className="userAppraisalsContainer" onScroll={(e) => handleScroll(e)}>
      <AppraisalList appraisals={appraisals} />
      {loading && <Loading extraClass={`${appraisals.length !== 0 && 'noMargin'}`} />}
      {!loading && appraisals.length === 0 && (
        <EmptyView viewName={'valorizaciones'} typeOf={'appraisal'} />
      )}
    </div>
  );
}

export default UserProfileAppraisals;

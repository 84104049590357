import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { userRoleVar, restoreUserRole } from './graphql/cache';
import './App.less';
import Landing from './components/Landing';
import Login from './components/Login';
import AppraisalLanding from './components/Appraisal/Landing';
import SearchLanding from './components/Properties/Landing';
import Properties from './components/Properties';
import Navbar from './components/Nav';
import Appraisal from './components/Appraisal';
import UserProfileMenu from './components/UserProfile';
// import UserAdminView from './components/UserProfile/AdminView';
import MassiveAppraisal from './components/MassiveAppraisal/Form';
import ModalAuth from './components/Modals/Auth';
import AppraisalForm from './components/Appraisal/AppraisalForm';
import MassiveAppraisalSummary from './components/MassiveAppraisal/Summary';
import Indicators from './components/Indicators';
import { Error404, Error500 } from './components/Errors';
import UsersManagement from './components/Admin/UsersManagement';
import PrivateRoute from './components/PrivateRoute';

import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import { AccessDenied } from './components/Access';
import { useUserAuth } from './context/AuthContext';
import { getUser } from './actions/users';

const { Content } = Layout;

function App() {
  const [userRole, setUserRole] = useState(null);//useReactiveVar(userRoleVar);
  const { user: userAuth } = useUserAuth();
  const [modalAuthType, setModalAuthType] = useState();
  const [appraisalFormVisible, setAppraisalFormVisible] = useState(false);
  const [appraisalFormPropertyType, setAppraisalFormPropertyType] = useState();
  const [appraisalFormAddress, setAppraisalFormAddress] = useState({});

  useEffect(() => {
    let isMounted = true;

    const fetchUser = async () => {
      try {
        const user = await getUser({ uid: userAuth.uid });
        console.log(user);
        if (user && user.admin && isMounted) {
          setUserRole('admin');
        }
        if (user && user.pro && isMounted) {
          setUserRole('pro');
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [getUser, userAuth]);

  return (
    <ConfigProvider locale={esES}>
      <Router>
        <AppraisalForm
          visible={appraisalFormVisible}
          setVisible={setAppraisalFormVisible}
          propertyType={appraisalFormPropertyType}
          address={appraisalFormAddress}
          setPropertyType={setAppraisalFormPropertyType}
          setAddress={(address) => setAppraisalFormAddress(address)}
          setModalAuthType={setModalAuthType}
        />
        <ModalAuth authType={modalAuthType} setAuthType={setModalAuthType} />
        <Layout>
          <Navbar
            setModalAuthType={setModalAuthType}
            modalAuthType={modalAuthType}
            appraisalFormVisible={appraisalFormVisible}
            setAppraisalFormVisible={setAppraisalFormVisible}
          />
          <ModalAuth authType={modalAuthType} setAuthType={setModalAuthType}></ModalAuth>
          <Content>
            <Switch>
              <Route
                path="/"
                exact
                component={() => {
                  switch (userRole) {
                    case null:
                      return <Login />;
                    case 'pro':
                      return (
                        <Landing
                          appraisalFormPropertyType={appraisalFormPropertyType}
                          appraisalFormAddress={appraisalFormAddress}
                          setAppraisalFormPropertyType={setAppraisalFormPropertyType}
                          setAppraisalFormAddress={setAppraisalFormAddress}
                          setAppraisalFormVisible={setAppraisalFormVisible}
                        />
                      );
                    case 'admin':
                      return <UsersManagement />;
                    default:
                      return <Login />; // Or whatever default component you'd like to render.
                  }
                }}
              />

              <Route
                path="/login"
                exact
                component={() => (
                  <Login />
                )}
              />
              <Route
                path="/appraisal"
                exact
                component={() => (
                  <AppraisalLanding
                    appraisalFormPropertyType={appraisalFormPropertyType}
                    appraisalFormAddress={appraisalFormAddress}
                    setAppraisalFormPropertyType={setAppraisalFormPropertyType}
                    setAppraisalFormAddress={setAppraisalFormAddress}
                    setAppraisalFormVisible={setAppraisalFormVisible}
                  />
                )}
              />
              <Route path="/search" exact component={() => <SearchLanding />} />
              <Route path="/access-denied" component={AccessDenied} />
              <PrivateRoute
                path={['/properties', '/properties/:id']}
                exact
                component={Properties}
                permissions="properties"
              />
              <PrivateRoute
                path={['/properties/:id/full']}
                exact
                component={Properties}
                permissions="properties"
              />
              <Route path="/appraisals/:id" exact component={Appraisal} />
              <PrivateRoute
                path="/massive-appraisals"
                exact
                component={MassiveAppraisal}
                permissions="massive-appraisals"
              />
              <Route
                path="/massive-appraisals/:id"
                exact
                component={MassiveAppraisalSummary}
                permissions="massive-appraisals"
              />
              <PrivateRoute
                path="/metrics"
                exact
                component={Indicators}
                permissions="massive-appraisals"
              />
              <Route path="/user" component={UserProfileMenu} permissions="profile" />
              {/* <Route path="/users/:id" component={UserAdminView} permissions="otherProfile" /> */}
              <Route path="/404" component={Error404} />
              <Route path="/500" component={Error500} />
            </Switch>
          </Content>
        </Layout>
      </Router>
    </ConfigProvider>
  );
}

export default App;

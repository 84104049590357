function grantAccess(role, permissions) {
  switch (role) {
    case 'free':
      switch (permissions) {
        case 'properties':
          return false;
        case 'profile':
          return true;
        default:
          return false;
      }
    case 'pro':
      switch (permissions) {
        case 'properties':
          return true;
        case 'massive-appraisals':
          return true;
        case 'profile':
          return true;
        default:
          return false;
      }
    case 'admin':
      return true;
    default:
      return false;
  }
}

export default grantAccess;

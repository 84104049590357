import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { userRoleVar } from '../../graphql/cache';
import { useLazyQuery, useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { UF_CLP } from '../../constants';
import { HIDE_PUBLICATION, UNHIDE_PUBLICATION } from '../../graphql/mutations/publications';
import PropertiesFilters from './Filters';
import PropertySummary from './PublicationDetailPage';
import AddCollectionModal from '../Modals/AddCollection';
import CardGrid from '../Cards/CardGrid';
import Map from '../Map';
import { filtersInit } from './constants';
import getAlerts from '../Alerts/Alert';
import Icon from '@ant-design/icons';
import { IconReload, IconCross } from '../../assets';
import SwipeUp, { SwipeContent } from '../RenderViews/SwipeUp';
import {
  searchPublications,
  fetchPublications,
  addPublicationsToFavorites,
  removePublicationsFromFavorites,
} from './backend';
import { parsePublicationPreviewsAPI, parsePublicationMarkersAPI } from './services';
import './style.less';

import { useLocalStorage } from '../../hooks';

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function Properties() {
  // Hooks
  const history = useHistory();
  const query = useQueryParams();
  const userRole = useReactiveVar(userRoleVar);

  // States
  const [selectedProperties, setSelectedProperties] = useState(new Set());
  const [gridLayout, setGridLayout] = useState('vertical');
  const [resultsInsteadOfMap, setResultsInsteadOfMap] = useState(false);
  const [propertyOnHook, setPropertyOnHook] = useState();
  const [searchedAddress, setSearchedAddress] = useState();
  const [filters, setFilters] = useLocalStorage('filters', filtersInit(query));
  const [viewPort, setViewport] = useLocalStorage('viewPort', {
    zoom: 15,
    latitude: -33.4082354,
    longitude: -70.5668972,
  });
  const [propertiesPreviewData, setPropertiesPreviewData] = useState([]);
  const [selectedPropertiesPreviewData, setSelectedPropertiesPreviewData] = useState([]);
  const [propertyCardsIndex, setPropertyCardsIndex] = useState({
    index: 1,
    max: 12,
  });
  const [polygonFilter, setPolygonFilter] = useLocalStorage('polygonFilter', null);
  // boolean if polygon filter is viewPort
  const [polygonFilterIsViewPort, setPolygonFilterIsViewPort] = useLocalStorage(
    'polygonFilterIsViewPort',
    false,
  );

  const [activePriceUnit, setActivePriceUnit] = useState('UF');
  const [markersData, setMarkersData] = useState();
  const [collectionModalIsVisible, setCollectionModalIsVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const undoFavorite = useRef(false);
  const cancelFavoriteQuery = useRef(false);
  const { id } = useParams();
  const { pathname } = useLocation();
  const isFullSize = pathname == '/properties/' + id + '/full';
  const propertyId = Number(id);
  const [loading, setLoading] = useState(true);

  const clearSelectedProperties = () => {
    setSelectedProperties(new Set());
    setSelectedPropertiesPreviewData([]);
  };

  const { openMessage, closeMessage, updateErrorMessage, updateSuccessMessage } = getAlerts(
    'favorite',
  );

  const [hidePublication] = useMutation(HIDE_PUBLICATION);

  const [unHidePublication] = useMutation(UNHIDE_PUBLICATION);

  function handleScroll(e) {
    // bottom is true is we are at the 90% of the page
    const bottom = e.target.clientHeight / 0.9 >= e.target.scrollHeight - e.target.scrollTop;
    if (bottom) {
      setPropertyCardsIndex({
        max: propertyCardsIndex.max,
        index: propertyCardsIndex.index + 1,
      });
    }
  }

  // Effects

  useEffect(() => {
    setLoading(true);
    setPropertiesPreviewData([]);
    setMarkersData();
    setPropertyCardsIndex({
      index: 1,
      max: 12,
    });
    if (polygonFilter && polygonFilter.coordinates && polygonFilter.coordinates[0].length > 0) {
      /*
      getPropertiesFromFilter({
        variables: {
          propertyType: filters.propertyTypeFilter,
          operationType: filters.operationTypeFilter,
          conditionType: filters.conditionFilter,
          publishedPriceMin:
            activePriceUnit === 'CLP'
              ? parseFloat(filters.publishedPriceFilter.minValue / UF_CLP)
              : parseFloat(filters.publishedPriceFilter.minValue),
          publishedPriceMax:
            activePriceUnit === 'CLP'
              ? parseFloat(filters.publishedPriceFilter.maxValue / UF_CLP)
              : parseFloat(filters.publishedPriceFilter.maxValue),
          roomsMin: parseInt(filters.roomsFilter.minValue, 10),
          roomsMax:
            filters.roomsFilter.maxValue === 6 ? null : parseInt(filters.roomsFilter.maxValue, 10),
          bathroomsMin: parseInt(filters.bathroomsFilter.minValue, 10),
          bathroomsMax:
            filters.bathroomsFilter.maxValue === 6
              ? null
              : parseInt(filters.bathroomsFilter.maxValue, 10),
          coveredAreaMin: parseFloat(filters.coveredAreaFilter.minValue),
          coveredAreaMax: parseFloat(filters.coveredAreaFilter.maxValue),
          totalAreaMin: parseFloat(filters.totalAreaFilter.minValue),
          totalAreaMax: parseFloat(filters.totalAreaFilter.maxValue),
          polygon: JSON.stringify(polygonFilter),
          publishedDateMin: filters.publishedDateFilter,
          sortBy: filters.sortFilter,
          disponibility: filters.disponibilityFilter === 'active' ? true : false,
        },
      });
      */
      // API promise call
      searchPublications({
        polygon: JSON.stringify(polygonFilter),
        property_type: filters.propertyTypeFilter,
        operation_type: filters.operationTypeFilter,
        condition_type: filters.conditionFilter,
        published_price__min:
          activePriceUnit === 'CLP'
            ? parseFloat(filters.publishedPriceFilter.minValue / UF_CLP)
            : parseFloat(filters.publishedPriceFilter.minValue),
        published_price__max:
          activePriceUnit === 'CLP'
            ? parseFloat(filters.publishedPriceFilter.maxValue / UF_CLP)
            : parseFloat(filters.publishedPriceFilter.maxValue),
        rooms__min: parseInt(filters.roomsFilter.minValue, 10),
        rooms__max:
          filters.roomsFilter.maxValue === 6 ? null : parseInt(filters.roomsFilter.maxValue, 10),
        bathrooms__min: parseInt(filters.bathroomsFilter.minValue, 10),
        bathrooms__max:
          filters.bathroomsFilter.maxValue === 6
            ? null
            : parseInt(filters.bathroomsFilter.maxValue, 10),
        covered_area__min: parseFloat(filters.coveredAreaFilter.minValue),
        covered_area__max: parseFloat(filters.coveredAreaFilter.maxValue),
        total_area__min: parseFloat(filters.totalAreaFilter.minValue),
        total_area__max: parseFloat(filters.totalAreaFilter.maxValue),
        polygon: JSON.stringify(polygonFilter),
        published_date__min: filters.publishedDateFilter,
        sort_by: filters.sortFilter,
        exclude_non_active: filters.disponibilityFilter === 'active' ? true : false,
        exclude_hidden: true,
        fields: {
          id: true,
          last_published_price: true,
          operation: true,
          title: true,
          location: true,
          created_time: true,
          first_time_found: true,
          last_time_found: true,
          source: true,
          is_active: true,
          is_hidden: true,
          property_type: true,
          total_area: true,
          rooms: true,
          covered_area: true,
          bedrooms: true,
          full_bathrooms: true,
          municipality: true,
          pictures: true,
          pictures_minimized: true,
          reference_picture: true,
          last_published_price_per_covered_area: true,
          last_published_price_per_total_area: true,
        },
      }).then((publications) => {
        setMarkersData(parsePublicationMarkersAPI(publications));
        const publicationIds = publications.map((publication) => publication.id);
        fetchPublications({
          publication_ids: publicationIds.slice(
            0,
            propertyCardsIndex.max * propertyCardsIndex.index,
          ),
          user_id: currentUser,
          fields: {
            id: true,
            is_favorite: true,
            title: true,
            address_line: true,
            condition: true,
            operation: true,
            property_type: true,
            construction_year: true,
            total_area: true,
            covered_area: true,
            created_time: true,
            description: true,
            external_source_id: true,
            external_source_url: true,
            first_time_found: true,
            full_bathrooms: true,
            rooms: true,
            bedrooms: true,
            warehouses: true,
            last_published_price: true,
            last_published_price_per_total_area: true,
            last_published_price_per_covered_area: true,
            last_time_found: true,
            location: true,
            maintenance_cost: true,
            pictures: true,
            pictures_minimized: true,
            source: true,
            region: true,
            country: true,
            region: true,
            province: true,
            municipality: true,
            district: true,
            zone_or_locality: true,
            block_or_entity: true,
            is_active: true,
            is_hidden: true,
            estimated_sale_price: true,
            estimated_rental_price: true,
            estimated_yield: true,
          },
        })
          .then((publications) => {
            setPropertiesPreviewData(parsePublicationPreviewsAPI(publications));
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      });
    }
  }, [
    polygonFilter,
    filters.propertyTypeFilter,
    filters.operationTypeFilter,
    filters.conditionFilter,
    filters.publishedPriceFilter,
    filters.roomsFilter,
    filters.bathroomsFilter,
    filters.coveredAreaFilter,
    filters.totalAreaFilter,
    filters.sortFilter,
    filters.publishedDateFilter,
    filters.disponibilityFilter,
    currentUser,
  ]);

  useEffect(() => {
    if (!polygonFilter) {
      setPolygonFilterIsViewPort(true);
    }
  }, [polygonFilter]);

  useEffect(() => {
    if (polygonFilterIsViewPort) {
      setPolygonFilter(viewPort.polygon);
    }
  }, [polygonFilterIsViewPort, viewPort]);

  useEffect(() => {
    if (markersData) {
      const publicationIds = markersData.map((x) => x.id);
      // TODO: remove null values, replace with empty list
      const alreadyFetchedPublicationIds = propertiesPreviewData.map((x) => x.id);
      fetchPublications({
        // TODO: only fetch marginal increment
        user_id: currentUser,
        publication_ids: publicationIds
          .slice(0, propertyCardsIndex.max * propertyCardsIndex.index)
          .filter((publicationId) => !alreadyFetchedPublicationIds.includes(publicationId)),
        fields: {
          id: true,
          is_favorite: true,
          title: true,
          address_line: true,
          condition: true,
          operation: true,
          property_type: true,
          construction_year: true,
          total_area: true,
          covered_area: true,
          created_time: true,
          description: true,
          external_source_id: true,
          external_source_url: true,
          first_time_found: true,
          full_bathrooms: true,
          rooms: true,
          bedrooms: true,
          warehouses: true,
          last_published_price: true,
          last_published_price_per_total_area: true,
          last_published_price_per_covered_area: true,
          last_time_found: true,
          location: true,
          maintenance_cost: true,
          pictures: true,
          pictures_minimized: true,
          source: true,
          region: true,
          country: true,
          region: true,
          province: true,
          municipality: true,
          district: true,
          zone_or_locality: true,
          block_or_entity: true,
          is_active: true,
          is_hidden: true,
          estimated_sale_price: true,
          estimated_rental_price: true,
          estimated_yield: true,
        },
      }).then((publications) => {
        setPropertiesPreviewData([
          ...propertiesPreviewData,
          ...parsePublicationPreviewsAPI(publications),
        ]);
      });
    }
  }, [propertyCardsIndex]);

  const isDesktop = useMediaQuery({ minWidth: 1290 });
  const forceGrid = useMediaQuery({ maxWidth: 767 });
  const isMobile = useMediaQuery({ maxWidth: 480 });

  useEffect(() => {
    if (forceGrid) {
      setGridLayout('vertical');
    }
  }, [isMobile]);

  const onCardClick = (property) => {
    history.push(`/properties/${property.id}`);
  };

  const handleSelectPublications = ({ publicationIds }) => {
    setSelectedProperties(new Set(publicationIds));
    fetchPublications({
      // TODO: only fetch marginal increment
      publication_ids: publicationIds,
      fields: {
        id: true,
        title: true,
        address_line: true,
        condition: true,
        operation: true,
        property_type: true,
        construction_year: true,
        total_area: true,
        covered_area: true,
        created_time: true,
        description: true,
        external_source_id: true,
        external_source_url: true,
        first_time_found: true,
        full_bathrooms: true,
        rooms: true,
        bedrooms: true,
        warehouses: true,
        last_published_price: true,
        last_published_price_per_total_area: true,
        last_published_price_per_covered_area: true,
        last_time_found: true,
        location: true,
        maintenance_cost: true,
        pictures: true,
        pictures_minimized: true,
        source: true,
        region: true,
        country: true,
        region: true,
        province: true,
        municipality: true,
        district: true,
        zone_or_locality: true,
        block_or_entity: true,
        is_active: true,
        is_hidden: true,
        estimated_sale_price: true,
        estimated_rental_price: true,
        estimated_yield: true,
      },
    }).then((publications) => {
      setSelectedPropertiesPreviewData([...parsePublicationPreviewsAPI(publications)]);
    });
  };

  const handleSelectProperty = (paramId, isSelected, propertyPreviewData = null) => {
    // legacy code, we should try to remove it
    const newSet = new Set(selectedProperties);
    if (!isSelected) {
      newSet.add(paramId);
      // TODO: fix this way of using propertyPreviewData
      setSelectedPropertiesPreviewData([
        ...selectedPropertiesPreviewData,
        propertyPreviewData
          ? propertyPreviewData
          : propertiesPreviewData.find((x) => x.id === paramId),
      ]);
    } else {
      newSet.delete(paramId);
      setSelectedPropertiesPreviewData(
        selectedPropertiesPreviewData.filter((x) => x.id != paramId),
      );
    }
    setSelectedProperties(newSet);
  };

  if (propertyId && !isDesktop | isFullSize) {
    return <PropertySummary propertyId={propertyId} userRole={userRole} />;
  }
  const showCollectionModal = () => {
    setCollectionModalIsVisible(true);
  };

  const handleHideSelectedProperties = () => {
    hidePublication({ variables: { ids: Array.from(selectedProperties) } });
    clearSelectedProperties();
  };

  const handleShowSelectedProperties = () => {
    unHidePublication({ variables: { ids: Array.from(selectedProperties) } });
    clearSelectedProperties();
  };

  const handleUserLikePublications = ({ publicationIds }) => {
    setTimeout(() => {
      if (!cancelFavoriteQuery.current) {
        openMessage(<p>Cargando ...</p>);
        addPublicationsToFavorites({
          userId: currentUser,
          publicationIds: publicationIds,
        }).then((response) => {
          updateSuccessMessage(
            <>
              <p>Propiedad guardada exitosamente en favoritos</p>
            </>,
            5,
          );
          /*
          <Button
            onClick={() => {
              undoFavorite.current = true;
              closeMessage();
            }}
          >
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </Button>
          */
        });
      } else {
        cancelFavoriteQuery.current = false;
      }
    }, 50);
  };

  const handleUserRemoveLikePublications = ({ publicationIds }) => {
    setTimeout(() => {
      if (!cancelFavoriteQuery.current) {
        openMessage(<p>Cargando ...</p>);
        removePublicationsFromFavorites({
          userId: currentUser,
          publicationIds: publicationIds,
        }).then((response) => {
          console.log(response);
          updateSuccessMessage(
            <>
              <p>Propiedad borrada de favoritos</p>
            </>,
            5,
          );
          /*
          <Button
            onClick={() => {
              undoFavorite.current = true;
              closeMessage();
            }}
          >
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </Button>
          */
        });
      } else {
        cancelFavoriteQuery.current = false;
      }
    }, 50);
  };

  const setPolygonCallback = (polygon) => {
    // setPolygonFilter as null if it is empty or undefined
    if (polygon && polygon.coordinates && polygon.coordinates[0].length > 0) {
      for (let i = 0; i < polygon.coordinates[0].length; i += 1) {
        if (polygonFilter && polygonFilter.coordinates[0][i]) {
          if (
            polygon.coordinates[0][i][0] !== polygonFilter.coordinates[0][i][0] ||
            polygon.coordinates[0][i][1] !== polygonFilter.coordinates[0][i][1]
          ) {
            setPolygonFilter(polygon);
            break;
          }
        } else {
          setPolygonFilter(polygon);
          break;
        }
      }
    } else {
      setPolygonFilter(null);
      setPolygonFilterIsViewPort(true);
    }
  };

  const hideResults = !isDesktop && !resultsInsteadOfMap;
  return (
    <div className="searchView">
      {isDesktop && (
        <>
          <Modal
            visible={propertyId}
            onCancel={() => history.goBack()}
            footer={null}
            closable={false}
            className="property-summary-modal"
            width={960}
            destroyOnClose
            centered
          >
            <PropertySummary propertyId={propertyId} userRole={userRole} />
          </Modal>

          <AddCollectionModal
            collectionModalIsVisible={collectionModalIsVisible}
            selectedProperties={selectedProperties}
            setCollectionModalIsVisible={setCollectionModalIsVisible}
            currentUser={currentUser}
          />

          <PropertiesFilters
            referenceLocation={searchedAddress ? searchedAddress.coords : null}
            filters={filters}
            setFilters={setFilters}
            propertiesPreviewData={propertiesPreviewData}
            selectedPropertiesPreviewData={selectedPropertiesPreviewData}
            activePriceUnit={activePriceUnit}
            setActivePriceUnit={setActivePriceUnit}
            markersLoading={loading}
            setGridLayout={setGridLayout}
            setResultsInsteadOfMap={() => setResultsInsteadOfMap(!resultsInsteadOfMap)}
            resultsInsteadOfMap={resultsInsteadOfMap}
            selectedProperties={selectedProperties}
            likeSelectedProperties={() => {
              handleUserLikePublications({ publicationIds: Array.from(selectedProperties) });
            }}
            clearSelectedProperties={() => clearSelectedProperties()}
            showCollectionsModal={showCollectionModal}
            hideSelectedProperties={handleHideSelectedProperties}
            showSelectedProperties={handleShowSelectedProperties}
            selectAll={() => {
              handleSelectPublications({ publicationIds: markersData.map((x) => x.id) });
            }}
            userRole={userRole}
          />

          <CardGrid
            handleScroll={loading ? () => {} : handleScroll}
            currentIndex={propertyCardsIndex.max * propertyCardsIndex.index}
            loadingMore={loading}
            chosenFilter={filters.sortFilter}
            gridLayout={gridLayout}
            propertiesPreviewData={propertiesPreviewData}
            onCardClick={onCardClick}
            seeMore={
              propertiesPreviewData.length > propertyCardsIndex.max * propertyCardsIndex.index
            }
            setPropertyOnHook={setPropertyOnHook}
            handleUserLikePublications={handleUserLikePublications}
            handleUserRemoveLikePublications={handleUserRemoveLikePublications}
            handleSelectProperty={handleSelectProperty}
            selectedProperties={selectedProperties}
            hidden={hideResults}
            activePriceUnit={activePriceUnit}
          />
          <div className="mapContainer">
            <div className="map">
              <Map
                forcePopup={propertyOnHook}
                onCardClick={onCardClick}
                address={
                  query.get('searchAddress') !== 'undefined' && query.get('searchAddress') !== null
                    ? {
                        value: query.get('searchAddress'),
                        coords: {
                          latitude: parseFloat(query.get('latitude')),
                          longitude: parseFloat(query.get('longitude')),
                        },
                      }
                    : undefined
                }
                directionCallback={(x) => setSearchedAddress(x)}
                markersData={markersData ? markersData : []}
                isLoadingMarkers={loading}
                initialViewPort={viewPort}
                setViewportCallback={(view) => {
                  setViewport({
                    zoom: view.zoom,
                    latitude: view.latitude,
                    longitude: view.longitude,
                    polygon: view.viewPortPolygon,
                  });
                }}
                polygonFilter={polygonFilterIsViewPort ? null : polygonFilter}
                setPolygonCallback={(polygon) => {
                  if (polygon) {
                    setPolygonFilterIsViewPort(false);
                    setPolygonCallback(polygon);
                  } else {
                    setPolygonFilterIsViewPort(true);
                    setPolygonCallback(null);
                  }
                }}
                handleSelectProperty={handleSelectProperty}
                selectedProperties={selectedProperties}
                currentUserId={currentUser}
              />
            </div>
          </div>
        </>
      )}
      {!isDesktop && (
        <SwipeUp>
          <SwipeContent>
            <div className="mapContainer" style={{ height: '100%' }}>
              <div className="map">
                <Map
                  forcePopup={propertyOnHook}
                  onCardClick={onCardClick}
                  address={
                    query.get('searchAddress') !== 'undefined' &&
                    query.get('searchAddress') !== null
                      ? {
                          value: query.get('searchAddress'),
                          coords: {
                            latitude: parseFloat(query.get('latitude')),
                            longitude: parseFloat(query.get('longitude')),
                          },
                        }
                      : undefined
                  }
                  directionCallback={(x) => setSearchedAddress(x)}
                  markersData={markersData ? markersData : []}
                  isLoadingMarkers={loading}
                  initialViewPort={viewPort}
                  setViewportCallback={(view) => {
                    setViewport({
                      zoom: view.zoom,
                      latitude: view.latitude,
                      longitude: view.longitude,
                      polygon: view.viewPortPolygon,
                    });
                  }}
                  polygonFilter={polygonFilterIsViewPort ? null : polygonFilter}
                  setPolygonCallback={(polygon) => {
                    if (polygon) {
                      setPolygonFilterIsViewPort(false);
                      setPolygonCallback(polygon);
                    } else {
                      setPolygonFilterIsViewPort(true);
                      setPolygonCallback(null);
                    }
                  }}
                  handleSelectProperty={handleSelectProperty}
                  selectedProperties={selectedProperties}
                  currentUserId={currentUser}
                />
              </div>
            </div>
          </SwipeContent>
          <SwipeContent style={{ paddingTop: '50px' }}>
            <PropertiesFilters
              referenceLocation={searchedAddress ? searchedAddress.coords : null}
              filters={filters}
              setFilters={setFilters}
              propertiesPreviewData={propertiesPreviewData}
              selectedPropertiesPreviewData={selectedPropertiesPreviewData}
              activePriceUnit={activePriceUnit}
              setActivePriceUnit={setActivePriceUnit}
              markersLoading={loading}
              setGridLayout={setGridLayout}
              setResultsInsteadOfMap={() => setResultsInsteadOfMap(!resultsInsteadOfMap)}
              resultsInsteadOfMap={resultsInsteadOfMap}
              selectedProperties={selectedProperties}
              likeSelectedProperties={() => {
                handleUserLikePublications({ publicationIds: Array.from(selectedProperties) });
              }}
              clearSelectedProperties={() => clearSelectedProperties()}
              showCollectionsModal={showCollectionModal}
              hideSelectedProperties={handleHideSelectedProperties}
              showSelectedProperties={handleShowSelectedProperties}
              userRole={userRole}
            />
            <CardGrid
              handleScroll={loading ? () => {} : handleScroll}
              currentIndex={propertyCardsIndex.max * propertyCardsIndex.index}
              loadingMore={loading}
              chosenFilter={filters.sortFilter}
              gridLayout={'horizontal'}
              propertiesPreviewData={propertiesPreviewData}
              onCardClick={onCardClick}
              seeMore={
                propertiesPreviewData.length > propertyCardsIndex.max * propertyCardsIndex.index
              }
              setPropertyOnHook={setPropertyOnHook}
              handleLikeProperties={() => {
                handleUserLikePublications({ publicationIds: Array.from(selectedProperties) });
              }}
              handleSelectProperty={handleSelectProperty}
              selectedProperties={selectedProperties}
              activePriceUnit={activePriceUnit}
            />
          </SwipeContent>
        </SwipeUp>
      )}
    </div>
  );
}

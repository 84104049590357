/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  GET_CURRENT_USER_MASSIVE_VALORIZATIONS,
  GET_MASSIVE_VALORIZATION_DETAILS,
} from '../../../graphql/queries';
import { DELETE_MASSIVE_APPRAISAL } from '../../../graphql/mutations/appraisals'; // TODO: Remove
import { Button, Tooltip, message } from 'antd';
import Icon from '@ant-design/icons';
import MassiveAppraisalList from '../../MassiveAppraisal/MassiveAppraisalList';
import EmptyView from '../EmptyView';
import Loading from '../../Loading';
import { IconClose, IconTrash, IconDownload } from '../../../assets';
import './style.less';
import { createAndDownloadExcel } from './services';
import GroupList from '../../GroupList';
import { generateExcelFile } from './excelFile';
import { parsePublicationAPI } from '../../parsers';
import { deleteCollection, fetchPublications, getUserCollections } from './backend';

function CollectionGroup({ userId, setSelectedCollection, setSelectedCollectionPublications }) {
  const [loading, setLoadingCollections] = useState(true);
  const [userCollections, setUserCollections] = useState([]);

  useEffect(() => {
    if (userId) {
      getUserCollections({ userId }).then((collections) => {
        setUserCollections(
          collections.map((collection) => ({
            id: collection.id,
            name: collection.name,
            createdAt: null, // we don't have this info
            elementCount: collection.publications.length,
            // check if real_estate_publications is not empty, if the first publication picture is not empty and then use it
            picture: collection.picture,
            handleClick: () => window.location.assign(`/user/collection/${collection.id}`),
            handleDownload: () =>
              fetchAndDownloadExcel({ publicationIds: collection.publication_ids }),
            handleDelete: () =>
              deleteCollection({ collectionId: collection.id, userId: userId }).then((response) => {
                console.log(response);
              }),
          })),
        );
        setLoadingCollections(false);
      });
    }
  }, [userId]);

  /*
  const [deleteMassiveAppraisal] = useMutation(DELETE_MASSIVE_APPRAISAL, {
    onCompleted: (variables) => {
      const massiveAppraisalId = variables.deleteMassiveValorizations[0]
      console.log(massiveAppraisalId);
      const htmlAppraisalElement = document.getElementById(`group-${massiveAppraisalId}`);
      htmlAppraisalElement.classList.add('deleted');
      setTimeout(() => {
        htmlAppraisalElement.remove();
      }, 1200);
      message.destroy(`group-${massiveAppraisalId}-delete`);
    },
  });
  */

  const fetchAndDownloadExcel = ({ publicationIds }) => {
    const params = {
      publication_ids: publicationIds,
      user_id: userId,
      fields: {
        id: true,
        title: true,
        address_line: true,
        condition: true,
        operation: true,
        property_type: true,
        construction_year: true,
        total_area: true,
        covered_area: true,
        created_time: true,
        description: true,
        external_source_id: true,
        external_source_url: true,
        first_time_found: true,
        full_bathrooms: true,
        rooms: true,
        bedrooms: true,
        warehouses: true,
        last_published_price: true,
        last_published_price_per_total_area: true,
        last_published_price_per_covered_area: true,
        last_time_found: true,
        location: true,
        maintenance_cost: true,
        pictures: true,
        pictures_minimized: true,
        source: true,
        region: true,
        country: true,
        region: true,
        province: true,
        municipality: true,
        district: true,
        zone_or_locality: true,
        block_or_entity: true,
        is_active: true,
        is_hidden: true,
        estimated_sale_price: true,
        estimated_rental_price: true,
        estimated_yield: true,
        pricing_history: true,
      },
    };
    fetchPublications(params).then((publications) => {
      const parsedPublications = publications.map((publication) =>
        parsePublicationAPI(publication),
      );
      generateExcelFile({
        data: parsedPublications,
        columns: [
          'row',
          'id',
          'source',
          'externalSourceId',
          'createdTime',
          'operation',
          'propertyType',
          'address',
          'publishedPriceAmount',
          'unit',
          'coveredArea',
          'totalArea',
          'bedRooms',
          'fullBathrooms',
          'parkingLots',
          'warehouses',
          'constructionYear',
          'latitude',
          'longitude',
          'description',
          'externalSourceUrl',
          'isActive',
          'firstTimeFound',
          'maintenanceCost',
          'picture',
          'pictures',
          'link',
        ],
        fileName: 'COLLECCION_PUBLICACIONES_' + new Date().getTime(),
        labels: {
          row: 'N°',
          id: 'ID',
          source: 'Fuente',
          externalSourceId: 'Código',
          createdTime: 'Fecha',
          operation: 'Operación',
          propertyType: 'Tipo Propiedad',
          address: 'Dirección',
          publishedPriceAmount: 'Precio publicado',
          unit: 'Moneda',
          coveredArea: 'Área Util',
          totalArea: 'Área Total',
          bedRooms: 'Dormitorios',
          fullBathrooms: 'Baños',
          parkingLots: 'Estacionamientos',
          warehouses: 'Bodegas',
          constructionYear: 'Año Construccion',
          latitude: 'Latitud',
          longitude: 'Longitud',
          description: 'Comentario',
          externalSourceUrl: 'Url',
          isActive: 'Status',
          firstTimeFound: 'Fecha Inical Publicacion',
          maintenanceCost: 'Gasto Comun',
          picture: 'Portada',
          pictures: 'Fotos',
          link: 'Link',
        },
      });
    });
  };

  return (
    <div className="collectionsContainer">
      <GroupList groups={userCollections} type="collection" />
      {loading && <Loading extraClass={`${userCollections.length !== 0 && 'noMargin'}`} />}
      {!loading && userCollections.length === 0 && (
        <EmptyView viewName={'Colecciones'} typeOf={'collection'} />
      )}
    </div>
  );
}

export default CollectionGroup;

import React from 'react';
import { TassAppLogo } from '../../assets';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const EmptyView = (props) => {
  const { viewName, typeOf } = props;

  return (
    <div className="error401">
      <img src={TassAppLogo} style={{ opacity: '0.6' }} alt="" />
      <br></br>
      <br></br>
      <h2>Aún no tienes {`${viewName}`} ¡Haz tu primera! </h2>
      {typeOf == 'appraisal' ? (
        <h4>Obten una ventaja competitiva valorizando con Tassapp </h4>
      ) : (
        <h4>Encuentra las propiedades para hacer tu primera</h4>
      )}

      <h4>
        {' '}
        <Link to={typeOf == 'appraisal' ? '/appraisal' : '/properties'}>
          <span>haciendo click aquí.</span>
        </Link>
      </h4>
    </div>
  );
};

export default EmptyView;

EmptyView.propTypes = {
  viewName: PropTypes.string,
  typeOf: PropTypes.string,
};

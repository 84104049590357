import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.less';

export const SwipeContent = function (props) {
  return (
    <div className="swipeContent" style={props.style}>
      {props.children}
    </div>
  );
};
SwipeContent.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
};

function SwipeUp(props) {
  const cardRef = useRef();
  const [deltaY, setDeltaY] = useState(0);
  const [prevMovementY, setPrevMovementY] = useState();
  const [touching, setTouching] = useState(false);
  const [cardHeight, setCardHeight] = useState();
  const [isMaxHeight, setMaxHeight] = useState(false);
  const [isMinHeight, setMinHeight] = useState(true);
  const maxHeight = 450;
  const minHeight = 70;

  useLayoutEffect(() => {
    if (cardHeight === 300 || cardHeight === 150) {
      cardRef.current.classList.add('card-animation');
    } else {
      cardRef.current.classList.remove('card-animation');
    }
  }, [cardHeight]);

  const touchStart = (e) => {
    if (!touching) {
      setCardHeight(cardRef.current.offsetHeight);
      setTouching(true);
      setPrevMovementY(e.touches[0].screenY);
    }
  };

  const touchMove = (e) => {
    if (touching) {
      if (isMinHeight) {
        setCardHeight(maxHeight);
        setMinHeight(false);
        setMaxHeight(true);
      }
      if (isMaxHeight) {
        setCardHeight(minHeight);
        setMinHeight(true);
        setMaxHeight(false);
      }
      setTouching(false);
    }
    if (cardHeight == 'this will never happen') {
      const deltaY = prevMovementY - e.touches[0].screenY;
      setDeltaY(deltaY);
      setCardHeight(cardHeight + deltaY);
      if (deltaY >= 20) {
        setCardHeight(300);
        setTouching(false);
      }
      if (deltaY <= -20) {
        setCardHeight(150);
        setTouching(false);
      }
      setPrevMovementY(e.touches[0].screenY);
    }
  };

  const touchEnd = () => {
    if (touching) {
      setTouching(false);
      setPrevMovementY(0);

      if (deltaY > 0) {
        setCardHeight(maxHeight);
      }
      if (deltaY < 0) {
        setCardHeight(minHeight);
      }
    }
  };

  return (
    <div className="swipeUp">
      <div className="swipeBack">{props.children[0]}</div>
      <div
        className="card"
        ref={cardRef}
        style={{ height: `${cardHeight}px`, backgroundColor: '#FFFFFF', zIndex: '1000' }}
      >
        <div className="swipeDrawer">{props.children[1]}</div>
        <span
          className="card-knob"
          draggable={true}
          onTouchStart={touchStart}
          onTouchMove={touchMove}
          onTouchEnd={touchEnd}
        ></span>
      </div>
    </div>
  );
}

SwipeUp.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default SwipeUp;

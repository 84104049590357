import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'antd';
import PageHeader from './PageHeader';
import PicturesCarousel from './PicturesCarousel';
import TassAppPropertyEstimations from './SummaryTassAppEstimations';
import PropertyPriceAnalisys from './SummaryPriceAnalysis';
import PropertyHistory from './SummaryPropertyHistory';
import SummaryInvestmentSimulator from './SummaryInvestmentSimulation';
import SimilarPublications from './SimilarPublications';
import DuplicatedPublications from './DuplicatedPublications';
import SameProjectPublications from './SameProjectPublications';
import Icon from '@ant-design/icons';
import { parsePublicationAPI } from '../../parsers';

import { IconArrowLeft, IconCross } from '../../../assets';
import './style.less';
import { useMediaQuery } from 'react-responsive';

import { useUserAuth } from '../../../context/AuthContext';
import { getUser } from '../../../actions/users';


// import EditPropertyForm from '../EditPropertyForm';

import SummaryPropertyInfo from './SummaryPropertyInfo';
import { fetchPublications } from './backend';

export default function PropertySummary({ propertyId, userRole }) {
  const history = useHistory();

  const modalRef = useRef();
  const [editionMode, setEditionMode] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isNotModal = useMediaQuery({ maxWidth: 1300 });

  const { pathname } = useLocation();
  const isFullSize = pathname == '/properties/' + propertyId + '/full';

  const [publicationData, setPublicationData] = useState();

  const { user: userAuth } = useUserAuth();
  const [currentUser, setCurrentUser] = useState(null);

  
  useEffect(() => {
    let isMounted = true;

    const fetchUser = async () => {
      try {
        const user = await getUser({ uid: userAuth.uid });
        if (user && isMounted) {
          setCurrentUser(user.id);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [getUser, userAuth]);

  useEffect(() => {
    if (currentUser) {
      fetchPublications({
        publication_ids: [propertyId],
        user_id: currentUser,
        fields: {
          id: true,
          title: true,
          address_line: true,
          condition: true,
          operation: true,
          property_type: true,
          construction_year: true,
          total_area: true,
          covered_area: true,
          created_time: true,
          description: true,
          external_source_id: true,
          external_source_url: true,
          first_time_found: true,
          full_bathrooms: true,
          rooms: true,
          bedrooms: true,
          warehouses: true,
          last_published_price: true,
          last_published_price_per_total_area: true,
          last_published_price_per_covered_area: true,
          last_time_found: true,
          location: true,
          maintenance_cost: true,
          pictures: true,
          pictures_minimized: true,
          source: true,
          region: true,
          country: true,
          region: true,
          province: true,
          municipality: true,
          district: true,
          zone_or_locality: true,
          block_or_entity: true,
          is_active: true,
          is_hidden: true,
          estimated_sale_price: true,
          estimated_rental_price: true,
          estimated_yield: true,
          pricing_history: true,
        },
      }).then((publications) => {
        publications.map((publication) => {
          // this should be only one value
          setPublicationData(parsePublicationAPI(publication));
        });
      });
    }
  }, [currentUser, propertyId]);

  useEffect(() => {
    if (currentUser) {
      window.scrollTo(0, 0);
      if (modalRef) {
        modalRef.current.scrollTo(0, 0);
      }
    }
  }, [propertyId]);

  if (!currentUser) {
    return <div></div>;
  }

  return (
    <>
      {!isFullSize && (
        <div className={'closeModalFixed'}>
          <Button
            onClick={() => history.push('/properties')}
            icon={<Icon component={IconCross} />}
            type="link"
          />
        </div>
      )}
      <div className="propertySumaryContainer">
        <div className="propertySummaryMain" ref={modalRef}>
          <Button
            className="btnBack"
            onClick={() => history.goBack()}
            icon={<Icon component={IconArrowLeft} />}
            type="link"
          >
            Volver
          </Button>
          <PageHeader
            publicationId={propertyId}
            publicationData={publicationData}
            userRole={userRole}
            editMode={editionMode}
            setEditMode={setEditionMode}
            fullScreenOption={!(isNotModal | isFullSize)}
          ></PageHeader>
          {isMobile && (
            <PicturesCarousel pictures={publicationData ? publicationData.pictures : []} />
          )}
          <SummaryPropertyInfo publicationData={publicationData} publicationId={propertyId} />

          <div>
            {/*
            <PropertyPriceAnalisys publicationData={publicationData} propertyId={propertyId} />
            <SummaryInvestmentSimulator publicationData={publicationData} propertyId={propertyId} />
            <SameProjectProperties publicationData={publicationData} openFullScreen={isFullSize}/>
            */}
            <TassAppPropertyEstimations
              publicationData={publicationData}
              openFullScreen={isFullSize}
            />
            <PropertyHistory publicationData={publicationData} openFullScreen={isFullSize} />
            <SimilarPublications
              publicationData={publicationData}
              userId={currentUser}
              openFullScreen={isFullSize}
            />
            <SameProjectPublications
              publicationData={publicationData}
              userId={currentUser}
              openFullScreen={isFullSize}
            />
            <DuplicatedPublications
              publicationData={publicationData}
              userId={currentUser}
              openFullScreen={isFullSize}
            />
          </div>
        </div>
        {!isMobile && (
          <PicturesCarousel pictures={publicationData ? publicationData.pictures : []} />
        )}
      </div>
    </>
  );
}
PropertySummary.propTypes = {
  propertyId: PropTypes.number.isRequired,
  userRole: PropTypes.string,
  dataLine: PropTypes.array,
};

PropertySummary.defaultProps = {
  userRole: '',
};

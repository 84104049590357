import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import EmptyView from './EmptyView';
import Loading from '../Loading';
import PublicationList from '../RenderViews/PublicationList';
import Constants from '../../constants';
import { fetchFavoritePublicationIds, fetchPublications } from './backend';
import { parsePublicationAPI } from '../parsers';

const { IS_MOBILE } = Constants;

export const FavoritePublicationsView = ({ userId }) => {
  const [favoritePublicationsData, setFavoritePublicationsData] = useState();
  // const [currentUser, setCurrentUser] = useState({});
  const isMobile = useMediaQuery(IS_MOBILE);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    try {
      if (userId) {
        fetchFavoritePublicationIds(userId).then((publicationIds) => {
          fetchPublications({ publicationIds, userId }).then((publications) => {
            setFavoritePublicationsData(
              publications.map((publication) => parsePublicationAPI(publication)),
            );
            setIsLoading(false);
          });
        });
      }
    } catch (error) {
      setHasError(true);
    }
  }, [userId]);

  if (!favoritePublicationsData || !userId)
    return (
      <div className="componentTitle">
        <Loading />
      </div>
    );

  return (
    <div className="favorites-data-view">
      {isMobile ? (
        <div className="favorites-tag">
          <div className="text"> Mis favoritos</div>
        </div>
      ) : (
        ''
      )}
      {favoritePublicationsData.length ? (
        <>
          <div className="componentDivider" />
          <PublicationList
            publications={favoritePublicationsData}
            openFullScreen={true}
            currentUserId={userId}
          ></PublicationList>
        </>
      ) : (
        <EmptyView viewName={'propiedades favoritas'} typeOf={'property'} />
      )}
    </div>
  );
};

FavoritePublicationsView.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default FavoritePublicationsView;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import PublicationList from '../../RenderViews/PublicationList';
import ToggleFilter from '../../Filters/Toggle';
import { parsePublicationAPI } from '../../parsers';
import './style.less';
import backend from '../../../backend';
import { conditionType, operationType } from '../constants';
import { Spin } from 'antd';

function SimilarPublications({ publicationData, userId, openFullScreen = false }) {
  const [similarPublicationsData, setSimilarPublicationsData] = useState([]);

  const [conditionTypeFilter, setConditionTypeFilter] = useState(null);
  const [operationTypeFilter, setOperationTypeFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const fetchSimilarPublications = async function (publicationId) {
    const params = {
      publication_ids: [publicationId],
      user_id: userId,
      operation_type: operationTypeFilter,
      condition_type: conditionTypeFilter,
      fields: {
        id: true,
        title: true,
        address_line: true,
        condition: true,
        operation: true,
        property_type: true,
        construction_year: true,
        total_area: true,
        covered_area: true,
        created_time: true,
        description: true,
        external_source_id: true,
        external_source_url: true,
        first_time_found: true,
        full_bathrooms: true,
        rooms: true,
        bedrooms: true,
        warehouses: true,
        last_published_price: true,
        last_published_price_per_total_area: true,
        last_published_price_per_covered_area: true,
        last_time_found: true,
        location: true,
        maintenance_cost: true,
        pictures: true,
        source: true,
        region: true,
        country: true,
        region: true,
        province: true,
        municipality: true,
        district: true,
        zone_or_locality: true,
        block_or_entity: true,
        is_active: true,
        is_favorite: true,
        is_hidden: true,
        estimated_sale_price: true,
        estimated_rental_price: true,
        estimated_yield: true,
      },
    };
    const response = await backend.post(
      '/real_estate_market/fetch_comparable_publications/',
      params,
    );
    return response.data.response[publicationId];
  };

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    try {
      const publicationId = publicationData ? publicationData.id : null;
      if (publicationId) {
        if (!conditionTypeFilter) {
          setConditionTypeFilter(publicationData.condition);
        }
        if (!operationTypeFilter) {
          setOperationTypeFilter(publicationData.operation);
        }
        fetchSimilarPublications(publicationId).then((publications) => {
          setSimilarPublicationsData(
            publications.map((publication) => parsePublicationAPI(publication)),
          );
          setIsLoading(false);
        });
      }
    } catch (error) {
      setHasError(true);
    }
  }, [publicationData, conditionTypeFilter, operationTypeFilter]);
  const shouldNotRenderView =
    !publicationData || !publicationData.id || !conditionTypeFilter || !operationTypeFilter;
  if (shouldNotRenderView) return <></>;

  return (
    <>
      <div className="componentDivider" />
      {hasError && <p>Something went wrong.</p>}

      <>
        <Row justify="space-between">
          <Col>
            <h1>Otras publicaciones similares</h1>
          </Col>
          <Col>
            <Row justify="space-between" gutter={[32, 32]}>
              <ToggleFilter
                defaultValue={conditionTypeFilter}
                options={conditionType}
                callback={setConditionTypeFilter}
              />
              <ToggleFilter
                defaultValue={operationTypeFilter}
                options={operationType}
                callback={setOperationTypeFilter}
              />
            </Row>
          </Col>
        </Row>
        {isLoading ? (
          <Row>
            <div className="componentTitle">
              <h1>Cargando...</h1>
              <Spin />
            </div>
          </Row>
        ) : (
          <>
            {!similarPublicationsData.length ? (
              <Row>
                <div className="not-found-title">
                  <h3>No se encontraron propiedades</h3>
                </div>
              </Row>
            ) : (
              <PublicationList
                publications={similarPublicationsData}
                referencePublication={publicationData}
                openFullScreen={openFullScreen}
                currentUserId={userId}
              ></PublicationList>
            )}
          </>
        )}
      </>
    </>
  );
}

SimilarPublications.propTypes = {
  openFullScreen: PropTypes.bool,
};

export default SimilarPublications;

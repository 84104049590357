function calculateEstimatedDividend(
  expectedSellPrice,
  initialDeposit,
  interestPercentage,
  duration,
) {
  const rm = Math.pow(1 + interestPercentage / 100, 1 / 12) - 1;
  return (
    (expectedSellPrice - initialDeposit) *
    rm *
    (Math.pow(1 + rm, 12 * duration) / (Math.pow(1 + rm, 12 * duration) - 1))
  ).toFixed(2);
}

function setEstimatedDividendAndDifference(form, setDiferenceColor) {
  let formValues = form.getFieldsValue(true);
  const estimatedDividend = calculateEstimatedDividend(
    formValues.propertyPrice,
    formValues.initialDepositValue,
    formValues.interest,
    formValues.duration,
  );
  form.setFieldsValue({ estimatedDividend });
  formValues = form.getFieldsValue(true);
  const diference = (formValues.simulatedRent - formValues.estimatedDividend).toFixed(2);
  form.setFieldsValue({ diference });
  setDiferenceColor(diference > 0);
}

export function formValuesControl(values, form, setDiferenceColor) {
  if (values.initialDepositValue) {
    let formValues = form.getFieldsValue(true);
    const initialDepositPercentage = (
      (values.initialDepositValue * 100) /
      formValues.propertyPrice
    ).toFixed(2);
    form.setFieldsValue({ initialDepositPercentage });
    setEstimatedDividendAndDifference(form, setDiferenceColor);
  }
  if (values.propertyPrice) {
    const initialDepositValue = (
      (values.propertyPrice * form.getFieldsValue(true).initialDepositPercentage) /
      100
    ).toFixed(2);
    form.setFieldsValue({ initialDepositValue });
    setEstimatedDividendAndDifference(form, setDiferenceColor);
  }
  if (values.initialDepositPercentage) {
    const initialDepositValue = (
      (form.getFieldsValue(true).propertyPrice * values.initialDepositPercentage) /
      100
    ).toFixed(2);
    form.setFieldsValue({ initialDepositValue });

    setEstimatedDividendAndDifference(form, setDiferenceColor);
  }
  if (values.duration || values.interest) {
    setEstimatedDividendAndDifference(form, setDiferenceColor);
  }
  if (values.simulatedRent) {
    const formValues = form.getFieldsValue(true);
    const diference = (formValues.simulatedRent - formValues.estimatedDividend).toFixed(2);
    form.setFieldsValue({ diference });
    setDiferenceColor(diference > 0);
  }
}

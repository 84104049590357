import { propertyTypeFormatter } from '../Properties/constants';
import { UF_CLP } from '../../constants';
import {
  getPropertyType,
  getOperationEstimatedText,
  parseNumbersToPrice,
  setPriceInUFtoUFandCLP,
  setPriceInUFtoFloatUFandCLP,
} from '../formatters';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
export const parseInvestmentSimulationData = (data) => {
  // check if data dictionary is empty then console.log
  if (Object.keys(data).length === 0) {
    const interest = 0.05;
    const duration = 20;
    const expectedSellPrice = 10000;
    const initialDepositValue = (10000 * 0.2).toFixed(2);
    const rm = Math.pow(1 + interest, 1 / 12) - 1;
    const estimatedDividend = (
      (expectedSellPrice - initialDepositValue) *
      rm *
      (Math.pow(1 + rm, 12 * duration) / (Math.pow(1 + rm, 12 * duration) - 1))
    ).toFixed(2);
    return {
      propertyPrice: expectedSellPrice,
      initialDepositValue: initialDepositValue,
      initialDepositPercentage: 20,
      duration,
      interest: interest * 100,
      rentProbability: 0,
      estimatedSellPrice: null,
      estimatedSellPricePerArea: null,
      estimatedSellPriceMin: null,
      estimatedSellPriceMinPerArea: null,
      estimatedSellPriceMax: null,
      estimatedSellPriceMaxPerArea: null,
      estimatedRentPrice: setPriceInUFtoFloatUFandCLP(expectedSellPrice / 200),
      estimatedRentPricePerArea: setPriceInUFtoFloatUFandCLP(expectedSellPrice / (200 * 120)),
      estimatedRentPriceMin: setPriceInUFtoFloatUFandCLP(expectedSellPrice / 300),
      estimatedRentPriceMinPerArea: setPriceInUFtoFloatUFandCLP(expectedSellPrice / (300 * 120)),
      estimatedRentPriceMax: setPriceInUFtoFloatUFandCLP(expectedSellPrice / 100),
      estimatedRentPriceMaxPerArea: setPriceInUFtoFloatUFandCLP(expectedSellPrice / (100 * 120)),
      estimatedRentPriceMaxNumericValue: {
        UF: expectedSellPrice / 100,
        CLP: (UF_CLP * expectedSellPrice) / 100,
      },
      estimatedDividend,
      diference: null,
    };
  } else {
    const interest = 0.05;
    const duration = 20;
    const expectedSellPrice = data.expected_sell_price.toFixed(2);
    const initialDepositValue = (data.last_published_price__amount * 0.2).toFixed(2);
    const rm = Math.pow(1 + interest, 1 / 12) - 1;
    const estimatedDividend = (
      (expectedSellPrice - initialDepositValue) *
      rm *
      (Math.pow(1 + rm, 12 * duration) / (Math.pow(1 + rm, 12 * duration) - 1))
    ).toFixed(2);
    return {
      propertyPrice: expectedSellPrice,
      initialDepositValue: initialDepositValue,
      initialDepositPercentage: 20,
      duration,
      interest: interest * 100,
      rentProbability: 0,
      estimatedSellPrice: setPriceInUFtoUFandCLP(data.estimated_sell_price_median__amount),
      estimatedSellPricePerArea: setPriceInUFtoUFandCLP(data.estimated_sell_price_median__per_area),
      estimatedSellPriceMin: setPriceInUFtoUFandCLP(data.estimated_sell_price_min__amount),
      estimatedSellPriceMinPerArea: setPriceInUFtoUFandCLP(data.estimated_sell_price_min__per_area),
      estimatedSellPriceMax: setPriceInUFtoUFandCLP(data.estimated_sell_price_max__amount),
      estimatedSellPriceMaxPerArea: setPriceInUFtoUFandCLP(data.estimated_sell_price_max__per_area),
      estimatedRentPrice: setPriceInUFtoUFandCLP(data.estimated_rental_price_median__amount),
      estimatedRentPricePerArea: setPriceInUFtoFloatUFandCLP(
        data.estimated_rental_price_median__per_area,
      ),
      estimatedRentPriceMin: setPriceInUFtoUFandCLP(data.estimated_rental_price_min__amount),
      estimatedRentPriceMinPerArea: setPriceInUFtoFloatUFandCLP(
        data.estimated_rental_price_min__per_area,
      ),
      estimatedRentPriceMax: setPriceInUFtoUFandCLP(data.estimated_rental_price_max__amount),
      estimatedRentPriceMaxPerArea: setPriceInUFtoFloatUFandCLP(
        data.estimated_rental_price_max__per_area,
      ),
      estimatedRentPriceMaxNumericValue: {
        UF: data.estimated_sell_price_max__per_area,
        CLP: data.estimated_sell_price_max__per_area * UF_CLP,
      },
      estimatedDividend,
      diference: (data.estimated_rental_price_median__amount - estimatedDividend).toFixed(2),
    };
  }
};

export const parsePublicationMarkersAPI = (publications) => {
  const parsedPublicationMarkers = publications.map((x) => ({
    id: x.id,
    latitude: x.location.latitude,
    longitude: x.location.longitude,
    isHidden: x.is_hidden,
    operation: x.operation,
    price: x.last_published_price.value,
  }));
  return parsedPublicationMarkers;
};

export const parsePropertyMarkersData = (propertiesData) => {
  const parsedMarkersData = propertiesData.map((x) => ({
    id: x.id,
    latitude: x.latitude,
    longitude: x.longitude,
    isHidden: x.is_hidden,
    operation: x.operation,
    price: x.last_published_price__amount,
  }));
  return { parsedMarkersData };
};

export const parsePublicationPreviewsAPI = (publications) => {
  // NEW API
  const parsedPublicationPreviews = publications.map((x) => ({
    id: x.id,
    isFavorite: x.is_favorite,
    operation: x.operation,
    condition: x.condition,
    propertyType: x.property_type,
    source: x.source,
    externalSourceId: x.external_source_id,
    externalSourceUrl: x.external_source_url,
    typeText: getPropertyType(x.operation, x.property_type),
    address: x.address_line,
    description: x.description,
    title: x.title,
    createdTime: x.first_time_found,
    firstTimeFound: x.first_time_found,
    dateText: moment(x.first_time_found).fromNow(),
    isActive: x.is_active,
    isHidden: x.is_hidden,
    latitude: x.location.latitude,
    longitude: x.location.longitude,
    unit: x.last_published_price.units,
    publishedPriceAmount: parseInt(x.last_published_price.value),
    publishedPriceUnit: x.last_published_price.units,
    priceText: `${parseNumbersToPrice(x.last_published_price.value, x.operation)}`,
    prices: {
      UF: `${parseNumbersToPrice(x.last_published_price.value, x.operation)}`,
      CLP: `${parseNumbersToPrice(
        x.last_published_price.value * UF_CLP,
        x.operation,
        false,
        'CLP',
      )}`,
    },
    coveredArea: x.covered_area,
    totalArea: x.total_area,
    bedRooms: x.bedrooms,
    rooms: x.rooms,
    fullBathrooms: x.full_bathrooms,
    pictures: x.pictures_minimized,
    largePictures: x.pictures,
    warehouses: x.warehouses ? 'Si' : 'No',
    maintenanceCost: x.maintenance_cost ? x.maintenance_cost.value : 'No hay datos',
    constructionYear: x.construction_year ? x.construction_year : 'No hay datos',
  }));
  return parsedPublicationPreviews;
};

export const parsePropertyPreview = (data) => {
  const parsedPropertiesPreview = data.map((x) => ({
    id: x.id,
    operation: x.operation,
    propertyType: x.property_type,
    source: x.source,
    externalSourceId: x.external_source_id,
    externalSourceUrl: x.external_source_url,
    typeText: getPropertyType(x.operation, x.property_type),
    address: x.address_line,
    description: x.description,
    title: x.title,
    createdTime: x.first_time_found,
    firstTimeFound: x.first_time_found,
    dateText: moment(x.first_time_found).fromNow(),
    isActive: x.is_active,
    isHidden: x.is_hidden,
    latitude: x.latitude,
    longitude: x.longitude,
    unit: x.last_published_price__unit,
    publishedPriceAmount: parseInt(x.last_published_price__amount),
    publishedPriceUnit: x.last_published_price__unit,
    priceText: `${parseNumbersToPrice(x.last_published_price__amount, x.operation)}`,
    prices: {
      UF: `${parseNumbersToPrice(x.last_published_price__amount, x.operation)}`,
      CLP: `${parseNumbersToPrice(
        x.last_published_price__amount * UF_CLP,
        x.operation,
        false,
        'CLP',
      )}`,
    },
    coveredArea: x.covered_area,
    totalArea: x.total_area,
    bedRooms: x.rooms,
    fullBathrooms: x.full_bathrooms,
    pictures: x.pictures,
    warehouses: x.warehouses ? 'Si' : 'No',
    maintenanceCost: x.maintenance_cost__amount,
    constructionYear: x.construction_year ? x.construction_year : 'No Disponible',
  }));
  return { parsedPropertiesPreview };
};

export const parsePublicationAPI = (data) => {
  const parsedPublication = {
    id: data.id,
    operation: data.operation,
    propertyType: propertyTypeFormatter[data.property_type],
    typeText: getPropertyType(data.operation, data.property_type),
    operationText: getOperationEstimatedText(data.operation),
    address: data.address_line,
    title: data.title,
    createdTime: data.first_time_found,
    firstTimeFound: data.first_time_found,
    dateText: moment(data.first_time_found).fromNow(),
    isActive: data.is_active,
    isHidden: data.is_hidden,
    isFavorite: data.is_favorite,
    latitude: data.location.latitude,
    longitude: data.location.longitude,
    publishedPriceAmount: parseInt(data.last_published_price.value),
    publishedPriceUnit: data.last_published_price.units,
    priceText: `${parseNumbersToPrice(data.last_published_price.value, data.operation)}`,
    coveredArea: data.covered_area,
    coveredAreaText: `${data.covered_area} m² útiles`,
    coveredAreaTextSmall: `${data.covered_area} m²`,
    totalArea: data.total_area,
    totalAreaText: `${data.total_area} m² totales`,
    totalAreaTextSmall: `${data.total_area} m²`,
    bedRooms: data.bedrooms,
    rooms: data.rooms,
    fullBathrooms: data.full_bathrooms,
    pictures: data.pictures,
    externalSourceUrl: data.external_source_url,
    floors: data.floors,
    parkings: data.parking_lots ? `${data.parking_lots} autos` : 'Sin estacionamiento',
    description: data.description,
    pricingHistory: data.pricing_history ? data.pricing_history : [],
    daysOnMarket: moment().diff(data.first_time_found, 'days'),
    estimatedSellPrice: !data.estimated_sale_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_sale_price.value),
    estimatedSellPricePerArea: !data.estimated_sale_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_sale_price_per_total_area.value),
    estimatedSellPriceMin: !data.estimated_min_sale_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_sale_price.value),
    estimatedSellPriceMinPerArea: !data.estimated_min_sale_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_sale_price_per_total_area.value),
    estimatedSellPriceMax: !data.estimated_max_sale_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_sale_price.value),
    estimatedSellPriceMaxPerArea: !data.estimated_max_sale_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_sale_price_per_total_area.value),
    estimatedRentPrice: !data.estimated_rental_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_rental_price.value),
    estimatedRentPricePerArea: !data.estimated_rental_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_rental_price_per_total_area.value),
    estimatedRentPriceMin: !data.estimated_min_rental_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_rental_price.value),
    estimatedRentPriceMinPerArea: !data.estimated_min_rental_price_per_total_are
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_min_rental_price_per_total_are.value),
    estimatedRentPriceMax: !data.estimated_max_rental_price
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_rental_price.value),
    estimatedRentPriceMaxPerArea: !data.estimated_max_rental_price_per_total_area
      ? { UF: null, CLP: null }
      : setPriceInUFtoFloatUFandCLP(data.estimated_max_rental_price_per_total_area.value),
    estimatedYield: (data.estimated_yield * 100).toFixed(2),
    estimatedMinYield: (data.estimated_min_yield * 100).toFixed(2),
    estimatedMaxYield: (data.estimated_max_yield * 100).toFixed(2),
  };
  return parsedPublication;
};

export const parsePropertyDetail = (data) => {
  const parsed = {
    id: data.id,
    operation: data.operation,
    propertyType: propertyTypeFormatter[data.property_type],
    typeText: getPropertyType(data.operation, data.property_type),
    operationText: getOperationEstimatedText(data.operation),
    address: data.address_line,
    title: data.title,
    createdTime: data.first_time_found,
    firstTimeFound: data.first_time_found,
    dateText: moment(data.first_time_found).fromNow(),
    isActive: data.is_active,
    isHidden: data.is_hidden,
    latitude: data.latitude,
    longitude: data.longitude,
    publishedPriceAmount: parseInt(data.last_published_price__amount),
    publishedPriceUnit: data.last_published_price__unit,
    priceText: `${parseNumbersToPrice(data.last_published_price__amount, data.operation)}`,
    coveredArea: data.covered_area,
    coveredAreaText: `${data.covered_area} m² útiles`,
    coveredAreaTextSmall: `${data.covered_area} m²`,
    totalArea: data.total_area,
    totalAreaText: `${data.total_area} m² totales`,
    totalAreaTextSmall: `${data.total_area} m²`,
    bedRooms: data.rooms,
    fullBathrooms: data.full_bathrooms,
    pictures: data.pictures,
    externalSourceUrl: data.external_source_url,
    floors: data.floors,
    parkings: data.parking_lots ? `${data.parking_lots} autos` : 'Sin estacionamiento',
    description: data.description,
  };
  return parsed;
};

export const parseValorizationDetails = (data) => {
  // Esta funcion asume que los datos llegaran en UF
  // console.log(data, "valorization");
  // check if data dictionary is empty then console.log
  if (Object.keys(data).length === 0) {
    return null;
  } else {
    const parsed = {
      estimatedSellPrice: setPriceInUFtoUFandCLP(data.estimated_sell_price_median__amount),
      estimatedSellPricePerArea: setPriceInUFtoUFandCLP(data.estimated_sell_price_median__per_area),
      estimatedSellPriceMin: setPriceInUFtoUFandCLP(data.estimated_sell_price_min__amount),
      estimatedSellPriceMinPerArea: setPriceInUFtoUFandCLP(data.estimated_sell_price_min__per_area),
      estimatedSellPriceMax: setPriceInUFtoUFandCLP(data.estimated_sell_price_max__amount),
      estimatedSellPriceMaxPerArea: setPriceInUFtoUFandCLP(data.estimated_sell_price_max__per_area),
      estimatedRentPrice: setPriceInUFtoUFandCLP(data.estimated_rental_price_median__amount),
      estimatedRentPricePerArea: setPriceInUFtoFloatUFandCLP(
        data.estimated_rental_price_median__per_area,
      ),
      estimatedRentPriceMin: setPriceInUFtoUFandCLP(data.estimated_rental_price_min__amount),
      estimatedRentPriceMinPerArea: setPriceInUFtoFloatUFandCLP(
        data.estimated_rental_price_min__per_area,
      ),
      estimatedRentPriceMax: setPriceInUFtoUFandCLP(data.estimated_rental_price_max__amount),
      estimatedRentPriceMaxPerArea: setPriceInUFtoFloatUFandCLP(
        data.estimated_rental_price_max__per_area,
      ),
      estimatedYield: (data.estimated_yield_median * 100).toFixed(1),
      estimatedMaxYield: (data.estimated_yield_max * 100).toFixed(2),
      estimatedMinYield: (data.estimated_yield_min * 100).toFixed(2),
    };
    return parsed;
  }
};

export const parsePriceAnalisis = (data) => {
  // Esta funcion asume que los datos llegaran en UF
  const markerPosition = Math.min(
    Math.max(
      (Math.abs(data.last_published_price__amount - data.published_price_p15) /
        (data.published_price_p85 - data.published_price_p15)) *
        (48.5 - 1.5) +
        1.5,
      1.5,
    ),
    48.5,
  );
  const parsed = {
    publishedPrice: {
      UF: `${parseNumbersToPrice(data.last_published_price__amount, data.operation)}`,
      CLP: `${parseNumbersToPrice(
        data.last_published_price__amount * UF_CLP,
        data.operation,
        false,
        'CLP',
      )}`,
    },
    estimatedPrice: {
      UF: `${parseNumbersToPrice(data.estimated_price__amount, data.operation)}`,
      CLP: `${parseNumbersToPrice(
        data.estimated_price__amount * UF_CLP,
        data.operation,
        false,
        'CLP',
      )}`,
    },
    priceComparison: `${Math.round(
      (100 * Math.abs(data.estimated_price__amount - data.last_published_price__amount)) /
        data.estimated_price__amount,
    )}% ${
      data.estimated_price__amount - data.last_published_price__amount > 0
        ? ' por debajo'
        : ' por arriba'
    }`,
    priceMin: {
      UF: `UF ${parseNumbersToPrice(data.published_price_p15)}`,
      CLP: `CLP ${parseNumbersToPrice(data.published_price_p15 * UF_CLP)}`,
    },
    priceMax: {
      UF: `UF ${parseNumbersToPrice(data.published_price_p85)}`,
      CLP: `CLP ${parseNumbersToPrice(data.published_price_p85 * UF_CLP)}`,
    },
    markerPosition: `${markerPosition}%`,
  };
  return parsed;
};

export const parseTimeSeries = (propertyData) => {
  const parsed = propertyData.time_series.map(({ published_price__amount, units, time }) => {
    const date = time.split('T')[0];
    return { Fecha: date, UF: Math.round(published_price__amount * 100) / 100 };
  });
  return parsed;
};

export const parseTimeInMarket = (data) => {
  const parsed = {
    timeInMarket: `${data.publication_days_in_the_market} días`,
    similarTimeInMarket: `${data.similar_publications_days_in_the_market} días`,
  };
  return parsed;
};

export const parseMarkersProperties = (propertiesData) => {
  const parsedMarkersData = propertiesData.map((x) => ({
    id: x.id,
    latitude: x.latitude,
    longitude: x.longitude,
    operation: x.operation,
    price: x.last_published_price__amount,
  }));
  return { parsedMarkersData };
};

export const parsePropertiesDataSummary = (propertiesData) => {
  const parsedPropertiesData = propertiesData.map((x) => ({
    id: x.id,
    pictures: x.pictures?.map((picture) => picture.original_url) || [],
    address: x.address_line,
    createdTime: x.first_time_found,
    operation: x.operation,
    propertyType: x.real_estate_asset.property_type,
    latitude: x.point_location[1],
    longitude: x.point_location[0],
    city: x.real_estate_asset.location.municipality.name,
    state: x.real_estate_asset.location.region.name,
    sellPrice: x.last_published_price.amount,
    rentPrice: x.last_published_price.amount,
    sellValorization: x.last_published_price.amount,
    rentValorization: x.last_published_price.amount,
    publicationDate: new Date(x.first_time_found),
    firstTimeFound: x.first_time_found,
    coveredArea: x.real_estate_asset.covered_area,
    totalArea: x.real_estate_asset.total_area,
    bedRooms: x.real_estate_asset.rooms || 0,
    fullBathrooms: x.real_estate_asset.full_bathrooms || 0,
    floors: x.real_estate_asset.floors,
    parkings: x.real_estate_asset.parking_lots,
    swimmingPool: x.real_estate_asset.has_swimming_pool,
    selected: false,
    liked: false,
    isHidden: x.is_hidden,
    yieldValue: x.yield_value,
    estimatedSellPriceMedian: x.estimated_sell_price_median,
    estimatedSellPriceMin: x.estimated_sell_price_min,
    estimatedSellPriceMax: x.estimated_sell_price_max,
    estimatedRentalPriceMedian: x.estimated_rental_price_median,
    estimatedRentalPriceMin: x.estimated_rental_price_min,
    estimatedRentalPriceMax: x.estimated_rental_price_max,
    min_zone_price: x.min_zone_price,
    max_zone_price: x.max_zone_price,
    zone_percentile: x.zone_percentile,
    source: x.source,
    externalSourceId: x.external_source_id,
    unit: x.last_published_price.unit,
    parkingLots: x.real_estate_asset.parking_lots,
    warehouses: x.real_estate_asset.warehouses,
    constructionYear: x.real_estate_asset.construction_year,
    externalSourceUrl: x.real_estate_asset.external_source_url,
    description: x.description,
    isActive: x.is_active,
    maintenanceCost: x.real_estate_asset.maintenance_cost
      ? x.real_estate_asset.maintenance_cost.amount
      : undefined,
  }));
  return { parsedPropertiesData };
};

export const parsePropertiesData = (propertiesData) => {
  const parsedPropertiesData = propertiesData.map((x) => ({
    id: x.id,
    pictures: x.pictures?.map((picture) => picture.original_url) || [],
    address: x.address_line,
    createdTime: x.first_time_found,
    operation: x.operation,
    propertyType: x.real_estate_asset.property_type,
    latitude: x.point_location[1],
    longitude: x.point_location[0],
    sellPrice: x.last_published_price.amount,
    rentPrice: x.last_published_price.amount,
    sellValorization: x.last_published_price.amount,
    rentValorization: x.last_published_price.amount,
    publicationDate: new Date(x.first_time_found),
    coveredArea: x.real_estate_asset.covered_area,
    totalArea: x.real_estate_asset.total_area,
    bedRooms: x.real_estate_asset.rooms || 0,
    fullBathrooms: x.real_estate_asset.full_bathrooms || 0,
    selected: false,
    liked: false,
    isHidden: x.is_hidden,
    yieldValue: x.yield_value,
    source: x.source,
    externalSourceId: x.external_source_id,
    unit: x.last_published_price.unit,
    parkingLots: x.real_estate_asset.parking_lots,
    warehouses: x.real_estate_asset.warehouses,
    constructionYear: x.real_estate_asset.construction_year,
    externalSourceUrl: x.real_estate_asset.external_source_url,
    description: x.description,
    isActive: x.is_active,
    firstTimeFound: x.first_time_found,
    maintenanceCost: x.real_estate_asset.maintenance_cost
      ? x.real_estate_asset.maintenance_cost.amount
      : undefined,
  }));
  return { parsedPropertiesData };
};

export const parsePropertySimulation = (data) => {
  return {
    propertyPrice: data.last_published_price__amount.toFixed(2),
    initialDepositValue: (data.last_published_price__amount * 0.2).toFixed(2),
    initialDepositPercentage: 20,
    duration: 10,
    interest: 45,
    simulatedRent: data.last_published_price__amount * 0.2,
    rentProbability: 0,
  };
};

import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import MapGL, { FlyToInterpolator, Popup } from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import MapMarker from './Marker';
import PointOfInterest from './PointOfInterestMarker';
import MainMarker from './MainMarker';
import ClusterMapMarker from './Markers/ClusterMarker';
import {
  iconPolygonWhite,
  iconPolygonBlack,
  IconTrash,
  IconSatelite,
  loading,
  IconMap,
} from '../../assets';
import area from '@turf/area';
import bbox from '@turf/bbox';
import { Editor, DrawPolygonMode } from 'react-map-gl-draw';
import SearchDirectionBarInMap from '../SearchDirectionBarInMap';
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
import './style.less';
import {
  getFeatureStyleDefault,
  getFeatureStyleSatelital,
  getEditHandleStyleSatelital,
  getEditHandleStyleDefault,
} from './polygonStyle';
import Icon from '@ant-design/icons';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

/*
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
*/

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = (props) => {
  const {
    config = {
      searchbar: true,
      polygon: true,
      markerLocation: true,
      ubication: true,
      zoomButtons: true,
      zoomWithWheel: true,
      satelitalControl: true,
      propertiesAlerts: true,
      calculateArea: false,
      setingAddress: false,
    },
    onCardClick,
    isLoadingMarkers,
    markersData = [],
    setViewportCallback,
    initialViewPort,
    satelital = false,
    address,
    directionCallback,
    forcePopup,
    handleSelectProperty,
    selectedProperties,
    getAddress,
    isBlocked,
    pointsOfInterest,
    polygonFilter,
    setPolygonCallback,
    currentUserId,
  } = props;

  const [viewport, setViewport] = useState(
    initialViewPort || {
      zoom: 15,
      latitude: -33.4082354,
      longitude: -70.5668972,
    },
  );

  const [actualAddress, setActualAddress] = useState({});

  const [isSatelital, setIsSatelital] = useState(false);

  const [mode, setMode] = useState();

  const [polygonOnMap, setPolygonOnMap] = useState(polygonFilter ? true : false);

  const [polylineCoords, setPolylineCoords] = useState([]);

  const editorRef = useRef();

  const mapRef = useRef();

  const bounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  const points = markersData.map((data) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      markerId: data.id,
      property_type: data.propertyType,
      property_id: data.id,
      operation_type: data.operation,
      price__value: data.price,
    },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(data.longitude), parseFloat(data.latitude)],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  useEffect(() => {
    if (satelital) {
      setIsSatelital(satelital);
    }
  }, [satelital]);

  useEffect(() => {
    if (address && address.value && address.coords.latitude && address.coords.longitude) {
      setActualAddress(address);
    }
  }, [address]);

  useEffect(() => {
    if (viewport) {
      setViewPortFromBounds(mapRef.current.getMap().getBounds());
    }
    if (editorRef.current && polygonFilter) {
      const polygonFeature = {
        type: 'Feature',
        properties: {},
        geometry: polygonFilter,
      };
      editorRef.current.addFeatures(polygonFeature);
    }
  }, [setViewport]);

  useEffect(() => {
    if (editorRef.current && polylineCoords.length > 1) {
      const polylines = {
        type: 'Feature',
        properties: {},
        geometry: { type: 'LineString', coordinates: polylineCoords },
      };
      editorRef.current.addFeatures(polylines);
    }
  }, [polylineCoords]);

  useEffect(() => {
    if (actualAddress.coords) {
      if (
        actualAddress.coords.longitude != viewport.longitude ||
        actualAddress.coords.latitude != viewport.latitude
      ) {
        if (!viewport.height == 0) {
          setViewport({
            zoom: viewport.zoom,
            transitionInterpolator: new FlyToInterpolator({
              speed: 2,
            }),
            transitionDuration: 'auto',
            latitude: actualAddress.coords.latitude,
            longitude: actualAddress.coords.longitude,
          });
        } else {
          setViewport({
            zoom: 14,
            latitude: actualAddress.coords.latitude,
            longitude: actualAddress.coords.longitude,
          });
        }
      }
    }
    {
      config.setingAddress && getAddress(actualAddress.value);
    }
  }, [actualAddress]);

  const setViewPortFromBounds = ({ _sw, _ne }) => {
    const _nw = [_sw.lng, _ne.lat];
    const _se = [_ne.lng, _sw.lat];
    _sw = [_sw.lng, _sw.lat];
    _ne = [_ne.lng, _ne.lat];
    if ((setViewportCallback, !mode)) {
      if (setViewportCallback && viewport) {
        setViewportCallback({
          zoom: viewport.zoom,
          latitude: viewport.latitude,
          longitude: viewport.longitude,
          viewPortPolygon: {
            type: 'Polygon',
            coordinates: [[_sw, _nw, _ne, _se, _sw]],
          },
        });
      }
    }
  };

  function getChild(childrens) {
    var isHoverPropery = false;
    childrens.map((children) => {
      if (!isHoverPropery && children.properties.cluster) {
        isHoverPropery = getChild(supercluster.getChildren(children.id));
      } else {
        if (children.properties.markerId == forcePopup) {
          isHoverPropery = true;
        }
      }
    });
    return isHoverPropery;
  }

  const toggleEditingMode = () => {
    if (polygonOnMap || mode) {
      editorRef.current.deleteFeatures(0);
      setPolylineCoords([]);
      setMode();
      setPolygonOnMap(false);
      // delete polygonFilter through setPolygonCallback
      setPolygonCallback(null);
      setViewPortFromBounds(mapRef.current.getMap().getBounds());
    } else {
      setMode(new DrawPolygonMode());
    }
  };

  const onUpdate = (el) => {
    if (el.editType === 'addTentativePosition') {
      editorRef.current.deleteFeatures(0);
      setPolylineCoords((prevState) => {
        return [...prevState, el.editContext.position];
      });
    }
    if (el.editType === 'addFeature') {
      editorRef.current.deleteFeatures(0);
      setPolylineCoords([]);
      setPolygonOnMap(true);
      setMode(null);
      if (setPolygonCallback) {
        setPolygonCallback(el.data[0].geometry);
      }
    }
  };

  const GetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setActualAddress({
          coords: {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          },
          value: 'latitud: ' + pos.coords.latitude + ', longitud: ' + pos.coords.longitude,
        });
        if (directionCallback) {
          directionCallback({
            value: 'latitud: ' + pos.coords.latitude + ', longitud: ' + pos.coords.longitude,
            coords: {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            },
          });
        }
        if (!polygonOnMap) {
          const ne = {
            lng:
              mapRef.current.getMap().getBounds()._ne.lng -
              mapRef.current.getMap().getCenter().lng +
              pos.coords.longitude,
            lat:
              mapRef.current.getMap().getBounds()._ne.lat -
              mapRef.current.getMap().getCenter().lat +
              pos.coords.latitude,
          };
          const sw = {
            lng:
              mapRef.current.getMap().getBounds()._sw.lng -
              mapRef.current.getMap().getCenter().lng +
              pos.coords.longitude,
            lat:
              mapRef.current.getMap().getBounds()._sw.lat -
              mapRef.current.getMap().getCenter().lat +
              pos.coords.latitude,
          };

          setViewPortFromBounds({ _sw: sw, _ne: ne });
        }
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };
  const mapStyle = isSatelital
    ? 'mapbox://styles/mapbox/satellite-v9'
    : 'mapbox://styles/gustav19x/ckgirm1xp24kd19pjn45kcalg';

  return (
    <MapGL
      keyboard={false}
      {...viewport}
      scrollZoom={config.zoomWithWheel}
      doubleClickZoom={config.zoomWithWheel}
      touchZoom={config.zoomWithWheel}
      bearing={0}
      pitch={0}
      minZoom={13}
      ref={mapRef}
      preventStyleDiffing={true}
      attributionControl={false}
      width="100%"
      height="100%"
      mapStyle={mapStyle}
      onViewportChange={(nextViewport) => {
        if (!mode) {
          setViewport(nextViewport);
        }
      }}
      onLoad={() => mapRef.current}
      // && setViewPortFromBounds(mapRef.current.getMap().getBounds());
      // este condicional es para que no haga mapRef.current no sea null
      onInteractionStateChange={(interactionState) => {
        if (
          !interactionState.isDragging &&
          !polygonOnMap &&
          !interactionState.isZooming &&
          mapRef.current
        ) {
          setViewPortFromBounds(mapRef.current.getMap().getBounds());
        }
      }}
      mapboxApiAccessToken={MAPBOX_TOKEN}
    >
      {config.searchbar && (
        <div className="DirectionBarContainer">
          <SearchDirectionBarInMap
            setAddress={(address) => {
              setActualAddress(address);
              if (!polygonOnMap) {
                const ne = {
                  lng:
                    mapRef.current.getMap().getBounds()._ne.lng -
                    mapRef.current.getMap().getCenter().lng +
                    address.coords.longitude,
                  lat:
                    mapRef.current.getMap().getBounds()._ne.lat -
                    mapRef.current.getMap().getCenter().lat +
                    address.coords.latitude,
                };
                const sw = {
                  lng:
                    mapRef.current.getMap().getBounds()._sw.lng -
                    mapRef.current.getMap().getCenter().lng +
                    address.coords.longitude,
                  lat:
                    mapRef.current.getMap().getBounds()._sw.lat -
                    mapRef.current.getMap().getCenter().lat +
                    address.coords.latitude,
                };

                setViewPortFromBounds({ _sw: sw, _ne: ne });
              }
              if (directionCallback) {
                directionCallback(address);
              }
            }}
            addressName={actualAddress.value}
          />
        </div>
      )}
      {markersData &&
        clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
            cluster_id: id,
            markerId: markerId,
            property_type: property_type,
            price__value: price__value,
            operation_type: operation_type,
          } = cluster.properties;
          if (isCluster) {
            const isHover = forcePopup ? getChild(supercluster.getChildren(id)) : false;
            return (
              <ClusterMapMarker
                latitude={latitude}
                longitude={longitude}
                sizeWeighting={0} // pointCount / points.length
                className={isHover ? 'clusterMarker selected' : 'clusterMarker'}
                publicationIds={supercluster
                  .getLeaves(id, Infinity)
                  .map((value) => value.properties.property_id)}
                creatingPolygone={mode !== undefined && mode !== null}
                pointCount={pointCount}
                forcePublicationPopupId={forcePopup}
                onCardClick={onCardClick}
                isSelected={(markerId) => selectedProperties?.has(markerId)}
                handleSelectProperty={handleSelectProperty}
                currentUserId={currentUserId}
              />
            );
          }

          return (
            <MapMarker
              onCardClick={onCardClick}
              key={markerId}
              id={markerId}
              latitude={latitude}
              longitude={longitude}
              operation={operation_type}
              price={price__value}
              propertyType={property_type}
              creatingPolygone={mode !== undefined && mode !== null}
              zoom={viewport.zoom}
              forcePopup={forcePopup == markerId}
              isSelected={selectedProperties?.has(markerId)}
              isBlocked={isBlocked}
              handleSelectProperty={handleSelectProperty}
              currentUserId={currentUserId}
            />
          );
        })}
      {pointsOfInterest.length !== 0 &&
        pointsOfInterest.map((point, index) => {
          const [longitude, latitude] = point.geometry.coordinates;
          const { category } = point.properties;
          return (
            <PointOfInterest
              key={index}
              category={category}
              latitude={latitude}
              longitude={longitude}
            />
          );
        })}
      {actualAddress.coords ? (
        <MainMarker
          latitude={actualAddress.coords.latitude}
          longitude={actualAddress.coords.longitude}
          isSatelital={isSatelital}
        />
      ) : (
        <></>
      )}
      {polygonOnMap && config.calculateArea && (
        <Fragment>
          <Popup
            className="areaPopup"
            closeButton={false}
            closeOnClick={false}
            dynamicPosition={false}
            latitude={bbox(editorRef.current.getFeatures()[0].geometry)[3]}
            longitude={
              (bbox(editorRef.current.getFeatures()[0].geometry)[0] +
                bbox(editorRef.current.getFeatures()[0].geometry)[2]) /
              2
            }
          >
            <div>{Math.round(area(editorRef.current.getFeatures()[0]))} m²</div>
          </Popup>
        </Fragment>
      )}
      <Editor
        ref={editorRef}
        style={{ width: '100%', height: '100%' }}
        clickRadius={12}
        mode={mode}
        features={
          polygonFilter
            ? [
                {
                  properties: {},
                  geometry: {
                    coordinates: polygonFilter.coordinates, // latitude longitude pairs of the geometry points
                    type: polygonFilter.type, // geojson type, one of `Point`, `LineString`, or `Polygon`
                  },
                },
              ]
            : []
        }
        onUpdate={onUpdate}
        editHandleShape={'circle'}
        editHandleStyle={isSatelital ? getEditHandleStyleSatelital : getEditHandleStyleDefault}
        featureStyle={isSatelital ? getFeatureStyleSatelital : getFeatureStyleDefault}
      />
      <div className="mapboxgl-ctrl-top-right">
        <div className="alert">
          {mode ? (
            polylineCoords.length < 3 ? (
              <>
                <div>Dibujar filtro por polígono</div>
              </>
            ) : (
              polylineCoords.length > 2 && (
                <>
                  <div>Cerrar dibujo para terminar</div>
                </>
              )
            )
          ) : (
            config.propertiesAlerts &&
            (isLoadingMarkers ? (
              <>
                <img className="loadingIcon" src={loading} />
                {polygonOnMap ? (
                  <div>Aplicando filtro por polígono</div>
                ) : (
                  <div>Buscando propiedades...</div>
                )}
              </>
            ) : (
              <>
                <div>{markersData.length} Resultados encontrados</div>
              </>
            ))
          )}
        </div>
      </div>
      <div className="mapboxgl-ctrl-bottom-right">
        <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
          {config.polygon && (
            <button
              className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
              title={polygonOnMap ? 'Borrar Polígono' : 'Generar polígono'}
              onClick={toggleEditingMode}
              style={
                mode || polygonOnMap
                  ? {
                      border: '2px solid #8186f7',
                      backgroundColor: '#8186f7',
                    }
                  : { border: '2px solid #ffffff', backgroundColor: '#ffffff' }
              }
            >
              {polygonOnMap ? (
                <Icon component={IconTrash} className="deleteTrashIcon" />
              ) : mode ? (
                <img src={iconPolygonWhite} />
              ) : (
                <img src={iconPolygonBlack} />
              )}
            </button>
          )}
          {config.markerLocation && actualAddress.coords && (
            <button
              title="Center Marker"
              onClick={() => {
                setViewport({
                  ...viewport,

                  transitionInterpolator: new FlyToInterpolator({
                    speed: 2,
                  }),
                  transitionDuration: 'auto',
                  latitude: actualAddress.coords.latitude,
                  longitude: actualAddress.coords.longitude,
                });
              }}
            >
              <img
                src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9c64cfe3-bb3b-4ae8-b5a6-d2f39d21ff87/d3jme6i-8c702ad4-4b7a-4763-9901-99f8b4f038b0.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvOWM2NGNmZTMtYmIzYi00YWU4LWI1YTYtZDJmMzlkMjFmZjg3XC9kM2ptZTZpLThjNzAyYWQ0LTRiN2EtNDc2My05OTAxLTk5ZjhiNGYwMzhiMC5wbmcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.JAMbat4sBPIi4yMAvudrMIWf7vOdCgts3vn-JqFq1Oo"
                className="iconMapPin"
              />
            </button>
          )}
          {config.ubication && (
            <button
              title="Center GPS"
              onClick={() => {
                GetUserLocation();
              }}
            >
              <img className="iconCenterGPS" />
            </button>
          )}
          {config.zoomButtons && (
            <div className="zoomButtons">
              <button
                onClick={() =>
                  setViewport({
                    ...viewport,
                    transitionDuration: 200,
                    transitionInterpolator: new FlyToInterpolator(),
                    zoom: viewport.zoom + 1,
                  })
                }
              >
                <img className="iconPlus" />
              </button>
              <button
                onClick={() =>
                  setViewport({
                    ...viewport,
                    transitionDuration: 200,
                    transitionInterpolator: new FlyToInterpolator(),
                    zoom: viewport.zoom - 1,
                  })
                }
              >
                <img className="iconMinus" />
              </button>
            </div>
          )}
        </div>
      </div>
      {config.satelitalControl && (
        <div className="mapbox-satelital-control mapboxgl-ctrl-group mapboxgl-ctrl">
          <div className="mapbox-satelital-button-group">
            <button
              onClick={() => {
                setIsSatelital(false);
              }}
              className={`satelitalControl ${!isSatelital ? 'active' : 'inactive'}`}
            >
              <IconMap />
              Mapa
            </button>
            <button
              onClick={() => {
                setIsSatelital(true);
              }}
              className={`satelitalControl ${!isSatelital ? 'inactive' : 'active'}`}
            >
              <IconSatelite />
              Satelite
            </button>
          </div>
        </div>
      )}
    </MapGL>
  );
};

export default Map;

Map.propTypes = {
  markersData: PropTypes.arrayOf(PropTypes.object),
  callback: PropTypes.func,
  setViewportCallback: PropTypes.func,
  setPolygonCallback: PropTypes.func,
  satelital: PropTypes.bool,
  address: PropTypes.object,
  initialViewPort: PropTypes.object,
  config: PropTypes.object,
  isLoadingMarkers: PropTypes.bool,
  directionCallback: PropTypes.func,
  onCardClick: PropTypes.func,
  forcePopup: PropTypes.number,
  handleSelectProperty: PropTypes.func,
  selectedProperties: PropTypes.object,
  getAddress: PropTypes.func,
  isBlocked: PropTypes.bool,
  pointsOfInterest: PropTypes.arrayOf(PropTypes.object),
  // polygon dictionary with type and coordinates
  polygonFilter: PropTypes.object,
};

Map.defaultProps = {
  isBlocked: false,
  pointsOfInterest: [],
};

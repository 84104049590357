import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useMutation, useQuery } from '@apollo/client';
import { PROPERTIES_PREVIEW } from '../../graphql/queries';
//import _ from 'lodash';
import CardGrid from '../Cards/CardGrid';
import Constants from '../../constants';
import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import PropertiesExcelDownload from '../Properties/ExcelDownload';
// import { CREATE_USER_FAVORITE_PUBLICATIONS } from '../../graphql/mutations/publications';
import {
  REMOVE_PUBLICATIONS_FROM_COLLECTION,
  ADD_PUBLICATIONS_TO_COLLECTION,
} from '../../graphql/mutations/collections';
import { GET_PUBLICATIONS_IN_USER_COLLECTIONS } from '../../graphql/queries';
import { IconClose, IconTrash, IconDownload, IconLikemini, IconReload } from '../../assets';
import { parsePropertyPreview } from '../Properties/services';
import { useHistory } from 'react-router-dom';

const { IS_DESKTOP, IS_MOBILE } = Constants;

export const CollectionPublications = ({
  //selectedCollection,
  //selectedCollectionPublications,
  currentUser,
}) => {
  // responsive stuff
  const isDesktop = useMediaQuery(IS_DESKTOP);
  const isMobile = useMediaQuery(IS_MOBILE);
  // id variables are explicitly defined here
  const history = useHistory();
  // collection id is only available in the URL
  const { id } = useParams();
  const currentCollectionId = Number(id);
  // collections are made of publications
  const [collectionPublicationIds, setCollectionPublicationIds] = useState([]);
  // preview publications are cards info for the publications inside the collection
  const [collectionPublicationPreviews, setCollectionPublicationPreviews] = useState([]);
  // publications can be selected
  const [selectedPublicationIds, setSelectedPublicationIds] = useState(new Set());
  // UI state
  const [deletedTooltip, setDeletedTooltip] = useState(false);
  const [addFavoriteTooltip, setAddedFavoriteTooltip] = useState(false);
  const undoDelete = useRef(true);
  const undoLike = useRef(true);
  // queries & mutations
  // TODO: this is a temporary solution, we need to refactor the queries and mutations to be implemented in the same way for all the components
  const [removePublicationsFromCollection] = useMutation(REMOVE_PUBLICATIONS_FROM_COLLECTION);
  const [addPublicationsToCollection] = useMutation(ADD_PUBLICATIONS_TO_COLLECTION);
  useQuery(GET_PUBLICATIONS_IN_USER_COLLECTIONS, {
    // THis is the query that gets the publications in the collection that the user is currently in
    fetchPolicy: 'cache-and-network',
    variables: { userId: currentUser },
    onCompleted: (data) => {
      const collection = data.getUserCollections.filter(({ id }) => id === currentCollectionId)[0];
      const collectionPublicationIds = collection.real_estate_publications.map(({ id }) => id);
      setCollectionPublicationIds(collectionPublicationIds);
    },
  });

  const { refetch } = useQuery(
    GET_PUBLICATIONS_IN_USER_COLLECTIONS,
    {
      // THis is the query that gets the publications in the collection that the user is currently in
      fetchPolicy: 'cache-and-network',
      variables: { userId: currentUser },
      onCompleted: (data) => {
        const collection = data.getUserCollections.filter(
          ({ id }) => id === currentCollectionId,
        )[0];
        const publicationIds = collection.real_estate_publications.map(({ id }) => id);
        setCollectionPublicationIds(publicationIds);
      },
    },
    { enabled: true },
  );

  useQuery(
    PROPERTIES_PREVIEW,
    {
      // This is the query that gets the publications details for the publications inside the collection
      // This query is concatenated with the query above to get the publications in the collection
      fetchPolicy: 'cache-and-network',
      variables: { ids: collectionPublicationIds },
      onCompleted: (data) => {
        const { parsedPropertiesPreview } = parsePropertyPreview(data.getPublicationPreviews);
        setCollectionPublicationPreviews(parsedPropertiesPreview);
      },
    },
    [collectionPublicationIds],
  );
  // END TODO

  // effects
  const handleSelectPublication = (id, isSelected) => {
    // Let's create a new set of selected publications
    const newSet = new Set(selectedPublicationIds);
    if (!isSelected) {
      // If the publication is not selected, we add it to the set
      newSet.add(id);
    } else {
      // If the publication is selected, we remove it from the set
      newSet.delete(id);
    }
    // Update the state
    setSelectedPublicationIds(newSet);
  };
  const resetSelectedPublicationIds = () => {
    // Reset the selected publications
    setSelectedPublicationIds(new Set());
  };

  const removeSelectedPublicationIds = () => {
    const selectedPublicationIdsToBeRemoved = Array.from(selectedPublicationIds);
    resetSelectedPublicationIds();
    removePublicationsFromCollection({
      variables: {
        collectionId: currentCollectionId,
        publicationIds: selectedPublicationIdsToBeRemoved,
      },
    }).then(() => refetch());
    setDeletedTooltip({ show: true, deleted: selectedPublicationIdsToBeRemoved });
    setTimeout(() => {
      undoDelete.current = true;
      setDeletedTooltip({ ...deletedTooltip, show: false });
    }, 7000);
  };

  const addFavoritePublications = (props) => {
    console.log('dummy add favorite publications');
    console.log(props);
  };

  const handleLikeSelectedPublications = () => {
    setAddedFavoriteTooltip({
      show: true,
      number: selectedPublicationIds.size,
    });
    setTimeout(() => {
      if (undoLike.current) {
        addFavoritePublications({
          variables: {
            userId: currentUser,
            collectionPublicationIds: Array.from(selectedPublicationIds),
          },
        });
      }
      undoLike.current = true;
      setAddedFavoriteTooltip({ ...addFavoriteTooltip, show: false });
    }, 7000);
    resetSelectedPublicationIds();
  };

  const restoreCollection = () => {
    //const newCollection = [...properties, ...deletedTooltip.deleted];
    setDeletedTooltip({ ...deletedTooltip, show: false });
    addPublicationsToCollection({
      variables: {
        collectionId: currentCollectionId,
        publicationIds: deletedTooltip.deleted,
      },
    });
    undoDelete.current = false;
    refetch();
  };

  const unlikeProperties = () => {
    setAddedFavoriteTooltip({ ...addFavoriteTooltip, show: false });
    undoLike.current = false;
  };
  // Render the component
  return (
    <div className="collections-data-view">
      {isMobile && (
        <div className="collections-tag">
          <div className="text">Mis colecciones</div>
        </div>
      )}
      <div className="specific-collection-view-header">
        <div className=" collections-view-header-count">
          {isDesktop
            ? `${collectionPublicationIds.length} propiedades guardadas en colección`
            : `${collectionPublicationIds.length} propiedades`}
        </div>
        <div className="collections-view-header-options">
          <div className="collections-view-header-text">
            {isDesktop
              ? `${selectedPublicationIds.size} propiedades seleccionadas`
              : `${selectedPublicationIds.size} seleccionadas`}
          </div>
          <div className="actions">
            <span className="collections-header-tools-first">
              <PropertiesExcelDownload
                render={
                  <Tooltip title="Descargar Excel">
                    <Button
                      icon={<Icon component={IconDownload} />}
                      type="link"
                      onClick={() => resetSelectedPublicationIds()}
                    />
                  </Tooltip>
                }
                filename="TassApp Resumen de Propiedades"
                sheetName="Hoja 1"
                data={
                  collectionPublicationPreviews &&
                  collectionPublicationPreviews.filter((x) => selectedPublicationIds.has(x.id))
                }
              ></PropertiesExcelDownload>
            </span>
            {selectedPublicationIds.size > 0 && (
              <>
                <span className="collections-header-tools">
                  <Tooltip title="Agregar a favoritos" placement="top">
                    <Button
                      icon={<Icon component={IconLikemini} />}
                      type="link"
                      onClick={handleLikeSelectedPublications}
                    />
                  </Tooltip>
                </span>
                <span className="collections-header-tools">
                  <Tooltip title="Sacar seleccionados">
                    <Button
                      icon={<Icon component={IconTrash} />}
                      type="link"
                      onClick={() => removeSelectedPublicationIds()}
                    />
                  </Tooltip>
                </span>
                <span className="collections-header-tools">
                  <Tooltip title="Deseleccionar">
                    <Button
                      icon={<Icon component={IconClose} />}
                      type="link"
                      onClick={() => resetSelectedPublicationIds()}
                    />
                  </Tooltip>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      {deletedTooltip.show && (
        <div className="add-favorites-popup">
          <div className="add-popup-text">
            {`${deletedTooltip.deleted.length} propiedades han sido eliminadas exitosamente de la colección`}
          </div>
          <div className="add-popup-undo" onClick={() => restoreCollection()}>
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </div>
        </div>
      )}
      {addFavoriteTooltip.show && (
        <div className="add-favorites-popup">
          <div className="add-popup-text">
            {`Propiedades han sido guardadas exitosamente en Favoritos`}
          </div>
          <div className="add-popup-undo" onClick={() => unlikeProperties()}>
            <Icon component={IconReload} style={{ color: '#FFFFFF' }} />
            Deshacer
          </div>
        </div>
      )}
      <CardGrid
        loadingMore={true}
        gridLayout={'vertical'}
        propertiesPreviewData={collectionPublicationPreviews}
        onCardClick={(publication) => history.push(`/properties/${publication.id}` + '/full')} // we could have a publication popup in the future
        seeMore={false}
        onSeeMoreClick={() => {}}
        setPropertyOnHook={() => {}}
        handleSelectProperty={handleSelectPublication}
        selectedProperties={selectedPublicationIds}
        hidden={false}
      />
    </div>
  );
};

CollectionPublications.defaultProps = {
  selectedCollection: null,
};

CollectionPublications.propTypes = {
  currentUser: PropTypes.string,
};

export default CollectionPublications;

import React from 'react';
import { useMutation } from '@apollo/client';
import { GET_REPORT_URL, DELETE_APPRAISAL } from '../../../graphql/mutations/appraisals';
import { message, Button, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { IconTrash, IconDownload } from '../../../assets';
import { propertyTypeFormatter } from '../../Properties/constants';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import './style.less';

moment.locale('es');

function AppraisalList({ appraisals }) {
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  const [createPDF] = useMutation(GET_REPORT_URL, {
    onCompleted: (data) => {
      window.open(data.getReportUrl);
    },
  });

  const [deleteAppraisalMutation] = useMutation(DELETE_APPRAISAL, {
    onCompleted: (data) => {
      const htmlAppraisalElement = document.getElementById(data.deleteValorization);
      htmlAppraisalElement.classList.add('deleted');
      setTimeout(() => {
        htmlAppraisalElement.remove();
      }, 1200);
      message.destroy(data.deleteValorization);
    },
  });

  const downloadPDF = async (id) => {
    message.loading({ content: 'Cargando PDF', key: id });
    await createPDF({
      variables: {
        valorizationId: id,
      },
    });
    message.destroy(id);
  };

  const deleteAppraisal = async (id) => {
    await deleteAppraisalMutation({
      variables: {
        appraisalId: id,
      },
    });
  };

  return (
    <table className="appraisalsTable">
      <thead>
        <tr className="appraisalsHeader">
          <th className="locationHeader">Ubicación en mapa</th>
          <th className="addressHeader">Dirección de propiedad</th>
          <th className="typeHeader">Tipo de propiedad</th>
          <th className="dateHeader">Tiempo desde valorización</th>
          <th className="priceHeader">Valorización de venta</th>
          <th className="priceHeader">Valorización de arriendo</th>
          <th className="actionsHeader">Acciones</th>
        </tr>
      </thead>

      {appraisals.length !== 0 && (
        <tbody>
          {appraisals.map((appraisal) => (
            <tr
              id={appraisal.id}
              key={appraisal.id}
              onClick={() => window.location.assign(`/appraisals/${appraisal.id}`)}
            >
              <td className="locationCell">
                <img
                  src={`https://api.mapbox.com/styles/v1/gustav19x/ckgirm1xp24kd19pjn45kcalg/static/pin-l+4D29DD(${appraisal.longitude},${appraisal.latitude})/${appraisal.longitude},${appraisal.latitude},14,0/200x150?access_token=${MAPBOX_TOKEN}`}
                />
              </td>

              <td className="addressCell">{appraisal.address}</td>
              <td className="typeCell">{propertyTypeFormatter[appraisal.propertyType]}</td>
              <td className="dateCell">{moment(appraisal.createdAt).fromNow()}</td>
              <td>{Math.floor(appraisal.sellPrice).toLocaleString('de-DE')} UF</td>
              <td>{Math.floor(appraisal.rentPrice).toLocaleString('de-DE')} UF/mes</td>

              <td className="actionsCell">
                <Tooltip title="Descargar PDF">
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadPDF(appraisal.id);
                    }}
                    icon={<Icon component={IconDownload} />}
                  />
                </Tooltip>
                <Tooltip title="Eliminar valorización" placement="topRight">
                  <Button
                    icon={<Icon component={IconTrash} />}
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      const htmlAppraisalElement = document.getElementById(appraisal.id);
                      htmlAppraisalElement.classList.add('removing');
                      message.loading({ content: 'Eliminando propiedad', key: appraisal.id });
                      deleteAppraisal(appraisal.id);
                    }}
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
}

AppraisalList.propTypes = {
  appraisals: PropTypes.arrayOf(PropTypes.object),
};

export default AppraisalList;

//

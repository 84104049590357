import backend from '../../../backend';

// Publications API

export const fetchPublications = async function (params) {
  const response = await backend.post('/real_estate_market/fetch_publications_from_ids/', params);
  return response.data.response;
};

export const fetchProjectPublications = async function ({ projectId, userId }) {
  const params = {
    project_id: projectId,
    user_id: userId,
    fields: {
      id: true,
      title: true,
      address_line: true,
      condition: true,
      operation: true,
      property_type: true,
      construction_year: true,
      total_area: true,
      covered_area: true,
      created_time: true,
      description: true,
      external_source_id: true,
      external_source_url: true,
      first_time_found: true,
      full_bathrooms: true,
      rooms: true,
      bedrooms: true,
      warehouses: true,
      last_published_price: true,
      last_published_price_per_total_area: true,
      last_published_price_per_covered_area: true,
      last_time_found: true,
      location: true,
      maintenance_cost: true,
      pictures: true,
      source: true,
      region: true,
      country: true,
      region: true,
      province: true,
      municipality: true,
      district: true,
      zone_or_locality: true,
      block_or_entity: true,
      is_active: true,
      is_favorite: true,
      is_hidden: true,
      estimated_sale_price: true,
      estimated_rental_price: true,
      estimated_yield: true,
    },
  };
  const response = await backend.post('/real_estate_market/fetch_project_publications/', params);
  return response.data.response;
};

export const fetchDuplicatedPublications = async function ({ publicationId, userId }) {
  const params = {
    publication_ids: [publicationId],
    user_id: userId,
    fields: {
      id: true,
      title: true,
      address_line: true,
      condition: true,
      operation: true,
      property_type: true,
      construction_year: true,
      total_area: true,
      covered_area: true,
      created_time: true,
      description: true,
      external_source_id: true,
      external_source_url: true,
      first_time_found: true,
      full_bathrooms: true,
      rooms: true,
      bedrooms: true,
      warehouses: true,
      last_published_price: true,
      last_published_price_per_total_area: true,
      last_published_price_per_covered_area: true,
      last_time_found: true,
      location: true,
      maintenance_cost: true,
      pictures: true,
      source: true,
      region: true,
      country: true,
      region: true,
      province: true,
      municipality: true,
      district: true,
      zone_or_locality: true,
      block_or_entity: true,
      is_active: true,
      is_favorite: true,
      is_hidden: true,
      estimated_sale_price: true,
      estimated_rental_price: true,
      estimated_yield: true,
    },
  };
  const response = await backend.post('/real_estate_market/fetch_duplicated_publications/', params);
  return response.data.response[publicationId];
};
//Mutations

export const addPublicationsToFavorites = async function ({ userId, publicationIds }) {
  const response = await backend.post('/real_estate_market/add_publications_to_user_favorites/', {
    user_id: userId,
    publication_ids: publicationIds,
    fields: {
      id: true,
      last_published_price: true,
      operation: true,
      title: true,
      address_line: true,
      created_time: true,
      first_time_found: true,
      last_time_found: true,
      is_favorite: true,
    },
  });
  return response.data.response;
};

export const removePublicationsFromFavorites = async function ({ userId, publicationIds }) {
  const response = await backend.post(
    '/real_estate_market/remove_publications_from_user_favorites/',
    {
      user_id: userId,
      publication_ids: publicationIds,
      fields: {
        id: true,
        last_published_price: true,
        operation: true,
        title: true,
        address_line: true,
        created_time: true,
        first_time_found: true,
        last_time_found: true,
        is_favorite: true,
      },
    },
  );
  return response.data.response;
};

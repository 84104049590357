import { gql } from '@apollo/client';

// Propterties Queries
const GET_PROPERTIES = gql`
  query filterPublicationsInsidePolygonByAttributes(
    $polygon: JSON
    $propertyType: String
    $operationType: String
    $conditionType: String
    $publishedPriceMin: Float
    $publishedPriceMax: Float
    $roomsMin: Int
    $roomsMax: Int
    $bathroomsMin: Int
    $bathroomsMax: Int
    $coveredAreaMin: Float
    $coveredAreaMax: Float
    $totalAreaMin: Float
    $totalAreaMax: Float
    $publishedDateMin: String
    $sortBy: String
    $disponibility: Boolean
  ) {
    filterPublicationsInsidePolygonByAttributes(
      polygon: $polygon
      property_type: $propertyType
      operation_type: $operationType
      condition_type: $conditionType
      published_price__min: $publishedPriceMin
      published_price__max: $publishedPriceMax
      rooms__min: $roomsMin
      rooms__max: $roomsMax
      bathrooms__min: $bathroomsMin
      bathrooms__max: $bathroomsMax
      covered_area__min: $coveredAreaMin
      covered_area__max: $coveredAreaMax
      total_area__min: $totalAreaMin
      total_area__max: $totalAreaMax
      published_date__min: $publishedDateMin
      sort_by: $sortBy
      exclude_non_active: $disponibility
    )
  }
`;

const PROPERTIES_PREVIEW = gql`
  query getPublicationPreviews($ids: [Int!]) {
    getPublicationPreviews(publication_ids: $ids)
  }
`;

const GET_PUBLICATION_DETAILS = gql`
  query getPublicationAttributesView($id: Int!) {
    getPublicationAttributesView(publication_id: $id)
  }
`;

const GET_PUBLICATION_VALORIZATION_DETAILS = gql`
  query getPublicationAppraisalView($id: Int!) {
    getPublicationAppraisalView(publication_id: $id)
  }
`;

const GET_PRICE_ANALISIS = gql`
  query getPublicationPriceAnalysisView($id: Int!) {
    getPublicationPriceAnalysisView(publication_id: $id)
  }
`;

const GET_PRICE_HISTORY = gql`
  query getPublicationPriceHistoryView($id: Int!) {
    getPublicationPriceHistoryView(publication_id: $id)
  }
`;

const GET_SIMILAR_PUBLICATIONS = gql`
  query filterSimilarPublicationsFromPublicationId($id: Int!) {
    filterSimilarPublicationsFromPublicationId(publication_id: $id)
  }
`;

const GET_SAME_PROJECT_PUBLICATIONS = gql`
  query filterPublicationsInTheSameProjectFromPublicationId($id: Int!) {
    filterPublicationsInTheSameProjectFromPublicationId(publication_id: $id)
  }
`;
const GET_DUPLICATED_PUBLICATIONS = gql`
  query filterPotentialDuplicatedPublicationsFromPublicationId($id: Int!) {
    filterPotentialDuplicatedPublicationsFromPublicationId(publication_id: $id)
  }
`;

const GET_PROPERTY_PICTURES = gql`
  query getPublicationPictures($id: Int!) {
    getPublicationPictures(publication_id: $id)
  }
`;

// Unassinged Queries

const GET_POINTS_OF_INTEREST_RADIUS = gql`
  query getPointsOfInterestsWithinRadius(
    $categories: [String]
    $latitude: Float!
    $longitude: Float!
    $radius: Float!
  ) {
    getPointsOfInterestsWithinRadius(
      categories: $categories
      latitude: $latitude
      longitude: $longitude
      radius: $radius
    )
  }
`;
const GET_METRIC_TIME = gql`
  query getMetricTimeSeries(
    $spatial_id: Int
    $spatial_type: String
    $metric_id: Int
    $property_type: String
  ) {
    getMetricTimeSeries(
      spatial_id: $spatial_id
      spatial_type: $spatial_type
      metric_id: $metric_id
      property_type: $property_type
    )
  }
`;

const GET_METRIC_TYPES = gql`
  query getMetricTypes {
    getMetricTypes {
      id
      category
      name
      unit
      spanish_name
    }
  }
`;

const GET_USER_FAVORITE_PUBLICATIONS = gql`
  query getUserFavoritePublications($userId: Int!) {
    getUserFavoritePublications(userId: $userId) {
      id
      point_location
      real_estate_asset {
        property_type
        parking_lots
        floors
        total_area
        covered_area
        rooms
        full_bathrooms
        has_swimming_pool
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      source
      external_source_id
      unique_external_id
      external_source_url
      description
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
    }
  }
`;

const GET_METRIC_VALUES = gql`
  query getMetricValues(
    $spatial_type: String
    $spatial_id: Int
    $metric_id: Int
    $property_type: String
    $aggregation: String
  ) {
    getMetricValues(
      spatial_type: $spatial_type
      spatial_id: $spatial_id
      metric_id: $metric_id
      property_type: $property_type
      aggregation: $aggregation
    )
  }
`;

const GET_REAL_ESTATE_OFFER_PUBLICATIONS = gql`
  query getRealEstateOfferPublications($ids: [Int!]) {
    getRealEstateOfferPublications(ids: $ids) {
      id
      real_estate_asset {
        property_type
        parking_lots
        floors
        total_area
        covered_area
        rooms
        full_bathrooms
        has_swimming_pool
        location {
          id
          region {
            id
            name
          }
          municipality {
            id
            name
          }
        }
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      source
      external_source_id
      unique_external_id
      external_source_url
      description
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
      yield_value
      estimated_sell_price_median
      estimated_sell_price_min
      estimated_sell_price_max
      estimated_rental_price_median
      estimated_rental_price_min
      estimated_rental_price_max
      min_zone_price {
        amount
        unit
      }
      max_zone_price {
        amount
        unit
      }
      zone_percentile
    }
  }
`;

const GET_REAL_ESTATE_OFFER_PUBLICATIONS_RESUMED = gql`
  query getRealEstateOfferPublications($ids: [Int!]) {
    getRealEstateOfferPublications(ids: $ids) {
      id
      real_estate_asset {
        property_type
        covered_area
        total_area
        rooms
        full_bathrooms
        location {
          id
          region {
            id
            name
          }
        }
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
      yield_value
    }
  }
`;

const GET_PROPERTIES_FROM_FILTER = gql`
  query getPropertiesFromFilter(
    $polygon: JSON
    $property_type: String
    $operation_type: String
    $published_price__min: Float
    $published_price__max: Float
    $rooms__min: Int
    $rooms__max: Int
    $bathrooms__min: Int
    $bathrooms__max: Int
    $covered_area__min: Float
    $covered_area__max: Float
    $total_area__min: Float
    $total_area__max: Float
    $published_date__min: String
    $is_admin: Boolean!
  ) {
    getPropertiesFromFilter(
      polygon: $polygon
      property_type: $property_type
      operation_type: $operation_type
      published_price__min: $published_price__min
      published_price__max: $published_price__max
      rooms__min: $rooms__min
      rooms__max: $rooms__max
      bathrooms__min: $bathrooms__min
      bathrooms__max: $bathrooms__max
      covered_area__min: $covered_area__min
      covered_area__max: $covered_area__max
      total_area__min: $total_area__min
      total_area__max: $total_area__max
      published_date__min: $published_date__min
      is_admin: $is_admin
    ) {
      id
      is_hidden
      point_location
      operation
      last_published_price {
        id
        amount
        unit
        frequency
      }
    }
  }
`;

const GET_PUBLICATIONS_IN_USER_COLLECTIONS = gql`
  query getUserCollections($userId: Int!) {
    getUserCollections(userId: $userId) {
      id
      name
      real_estate_publications {
        id
      }
      user_id
    }
  }
`;

const GET_USER_COLLECTIONS = gql`
  query getUserCollections($userId: Int!) {
    getUserCollections(userId: $userId) {
      id
      name
      real_estate_publications {
        id
        real_estate_asset {
          property_type
          parking_lots
          floors
          total_area
          covered_area
          rooms
          full_bathrooms
          has_swimming_pool
          location {
            id
            region {
              id
              name
            }
            municipality {
              id
              name
            }
          }
        }
        last_published_price {
          id
          amount
          unit
          frequency
        }
        operation
        title
        address_line
        created_time
        first_time_found
        last_time_found
        source
        external_source_id
        unique_external_id
        external_source_url
        description
        is_active
        is_hidden
        point_location
        pictures {
          id
          original_url
        }
      }
      user_id
    }
  }
`;

const LOCATE_MUNICIPALITY = gql`
  query getMunicipality($latitude: Float!, $longitude: Float!) {
    getMunicipality(latitude: $latitude, longitude: $longitude) {
      id
      name
      region {
        id
        name
      }
    }
  }
`;

const LOCATE_CITY = gql`
  query locateCity($latitude: Float!, $longitude: Float!) {
    locateCity(latitude: $latitude, longitude: $longitude) {
      id
      name
      state {
        id
        name
      }
    }
  }
`;

const GET_CURRENT_USER_DATA = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      email
      phone
      role {
        id
        name
      }
    }
  }
`;
const GET_USER_PROFILE = gql`
  query getUserProfile($userId: Int!) {
    getUserProfile(userId: $userId)
  }
`;

const PROFILE_VALORIZATION_INFO = gql`
  query getProfileValorizationInfo {
    getProfileValorizationInfo
  }
`;

const GET_ID_USER_DATA = gql`
  query getUser($id: Int!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      phone
      role {
        id
        name
      }
    }
  }
`;

const GET_CURRENT_USER_VALORIZATIONS = gql`
  query getMyValorizations($limit: Int!, $offset: Int!) {
    getMyValorizations(limit: $limit, offset: $offset) {
      id
      address
      propertyType
      createdAt
      sellPrice
      rentPrice
      longitude
      latitude
    }
  }
`;
const GET_CURRENT_USER_MASSIVE_VALORIZATIONS = gql`
  query getMyMassiveValorizations($limit: Int!, $offset: Int!) {
    getMyMassiveValorizations(limit: $limit, offset: $offset) {
      id
      name
      valorizationCount
      createdAt
    }
  }
`;

const GET_MASSIVE_VALORIZATION = gql`
  query getMassiveValorization($id: Int!) {
    getMassiveValorization(id: $id) {
      name
      createdAt
    }
  }
`;

const GET_MASSIVE_VALORIZATION_DETAILS = gql`
  query getMassiveValorizationDetail($limit: Int!, $offset: Int!, $id: Int!) {
    getMassiveValorizationDetail(limit: $limit, offset: $offset, id: $id) {
      id
      sellPrice
      rentPrice
      address
      propertyType
      latitude
      longitude
    }
  }
`;
const GET_USERS = gql`
  query getUsers(
    $textSearch: NonEmptyString
    $roleId: Int
    $fromDate: DateTime
    $toDate: DateTime
  ) {
    getUsers(textSearch: $textSearch, roleId: $roleId, fromDate: $fromDate, toDate: $toDate) {
      id
      lastName
      firstName
      email
      phone
      active
      createdAt
      role {
        id
        name
      }
    }
  }
`;

const GET_ROLES = gql`
  query getRoles($active: Boolean!) {
    getRoles(active: $active) {
      id
      spanishName
    }
  }
`;

const GET_SIMILAR_PROPERTIES = gql`
  query getSimilarProperties(
    $propertyId: Int!
    $operation: String!
    $propertyType: String!
    $city: String!
    $state: String!
    $hasSwimmingPool: Boolean!
    $latitude: Float!
    $longitude: Float!
    $totalArea: Float!
    $coveredArea: Float!
    $parkingLots: Int!
    $bathrooms: Int!
    $floors: Int!
    $bedrooms: Int!
  ) {
    getSimilarProperties(
      propertyId: $propertyId
      operation: $operation
      propertyType: $propertyType
      city: $city
      state: $state
      hasSwimmingPool: $hasSwimmingPool
      latitude: $latitude
      longitude: $longitude
      totalArea: $totalArea
      coveredArea: $coveredArea
      parkingLots: $parkingLots
      bathrooms: $bathrooms
      floors: $floors
      bedrooms: $bedrooms
    ) {
      id
      real_estate_asset {
        property_type
        parking_lots
        floors
        total_area
        covered_area
        rooms
        full_bathrooms
        has_swimming_pool
        location {
          id
          region {
            id
            name
          }
          municipality {
            id
            name
          }
        }
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      source
      external_source_id
      unique_external_id
      external_source_url
      description
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
    }
  }
`;

const GET_POINTS_OF_INTEREST_CATEGORIES = gql`
  query getPointOfInterestCategories {
    getPointOfInterestCategories
  }
`;

const GET_POINTS_OF_INTEREST = gql`
  query getPointsOfInterests($categories: [String], $polygon: JSON) {
    getPointsOfInterests(categories: $categories, polygon: $polygon)
  }
`;

const GET_TIME_FORECAST = gql`
  query getTimeForecast($propertyAttrs: [JSON]!, $days: Int!) {
    getTimeForecast(propertyAttrs: $propertyAttrs, days: $days)
  }
`;

// LEGACY

const GET_VALORIZATION = gql`
  query getValorization($id: Int!, $shareToken: String) {
    getValorization(id: $id, shareToken: $shareToken) {
      id
      city
      state
      sellPrice
      rentPrice
      address
      latitude
      longitude
      lowerBoundSellCI
      upperBoundSellCI
      lowerBoundRentCI
      upperBoundRentCI
      propertyType
      hasSwimmingPool
      totalArea
      coveredArea
      parkingLots
      bathrooms
      bedrooms
      floors
      yield
      upperBoundYieldCI
      lowerBoundYieldCI
      warehouses
      hasBalcony
    }
  }
`;

export {
  PROPERTIES_PREVIEW,
  GET_PUBLICATION_DETAILS,
  GET_PUBLICATION_VALORIZATION_DETAILS,
  GET_PRICE_ANALISIS,
  GET_PRICE_HISTORY,
  GET_SIMILAR_PUBLICATIONS,
  GET_SAME_PROJECT_PUBLICATIONS,
  GET_DUPLICATED_PUBLICATIONS,
  GET_PROPERTIES_FROM_FILTER,
  GET_PROPERTY_PICTURES,
  GET_POINTS_OF_INTEREST_RADIUS,
  LOCATE_CITY,
  LOCATE_MUNICIPALITY,
  GET_USER_COLLECTIONS,
  GET_PUBLICATIONS_IN_USER_COLLECTIONS,
  GET_VALORIZATION,
  GET_PROPERTIES,
  GET_MASSIVE_VALORIZATION,
  GET_MASSIVE_VALORIZATION_DETAILS,
  GET_METRIC_TIME,
  GET_METRIC_TYPES,
  GET_METRIC_VALUES,
  GET_CURRENT_USER_DATA,
  GET_CURRENT_USER_VALORIZATIONS,
  GET_CURRENT_USER_MASSIVE_VALORIZATIONS,
  GET_USERS,
  GET_USER_FAVORITE_PUBLICATIONS,
  GET_ROLES,
  GET_SIMILAR_PROPERTIES,
  GET_POINTS_OF_INTEREST,
  GET_TIME_FORECAST,
  GET_POINTS_OF_INTEREST_CATEGORIES,
  GET_REAL_ESTATE_OFFER_PUBLICATIONS,
  GET_REAL_ESTATE_OFFER_PUBLICATIONS_RESUMED,
  GET_ID_USER_DATA,
  GET_USER_PROFILE,
  PROFILE_VALORIZATION_INFO,
};

import {
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc,
    getDocs,
    setDoc,
    addDoc,
    updateDoc,
    doc,
    serverTimestamp,
    arrayUnion,
    where,
    deleteDoc
  } from 'firebase/firestore';
  import { getAuth, signInAnonymously } from 'firebase/auth';
  import { db, app } from '../backend';
  
  /* Users */
  
  export const getCurrentUser = () => {  
    const auth = getAuth(app);
    const user = auth.currentUser;
    return user;
  };
  
  export const getUsers = () => {
    const usersDocRef = collection(db, 'users');
    return getDocs(usersDocRef).then((usersRef) => usersRef.docs);
  };
  
  export const getUser = async ({uid}) => {
    const docRef = doc(db, 'users', uid);
    try {
        const docSnap = await getDoc(docRef);
        return docSnap.data();
    } catch(error) {
        console.log(error)
        return;
    }
  };
  
  export const updateUser = async ({uid, ...updatedFields}) => {
    const userColRef = collection(db, 'users');
    const docRef = doc(userColRef, uid);
    const newUser = await updateDoc(docRef, updatedFields);
    return newUser;
  }
  
  export const createUser = async ({ uid, email, name }) => {
    const userColRef = collection(db, 'users');
    return await setDoc(doc(userColRef, uid), {
      uid: uid,
      email: email,
      name: name,
      createdAt: serverTimestamp(),
      pro: false,
      admin: false
    });
  };
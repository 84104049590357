import React, { Fragment, useCallback, useEffect, useState } from 'react'; //useEffect,
import PropTypes from 'prop-types';
import { StaticMap } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers'; //, PolygonLayer
import { LightingEffect, AmbientLight, _SunLight as SunLight } from '@deck.gl/core';
import { scaleThreshold } from 'd3-scale';
import DemoMix from './graphic';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse } from '@material-ui/core';
import Loading from '../Loading';
import { Row, Col } from 'antd';
import './style.less';
//import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

export const COLOR_SCALE = scaleThreshold()
  .domain([0, 0.1, 0.3, 0.5, 0.7, 0.9])
  .range([
    [248, 177, 149],
    [232, 153, 141],
    [216, 129, 133],
    [145, 102, 129],
    [99, 97, 127],
    [53, 92, 125],
  ]);

const INITIAL_VIEW_STATE = {
  latitude: -33.447487,
  longitude: -70.673676,
  zoom: 10,
  //maxZoom: 16,
  maxPitch: 0,
  pitch: 0,
  bearing: 0,
  //minZoom: 11,
};

const MAP_STYLE = 'mapbox://styles/gustav19x/ckgirm1xp24kd19pjn45kcalg';
const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
});

const dirLight = new SunLight({
  timestamp: Date.UTC(2019, 7, 1, 22),
  color: [255, 255, 255],
  intensity: 1.0,
  _shadow: false,
});

/*const landCover = [
  [
    [-123.0, 49.196],
    [-123.0, 49.324],
    [-123.306, 49.324],
    [-123.306, 49.196]
  ]
];*/

function getTooltip({ object }) {
  return (
    object && {
      html: `\
      <div>${object.properties.spatial_entity_name}</div>
      <div>
        <table> 
          <thead>
            <tr>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UF ${object.properties.value__percentile_50.toFixed()}</td>
            </tr>
          </tbody>
        </table>
      </div>

  `,
      style: {
        display: 'grid',
        backgroundColor: 'white',
        color: 'black',
        'border-radius': '10px',
        'text-align': 'center',
        'line-height': '30 px',
        pading: '40 px',
      },
    }
  );
}

function getTooltipVolumen({ object }) {
  return (
    object && {
      html: `\
      <div>${object.properties.spatial_entity_name}</div>
      <div>
        <table> 
          <thead>
            <tr>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>${object.properties.property_count} propiedades</td>
            </tr>
          </tbody>
        </table>
      </div>

  `,
      style: {
        display: 'grid',
        backgroundColor: 'white',
        color: 'black',
        'border-radius': '10px',
        'text-align': 'center',
        'line-height': '30 px',
        pading: '40 px',
      },
    }
  );
}

const ExpandableItem = (props) => {
  const [open, setOpen] = useState(false);
  return props.render({ open, setOpen });
};

const Grafico = ({ graphicName, dataLine, dataArea, zoneName }) => (
  <div className="area">
    <ExpandableItem
      render={(xprops) => (
        <Fragment>
          <ListItem button>
            <ListItemText
              primary={graphicName + ' / ' + zoneName}
              onClick={() => xprops.setOpen(!xprops.open)}
            />
            {xprops.open ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          <Collapse
            in={xprops.open}
            timeout="auto"
            unmountOnExit
            className="chart2"
            collapsedSize="10px"
          >
            {dataLine[0] ? (
              <DemoMix className="chart" dataLine={dataLine} dataArea={dataArea} />
            ) : (
              <h3>No hay datos seleccionados</h3>
            )}
          </Collapse>
        </Fragment>
      )}
    />
  </div>
);

Grafico.propTypes = {
  graphicName: PropTypes.string,
  zoneName: PropTypes.string,
  dataLine: PropTypes.array,
  dataArea: PropTypes.array,
};

const Leyenda = ({ graphicName, maxMetricValue, loading, metricId }) => (
  <div className={loading ? 'leyenda loading' : 'leyenda'}>
    <h3>{graphicName}</h3>
    <Row className="valores">
      <Col id="min">
        <p>
          {loading
            ? 'max'
            : metricId == 227 || metricId == 228
            ? '0%'
            : metricId == 223 || metricId == 224
            ? '0'
            : 'UF 0'}
        </p>
      </Col>
      <Col id="max">
        <p>
          {loading
            ? 'max'
            : metricId == 227 || metricId == 228
            ? maxMetricValue + '%'
            : metricId == 223 || metricId == 224
            ? maxMetricValue
            : 'UF ' + maxMetricValue}
        </p>
      </Col>
    </Row>
    <Row className="colores">
      <Col className="color" id="color-1"></Col>
      <Col className="color" id="color-2"></Col>
      <Col className="color" id="color-3"></Col>
      <Col className="color" id="color-4"></Col>
      <Col className="color" id="color-5"></Col>
      <Col className="color" id="color-6"></Col>
    </Row>
  </div>
);

Leyenda.propTypes = {
  graphicName: PropTypes.string,
  maxMetricValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  metricId: PropTypes.number,
};

const LoadingMap = () => (
  <div className="loading-map">
    <h3>¡Ya estamos casi!</h3>
    <p>
      Nuestros sistemas de búsqueda están calculando las métricas en tiempo real con nuestras bases
      de datos
    </p>
    <p>¡Quedará Filete!</p>
    <div className="spin">
      <Loading />
    </div>
  </div>
);

export default function IndicatorMap({
  data,
  graphicName,
  dataLine,
  dataArea,
  loading,
  setSelectedSpatialID,
  metricId,
}) {
  const [dataPolygonSelected, setDataPolygonSelected] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [maxMetricValue, setMaxMetricValue] = useState(1);
  const [effects] = useState(() => {
    const lightingEffect = new LightingEffect({ ambientLight, dirLight });
    lightingEffect.shadowColor = [0, 0, 0, 0.5];
    return [lightingEffect];
  });
  const [zoneName, setZoneName] = useState('Seleccione un Sector');
  const [maxValueReady, setMaxValueReady] = useState(false);

  const layers = [
    // only needed when using shadows - a plane for shadows to drop on
    new GeoJsonLayer({
      id: 'geojson',
      data,
      opacity: 0.5,
      stroked: true,
      filled: true,
      lineWidthUnits: 'pixels',
      extruded: false,
      wireframe: true,
      getElevation: null,
      getFillColor: (f) =>
        COLOR_SCALE(
          metricId == 224 || metricId == 223
            ? f.properties.property_count / maxMetricValue
            : f.properties.value__percentile_50 / maxMetricValue,
        ),
      getLineColor: [0, 50, 150, 100],
      pickable: true,
      getLineWidth: 2,
    }),
    new GeoJsonLayer({
      id: 'selectedGeojson',
      data: dataPolygonSelected,
      opacity: 1,
      stroked: true,
      filled: true,
      lineWidthUnits: 'pixels',
      extruded: false,
      wireframe: true,
      getElevation: null,
      getFillColor: (f) =>
        COLOR_SCALE(
          (metricId == 224 || metricId == 223
            ? f.properties.property_count
            : f.properties.value__percentile_50) / maxMetricValue,
        ),
      getLineColor: [0, 50, 150],
      pickable: true,
      getLineWidth: 5,
    }),
  ];

  useEffect(() => {
    setDataPolygonSelected({ type: 'FeatureCollection', features: [] });
    setMaxValueReady(false);
    setZoneName('seleccione un sector');
    if (data.features) {
      let value = 1;
      data.features.map((polygon) => {
        if (
          (metricId == 223 || metricId == 224
            ? polygon.properties.property_count
            : polygon.properties.value__percentile_50) > value
        ) {
          value =
            metricId == 223 || metricId == 224
              ? polygon.properties.property_count
              : polygon.properties.value__percentile_50;
        }
      });
      setMaxMetricValue(value.toFixed());
    }
  }, [data, metricId]);

  useEffect(() => {
    setMaxValueReady(true);
  }, [maxMetricValue]);

  const onClickPolygon = useCallback((event) => {
    if (event.object) {
      setDataPolygonSelected({ type: 'FeatureCollection', features: [event.object] });
      setSelectedSpatialID(event.object.properties.spatial_id);
      setZoneName(event.object.properties.spatial_entity_name);
    }
  }, []);

  return (
    <div>
      <DeckGL
        layers={maxValueReady && layers}
        effects={effects}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        pickable={true}
        getTooltip={metricId == 223 || metricId == 224 ? getTooltipVolumen : getTooltip}
        onClick={onClickPolygon}
      >
        <StaticMap reuseMaps mapStyle={MAP_STYLE} preventStyleDiffing={true} />
        {loading && <LoadingMap />}
        <Leyenda
          graphicName={graphicName}
          maxMetricValue={maxMetricValue}
          loading={loading}
          metricId={metricId}
        />
        <Grafico
          graphicName={graphicName}
          zoneName={zoneName}
          dataLine={dataLine}
          dataArea={dataArea}
        />
      </DeckGL>
    </div>
  );
}

IndicatorMap.propTypes = {
  data: PropTypes.object,
  graphicName: PropTypes.string,
  dataLine: PropTypes.array,
  dataArea: PropTypes.array,
  loading: PropTypes.bool,
  setSelectedSpatialID: PropTypes.func,
  metricId: PropTypes.number,
};

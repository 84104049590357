import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import SearchDirectionBar from '../../SearchDirectionBar';
import { LandingPage, Title, Text, Row, InputBar } from '../../RenderViews/LandingPage';

export default function SearchLandingPage() {
  //const {} = props;

  //const [searchPropertyTypeValue, setSearchPropertyTypeValue] = useState('house');
  //const [searchPropertyOperationValue, setSearchPropertyOperationValue] = useState('sell');
  const searchPropertyTypeValue = 'apartment';
  const searchPropertyOperationValue = 'sell';
  const [searchAddressValue, setSearchAddressValue] = useState('');

  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  //const isSmallDesktop = useMediaQuery({ maxWidth: 1300, minWidth: 767 });

  const buttonSize = isDesktop ? 'large' : 'medium';

  const SearchButton = () => (
    <Button type="primary" size={buttonSize}>
      <Link
        to={`/properties?propertyTypeParam=${searchPropertyTypeValue}&propertyOperationType=${searchPropertyOperationValue}&searchAddress=${
          searchAddressValue.value
        }&latitude=${
          searchAddressValue.coords ? searchAddressValue.coords.latitude : undefined
        }&longitude=${searchAddressValue.coords ? searchAddressValue.coords.longitude : undefined}`}
      >
        BUSCAR
      </Link>
    </Button>
  );

  return (
    <LandingPage>
      <Row>
        <div>
          <Title type="main">
            {isMobile ? 'Encuentra propiedades publicadas en portales' : 'Encuentra propiedades'}
          </Title>
          <Title type="secondary">
            {isMobile ? '' : 'publicadas en portales y descubre el mercado como nunca antes.'}
          </Title>
          <Text>
            Acelera tu negocio con el más alto estándar en ciencia de datos explorando más de 1
            millón de propiedades.
          </Text>
          <SearchButton />
        </div>
        {isDesktop && (
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchExampleLandingV2.png"
              alt="Search example"
              width="100%"
            />
          </div>
        )}
      </Row>
      {!isDesktop && (
        <Row>
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchExampleLandingV2.png"
              alt="Search example"
              width="100%"
            />
          </div>
        </Row>
      )}

      {!isMobile && (
        <Row>
          <InputBar>
            <SearchDirectionBar
              setAddress={(address) => {
                setSearchAddressValue(address);
              }}
            />
            <SearchButton />
          </InputBar>
        </Row>
      )}

      <Row>
        {isDesktop && (
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchNewPropertiesLanding.png"
              alt="Search new publications"
              width="100%"
            />
          </div>
        )}
        <div>
          <div>
            <Title type="main">Proyectos inmobiliarios</Title>
            <Title type="secondary">
              Propiedades nuevas con sus planos y valores en tiempo real.
            </Title>
          </div>
          <div style={{ marginTop: '60px', marginBottom: '60px' }}>
            <Text>
              Obtén todas las fotos y planos de las propiedades nuevas. Podrás ver los valores de
              cada propiedad al instante, obtén en segundos los datos de todas las unidades de los
              proyectos inmobiliarios.
            </Text>
          </div>
          <SearchButton />
        </div>
      </Row>
      {!isDesktop && (
        <Row>
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchNewPropertiesLanding.png"
              alt="Search new publications"
              width="100%"
            />
          </div>
        </Row>
      )}

      <Row>
        <div>
          <div>
            <Title type="main">Propiedades comparables</Title>
            <Title type="secondary">
              Actualizadas diariamente desde toda la oferta inmobiliaria.
            </Title>
          </div>
          <div style={{ marginTop: '60px', marginBottom: '60px' }}>
            <Text>
              En el detalle de la propiedad buscada, podrás ver todas las fotos de la propiedad y
              las propiedades comparables del sector. Estas propiedades comparables se pueden
              seleccionar para descargar, guardar en tus colecciones o agregar a tus favoritos.
            </Text>
          </div>
          <SearchButton />
        </div>
        {isDesktop && (
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchComparableLanding.png"
              alt="Search comparables"
              width="100%"
            />
          </div>
        )}
      </Row>
      {!isDesktop && (
        <Row>
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchComparableLanding.png"
              alt="Search comparables"
              width="100%"
            />
          </div>
        </Row>
      )}

      <Row>
        {isDesktop && (
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchInvestmentSimulationLanding.png"
              alt="Search investment simulation"
              width="100%"
            />
          </div>
        )}
        <div>
          <div>
            <Title type="main">Simulación de inversión</Title>
            <Title type="secondary">
              Simula el dividendo de tu crédito y comparalo con el arriendo estimado
            </Title>
          </div>
          <div style={{ marginTop: '60px', marginBottom: '60px' }}>
            <Text>
              Determina cuánto tendrías que pagar mensualmente en dividendos y compara estos valores
              con los valores de arriendo estimados por TassApp.
            </Text>
          </div>
          <SearchButton />
        </div>
      </Row>
      {!isDesktop && (
        <Row>
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchInvestmentSimulationLanding.png"
              alt="Search investment simulation"
              width="100%"
            />
          </div>
        </Row>
      )}
    </LandingPage>
  );
}

SearchLandingPage.propTypes = {};

SearchLandingPage.defaultProps = {};

import React from "react";
import PropTypes from "prop-types";
import { Button, Drawer, Modal } from "antd";
import Icon from "@ant-design/icons";

import LogIn from "./LogInContent";
import SignUp from "./SignUpContent";
import ButtonGoogleAuth from "../Buttons/GoogleAuth";
import { IconClose } from "../../assets";
import "./style.less";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

export default function ModalAuth(props) {
  const { authType, setAuthType } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const closeModal = () => {
    setAuthType();
  };

  if (isMobile) {
    return (
      <Drawer
        visible={!!authType}
        closable={true}
        onClose={closeModal}
        placement="bottom"
        closeIcon={<Icon component={IconClose} />}
        width="100%"
        height="calc(100% - 48px)"
        className="drawer-auth"
        zIndex={9}
      >
        <AuthContent {...props} />
      </Drawer>
    );
  }

  return (
    <Modal
      visible={!!authType}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
      closeIcon={<Icon component={IconClose} />}
      forceRender
      className="modal-auth"
      zIndex={1001}
    >
      <AuthContent {...props} />
    </Modal>
  );
}

ModalAuth.propTypes = {
  authType: PropTypes.oneOf(["logIn", "signUp"]),
  setAuthType: PropTypes.func,
};

const AuthContent = ({ authType, setAuthType }) => {
  const closeModal = () => {
    setAuthType();
  };

  const footerText = {
    logIn: "Regístrate aquí",
    signUp: "Ya tengo una cuenta",
  };

  const toggleModalType = () => {
    if (authType === "logIn") {
      setAuthType("signUp");
    } else {
      setAuthType("logIn");
    }
  };
  return (
    <Fragment>
      {authType === "logIn" && (
        <LogIn
          closeModal={closeModal}
          handleSignUp={() => setAuthType("signUp")}
        ></LogIn>
      )}
      {authType === "signUp" && (
        <SignUp
          closeModal={closeModal}
          handleLogIn={() => setAuthType("logIn")}
        ></SignUp>
      )}
      <span className="google-auth-divider">o</span>
      <ButtonGoogleAuth onCompleted={closeModal} />
      <Button type="link" onClick={toggleModalType} className="footer-link">
        {footerText[authType]}
      </Button>
    </Fragment>
  );
};

AuthContent.propTypes = {
  authType: PropTypes.oneOf(["logIn", "signUp"]),
  setAuthType: PropTypes.func,
};

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import {
  GET_CURRENT_USER_MASSIVE_VALORIZATIONS,
  GET_MASSIVE_VALORIZATION_DETAILS,
} from '../../../graphql/queries';
import { DELETE_MASSIVE_APPRAISAL } from '../../../graphql/mutations/appraisals'; // TODO: Remove
import { Button, Tooltip, message } from 'antd';
import Icon from '@ant-design/icons';
import MassiveAppraisalList from '../../MassiveAppraisal/MassiveAppraisalList';
import EmptyView from '../EmptyView';
import Loading from '../../Loading';
import { IconClose, IconTrash, IconDownload } from '../../../assets';
import './style.less';
import { generateExcelFile } from './excelFile';
import GroupList from '../../GroupList';

const columns = [
  { label: 'Propiedad', value: 'row', style: { alignment: { wrapText: true } } },
  { label: 'ID', value: 'id', style: { alignment: { wrapText: true } } },
  { label: 'Fecha', value: 'createdTime', style: { alignment: { wrapText: true } } },
  { label: 'Tipo Propiedad', value: (x) => (x.propertyType === 'house' ? 'Casa' : 'Departamento') },
  { label: 'Dirección', value: 'address' },
  { label: 'Valorización venta', value: 'publishedPriceAmount' },
  { label: 'Valorización arriendo', value: 'publishedPriceAmount' },
  { label: 'Latitud', value: 'latitude' },
  { label: 'Longitud', value: 'longitude' },
  { label: 'Link', value: (x) => 'https://tassapp.com/properties/' + x.id + '/full' },
];

function MassiveAppraisalGroup() {
  const [massiveAppraisals, setMassiveAppraisals] = useState([]);
  const [massiveAppraisalsIndex, setMassiveAppraisalsIndex] = useState(0);
  const [getMoreData, setGetMoreData] = useState(true);
  const [massiveAppraisalsProperties, setMassiveAppraisalProperties] = useState({});
  const [selected, setSelected] = useState(new Set());

  const [massiveAppraisalPublicationsData, setMassiveAppraisalPublicationsData] = useState([]);

  function handleScroll(e) {
    const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
    if (bottom && getMoreData && !loading) {
      refetch({ limit: 10, offset: (massiveAppraisalsIndex + 1) * 10 });
      setMassiveAppraisalsIndex((old) => old + 1);
    }
  }

  function handleSelectAppraisals(appraisalId, action) {
    if (action) {
      setSelected((old) => new Set(old.add(appraisalId)));
    } else {
      setSelected((old) => {
        old.delete(appraisalId);
        return new Set(old);
      });
    }
  }

  const { loading, refetch } = useQuery(GET_CURRENT_USER_MASSIVE_VALORIZATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 10,
      offset: massiveAppraisalsIndex * 10,
    },
    onCompleted: (data) => {
      if (data.getMyMassiveValorizations.length === 0) {
        setGetMoreData(false);
      }

      const newData = [
        ...massiveAppraisals,
        ...data.getMyMassiveValorizations.map((item) => ({
          id: item.id,
          name: item.name,
          createdAt: item.createdAt,
          elementCount: item.valorizationCount,
          handleClick: () => window.location.assign(`/massive-appraisals/${item.id}`),
          handleDownload: () => {
            generateMassiveAppraisalExcel({
              variables: {
                id: item.id,
                limit: 0,
                offset: 0,
              },
            });
          },
          handleDelete: () =>
            deleteMassiveAppraisal({
              variables: {
                massiveAppraisals: [item.id],
              },
            }),
        })),
      ];

      setMassiveAppraisalProperties(
        newData.reduce((o, value) => ({ ...o, [value.id]: value }), {}),
      );
      setMassiveAppraisals(newData);
    },
  });

  const [generateMassiveAppraisalExcel] = useLazyQuery(GET_MASSIVE_VALORIZATION_DETAILS, {
    onCompleted: (response) => {
      let rowCount = 1;
      const massiveAppraisalData = response.getMassiveValorizationDetail.map((item) => ({
        id: item.id,
        row: ++rowCount,
        latitude: item.latitude,
        longitude: item.longitude,
        address: item.address,
        propertyType: item.propertyType,
        sellPrice: item.sellPrice,
        rentPrice: item.rentPrice,
      }));
      generateExcelFile({
        data: massiveAppraisalData,
        columns: [
          'id',
          'row',
          'latitude',
          'longitude',
          'address',
          'propertyType',
          'sellPrice',
          'rentPrice',
        ],
        fileName: 'VALORIZACION_MASIVA_' + new Date().getTime(),
        labels: {
          id: 'ID',
          row: 'Propiedad',
          latitude: 'Latitud',
          longitude: 'Longitud',
          address: 'Dirección de propiedad',
          propertyType: 'Tipo de propiedad',
          sellPrice: 'Valorización de venta',
          rentPrice: 'Valorizacion de arriendo',
        },
      });
    },
  });

  const [deleteMassiveAppraisal] = useMutation(DELETE_MASSIVE_APPRAISAL, {
    onCompleted: (variables) => {
      const massiveAppraisalId = variables.deleteMassiveValorizations[0];
      console.log(massiveAppraisalId);
      const htmlAppraisalElement = document.getElementById(`group-${massiveAppraisalId}`);
      htmlAppraisalElement.classList.add('deleted');
      setTimeout(() => {
        htmlAppraisalElement.remove();
      }, 1200);
      message.destroy(`group-${massiveAppraisalId}-delete`);
    },
  });

  return (
    <div className="userMassiveAppraisalsContainer" onScroll={(e) => handleScroll(e)}>
      <GroupList groups={massiveAppraisals} title={'Valorizaciones masivas'} />
      {loading && <Loading extraClass={`${massiveAppraisals.length !== 0 && 'noMargin'}`} />}
      {!loading && massiveAppraisals.length === 0 && (
        <EmptyView viewName={'valorizaciones masivas'} typeOf={'appraisal'} />
      )}
    </div>
  );
}

export default MassiveAppraisalGroup;

import React from 'react';
import PropTypes from 'prop-types';
import Card from './PropertyPreviewCard';
import SkeletonCard from './SkeletonCard';
import _ from 'lodash';
import './style.less';

export default function CardGrid(props) {
  const {
    currentIndex,
    chosenFilter,
    gridLayout,
    propertiesPreviewData,
    onCardClick,
    setPropertyOnHook,
    handleSelectProperty,
    handleUserLikePublications,
    handleUserRemoveLikePublications,
    selectedProperties,
    hidden,
    isBlocked,
    handleScroll,
    activePriceUnit,
    loadingMore,
    seeMore,
  } = props;

  const cardsToMap =
    chosenFilter && propertiesPreviewData
      ? _.orderBy(
          propertiesPreviewData.slice(0, currentIndex),
          chosenFilter.slice(0, -5),
          chosenFilter.slice(-3),
        )
      : propertiesPreviewData?.slice(0, currentIndex);

  return (
    <div
      className={`resultsScrollable ${gridLayout} ${hidden && 'hidden'}`}
      onScroll={(e) => handleScroll(e)}
    >
      <div
        className={`searchResultsGrid ${gridLayout}`}
        onScroll={(e) => {
          if (gridLayout == 'horizontal') {
            return handleScroll(e);
          }
        }}
      >
        {propertiesPreviewData
          ? cardsToMap.map((cardData) => (
              <Card
                property={{ ...cardData, priceText: cardData.prices[activePriceUnit] }}
                layout={gridLayout}
                key={`property-${cardData.id}`}
                onClick={() => onCardClick(cardData)}
                onMouseEnter={() => setPropertyOnHook(cardData.id)}
                onMouseLeave={() => setPropertyOnHook()}
                handleSelect={handleSelectProperty}
                handleLike={() => {
                  if (cardData.isFavorite) {
                    handleUserRemoveLikePublications({ publicationIds: [cardData.id] });
                  } else {
                    handleUserLikePublications({ publicationIds: [cardData.id] });
                  }
                }}
                isSelected={selectedProperties.has(cardData.id)}
                isBlocked={isBlocked}
                isFavorite={cardData.isFavorite}
              />
            ))
          : [...Array(12)].map((_, idx) => (
              <SkeletonCard layout={gridLayout} key={`skeleton-${idx}`} />
            ))}
        {loadingMore &&
          seeMore &&
          [...Array(12)].map((_, idx) => (
            <SkeletonCard layout={gridLayout} key={`skeleton-${idx}`} />
          ))}
        {propertiesPreviewData && !propertiesPreviewData.length && (
          <span className="no-results">No se encontraron resultados</span>
        )}
      </div>
    </div>
  );
}

CardGrid.propTypes = {
  handleScroll: PropTypes.func,
  currentIndex: PropTypes.number,
  chosenFilter: PropTypes.string,
  loadingMore: PropTypes.bool,
  gridLayout: PropTypes.string,
  propertiesPreviewData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      operation: PropTypes.string,
      title: PropTypes.string,
      address: PropTypes.string,
      createdTime: PropTypes.string,
      firstTimeFound: PropTypes.string,
      lastTimeFound: PropTypes.string,
      isActive: PropTypes.bool,
      isHidden: PropTypes.bool,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      publishedPriceAmount: PropTypes.number,
      publishedPriceUnit: PropTypes.string,
      propertyType: PropTypes.string,
      coveredArea: PropTypes.number,
      totalArea: PropTypes.number,
      bedRooms: PropTypes.number,
      fullBathrooms: PropTypes.number,
      pictures: PropTypes.arrayOf(PropTypes.string),
      yieldValue: PropTypes.number,
    }),
  ),
  onCardClick: PropTypes.func,
  seeMore: PropTypes.bool,
  setPropertyOnHook: PropTypes.func,
  handleSelectProperty: PropTypes.func,
  selectedProperties: PropTypes.object,
  hidden: PropTypes.bool,
  isBlocked: PropTypes.bool,
  activePriceUnit: PropTypes.string,
};

CardGrid.defaultProps = {
  isBlocked: false,
  handleScroll: () => {},
  activePriceUnit: 'UF',
};

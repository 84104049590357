import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar, useMutation, useLazyQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { Button, Drawer, Form, Input, Modal, Select, Steps, Switch, Spin } from 'antd';
import Icon from '@ant-design/icons';
import SearchDirectionBar from '../../SearchDirectionBar';
import { userTokenVar, restoreUserToken } from '../../../graphql/cache';
import { LOCATE_MUNICIPALITY } from '../../../graphql/queries';
import { CREATE_APPAISAL } from '../../../graphql/mutations/appraisals';
import Map from '../../Map';
import { IconArrowLeft } from '../../../assets';
import FloatLabel from '../../Modals/FloatLabel';
import './style.less';

const { Option } = Select;
const { Step } = Steps;

export default function AppraisalForm(props) {
  const { visible, setVisible } = props;
  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (isMobile) {
    return (
      <Drawer
        visible={visible}
        closable={false}
        placement="bottom"
        width="100%"
        height="100%"
        //height="calc(100% - 48px)"
        className="appraisal-drawer"
        zIndex={999}
      >
        <Button
          type="link"
          icon={<Icon component={IconArrowLeft} />}
          size="large"
          onClick={handleCancel}
          className="btn-go-back"
        >
          Volver
        </Button>
        <AppraisalFormContent {...props} />
      </Drawer>
    );
  }

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width="80%"
      height="90vh"
      className="appraisal-modal"
      zIndex={1000}
    >
      <AppraisalFormContent {...props} />
    </Modal>
  );
}

AppraisalForm.propTypes = {
  propertyType: PropTypes.string,
  setPropertyType: PropTypes.func.isRequired,
  address: PropTypes.object,
  setAddress: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setModalAuthType: PropTypes.func,
};

const AppraisalFormContent = (props) => {
  const {
    setVisible,
    propertyType,
    address,
    setPropertyType,
    setAddress,
    setModalAuthType,
  } = props;

  const userToken = useReactiveVar(userTokenVar);
  const history = useHistory();
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const [coveredAreaFormState, setCoveredAreaFormState] = useState();
  const [totalAreaFormState, setTotalAreaFormState] = useState();
  const [roomsFormState, setRoomsFormState] = useState();
  const [bathroomsFormState, setBathroomsFormState] = useState();
  const [parkingsFormState, setParkingsFormState] = useState();
  const [floorsFormState, setFloorsFormState] = useState();
  const [poolFormState, setPoolFormState] = useState();
  const [warehousesFormState, setwarehousesFormState] = useState();
  const [isDuplexFormState, setIsDuplexFormState] = useState();
  const [hasBalconyFormState, setHasBalconyFormState] = useState();
  const [currentFormStep, setCurrentFormStep] = useState(0);

  const houseIsReadyToAppraise =
    propertyType == 'house' &&
    coveredAreaFormState != null &&
    coveredAreaFormState != '' &&
    totalAreaFormState != null &&
    totalAreaFormState != '' &&
    roomsFormState != null &&
    roomsFormState != '' &&
    bathroomsFormState != null &&
    bathroomsFormState != '' &&
    parkingsFormState != null &&
    parkingsFormState != '' &&
    floorsFormState != null &&
    floorsFormState != '';
  const apartmentIsReadyToAppraise =
    propertyType == 'apartment' &&
    coveredAreaFormState != null &&
    coveredAreaFormState != '' &&
    totalAreaFormState != null &&
    totalAreaFormState != '' &&
    roomsFormState != null &&
    roomsFormState != '' &&
    bathroomsFormState != null &&
    bathroomsFormState != '' &&
    parkingsFormState != null &&
    parkingsFormState != '' &&
    warehousesFormState != null &&
    warehousesFormState != '';
  const isReadyToAppraise =
    propertyType == 'house' ? houseIsReadyToAppraise : apartmentIsReadyToAppraise;
  function clearData() {
    form.resetFields();
    setCurrentFormStep(0);
    setAddress();
    setPropertyType();
    setCoveredAreaFormState();
    setTotalAreaFormState();
    setRoomsFormState();
    setBathroomsFormState();
    setParkingsFormState();
    setFloorsFormState();
    setPoolFormState();
    setwarehousesFormState();
    setIsDuplexFormState();
    setHasBalconyFormState();
    setVisible(false);
  }

  const [createValorization, { loading }] = useMutation(CREATE_APPAISAL, {
    onCompleted: (data) => {
      clearData();
      history.push(`/appraisals/${data.createValorization.id}`);
    },
  });
  const [getLocation] = useLazyQuery(LOCATE_MUNICIPALITY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      createValorization({
        variables:
          propertyType === 'house'
            ? {
                createValorizationInput: {
                  propertyType: propertyType ? propertyType : 'house',
                  address: address.value,
                  city: data.getMunicipality.name,
                  state: data.getMunicipality.region.name,
                  hasSwimmingPool: poolFormState ? true : false,
                  latitude: address.coords.latitude,
                  longitude: address.coords.longitude,
                  totalArea: parseFloat(totalAreaFormState),
                  coveredArea: parseFloat(coveredAreaFormState),
                  parkingLots: parseInt(parkingsFormState, 10),
                  bathrooms: parseInt(bathroomsFormState, 10),
                  floors: parseInt(floorsFormState, 10),
                  bedrooms: parseInt(roomsFormState, 10),
                },
              }
            : {
                createValorizationInput: {
                  propertyType: propertyType ? propertyType : 'apartment',
                  address: address.value,
                  city: data.getMunicipality.name,
                  state: data.getMunicipality.region.name,
                  latitude: address.coords.latitude,
                  longitude: address.coords.longitude,
                  totalArea: parseFloat(totalAreaFormState),
                  coveredArea: parseFloat(coveredAreaFormState),
                  parkingLots: parseInt(parkingsFormState, 10),
                  bathrooms: parseInt(bathroomsFormState, 10),
                  bedrooms: parseInt(roomsFormState, 10),
                  isDuplex: isDuplexFormState ? isDuplexFormState : false,
                  hasBalcony: hasBalconyFormState ? hasBalconyFormState : false,
                  warehouses: parseInt(warehousesFormState, 10),
                },
              },
      });
    },
  });

  useEffect(() => form.resetFields(), [propertyType]);

  useEffect(() => {
    restoreUserToken();
  }, []);

  useEffect(() => {
    restoreUserToken();
  }, [userToken]);

  const onFinish = () => {
    if (address) {
      getLocation({
        variables: {
          latitude: address.coords.latitude,
          longitude: address.coords.longitude,
        },
      });
    }
  };

  const nextStep = () => {
    setCurrentFormStep(currentFormStep + 1);
  };

  const prevStep = () => {
    setCurrentFormStep(currentFormStep - 1);
  };
  const inputSize = isMobile ? 'middle' : 'middle';
  const buttonSize = isMobile ? 'middle' : 'large';
  return (
    <div className="appraisal-content">
      <div className="appraisal-form">
        <h1>¡Ya estamos casi!</h1>
        <Form
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
          initialValues={{ propertyType: propertyType }}
        >
          {!isMobile ? (
            <>
              <Form.Item className="col-2">
                <FloatLabel label="Dirección de la propiedad">
                  <SearchDirectionBar
                    address={address}
                    size="medium"
                    setAddress={(address) => {
                      setAddress(address);
                    }}
                  />
                </FloatLabel>
              </Form.Item>

              <Form.Item
                name="propertyType"
                rules={[
                  {
                    required: true,
                    message: 'Tipo de propiedad',
                  },
                ]}
                className="mobile-col-2"
              >
                <FloatLabel label="Tipo de propiedad">
                  <Select
                    onChange={(x) => {
                      setPropertyType(x);
                      const prevValues = form.getFieldsValue();
                      form.setFieldsValue({ ...prevValues, ['propertyType']: x });
                    }}
                    value={propertyType}
                    size={inputSize}
                  >
                    <Option value="house">Casa</Option>
                    <Option value="apartment">Departamento</Option>
                  </Select>
                </FloatLabel>
              </Form.Item>

              <Form.Item
                name="coveredArea"
                rules={[
                  {
                    required: true,
                  },
                ]}
                className="col-2"
              >
                <FloatLabel label="Área útil en m²">
                  <Input
                    value={coveredAreaFormState}
                    onChange={(x) => {
                      setCoveredAreaFormState(x.target.value);
                      const prevValues = form.getFieldsValue();
                      form.setFieldsValue({ ...prevValues, ['coveredArea']: x.target.value });
                    }}
                    type={'number'}
                    size={inputSize}
                  />
                </FloatLabel>
              </Form.Item>

              <Form.Item
                name="totalArea"
                rules={[
                  {
                    required: true,
                  },
                ]}
                className="col-2"
              >
                <FloatLabel label="Área total en m²">
                  <Input
                    value={totalAreaFormState}
                    onChange={(x) => {
                      setTotalAreaFormState(x.target.value);
                      const prevValues = form.getFieldsValue();
                      form.setFieldsValue({ ...prevValues, ['totalArea']: x.target.value });
                    }}
                    type={'number'}
                    size={inputSize}
                  />
                </FloatLabel>{' '}
              </Form.Item>
              <Form.Item
                name="rooms"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <FloatLabel label="Habitaciones">
                  <Input
                    value={roomsFormState}
                    onChange={(x) => {
                      setRoomsFormState(x.target.value);
                      const prevValues = form.getFieldsValue();
                      form.setFieldsValue({ ...prevValues, ['rooms']: x.target.value });
                    }}
                    type={'number'}
                    size={inputSize}
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item
                name="bathrooms"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <FloatLabel label="Baños completos">
                  <Input
                    value={bathroomsFormState}
                    onChange={(x) => {
                      setBathroomsFormState(x.target.value);
                      const prevValues = form.getFieldsValue();
                      form.setFieldsValue({ ...prevValues, ['bathrooms']: x.target.value });
                    }}
                    type={'number'}
                    size={inputSize}
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item
                name="parkings"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <FloatLabel label="Estacionamiento">
                  <Input
                    value={parkingsFormState}
                    onChange={(x) => {
                      setParkingsFormState(x.target.value);
                      const prevValues = form.getFieldsValue();
                      form.setFieldsValue({ ...prevValues, ['parkings']: x.target.value });
                    }}
                    type={'number'}
                    size={inputSize}
                  />
                </FloatLabel>
              </Form.Item>
              {propertyType === 'house' ? (
                <Form.Item
                  name="floors"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                >
                  <FloatLabel label="Cantidad de pisos">
                    <Input
                      value={floorsFormState}
                      onChange={(x) => {
                        setFloorsFormState(x.target.value);
                        const prevValues = form.getFieldsValue();
                        form.setFieldsValue({ ...prevValues, ['floors']: x.target.value });
                      }}
                      type={'number'}
                      size={inputSize}
                    />
                  </FloatLabel>
                </Form.Item>
              ) : (
                <Form.Item
                  name="warehouses"
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                >
                  <FloatLabel label="Bodegas">
                    <Input
                      value={warehousesFormState}
                      onChange={(x) => {
                        setwarehousesFormState(x.target.value);
                        const prevValues = form.getFieldsValue();
                        form.setFieldsValue({ ...prevValues, ['warehouses']: x.target.value });
                      }}
                      type={'number'}
                      size={inputSize}
                    />
                  </FloatLabel>
                </Form.Item>
              )}
              {propertyType === 'house' ? (
                <Form.Item
                  name="pool"
                  label={!isMobile && '¿Propiedad tiene piscina?'}
                  valuePropName="checked"
                  className="inline col-2"
                >
                  <Switch
                    checked={poolFormState}
                    onChange={(value) => {
                      setPoolFormState(value);
                      const prevValues = form.getFieldsValue();
                      form.setFieldsValue({ ...prevValues, ['pool']: value });
                    }}
                    size={inputSize}
                  />
                </Form.Item>
              ) : (
                <Fragment>
                  <Form.Item
                    name="isDuplex"
                    label={!isMobile && '¿Es duplex?'}
                    valuePropName="checked"
                    className="inline"
                  >
                    <Switch
                      checked={isDuplexFormState}
                      onChange={(value) => {
                        setIsDuplexFormState(value);
                        const prevValues = form.getFieldsValue();
                        form.setFieldsValue({ ...prevValues, ['isDuplex']: value });
                      }}
                      size={inputSize}
                    />
                  </Form.Item>
                  <Form.Item
                    name="hasBalcony"
                    label={!isMobile && '¿Tiene bálcon?'}
                    valuePropName="checked"
                    className="inline"
                  >
                    <Switch
                      checked={hasBalconyFormState}
                      onChange={(value) => {
                        setHasBalconyFormState(value);
                        const prevValues = form.getFieldsValue();
                        form.setFieldsValue({ ...prevValues, ['hasBalcony']: value });
                      }}
                      size={inputSize}
                    />
                  </Form.Item>
                </Fragment>
              )}

              {userToken ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  size={buttonSize}
                  disabled={address && address.coords && isReadyToAppraise ? false : true}
                  loading={loading}
                >
                  {loading ? 'Valorizando la propiedad' : 'Valorizar propiedad'}
                </Button>
              ) : (
                <Button
                  type="primary"
                  className="btn-submit"
                  size={buttonSize}
                  onClick={() => {
                    setModalAuthType('signUp');
                  }}
                >
                  REGÍSTRATE PARA VALORIZAR
                </Button>
              )}
            </>
          ) : (
            <Fragment>
              {loading ? (
                <>
                  <p>
                    Nuestra inteligencia artificial está valorizando la propiedad, esto puede tardar
                    unos minutos...
                  </p>
                  <Spin size="large" />
                </>
              ) : (
                <>
                  <Steps current={currentFormStep} size="default">
                    <Step key={'uno'} title={'Primer Paso'} />
                    <Step key={'dos'} title={'Segundo Paso'} />
                  </Steps>
                  <div className="steps-content">
                    {currentFormStep < 1 ? (
                      <div className="step-one">
                        <Map
                          directionCallback={(address) => {
                            setAddress(address);
                          }}
                          config={{
                            searchbar: true,
                            polygon: true,
                            markerUbication: true,
                            ubication: true,
                            zoomButtons: true,
                            satelitalControl: true,
                            propertiesAlerts: false,
                            calculateArea: true,
                          }}
                          satelital
                          address={address}
                        />
                      </div>
                    ) : (
                      <div className="step-two">
                        <Form.Item
                          name="propertyType"
                          label={!isMobile && 'Tipo de propiedad'}
                          rules={[
                            {
                              required: true,
                              message: 'Ingresa el tipo de propiedad',
                            },
                          ]}
                          className="col-2"
                        >
                          <FloatLabel label="Casa o Departamento">
                            <Select
                              onChange={(x) => {
                                setPropertyType(x);
                                const prevValues = form.getFieldsValue();
                                form.setFieldsValue({
                                  ...prevValues,
                                  ['propertyType']: x,
                                });
                              }}
                              value={propertyType}
                              size={inputSize}
                            >
                              <Option value="house">Casa</Option>
                              <Option value="apartment">Departamento</Option>
                            </Select>
                          </FloatLabel>
                        </Form.Item>

                        <Form.Item
                          name="coveredArea"
                          label={
                            !isMobile && 'Tamaño construido' // (área útil sin consederar patio/terraza)
                          }
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          className="col-2"
                        >
                          <FloatLabel label="Área útil en m²">
                            <Input
                              value={coveredAreaFormState}
                              onChange={(x) => {
                                setCoveredAreaFormState(x.target.value);
                                const prevValues = form.getFieldsValue();
                                form.setFieldsValue({
                                  ...prevValues,
                                  ['coveredArea']: x.target.value,
                                });
                              }}
                              type={'number'}
                              size={inputSize}
                            />
                          </FloatLabel>
                        </Form.Item>

                        <Form.Item
                          name="totalArea"
                          label={!isMobile && 'Tamaño terreno'} // (área total considerando patio/terraza)
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          className="col-2"
                        >
                          <FloatLabel label="Área total en m²">
                            <Input
                              value={totalAreaFormState}
                              onChange={(x) => {
                                setTotalAreaFormState(x.target.value);
                                const prevValues = form.getFieldsValue();
                                form.setFieldsValue({
                                  ...prevValues,
                                  ['totalArea']: x.target.value,
                                });
                              }}
                              type={'number'}
                              size={inputSize}
                            />
                          </FloatLabel>
                        </Form.Item>
                        <Form.Item
                          name="rooms"
                          label={!isMobile && 'Cantidad de habitaciones'}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <FloatLabel label="Habitaciones">
                            <Input
                              value={roomsFormState}
                              onChange={(x) => {
                                setRoomsFormState(x.target.value);
                                const prevValues = form.getFieldsValue();
                                form.setFieldsValue({ ...prevValues, ['rooms']: x.target.value });
                              }}
                              type={'number'}
                              size={inputSize}
                            />
                          </FloatLabel>
                        </Form.Item>
                        <Form.Item
                          name="bathrooms"
                          label={!isMobile && 'Cantidad de baños'}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <FloatLabel label="Baños">
                            <Input
                              value={bathroomsFormState}
                              onChange={(x) => {
                                setBathroomsFormState(x.target.value);
                                const prevValues = form.getFieldsValue();
                                form.setFieldsValue({
                                  ...prevValues,
                                  ['bathrooms']: x.target.value,
                                });
                              }}
                              type={'number'}
                              size={inputSize}
                            />
                          </FloatLabel>
                        </Form.Item>
                        <Form.Item
                          name="parkings"
                          label={!isMobile && 'Capacidad estacionamiento'}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <FloatLabel label="Estacionamientos">
                            <Input
                              value={parkingsFormState}
                              onChange={(x) => {
                                setParkingsFormState(x.target.value);
                                const prevValues = form.getFieldsValue();
                                form.setFieldsValue({
                                  ...prevValues,
                                  ['parkings']: x.target.value,
                                });
                              }}
                              type={'number'}
                              size={inputSize}
                            />
                          </FloatLabel>
                        </Form.Item>
                        {propertyType === 'house' ? (
                          <Form.Item
                            name="floors"
                            label={!isMobile && 'Cantidad de pisos'}
                            rules={[
                              {
                                required: true,
                                message: '',
                              },
                            ]}
                          >
                            <FloatLabel label="Pisos">
                              <Input
                                value={floorsFormState}
                                onChange={(x) => {
                                  setFloorsFormState(x.target.value);
                                  const prevValues = form.getFieldsValue();
                                  form.setFieldsValue({
                                    ...prevValues,
                                    ['floors']: x.target.value,
                                  });
                                }}
                                type={'number'}
                                size={inputSize}
                              />
                            </FloatLabel>
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name="parkings"
                            label={!isMobile && 'Capacidad estacionamiento'}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <FloatLabel label="Bodegas">
                              <Input
                                value={warehousesFormState}
                                onChange={(x) => {
                                  setwarehousesFormState(x.target.value);
                                  const prevValues = form.getFieldsValue();
                                  form.setFieldsValue({
                                    ...prevValues,
                                    ['warehoueses']: x.target.value,
                                  });
                                }}
                                type={'number'}
                                size={inputSize}
                              />
                            </FloatLabel>
                          </Form.Item>
                        )}
                        {propertyType === 'house' ? (
                          <Form.Item
                            name="pool"
                            label="¿Propiedad tiene piscina?"
                            valuePropName="checked"
                            className="inline col-2"
                          >
                            <Switch
                              checked={poolFormState}
                              onChange={(value) => {
                                setPoolFormState(value);
                                const prevValues = form.getFieldsValue();
                                form.setFieldsValue({ ...prevValues, ['pool']: value });
                              }}
                              size={inputSize}
                            />
                          </Form.Item>
                        ) : (
                          <Fragment>
                            <Form.Item
                              name="isDuplex"
                              label="¿Es duplex?"
                              valuePropName="checked"
                              className="inline"
                            >
                              <Switch
                                checked={isDuplexFormState}
                                onChange={(value) => {
                                  setIsDuplexFormState(value);
                                  const prevValues = form.getFieldsValue();
                                  form.setFieldsValue({ ...prevValues, ['isDuplex']: value });
                                }}
                                size={inputSize}
                              />
                            </Form.Item>
                            <Form.Item
                              name="hasBalcony"
                              label="¿Tiene bálcon?"
                              valuePropName="checked"
                              className="inline"
                            >
                              <Switch
                                checked={hasBalconyFormState}
                                onChange={(value) => {
                                  setHasBalconyFormState(value);
                                  const prevValues = form.getFieldsValue();
                                  form.setFieldsValue({
                                    ...prevValues,
                                    ['hasBalcony']: value,
                                  });
                                }}
                                size={inputSize}
                              />
                            </Form.Item>
                          </Fragment>
                        )}
                      </div>
                    )}
                    {currentFormStep < 1 && address != null && address.coords != null ? (
                      <div className="map-overlay steps-action">
                        <Button size="large" type="primary" onClick={() => nextStep()}>
                          Siguiente
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {currentFormStep > 0 && (
                      <div className="steps-action">
                        <Button
                          onClick={() => prevStep()}
                          type="primary"
                          htmlType="submit"
                          className="btn-submit"
                          size={buttonSize}
                        >
                          Atrás
                        </Button>
                        {currentFormStep === 1 && isReadyToAppraise && (
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-submit"
                            size={buttonSize}
                            disabled={address && address.coords ? false : true}
                            loading={loading}
                          >
                            Valorizar
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </Fragment>
          )}
        </Form>
      </div>
      {!isMobile && (
        <div className="map-container">
          <Map
            directionCallback={(address) => {
              setAddress(address);
            }}
            config={{
              polygon: true,
              markerUbication: true,
              ubication: true,
              zoomButtons: true,
              satelitalControl: true,
              propertiesAlerts: false,
              calculateArea: true,
            }}
            satelital
            address={address}
          />
        </div>
      )}
    </div>
  );
};

AppraisalFormContent.propTypes = AppraisalForm.propTypes;

import React, { useState } from 'react';
import { Image } from 'antd';
import './style.less';

function PicturesCarousel({ pictures }) {
  if (!pictures) {
    return <></>;
  }
  return (
    <div className="propertyImages">
      <Image.PreviewGroup>
        {pictures.map((src, idx) => (
          <Image key={`${idx}-${src}`} src={src} idx={idx} />
        ))}
      </Image.PreviewGroup>
    </div>
  );
}

PicturesCarousel.propTypes = {
  //publicationPictures: PropTypes.arrayOf,
};

export default PicturesCarousel;

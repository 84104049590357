import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import PublicationList from '../../RenderViews/PublicationList';
import ToggleFilter from '../../Filters/Toggle';
import { parsePublicationAPI } from '../../parsers';
import './style.less';
import { conditionType, operationType } from '../constants';
import { Spin } from 'antd';
import { fetchProjectPublications } from './backend';

function SameProjectPublications({ publicationData, userId, openFullScreen = false }) {
  const [sameProjectPublicationsData, setsameProjectPublicationsData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    try {
      //const publicationId = publicationData ? publicationData.id : null;
      if (publicationData.projectId) {
        fetchProjectPublications({ projectId: publicationData.projectId, userId: userId }).then(
          (publications) => {
            setsameProjectPublicationsData(
              publications.map((publication) => parsePublicationAPI(publication)),
            );
            setIsLoading(false);
          },
        );
      }
    } catch (error) {
      setHasError(true);
    }
  }, [publicationData]);

  const shouldNotRenderView = !publicationData || !publicationData.id || !publicationData.projectId;
  if (shouldNotRenderView) return <></>;

  return (
    <>
      <div className="componentDivider" />
      {hasError && <p>Something went wrong.</p>}

      <>
        <Row justify="space-between">
          <Col>
            <h1>Publicaciones del mismo proyecto</h1>
          </Col>
        </Row>
        {isLoading ? (
          <Row>
            <div className="componentTitle">
              <h1>Cargando...</h1>
              <Spin />
            </div>
          </Row>
        ) : (
          <>
            {!sameProjectPublicationsData.length ? (
              <Row>
                <div className="not-found-title">
                  <h3>No se encontraron propiedades</h3>
                </div>
              </Row>
            ) : (
              <PublicationList
                publications={sameProjectPublicationsData}
                referencePublication={publicationData}
                openFullScreen={openFullScreen}
                currentUserId={userId}
                mode={'row'}
              ></PublicationList>
            )}
          </>
        )}
      </>
    </>
  );
}

SameProjectPublications.propTypes = {
  openFullScreen: PropTypes.bool,
};

export default SameProjectPublications;

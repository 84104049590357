import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import DefaultInfoCard from '../../Cards/DefaultInfoCard';
import { IconHouseCoin, IconLike, IconSaved, iconUserProfileImage } from '../../../assets';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_USER_PROFILE, PROFILE_VALORIZATION_INFO } from '../../../graphql/queries';
import Loading from '../../Loading';
import './style.less';

function UserProfileData({ userName, lastName, userRole, userEmail, userPhone, userId }) {
  const [favoritesData, setfavoritesData] = useState();
  const [collectionData, setcolectionData] = useState();
  const [appraisalsThisMonth, setAppraisalsThisMonth] = useState([]);
  const [totalAppraisalsMade, setTotalAppraisalsMade] = useState([]);

  const isBigScreen = useMediaQuery({ minWidth: 1440 });
  const isIpadPro = useMediaQuery({ minWidth: 834, maxWidth: 1024 });
  const cardSize = isBigScreen || isIpadPro ? 'large' : 'medium';

  const [getUserProfileInfo] = useLazyQuery(GET_USER_PROFILE, {
    variables: {
      userId: parseInt(userId, 10),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setfavoritesData(data.getUserProfile['propiedades favoritas']);
      setcolectionData(data.getUserProfile['propiedades guardadas']);
    },
  });

  const { loading } = useQuery(PROFILE_VALORIZATION_INFO, {
    onCompleted: (data) => {
      setAppraisalsThisMonth(data.getProfileValorizationInfo[1]);
      setTotalAppraisalsMade(data.getProfileValorizationInfo[0]);
    },
  });

  useEffect(() => {
    if (userId) {
      getUserProfileInfo({
        variables: {
          userId: parseInt(userId, 10),
        },
      });
    }
  }, [userId]);

  return !loading ? (
    <div className="userProfileDataView">
      <div className="userProfileData">
        <img src={iconUserProfileImage} alt="" className="userProfileAvatar" />
        <h2>{`Perfil de ${userName} ${lastName}`}</h2>
        <span>
          Perfil <em>{userRole}</em>
        </span>
        <span>
          Email <em>{userEmail}</em>
        </span>
        <span>
          Celular <em>{userPhone || 'Sin información'}</em>
        </span>
      </div>
      <div className="userProfileDataCards">
        <DefaultInfoCard
          title="Propiedades valorizadas este mes"
          body={appraisalsThisMonth}
          footer="Valorizaciones realizadas por TassApp"
          icon={IconHouseCoin}
          size={cardSize}
        />
        <DefaultInfoCard
          title="Total propiedades valorizadas"
          body={totalAppraisalsMade}
          footer="Valorizaciones realizadas por TassApp"
          icon={IconHouseCoin}
          size={cardSize}
        />
        <DefaultInfoCard
          title="Propiedades en colecciones"
          body={collectionData}
          footer="Propiedades publicadas en TassApp"
          icon={IconSaved}
          size={cardSize}
        />
        <DefaultInfoCard
          title="Propiedades favoritas"
          body={favoritesData}
          footer="Propiedades publicadas en TassApp"
          icon={IconLike}
          size={cardSize}
        />
      </div>
    </div>
  ) : (
    <Loading />
  );
}

export default UserProfileData;

UserProfileData.propTypes = {
  userName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userPhone: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

import React, { cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';

import './style.less';

const FloatLabel = ({ children, label }) => {
  return (
    <Fragment>
      {cloneElement(children, { placeholder: ' ' })}
      <label className="label-float">{label}</label>
    </Fragment>
  );
};

FloatLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default FloatLabel;

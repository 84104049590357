import React from 'react';
import PropTypes from 'prop-types';
import PropertyInfo from '../../RenderViews/PropertyInfo';

function AppraisalPropertyInfo({ appraisalData }) {
  return <PropertyInfo data={appraisalData} mode="appraisal"></PropertyInfo>;
}

AppraisalPropertyInfo.propTypes = {
  appraisalData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    swimmingPool: PropTypes.bool.isRequired,
    parkings: PropTypes.number.isRequired,
    propertyType: PropTypes.oneOf(['Casa', 'Depto']).isRequired,
    floors: PropTypes.number.isRequired,
    totalAreaTextSmall: PropTypes.string.isRequired,
    fullBathrooms: PropTypes.number.isRequired,
    coveredAreaTextSmall: PropTypes.string.isRequired,
    bedRooms: PropTypes.string.isRequired,
    totalAreaText: PropTypes.string.isRequired,
    coveredAreaText: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    quality: PropTypes.number,
  }),
};

export default AppraisalPropertyInfo;

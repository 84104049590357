/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, message, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';
import {
  IconTrash,
  IconDownload,
  selectSelected,
  selectSelectedLavander,
  selectUnselected,
  selectUnselectedLavander,
} from '../../assets';
import ExcelFileButton from '../ExcelFileButton';
import PropTypes from 'prop-types';
import './style.less';

moment.locale('es');

const columns = [
  { label: 'Propiedad', value: 'row', style: { alignment: { wrapText: true } } },
  { label: 'ID', value: 'id', style: { alignment: { wrapText: true } } },
  { label: 'Fuente', value: 'source' },
  { label: 'Código', value: 'externalSourceId' },
  { label: 'Fecha', value: 'createdTime', style: { alignment: { wrapText: true } } },
  { label: 'Operación', value: (x) => (x.operation === 'sell' ? 'Venta' : 'Arriendo') },
  { label: 'Tipo Propiedad', value: (x) => (x.propertyType === 'house' ? 'Casa' : 'Departamento') },
  { label: 'Dirección', value: 'address' },
  { label: 'Precio publicado', value: 'publishedPriceAmount' },
  { label: 'Moneda', value: 'unit' },
  { label: 'Área Util', value: 'coveredArea' },
  { label: 'Área Total', value: 'totalArea' },
  { label: 'Dormitorios', value: 'bedRooms' },
  { label: 'Baños', value: 'fullBathrooms' },
  // { label: 'Estacionamientos', value: 'parkingLots' },
  { label: 'Bodegas', value: 'warehouses' },
  { label: 'Año Construccion', value: 'constructionYear' },
  { label: 'Latitud', value: 'latitude' },
  { label: 'Longitud', value: 'longitude' },
  { label: 'Comentario', value: 'description' },
  { label: 'Url', value: 'externalSourceUrl' },
  { label: 'Status', value: (x) => (x.isActive ? 'Disponible' : 'No disponible') },
  { label: 'Fecha Inical Publicacion', value: 'firstTimeFound' },
  { label: 'Gasto Comun', value: 'maintenanceCost' },
  {
    label: 'Distancia a dirección (m)',
    value: (x) => (x.distance !== null ? Math.round(x.distance * 1000, 0) : 'Faltan datos'),
  },
  { label: 'Foto', value: (x) => (x.pictures.length > 0 ? x.pictures[0] : '') },
  { label: 'Link', value: (x) => 'https://tassapp.com/properties/' + x.id + '/full' },
];

function GroupList({ groups, title, children }) {
  // groups is an array of groups, each group is an object with the following properties: id, name, createdAt, valorizationCount
  return (
    <>
      <div className="groupTitle">
        <h3>{title}</h3>
      </div>
      <table className="groupTable">
        <tbody>
          {groups.map(
            ({
              id,
              name,
              createdAt,
              elementCount,
              picture,
              handleClick,
              handleDownload,
              handleDelete,
            }) => (
              <GroupListRow
                key={id}
                id={id}
                name={name}
                createdAt={createdAt}
                elementCount={elementCount}
                picture={picture}
                handleClick={handleClick}
                handleDownload={handleDownload}
                handleDelete={handleDelete}
              />
            ),
          )}
        </tbody>
      </table>
      <div className="groupBody">{children}</div>
    </>
  );
}

GroupList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
GroupList.defaultProps = {
  groups: [],
  title: '',
};

export default GroupList; // this is the function for the group list

function GroupListRow({
  id,
  name,
  createdAt,
  elementCount,
  picture,
  handleClick,
  handleDownload,
  handleDelete,
}) {
  /*
  const [getExcelData] = useLazyQuery(GET_MASSIVE_VALORIZATION_DETAILS, {
    onCompleted: (data) => {
      onDownload([...data.getMassiveValorizationDetail], massiveAppraisal.id);
    },
  });
  const [deleteMassiveAppraisal] = useMutation(DELETE_MASSIVE_APPRAISAL, {
    variables: { massiveAppraisals: [massiveAppraisal.id] },
    onCompleted: () => {
      const htmlAppraisalElement = document.getElementById(`massive-${massiveAppraisal.id}`);
      htmlAppraisalElement.classList.add('deleted');
      setTimeout(() => {
        htmlAppraisalElement.remove();
      }, 1200);
      message.destroy(`massive-${massiveAppraisal.id}-delete`);
    },
  });
  */
  console.log(picture);
  return (
    <tr
      id={`group-${id}`}
      onClick={handleClick}
      // onClick = () => window.location.assign(`/massive-appraisals/${massiveAppraisal.id}`)
    >
      {/* <CheckTableCell
        handleCheck={(action) => handleCheck(massiveAppraisal.id, action)}
        isSelected={selected.has(massiveAppraisal.id)}
      /> */}
      {picture ? (
        <td className="groupPicture">
          <img src={picture} />
        </td>
      ) : (
        <td className="groupPicture"></td>
      )}
      <td className="nameCell">{name}</td>
      <td className="dateCell">{moment(createdAt).fromNow()}</td>{' '}
      {/* createdAt example: 2020-04-01T00:00:00.000Z */}
      <td className="quantityCell">
        {elementCount > 1 || elementCount === 0 ? `${elementCount} propiedades` : '1 propiedad'}
      </td>
      <td className="actionCell">
        {/*
        <ExcelFileButton
          filename={`${name}-${moment(createdAt).format('YYYY-MM-DD')}`}
          fetchData={fetchRowData}
     
          fetchData={() => fetchData()}
  
          columns={columns}
          callback={() => console.log("callback")}
       
          callback={() => {
            message.loading({
              content: `Generando Excel de ${name}`,
              key: `${id}-excel`,
            });
          }}
     
        />
        */}
        <Tooltip title="Descargar Excel">
          <Button
            icon={<Icon component={IconDownload} />}
            onClick={(e) => {
              e.stopPropagation();
              /*
              message.loading({
                content: `Generando Excel de ${name}`,
                key: `${id}-excel`,
              });
              */
              handleDownload();
              // getExcelData({ variables: { id: massiveAppraisal.id, limit: 0, offset: 0 } }); => Replace with handleDownload
            }}
          ></Button>
        </Tooltip>
      </td>
      <td className="actionCell">
        <Tooltip title="Borrar">
          <Button
            icon={
              <Icon
                component={IconTrash}
                onClick={(e) => {
                  e.stopPropagation();
                  const htmlElement = document.getElementById(`group-${id}`);
                  htmlElement.classList.add('removing');
                  message.loading({
                    content: `Eliminando ${name}`,
                    key: `${id}-delete`,
                  });
                  handleDelete().then(({ data }) => {
                    if (data.deleteCollection) {
                      htmlElement.parentNode.removeChild(htmlElement);
                    }
                  });
                  // deleteMassiveAppraisal(); => Replace with handleDelete
                }}
              />
            }
          ></Button>
        </Tooltip>
      </td>
    </tr>
  );
}

GroupListRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  createdAt: PropTypes.string,
  elementCount: PropTypes.number,
  picture: PropTypes.string,
  handleClick: PropTypes.func,
  handleDownload: PropTypes.func,
  handleDelete: PropTypes.func,
};

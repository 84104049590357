import { gql } from '@apollo/client';

export const HIDE_PUBLICATION = gql`
  mutation hideRealEstateOfferPublications($ids: [Int]!) {
    hideRealEstateOfferPublications(ids: $ids)
  }
`;

export const UNHIDE_PUBLICATION = gql`
  mutation unhideRealEstateOfferPublications($ids: [Int]!) {
    unhideRealEstateOfferPublications(ids: $ids)
  }
`;

export const CREATE_USER_FAVORITE_PUBLICATIONS = gql`
  mutation createUserFavoritePublications($userId: Int!, $publicationIds: [Int]!) {
    createUserFavoritePublications(userId: $userId, publicationIds: $publicationIds) {
      id
      real_estate_asset {
        property_type
        parking_lots
        floors
        total_area
        covered_area
        rooms
        full_bathrooms
        has_swimming_pool
      }
      last_published_price {
        id
        amount
        unit
        frequency
      }
      operation
      title
      address_line
      created_time
      first_time_found
      last_time_found
      source
      external_source_id
      unique_external_id
      external_source_url
      description
      is_active
      is_hidden
      point_location
      pictures {
        id
        original_url
      }
    }
  }
`;

export const DELETE_USER_FAVORITE_PUBLICATIONS = gql`
  mutation deleteUserFavoritePublications($userId: Int!, $publicationIds: [Int]!) {
    deleteUserFavoritePublications(userId: $userId, publicationIds: $publicationIds)
  }
`;

import XLSX from 'xlsx';
import FileSaver from 'file-saver';
// this files generates an excel file based on
// the data received and the columns received using the XLSX library and the FileSaver library

export function generateExcelFile({ data, columns, fileName, labels }) {
  // labels is a dictionary with the labels of the columns to be used in the excel file
  // the keys of the dictionary are the names of the columns
  // the values of the dictionary are the labels of the columns
  /*
  labels = {
    id: 'ID',
    latitude: 'Latitud',
    longitude: 'Longitud',
    address: 'Dirección de propiedad',
    propertyType: 'Tipo de propiedad',
    sellPrice: 'Valorización de venta',
    rentPrice: 'Valorizacion de arriendo',
  }
  */

  const csvData = data;
  console.log(csvData);
  console.log(columns);
  const wscols = columns.map((column) => ({
    wch: Math.max(
      labels[column],
      ...csvData.map((d) => (d[column] ? d[column].toString().length : 0)),
    ),
  }));
  const newArray = csvData.map((data) => {
    // eslint-disable-next-line no-unused-vars
    let { __typename, ...fixedData } = data;
    return fixedData;
  });
  const ws = XLSX.utils.json_to_sheet([labels], {
    header: columns,
    skipHeader: true,
    origin: 0, //ok
  });
  ws['!cols'] = wscols;
  XLSX.utils.sheet_add_json(ws, newArray, {
    header: columns,
    skipHeader: true,
    origin: -1, //ok
  });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const da = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(da, fileName + '.xlsx');
}

/*
export function createAndDownloadExcel(data, id) {
  console.log(data, id);
  const csvData = data;
  const wscols = [
    {
      wch: Math.max('id'.length, ...data.map((d) => d.id.toString().length)),
    },
    {
      wch: Math.max('Latitud'.length, ...data.map((d) => d.latitude.toString().length)),
    },
    {
      wch: Math.max('Longitud'.length, ...data.map((d) => d.longitude.toString().length)),
    },
    {
      wch: Math.max('Dirección de propiedad'.length, ...data.map((d) => d.address.length)),
    },
    {
      wch: Math.max('Tipo de propiedad'.length, ...data.map((d) => d.propertyType.length)),
    },
    {
      wch: Math.max(
        'Valorización de venta'.length,
        ...data.map((d) => d.sellPrice.toString().length),
      ),
    },
    {
      wch: Math.max(
        'Valorizacion de arriendo'.length,
        ...data.map((d) => d.rentPrice.toString().length),
      ),
    },
    {
      wch: Math.max(...data.map((d) => d.address.length)),
    },
  ];

  const fileName = `valorizacionMasiva-${id}`;
  const newArray = csvData.map((data) => {
    // eslint-disable-next-line no-unused-vars
    let { __typename, ...fixedData } = data;
    return fixedData;
  });
  const ws = XLSX.utils.json_to_sheet(
    [
      {
        id: 'ID',
        latitude: 'Latitud',
        longitude: 'Longitud',
        address: 'Dirección de propiedad',
        propertyType: 'Tipo de propiedad',
        sellPrice: 'Valorización de venta',
        rentPrice: 'Valorizacion de arriendo',
      },
    ],
    {
      header: ['id', 'latitude', 'longitude', 'address', 'propertyType', 'sellPrice', 'rentPrice'],
      skipHeader: true,
      origin: 0, //ok
    },
  );
  ws['!cols'] = wscols;
  XLSX.utils.sheet_add_json(ws, newArray, {
    header: ['id', 'latitude', 'longitude', 'address', 'propertyType', 'sellPrice', 'rentPrice'],
    skipHeader: true,
    origin: -1, //ok
  });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const da = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(da, fileName + '.xlsx');
  message.destroy(`massive-${id}-excel`);
}
*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Marker, Popup } from 'react-map-gl';
import PropertyPreviewCard from '../Cards/PropertyPreviewCard';
import SkeletonCard from '../Cards/SkeletonCard';
import { PROPERTIES_PREVIEW } from '../../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { parsePropertyPreview } from '../Properties/services';
import './style.less';

function MapMarker({
  id,
  latitude,
  longitude,
  operation,
  price,
  creatingPolygone,
  zoom,
  forcePopup,
  isSelected,
  handleSelectProperty,
  onCardClick,
}) {
  const [onHover, setHover] = useState(false);
  const [cardData, setCardData] = useState();

  const [getCardData] = useLazyQuery(PROPERTIES_PREVIEW, {
    variables: {
      ids: [id],
    },
    onCompleted: (data) => {
      const { parsedPropertiesPreview } = parsePropertyPreview(data.getPublicationPreviews);
      setCardData(parsedPropertiesPreview[0]);
    },
  });

  useEffect(() => {
    if (forcePopup) {
      getCardData({
        variables: {
          ids: [id],
        },
      });
    }
  }, [forcePopup]);
  return (
    <div
      className="markerContainer"
      onMouseEnter={() => {
        setHover(true);
        getCardData({
          variables: {
            propertyIds: id,
          },
        });
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {(onHover || forcePopup) && !creatingPolygone ? (
        <Popup
          latitude={latitude}
          longitude={longitude}
          closeButton={false}
          closeOnClick={false}
          onClose={() => this.setState({ showPopup: false })}
          dynamicPosition={false}
          anchor="top"
        >
          {cardData ? (
            <PropertyPreviewCard
              onClick={() => onCardClick(cardData)}
              property={cardData}
              layout="mapMarkerCard"
              handleSelect={() => handleSelectProperty(id, isSelected, cardData)}
              isSelected={isSelected}
            />
          ) : (
            <SkeletonCard layout="simple" />
          )}
        </Popup>
      ) : (
        <div />
      )}
      <Marker onHover setHover latitude={latitude} longitude={longitude}>
        {forcePopup || onHover ? (
          <div className="mapPin">
            <div className="mapPinDot mapPinDot--hover" />
          </div>
        ) : (
          <div className="mapPin">
            {zoom >= 18 ? (
              <div className="mapPinValue">
                {`UF ${
                  operation == 'sell'
                    ? price
                      ? Math.round(price).toLocaleString('de-DE')
                      : '-'
                    : price
                    ? Math.round(price).toLocaleString('de-DE') + ' /MES'
                    : '-'
                }`}
              </div>
            ) : (
              ''
            )}
            <div className="mapPinDot" />
          </div>
        )}
      </Marker>
    </div>
  );
}

MapMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  operation: PropTypes.string.isRequired,
  id: PropTypes.number,
  creatingPolygone: PropTypes.bool,
  propertyType: PropTypes.string,
  forcePopup: PropTypes.bool,
  handleSelectProperty: PropTypes.func,
  onCardClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isBlocked: PropTypes.bool,
};

MapMarker.defaultProps = {
  isBlocked: true,
};

export default MapMarker;

import { gql } from '@apollo/client';

export const GET_APPRAISALS = gql`
  query getValorization($appraisalId: Int!, $shareToken: String) {
    getValorization(id: $appraisalId, shareToken: $shareToken) {
      id
      city
      state
      sellPrice
      rentPrice
      address
      latitude
      longitude
      lowerBoundSellCI
      upperBoundSellCI
      lowerBoundRentCI
      upperBoundRentCI
      propertyType
      hasSwimmingPool
      totalArea
      coveredArea
      parkingLots
      bathrooms
      bedrooms
      floors
      yield
      upperBoundYieldCI
      lowerBoundYieldCI
      warehouses
      hasBalcony
      lowerBoundSellCIPerArea
      upperBoundSellCIPerArea
      sellPricePerArea
      lowerBoundRentCIPerArea
      upperBoundRentCIPerArea
      rentPricePerArea
      quality
    }
  }
`;

export const GET_SIMILAR_PUBLICATIONS_FROM_ATTRS = gql`
  query filterSimilarPublicationsFromAttributes(
    $operation: String!
    $propertyTypeDB: String!
    $latitude: Float!
    $longitude: Float!
    $coveredArea: Float!
    $totalArea: Float!
  ) {
    filterSimilarPublicationsFromAttributes(
      operation: $operation
      property_type: $propertyTypeDB
      latitude: $latitude
      longitude: $longitude
      total_area: $totalArea
      covered_area: $coveredArea
    )
  }
`;

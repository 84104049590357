import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';

import { LOG_OUT } from '../../graphql/mutations/session';
import { userTokenVar, userRoleVar } from '../../graphql/cache';
import {
  IconEdit,
  IconHelp,
  IconLikemini,
  IconList,
  IconLogOut,
  //IconHome,
  IconSavedmini,
  IconUser,
} from '../../assets';

export default function UserProfileSideMenu({ title, onClick }) {
  const history = useHistory();

  const [logOut] = useMutation(LOG_OUT, {
    onError: ({ networkError }) => {
      if (networkError) {
        for (let err of networkError.result.errors) {
          switch (err.extensions.code) {
            case 'BAD_USER_INPUT':
              userTokenVar('');
              window.location.href = '/';
          }
        }
      }
    },

    onCompleted: () => {
      userTokenVar('');
      userRoleVar('');
      window.localStorage.setItem('@refreshToken', '');
      window.location.href = '/';
    },
  });

  const propertiesMenuItems = [
    {
      key: 'valorizations',
      icon: <IconList />,
      label: 'Mis valorizaciones',
      link: '/user/appraisals',
    },
    {
      key: 'massive-appraisals',
      icon: <IconList />,
      label: 'Valorizaciones masivas',
      link: '/user/massive-appraisals',
    },
    {
      key: 'liked',
      icon: <IconLikemini />,
      label: 'Favoritos',
      link: '/user/favorites',
    },
    {
      key: 'saved',
      icon: <IconSavedmini />,
      label: 'Colecciones',
      link: '/user/collections',
    },
  ];

  const userManagementMenuItems = [
    {
      key: 'profile',
      icon: <IconUser />,
      label: 'Perfil de usuario',
      link: '/user/profile',
    },
    {
      key: 'edit',
      icon: <IconEdit />,
      label: 'Editar perfil',
      link: '#',
    },
  ];

  const footerMenuItems = [
    {
      key: 'help',
      icon: <IconHelp />,
      label: 'Ayuda',
      link: '#',
    },
    {
      key: 'logOut',
      icon: <IconLogOut />,
      label: 'Cerrar sesión',
      callback: logOut,
    },
  ];

  const renderMenuItem = (elemento) => {
    //{ key, icon, label, link, callback }
    const { key, icon, label, link, callback } = elemento;
    const handleClick =
      callback ||
      (() => {
        onClick();
        history.push(link);
      });

    return (
      <Menu.Item key={key} onClick={handleClick} icon={icon}>
        {label}
      </Menu.Item>
    );
  };

  return (
    <Menu className="user-profile-side-menu">
      {title && <Menu.ItemGroup key="menu-header" title={title} id="menu-header" />}

      {/*
      <Menu.ItemGroup
        key="g1"
        title={
          <>
            <IconHome />
            Propiedades
          </>
        }
      >
        {propertiesMenuItems.map(renderMenuItem)}
      </Menu.ItemGroup>

      <Menu.ItemGroup
        key="g2"
        title={
          <>
            <Icon component={IconUser} />
            <span>Gestión de usuario</span>
          </>
        }
      >
        {userManagementMenuItems.map(renderMenuItem)}
      </Menu.ItemGroup>
      */}

      <Menu.ItemGroup
        key="g1"
        title={
          <>
            <Icon component={IconUser} />
            <span>Mi perfil</span>
          </>
        }
      >
        {[...propertiesMenuItems, ...userManagementMenuItems].map(renderMenuItem)}
      </Menu.ItemGroup>

      <Menu.ItemGroup key="g2" className="footer-menu">
        {footerMenuItems.map(renderMenuItem)}
      </Menu.ItemGroup>
    </Menu>
  );
}

UserProfileSideMenu.defaultProps = {
  title: '',
  onClick: () => {},
};

UserProfileSideMenu.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  key: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  callback: PropTypes.func,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import { GET_PRICE_ANALISIS } from '../../../graphql/queries';
import { parsePriceAnalisis } from '../services';

import PriceAnalisys from '../../RenderViews/PriceAnalisys';

import './style.less';

function PropertyPriceAnalisys({ propertyId }) {
  const [propertyData, setPropertyData] = useState();
  const { loading } = useQuery(GET_PRICE_ANALISIS, {
    variables: { id: propertyId },
    onCompleted: (data) =>
      setPropertyData(parsePriceAnalisis(data.getPublicationPriceAnalysisView)),
  });

  if (loading || !propertyData)
    return (
      <div className="componentTitle">
        <h1>Análisis de precio</h1>
        <Spin />
      </div>
    );

  return (
    <>
      {propertyData && (
        <>
          <div className="componentDivider" />
          <PriceAnalisys propertyData={propertyData} />
        </>
      )}
    </>
  );
}

PropertyPriceAnalisys.propTypes = {
  propertyId: PropTypes.number,
};

export default PropertyPriceAnalisys;

import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, signInWithRedirect, GoogleAuthProvider, inMemoryPersistence, browserSessionPersistence } from 'firebase/auth';

const backend = axios.create({
  baseURL: 'https://backend.capstone.tassapp.com/api',
  //baseURL: "http://127.0.0.1:56977/api/"
});

// Alter defaults after instance has been created
//backend.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDULk-uGNdh_AP7Amz0hm5YhgRLmxV0Dv0",
  authDomain: "tassapp-a05b0.firebaseapp.com",
  projectId: "tassapp-a05b0",
  storageBucket: "tassapp-a05b0.appspot.com",
  messagingSenderId: "37763324517",
  appId: "1:37763324517:web:92cfe506f3a510a1a1848c",
  measurementId: "G-CQSLP2TKKE"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

export {auth, app, db};
export default backend;

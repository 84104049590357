/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Switch, Route } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import UserProfileData from './UserProfileData';
import UserProfileMassiveAppraisals from './UserMassiveAppraisal';
import UserProfileSideMenu from './SideMenu';
import UserProfileAppraisals from './UserAppraisals';
import UserProfileCollections from './UserCollections';

import CollectionPublications from './CollectionPublications';
import { CollectionPublicationsView } from './CollectionPublicationsView';
import { FavoritePublicationsView } from './FavoritePublicationsView';
// import { UserProfileCollections } from './Collections';
import Loading from '../Loading';
import { userRoleVar } from '../../graphql/cache';
import { GET_CURRENT_USER_DATA } from '../../graphql/queries';
import './style.less';
import { useUserAuth } from '../../context/AuthContext';
import { getUser } from '../../actions/users';
  
export default function UserProfile(props) {
  const { user: userAuth } = useUserAuth();
  const [userName, setUserName] = useState();
  const [userRole, setUserRole] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userId, setUserId] = useState();
  const [selectedCollectionPublications, setSelectedCollectionPublications] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const fetchUser = async () => {
      try {
        const user = await getUser({ uid: userAuth.uid });
        if (user && isMounted) {
          setUserName(user.name);
          setUserEmail(user.email);
          setUserId(user.id);
          if (user && user.admin) {
            setUserRole('admin');
          };
          if (user && user.pro) {
            setUserRole('pro');
          };
          if (user && !user.pro && !user.admin) {
            setUserRole('free');
          };
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [getUser, userAuth]);

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return userName && userId ? (
    <div className="user-profile-view">
      {isDesktop && (
        <UserProfileSideMenu
          title={`Perfil de ${userName || ''}`.toUpperCase()}
        />
      )}
      <Switch>
        <Route
          path="/user/massive-appraisals"
          render={(params) => <UserProfileMassiveAppraisals {...params} />}
        />
        <Route
          path="/user/appraisals"
          render={(params) => <UserProfileAppraisals {...params} />}
        />
        <Route
          path="/user/profile"
          render={(params) => (
            <UserProfileData
              {...params}
              userName={userName}
              lastName={''}
              userRole={userRole}
              userEmail={userEmail}
              userPhone={''}
              userId={userId.toString()}
            />
          )}
        />
        <Route
          path="/user/favorites"
          render={(params) => (
            <FavoritePublicationsView
              userId={userId}
              {...params}
            />
          )}
        />
        <Route
          path="/user/collections"
          // render={(params) => (
          //   <UserProfileCollections
          //     setSelectedCollection={setSelectedCollection}
          //     setSelectedCollectionPublications={setSelectedCollectionPublications}
          //     userId={parseInt(data.getCurrentUser.id, 10)}
          //     userToken={userToken}
          //     {...params}
          //   />
          // )}
          render={(params) => (
            <UserProfileCollections
              userId={userId}
              setSelectedCollection={setSelectedCollection}
              setSelectedCollectionPublications={setSelectedCollectionPublications}
            />
          )}
        />
        <Route
          path="/user/collection/:id"
          render={() => (
            <CollectionPublicationsView
              selectedCollection={selectedCollection}
              selectedCollectionPublications={selectedCollectionPublications}
              userId={userId}
            />
          )}
        />
      </Switch>
    </div>
  ) : (
    <Loading />
  );
}

UserProfile.propTypes = {
  //userToken: PropTypes.string.isRequired,
};

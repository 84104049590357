import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Layout, Menu, Select, Tabs, Tooltip } from 'antd';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_METRIC_TYPES, GET_METRIC_VALUES, GET_METRIC_TIME } from '../../graphql/queries';
import SearchDirectionBar from '../SearchDirectionBar';
import IndicatorMap from './map';
import ToggleFilter from '../Filters/Toggle';
import { propertyTipeActionValues, sectorActionValues, regionIdValues } from './constants';
import './style.less';
import PropTypes from 'prop-types';

//import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;
const { TabPane } = Tabs;
//const { Panel } = Collapse;

export default function Indicators() {
  //const isDesktop = useMediaQuery({ minWidth: 1200 });
  //const forceGrid = useMediaQuery({ maxWidth: 767 });
  //const isMobile = useMediaQuery({ maxWidth: 480 });

  const indicators = [
    { name: 'Valor de venta en UF' },
    { name: 'Valor de arriendo' },
    { name: 'Volumen de oferta' },
    { name: 'Rentabilidad por m² útil' },
    { name: 'Valor de venta en UF/m²' },
    { name: 'Antiguedad promedio de contrución' },
    { name: 'Tiempo en el mercado' },
  ];

  const [metricData, setMetricData] = useState({
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: {
        name: 'EPSG:4326',
      },
    },
    features: [],
  });
  const [spatialID, setSpatialID] = useState(1);
  const [selectedSpatialID, setSelectedSpatialID] = useState(1);
  const [spatialType /*, setSpatialType*/] = useState('Region');
  const [metricId, setMetricId] = useState(217);
  const [propertyType, setPropertyType] = useState('house');
  const [aggregation, setAggregation] = useState('District');
  const [indicatorsList, setIndicatorsList] = useState(indicators);
  const [dataArea, setDataArea] = useState([]);
  const [dataLine, setDataLine] = useState([]);
  const [graphicName, setGraphicName] = useState('Precio venta');
  const [address, setAddress] = useState({});

  const isWideScreen = useMediaQuery({ minWidth: 1650 });
  const antButtonSize = isWideScreen ? 'large' : 'middle';

  const [getMetricValues, { loading }] = useLazyQuery(GET_METRIC_VALUES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setMetricData(data.getMetricValues);
    },
  });

  const [getMetricTime] = useLazyQuery(GET_METRIC_TIME, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setDataLine([]);
      setDataArea([]);

      Array.from(data.getMetricTimeSeries).map(
        ({ date, value__percentile_25, value__percentile_50, value__percentile_75 }) => {
          setDataLine((dataLine) => [
            ...dataLine,
            { time: date, temperature: Math.round(value__percentile_50) },
          ]);
          setDataArea((dataArea) => [
            ...dataArea,
            {
              time: date,
              temperature: [Math.round(value__percentile_25), Math.round(value__percentile_75)],
            },
          ]);
        },
      );
    },
  });

  //Queries
  useQuery(GET_METRIC_TYPES, {
    onCompleted: (data) => {
      setIndicatorsList(data.getMetricTypes);
    },
  });

  useEffect(() => {
    getMetricValues({
      variables: {
        spatial_id: spatialID,
        spatial_type: spatialType,
        metric_id: metricId == 224 ? 220 : metricId == 223 ? 217 : metricId,
        property_type: propertyType,
        aggregation: aggregation,
      },
    });
  }, [spatialID, spatialType, metricId, propertyType, aggregation]);

  useEffect(() => {
    if (selectedSpatialID && aggregation && metricId && propertyType) {
      getMetricTime({
        variables: {
          spatial_id: selectedSpatialID,
          spatial_type: aggregation,
          metric_id: metricId,
          property_type: propertyType,
        },
      });
    }
  }, [selectedSpatialID, aggregation, metricId, propertyType]);

  useEffect(() => {
    regionIdValues.map((region) => {
      if (region.short_code == address.region) {
        setSpatialID(region.value);
      }
    });
  }, [address]);

  return (
    <div className="indicatorsView">
      <Layout>
        <Layout>
          <Sider
            width={320}
            height={'100%'}
            theme={'light'}
            className="site-layout-background"
            style={{ overflowY: 'scroll' }}
          >
            <div className="tools">
              <div className="title">Indicadores de mercado</div>
            </div>
            <div className="description">
              <Tabs defaultActiveKey="1" centered={true}>
                <TabPane tab="Indicadores" keConty="1">
                  {/*<span className="selected-zone">Santiago Centro, RM.</span>*/}
                  <div className="indicators">
                    <Menu
                      mode="inline"
                      height={'100%'}
                      overflowY={'scroll'}
                      style={{ borderRight: 0 }}
                      defaultSelectedKeys={['217']}
                    >
                      {(indicatorsList.length != 0 ? indicatorsList : indicators).map(
                        (indicator) => {
                          const { id, spanish_name } = indicator;
                          return (
                            <Menu.Item
                              className="custom"
                              key={id}
                              style={{ height: 50, lineHeight: 3 }}
                              onClick={() => {
                                setMetricId(id);
                                setGraphicName(spanish_name);
                              }}
                            >
                              {spanish_name?.length > 30 ? (
                                <Tooltip title={spanish_name}>{spanish_name}</Tooltip>
                              ) : (
                                spanish_name
                              )}
                            </Menu.Item>
                          );
                        },
                      )}
                    </Menu>
                  </div>
                </TabPane>
                <TabPane tab="Información del sector" key="2">
                  <span className="selected-zone">Santiago Centro, RM.</span>
                  <div className="information">Información importante</div>
                </TabPane>
              </Tabs>
            </div>
          </Sider>
          <Layout>
            <Content
              className="site-layout-background map-layout"
              style={{
                margin: 0,
                height: '100%',
              }}
            >
              <div className="bar-and-filters">
                <SearchDirectionBar
                  address={address}
                  setAddress={setAddress}
                  mediaQueryLimit={1400}
                />
                <Select
                  options={regionIdValues}
                  placeholder="Región"
                  size={antButtonSize}
                  onChange={(value) => {
                    setSpatialID(value);
                  }}
                  value={spatialID}
                />
                <Select
                  options={sectorActionValues}
                  placeholder="Tipo de sector"
                  size={antButtonSize}
                  onChange={(value) => {
                    setAggregation(value);
                  }}
                  value={aggregation}
                />
                <ToggleFilter
                  defaultValue={'house'}
                  options={propertyTipeActionValues}
                  callback={(value) => {
                    setPropertyType(value);
                  }}
                />
              </div>
              <div className="map-container">
                <IndicatorMap
                  data={metricData}
                  graphicName={graphicName}
                  dataLine={dataLine}
                  dataArea={dataArea}
                  loading={loading}
                  setSelectedSpatialID={setSelectedSpatialID}
                  metricId={metricId}
                />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}

Indicators.propTypes = {
  render: PropTypes.any,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToggleFilter from '../../Filters/Toggle';
import { EstimationCard, EstimationCircle } from '../Fragments';
import { Spin } from 'antd';
import './style.less';

function TassAppEstimations({ data }) {
  const [unitTogle, setUnitTogle] = useState('UF');

  if (!data)
    return (
      <div className="componentTitle">
        <h1>Estimaciones de TassApp</h1>
        <Spin />
      </div>
    );

  return (
    <div className="TassappPropertyEstimationsContainer">
      <div className="componentTitle">
        <h1>Estimaciones de TassApp</h1>
        <ToggleFilter
          defaultValue={'UF'}
          options={[
            { label: 'UF', value: 'UF' },
            { label: 'CLP', value: 'CLP' },
          ]}
          callback={setUnitTogle}
        />
      </div>
      <EstimationCard
        title={'Valor estimado de venta:'}
        values={{
          main: data.estimatedSellPrice[unitTogle],
          mainPerM2: data.estimatedSellPricePerArea[unitTogle],
          min: data.estimatedSellPriceMin[unitTogle],
          minPerM2: data.estimatedSellPriceMinPerArea[unitTogle],
          max: data.estimatedSellPriceMax[unitTogle],
          maxPerM2: data.estimatedSellPriceMaxPerArea[unitTogle],
        }}
        unit={unitTogle}
      />
      <EstimationCard
        title={'Valor estimado de arriendo:'}
        values={{
          main: data.estimatedRentPrice[unitTogle],
          mainPerM2: data.estimatedRentPricePerArea[unitTogle],
          min: data.estimatedRentPriceMin[unitTogle],
          minPerM2: data.estimatedRentPriceMinPerArea[unitTogle],
          max: data.estimatedRentPriceMax[unitTogle],
          maxPerM2: data.estimatedRentPriceMaxPerArea[unitTogle],
        }}
        unit={unitTogle}
      />
      <EstimationCircle
        title={'Rentabilidad anual estimada'}
        values={{
          main: data.estimatedYield,
          min: data.estimatedMinYield,
          max: data.estimatedMaxYield,
        }}
      ></EstimationCircle>
    </div>
  );
}

const auxPricePropType = PropTypes.shape({
  UF: PropTypes.string,
  CLP: PropTypes.string,
});

TassAppEstimations.propTypes = {
  data: PropTypes.shape({
    estimatedSellPrice: auxPricePropType,
    estimatedSellPricePerArea: auxPricePropType,
    estimatedSellPriceMin: auxPricePropType,
    estimatedSellPriceMinPerArea: auxPricePropType,
    estimatedSellPriceMax: auxPricePropType,
    estimatedSellPriceMaxPerArea: auxPricePropType,
    estimatedRentPrice: auxPricePropType,
    estimatedRentPricePerArea: auxPricePropType,
    estimatedRentPriceMin: auxPricePropType,
    estimatedRentPriceMinPerArea: auxPricePropType,
    estimatedRentPriceMax: auxPricePropType,
    estimatedRentPriceMaxPerArea: auxPricePropType,
    estimatedYield: PropTypes.number,
    estimatedMinYield: PropTypes.number,
    estimatedMaxYield: PropTypes.number,
  }),
};

export default TassAppEstimations;

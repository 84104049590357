import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button, Drawer, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import PropertiesExcelDownload from './ExcelDownload';
//import Toggle from '../Buttons/ToggleButton';
import CloseFilter from '../Buttons/CloseFilterButton';
import RangeFilter, { RangeFilterInput } from '../Filters/Range';
import SelectFilter, { SelectFilterInput } from '../Filters/Select';
import ToggleFilter from '../Filters/Toggle';
import SliderFilter, { SliderFilterInput } from '../Filters/Slider';
import SortFilter from '../Filters/Sort';
import { IconMap, IconList } from '../../assets';

import {
  propertyTypeFilterValues,
  operationTypeFilterValues,
  publishedDateFilterValues,
  sortFilterValues,
  filtersInit,
  movilActionValues,
  movilActionValuesAdmin,
  disponibilityFilterValues,
  priceType,
  conditionType,
  sortFilterLabels,
  availabilityIncludeUnpublished,
} from './constants';
import {
  IconBookmark,
  IconClose,
  IconDownload,
  IconLikemini,
  IconHide,
  IconShow,
  IconFilter,
} from '../../assets';

function rangeFilterFormater(obj) {
  const { minValue, maxValue } = obj;
  if (maxValue === 6 && minValue) return `mayor a ${minValue}`;
  if (minValue && maxValue) return `entre ${minValue} - ${maxValue}`;

  if (minValue && !maxValue) {
    return `mayor a ${minValue}`;
  }
  if (!minValue && maxValue) {
    return `menor a ${maxValue}`;
  } else {
    return '';
  }
}

export default function PropertiesFilters({
  filters,
  setFilters,
  //setGridLayout,
  propertiesPreviewData,
  selectedPropertiesPreviewData,
  selectedProperties,
  clearSelectedProperties,
  showCollectionsModal,
  hideSelectedProperties,
  likeSelectedProperties,
  showSelectedProperties,
  userRole,
  setActivePriceUnit,
  activePriceUnit,
  setResultsInsteadOfMap,
  resultsInsteadOfMap,
  referenceLocation,
  selectAll,
}) {
  const isDesktop = useMediaQuery({ minWidth: 1290 });

  const handleFilterChange = (key) => {
    return (newValue) => {
      setFilters({ ...filters, [key]: newValue });
    };
  };
  return (
    <>
      {isDesktop && (
        <PropertiesFiltersDesktop
          filters={filters}
          handleFilterChange={handleFilterChange}
          activePriceUnit={activePriceUnit}
        />
      )}
      <div className="results-header">
        <div className="results-header-options">
          {isDesktop && (
            <>
              <ToggleFilter
                defaultValue={filters.disponibilityFilter}
                options={disponibilityFilterValues}
                callback={handleFilterChange('disponibilityFilter')}
              />
              {/*<Toggle callback={setGridLayout} values={['horizontal', 'vertical']} />*/}
              <ToggleFilter
                defaultValue={filters.conditionFilter}
                options={conditionType}
                callback={handleFilterChange('conditionFilter')}
              />
              <ToggleFilter
                defaultValue={'UF'}
                options={priceType}
                callback={(value) => setActivePriceUnit(value)}
              />
            </>
          )}

          {isDesktop ? (
            <SortFilter
              label={filters.sortFilter ? sortFilterLabels[filters.sortFilter] : 'Ordenar'}
              defaultValue={filters.sortFilter}
              values={sortFilterValues}
              callback={handleFilterChange('sortFilter')}
            />
          ) : (
            <PropertiesFiltersMobile
              filters={filters}
              setFilters={setFilters}
              selectedProperties={selectedProperties}
              userRole={userRole}
              propertiesPreviewData={propertiesPreviewData}
              clearSelectedProperties={clearSelectedProperties}
              showCollectionsModal={showCollectionsModal}
              hideSelectedProperties={hideSelectedProperties}
              likeSelectedProperties={likeSelectedProperties}
              showSelectedProperties={showSelectedProperties}
              activePriceUnit={activePriceUnit}
              setActivePriceUnit={setActivePriceUnit}
              setResultsInsteadOfMap={setResultsInsteadOfMap}
              resultsInsteadOfMap={resultsInsteadOfMap}
            />
          )}
        </div>
      </div>
      <div className="map-header">
        <span>{selectedProperties.size} propiedades seleccionadas</span>
        <div className="actions">
          <Button className={'selectAllButton'} onClick={selectAll}>
            Seleccionar todo
          </Button>
        </div>
        {selectedProperties.size > 0 && (
          <div className="actions">
            {userRole === 'admin' ||
              (userRole === 'superAdmin' && (
                <fragment>
                  <Tooltip title="Ocultar Propiedad">
                    <Button
                      icon={<Icon component={IconHide} />}
                      type="link"
                      onClick={hideSelectedProperties}
                    />
                  </Tooltip>
                  <Tooltip title="Mostrar Propiedad">
                    <Button
                      icon={<Icon component={IconShow} />}
                      type="link"
                      onClick={showSelectedProperties}
                    />
                  </Tooltip>
                </fragment>
              ))}
            <Tooltip title="Guardar propiedad" placement="top">
              <Button
                icon={<Icon component={IconBookmark} />}
                type="link"
                onClick={showCollectionsModal}
              />
            </Tooltip>
            <Tooltip title="Agregar a favoritos" placement="top">
              <Button
                icon={<Icon component={IconLikemini} />}
                type="link"
                onClick={likeSelectedProperties}
              />
            </Tooltip>
            <PropertiesExcelDownload
              render={
                <Tooltip title="Descargar Excel">
                  <Button
                    icon={<Icon component={IconDownload} />}
                    type="link"
                    onClick={clearSelectedProperties}
                  />
                </Tooltip>
              }
              filename="TassApp Resumen de Propiedades"
              sheetName="Hoja 1"
              data={selectedPropertiesPreviewData}
              referenceLocation={referenceLocation}
            />
            <Tooltip title="Deseleccionar" placement="topRight">
              <Button
                icon={<Icon component={IconClose} />}
                type="link"
                onClick={clearSelectedProperties}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
}

PropertiesFilters.propTypes = {
  referenceLocation: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  setResultsInsteadOfMap: PropTypes.func,
  selectAll: PropTypes.func,
  resultsInsteadOfMap: PropTypes.bool,
  filters: PropTypes.shape({
    propertyTypeFilter: PropTypes.string.isRequired,
    operationTypeFilter: PropTypes.string.isRequired,
    conditionFilter: PropTypes.string,
    publishedPriceFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    roomsFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    bathroomsFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    coveredAreaFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    totalAreaFilter: PropTypes.shape({
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
    }).isRequired,
    publishedDateFilter: PropTypes.string,
    sortFilter: PropTypes.string,
    disponibilityFilter: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  propertiesPreviewData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      operation: PropTypes.string,
      title: PropTypes.string,
      address: PropTypes.string,
      createdTime: PropTypes.string,
      firstTimeFound: PropTypes.string,
      lastTimeFound: PropTypes.string,
      isActive: PropTypes.bool,
      isHidden: PropTypes.bool,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      publishedPriceAmount: PropTypes.number,
      publishedPriceUnit: PropTypes.string,
      propertyType: PropTypes.string,
      coveredArea: PropTypes.number,
      totalArea: PropTypes.number,
      bedRooms: PropTypes.number,
      fullBathrooms: PropTypes.number,
      pictures: PropTypes.arrayOf(PropTypes.string),
      yieldValue: PropTypes.number,
    }),
  ),
  selectedPropertiesPreviewData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      operation: PropTypes.string,
      title: PropTypes.string,
      address: PropTypes.string,
      createdTime: PropTypes.string,
      firstTimeFound: PropTypes.string,
      lastTimeFound: PropTypes.string,
      isActive: PropTypes.bool,
      isHidden: PropTypes.bool,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      publishedPriceAmount: PropTypes.number,
      publishedPriceUnit: PropTypes.string,
      propertyType: PropTypes.string,
      coveredArea: PropTypes.number,
      totalArea: PropTypes.number,
      bedRooms: PropTypes.number,
      fullBathrooms: PropTypes.number,
      pictures: PropTypes.arrayOf(PropTypes.string),
      yieldValue: PropTypes.number,
    }),
  ),
  setGridLayout: PropTypes.func.isRequired,
  selectedProperties: PropTypes.instanceOf(Object),
  clearSelectedProperties: PropTypes.func,
  showCollectionsModal: PropTypes.func,
  hideSelectedProperties: PropTypes.func,
  likeSelectedProperties: PropTypes.func,
  showSelectedProperties: PropTypes.func,
  userRole: PropTypes.string.isRequired,
  setActivePriceUnit: PropTypes.func,
  activePriceUnit: PropTypes.string,
};

PropertiesFilters.defaultProps = {
  propertiesPreviewData: null,
  selectedPropertiesPreviewData: null,
  selectedProperties: null,
  clearSelectedProperties: null,
  hideSelectedProperties: null,
  showSelectedProperties: null,
  likeSelectedProperties: null,
  setActivePriceUnit: null,
  activePriceUnit: PropTypes.string,
};

const PropertiesFiltersDesktop = ({ filters, handleFilterChange, activePriceUnit }) => {
  return (
    <>
      <div className="filtersOptions">
        <ToggleFilter
          defaultValue={filters.propertyTypeFilter}
          options={propertyTypeFilterValues}
          callback={handleFilterChange('propertyTypeFilter')}
        />
        <ToggleFilter
          defaultValue={filters.operationTypeFilter}
          options={operationTypeFilterValues}
          callback={handleFilterChange('operationTypeFilter')}
        />
        {activePriceUnit === 'CLP' ? (
          <RangeFilter
            title={'Precio publicado'}
            label={'Precio publicado'}
            prefix={'CLP'}
            callback={handleFilterChange('publishedPriceFilter')}
          />
        ) : (
          <RangeFilter
            title={'Precio publicado'}
            label={'Precio publicado'}
            prefix={'UF'}
            callback={handleFilterChange('publishedPriceFilter')}
          />
        )}
        <SliderFilter
          title={'Cantidad de dormitorios'}
          label={'Dormitorios'}
          callback={handleFilterChange('roomsFilter')}
        />
        <SliderFilter
          title={'Cantidad de baños'}
          label={'Baños'}
          callback={handleFilterChange('bathroomsFilter')}
        />
        <RangeFilter
          title={'Área útil (sin contar la terraza o patio)'}
          label={'Área útil'}
          suffix={'m²'}
          callback={handleFilterChange('coveredAreaFilter')}
        />
        <RangeFilter
          title={'Área total (área útil más terrazas y patios)'}
          label={'Área total'}
          suffix={'m²'}
          callback={handleFilterChange('totalAreaFilter')}
        />
        <SelectFilter
          label={'Tiempo publicación'}
          defaultValue={filters.publishedDateFilter}
          options={publishedDateFilterValues}
          callback={handleFilterChange('publishedDateFilter')}
        />
      </div>
      <div className="filterSelected">
        {filters.publishedPriceFilter.minValue || filters.publishedPriceFilter.maxValue ? (
          <CloseFilter
            label={`Precio en UF ${rangeFilterFormater(filters.publishedPriceFilter)}`}
            callback={handleFilterChange('publishedPriceFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.roomsFilter.minValue || filters.roomsFilter.maxValue ? (
          <CloseFilter
            label={`Dormitorios: ${rangeFilterFormater(filters.roomsFilter)}`}
            callback={handleFilterChange('roomsFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.bathroomsFilter.minValue || filters.bathroomsFilter.maxValue ? (
          <CloseFilter
            label={`Baños: ${rangeFilterFormater(filters.bathroomsFilter)}`}
            callback={handleFilterChange('bathroomsFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.coveredAreaFilter.minValue || filters.coveredAreaFilter.maxValue ? (
          <CloseFilter
            label={`Área útil: ${rangeFilterFormater(filters.coveredAreaFilter)}`}
            callback={handleFilterChange('coveredAreaFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.totalAreaFilter.minValue || filters.totalAreaFilter.maxValue ? (
          <CloseFilter
            label={`Área total: ${rangeFilterFormater(filters.totalAreaFilter)}`}
            callback={handleFilterChange('totalAreaFilter')}
            params={{ minValue: undefined, maxValue: undefined }}
          />
        ) : null}
        {filters.publishedDateFilter ? (
          <CloseFilter
            label={`Desde ${filters.publishedDateFilter}`}
            callback={handleFilterChange('publishedDateFilter')}
            params={null}
          />
        ) : null}
      </div>
    </>
  );
};

PropertiesFiltersDesktop.propTypes = {
  filters: PropertiesFilters.propTypes.filters, // definir defaults
  handleFilterChange: PropTypes.func, // def default
  activePriceUnit: PropTypes.string,
};
PropertiesFiltersDesktop.defaultProps = {
  filters: null,
  handleFilterChange: null,
  activePriceUnit: 'UF',
};

const PropertiesFiltersMobile = ({
  filters,
  setFilters,
  selectedProperties,
  userRole,
  clearSelectedProperties,
  showCollectionsModal,
  hideSelectedProperties,
  likeSelectedProperties,
  showSelectedProperties,
  setActivePriceUnit,
  activePriceUnit,
  setResultsInsteadOfMap,
  resultsInsteadOfMap,
}) => {
  const largeButton = useMediaQuery({ minWidth: 1290 });
  const antButtonSize = largeButton ? 'large' : 'middle';
  const [drawerVisible, setDrawerVisible] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1290 });
  return (
    <Fragment>
      {!isDesktop && (
        <Button
          className="mapOrGridButton"
          onClick={() => setResultsInsteadOfMap(!resultsInsteadOfMap)}
          type="primary"
          shape="round"
          icon={resultsInsteadOfMap ? <IconMap /> : <IconList />}
        >
          {resultsInsteadOfMap ? 'Mapa' : 'Listado'}
        </Button>
      )}
      <Button size={antButtonSize} onClick={() => setDrawerVisible(true)}>
        Filtros <Icon component={IconFilter} />
      </Button>
      <SelectFilter
        label={`${selectedProperties.size} seleccionadas`}
        options={
          userRole === 'admin' || userRole === 'superAdmin'
            ? movilActionValuesAdmin
            : movilActionValues
        }
        callback={(value) => {
          value === 'save_property' && showCollectionsModal();
          value === 'fav_property' && likeSelectedProperties();
          value === 'hide_properties' && hideSelectedProperties();
          value === 'show_properties' && showSelectedProperties();
          value === 'errase_selection' && clearSelectedProperties();
        }}
      />

      <Drawer
        title="Filtros"
        placement="right"
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={330}
      >
        <PropertiesFiltersMobileOverlay
          filters={filters}
          setFilters={setFilters}
          setDrawerVisible={setDrawerVisible}
          activePriceUnit={activePriceUnit}
          setActivePriceUnit={setActivePriceUnit}
        />
      </Drawer>
    </Fragment>
  );
};

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const PropertiesFiltersMobileOverlay = ({
  filters,
  setFilters,
  setDrawerVisible,
  setActivePriceUnit,
  activePriceUnit,
}) => {
  let query = useQueryParams();
  const [newFilters, setNewFilters] = useState(filters);
  const handleFilterChange = (key) => {
    return (newValue) => setNewFilters({ ...newFilters, [key]: newValue });
  };

  const applyFilters = () => {
    setFilters(newFilters);
    setDrawerVisible(false);
  };
  const resetFilters = () => {
    setNewFilters(filtersInit(query));
    setFilters(filtersInit(query));
  };

  const applyDisabled = JSON.stringify(filters) === JSON.stringify(newFilters);
  const resetDisabled = JSON.stringify(filtersInit(query)) === JSON.stringify(newFilters);

  const filtersComponents = [
    {
      label: 'Incluir despublicados',
      component: SelectFilterInput,
      props: {
        value: newFilters.disponibilityFilter,
        options: availabilityIncludeUnpublished,
        onChange: handleFilterChange('disponibilityFilter'),
      },
    },
    {
      label: 'Nuevo o usado',
      component: SelectFilterInput,
      props: {
        value: newFilters.conditionFilter,
        options: conditionType,
        onChange: handleFilterChange('conditionFilter'),
      },
    },
    {
      label: 'Unidad monetaria',
      component: SelectFilterInput,
      props: {
        value: activePriceUnit,
        options: priceType,
        onChange: (value) => setActivePriceUnit(value),
      },
    },
    {
      label: 'Tipo de propiedad',
      component: SelectFilterInput,
      props: {
        value: newFilters.propertyTypeFilter,
        options: propertyTypeFilterValues,
        onChange: handleFilterChange('propertyTypeFilter'),
      },
    },
    {
      label: 'Venta o arriendo',
      component: SelectFilterInput,
      props: {
        value: newFilters.operationTypeFilter,
        options: operationTypeFilterValues,
        onChange: handleFilterChange('operationTypeFilter'),
      },
    },
    {
      label: 'Precio publicado',
      component: RangeFilterInput,
      props: {
        prefix: activePriceUnit === 'CLP' ? 'CLP' : 'UF',
        minValue: newFilters.publishedPriceFilter.minValue,
        maxValue: newFilters.publishedPriceFilter.maxValue,
        onChange: handleFilterChange('publishedPriceFilter'),
      },
    },
    {
      label: 'Dormitorios',
      component: SliderFilterInput,
      props: {
        onChange: handleFilterChange('roomsFilter'),
      },
    },
    {
      label: 'Baños',
      component: SliderFilterInput,
      props: {
        onChange: handleFilterChange('bathroomsFilter'),
      },
    },
    {
      label: 'Área util',
      helper: 'sin contar terraza o patio',
      component: RangeFilterInput,
      props: {
        suffix: 'm²',
        minValue: newFilters.coveredAreaFilter.minValue,
        maxValue: newFilters.coveredAreaFilter.maxValue,
        onChange: handleFilterChange('coveredAreaFilter'),
      },
    },
    {
      label: 'Área total',
      helper: 'área útil más terraza o patio',
      component: RangeFilterInput,
      props: {
        suffix: 'm²',
        minValue: newFilters.totalAreaFilter.minValue,
        maxValue: newFilters.totalAreaFilter.maxValue,
        onChange: handleFilterChange('totalAreaFilter'),
      },
    },
    {
      label: 'Tiempo de publicación',
      component: SelectFilterInput,
      props: {
        value: newFilters.publishedDateFilter,
        options: publishedDateFilterValues,
        onChange: handleFilterChange('publishedDateFilter'),
      },
    },
  ];

  return (
    <div className="search-filters-drawer-content">
      {filtersComponents.map((filter, idx) => (
        <Fragment key={`${filter.label}-${idx.toString()}`}>
          <h4 className="search-filter-label">{filter.label}</h4>
          <filter.component
            onChange={filter.props.onChange}
            value={filter.props.value}
            prefix={filter.props.prefix}
            suffix={filter.props.suffix}
            minValue={filter.props.minValue}
            maxValue={filter.props.maxValue}
            options={filter.props.options}
          />
        </Fragment>
      ))}
      <div className="search-filters-drawer-buttons">
        <Button onClick={applyFilters} type="primary" disabled={applyDisabled}>
          Aplicar filtros
        </Button>
        <Button onClick={resetFilters} disabled={resetDisabled}>
          Eliminar filtros
        </Button>
        <Button onClick={() => setDrawerVisible(false)} type="link">
          Cancelar
        </Button>
      </div>
    </div>
  );
};

PropertiesFiltersMobile.propTypes = {
  filters: PropertiesFilters.propTypes.filters,
  setFilters: PropTypes.func,
  setDrawerVisible: PropTypes.func.isRequired,
  selectedProperties: PropTypes.instanceOf(Object),
  clearSelectedProperties: PropTypes.func,
  showCollectionsModal: PropTypes.func,
  hideSelectedProperties: PropTypes.func,
  likeSelectedProperties: PropTypes.func,
  showSelectedProperties: PropTypes.func,
  userRole: PropTypes.string.isRequired,
  setActivePriceUnit: PropTypes.func,
  activePriceUnit: PropTypes.string,
  setResultsInsteadOfMap: PropTypes.func,
  resultsInsteadOfMap: PropTypes.bool,
};

PropertiesFiltersMobile.defaultProps = {
  filters: null,
  setFilters: null,
  propertiesPreviewData: null,
  selectedProperties: null,
  clearSelectedProperties: null,
  hideSelectedProperties: null,
  showSelectedProperties: null,
  likeSelectedProperties: null,
  setActivePriceUnit: null,
  activePriceUnit: 'UF',
};

PropertiesFiltersMobileOverlay.propTypes = PropertiesFiltersMobile.propTypes;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import SearchDirectionBar from '../../SearchDirectionBar';
import { LandingPage, Title, Text, Row, InputBar } from '../../RenderViews/LandingPage';
import './style.less';

const { Option } = Select;

export default function AppraisalLandingPage(props) {
  const {
    appraisalFormPropertyType,
    appraisalFormAddress,
    setAppraisalFormPropertyType,
    setAppraisalFormAddress,
    setAppraisalFormVisible,
  } = props;

  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const buttonSize = isDesktop ? 'large' : 'medium';

  const AppraisalButton = () => (
    <Button onClick={() => setAppraisalFormVisible(true)} size={buttonSize} type="primary">
      VALORIZAR
    </Button>
  );

  return (
    <LandingPage>
      <Row>
        <div>
          <Title type="main">Valoriza tu propiedad hoy</Title>
          <Title type="secondary">
            Descubre el valor de mercado de tu propiedad para vender o arrendar.
          </Title>
          <Text>
            Sacale el máximo provecho a tu propiedad con nuestro modelo de inteligencia artificial
            en sólo 3 pasos.
          </Text>
          <AppraisalButton />
        </div>
        {isDesktop && (
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/LandingAppraisalWeb(1).png"
              alt="Example appraisal"
              width="100%"
            />
          </div>
        )}
      </Row>
      {!isDesktop && (
        <Row>
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/LandingAppraisalPhone(1).png"
              alt="Example appraisal"
              width="100%"
            />
          </div>
        </Row>
      )}
      {!isMobile && (
        <Row>
          <InputBar>
            <SearchDirectionBar
              address={appraisalFormAddress}
              setAddress={(address) => {
                setAppraisalFormAddress(address);
              }}
            />
            {isDesktop && (
              <Select
                placeholder="Casa o Departamento"
                size={buttonSize}
                onChange={(x) => setAppraisalFormPropertyType(x)}
                value={appraisalFormPropertyType}
              >
                <Option value="house">Casa</Option>
                <Option value="apartment">Departamento</Option>
              </Select>
            )}
            <AppraisalButton />
          </InputBar>
        </Row>
      )}

      <Row>
        <div>
          <div>
            <Title type="main">Propiedades comparables</Title>
            <Title type="secondary">
              En tiempo real publicadas en portales para venta y arriendo.
            </Title>
          </div>
          <div style={{ marginTop: '60px', marginBottom: '60px' }}>
            <Text>
              Cada reporte de tasación incluye información de las propiedades similares en el sector
              que esten actualmente publicadas en portales inmobilarios. Estas propiedades
              comparables se pueden seleccionar para descargar en formato Excel, guardar en tus
              colecciones o agregar a tus favoritos.
            </Text>
          </div>
          <AppraisalButton />
        </div>
        {isDesktop && (
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchComparableLanding.png"
              alt="Search comparables"
              width="100%"
            />
          </div>
        )}
      </Row>
      {!isDesktop && (
        <Row>
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchComparableLanding.png"
              alt="Search comparables"
              width="100%"
            />
          </div>
        </Row>
      )}

      <Row>
        {isDesktop && (
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchInvestmentSimulationLanding.png"
              alt="Search investment simulation"
              width="100%"
            />
          </div>
        )}
        <div>
          <div>
            <Title type="main">Simulación de inversión</Title>
            <Title type="secondary">
              Simula el dividendo de tu crédito y comparalo con el arriendo estimado.
            </Title>
          </div>
          <div style={{ marginTop: '60px', marginBottom: '60px' }}>
            <Text>
              Determina cuánto tendrías que pagar mensualmente en dividendos si quisieras comprar
              esta propiedad y compara estos valores con los valores de arriendo estimados por
              TassApp.
            </Text>
          </div>
          <AppraisalButton />
        </div>
      </Row>
      {!isDesktop && (
        <Row>
          <div>
            <img
              src="https://tassapp-public-assets.s3.amazonaws.com/SearchInvestmentSimulationLanding.png"
              alt="Search investment simulation"
              width="100%"
            />
          </div>
        </Row>
      )}
    </LandingPage>
  );
}

AppraisalLandingPage.propTypes = {
  appraisalFormPropertyType: PropTypes.string,
  appraisalFormAddress: PropTypes.instanceOf(Object),
  setAppraisalFormPropertyType: PropTypes.func,
  setAppraisalFormAddress: PropTypes.func.isRequired,
  setAppraisalFormVisible: PropTypes.func.isRequired,
};

AppraisalLandingPage.defaultProps = {
  appraisalFormAddress: null,
};

import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import './style.less';

function Loading({ extraClass }) {
  return (
    <div className={`loadingComponent ${extraClass}`}>
      <Spin size="large" />
    </div>
  );
}

Loading.propTypes = {
  extraClass: PropTypes.string,
};

Loading.defaultProps = {
  extraClass: '',
};

export default Loading;

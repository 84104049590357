export const propertyStatus = {
  new: {
    title: 'Como nueva',
    text:
      'Impecable, ningún problema estético o funcional. Aparatos domésticos en perfecto estado.',
  },
  good: {
    title: 'Muy buen estado',
    text: 'Está en muy buen estado. Ha sido usada pero remodelada y/o bien mantenida.',
  },
  used: {
    title: 'Usada en buen estado',
    text: 'Tiene leves daños o desgaste estético pero sin problemas en su funcionalidad.',
  },
  bad: {
    title: 'Necesita reparaciones',
    text: 'Necesita algunas reparaciones y remodelaciones, pero con ellas será habitable.',
  },
  awful: {
    title: 'Inhabitable',
    text: 'Necesita restauraciones importantes al punto que se vuelve inseguro o no funcional.',
  },
};

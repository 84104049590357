import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_POINTS_OF_INTEREST_RADIUS } from '../../../graphql/queries';
import { UPDATE_APPRAISAL_QUALITY } from '../../../graphql/mutations/appraisals';
import Map from '../../Map';
import PropertySummaryImages from '../../Properties/PublicationDetailPage/PicturesCarousel';
import PointofInterest from '../../PointsOfinterest';
import { Spin } from 'antd';
import Icon from '@ant-design/icons';
import { PropertyStatusBoxes } from '../Fragments';
import { IconBedroom, IconBathroom, IconSquareMeters } from '../../../assets';
import './style.less';

function PropertyInfo({ data, mode }) {
  const [selectedPoints, setSelecterPoints] = useState(new Set());
  const [pointsOfInterest, setPointOfInterest] = useState([]);
  const [showPointsOfInterest, setShowPointsOfInterest] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [getPointsOfInterest] = useLazyQuery(GET_POINTS_OF_INTEREST_RADIUS, {
    onCompleted: (result) => {
      setPointOfInterest(result.getPointsOfInterestsWithinRadius.features);
    },
  });

  const [updateQuality] = useMutation(UPDATE_APPRAISAL_QUALITY, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  const handleQualityChange = (quality) => {
    updateQuality({ variables: { appraisalId: data.id, quality } });
  };

  const handleSelectedPoints = (category) => {
    const newSet = new Set(selectedPoints);
    if (newSet.has(category)) {
      newSet.delete(category);
    } else {
      newSet.add(category);
    }
    setSelecterPoints(newSet);
    getPointsOfInterest({
      variables: {
        categories: Array(...newSet),
        latitude: data.latitude,
        longitude: data.longitude,
        radius: 1,
      },
    });
  };

  if (!data)
    return (
      <div className="componentTitle">
        <Spin />
      </div>
    );
  return (
    <div className="propertySummaryInfo">
      {isMobile && mode === 'propetry' && <PropertySummaryImages propertyId={data.id} />}{' '}
      {showPointsOfInterest && (
        <PointofInterest selected={selectedPoints} handleSelected={handleSelectedPoints} />
      )}
      <button onClick={() => setShowPointsOfInterest(true)} hidden></button>
      <div className="mapContainer">
        <Map
          address={{
            value: data.address,
            coords: {
              latitude: data.latitude,
              longitude: data.longitude,
            },
          }}
          config={{
            searchbar: false,
            polygon: false,
            markerLocation: true,
            ubication: false,
            zoomButtons: true,
            satelitalControl: true,
            propertiesAlerts: false,
            calculateArea: false,
            zoomWithWheel: false,
            setingAddress: true,
          }}
          withSearchBar={false}
          getAddress={(address) => {
            data.address = address;
          }}
          pointsOfInterest={pointsOfInterest ? pointsOfInterest : []}
        />
      </div>
      {mode === 'appraisal' ? (
        <></>
      ) : (
        <div className="summaryDataIcons">
          <span>
            <Icon component={IconBedroom} />
            {data.bedRooms}
          </span>
          <span>
            <Icon component={IconBathroom} />
            {data.fullBathrooms}
          </span>
          <span>
            <Icon component={IconSquareMeters} />
            {data.coveredAreaText}
          </span>
          <span>
            <Icon component={IconSquareMeters} />
            {data.totalAreaText}
          </span>
        </div>
      )}
      {mode === 'appraisal' && (
        <PropertyStatusBoxes
          handleQualityChange={handleQualityChange}
          defaultActive={data.quality}
        />
      )}
      <div className="summaryTable">
        <div className="summaryTableItem">
          <h4>Tipo de propiedad:</h4>
          <span>{data.propertyType}</span>
        </div>
        <div className="summaryTableItem">
          <h4>Habitaciones:</h4>
          <span>{data.bedRooms}</span>
        </div>
        <div className="summaryTableItem">
          <h4>Tamaño construido:</h4>
          <span>{data.coveredAreaTextSmall}</span>
        </div>
        <div className="summaryTableItem">
          <h4>Baños:</h4>
          <span>{data.fullBathrooms}</span>
        </div>
        <div className="summaryTableItem">
          <h4>Tamaño terreno:</h4>
          <span>{data.totalAreaTextSmall}</span>
        </div>
        <div className="summaryTableItem">
          <h4>{data.propertyType === 'Casa' ? 'Pisos:' : 'Piso:'}</h4>
          <span>{data.floors ? data.floors : 'Sin información'}</span>
        </div>
        <div className="summaryTableItem">
          <h4>Estacionamientos:</h4>
          <span>{data.parkings}</span>
        </div>
        {data.propertyType === 'Casa' && (
          <div className="summaryTableItem">
            <h4>Piscina:</h4>
            <span>{data.swimmingPool ? 'Si' : 'No'}</span>
          </div>
        )}
      </div>
      {mode === 'appraisal' ? (
        <></>
      ) : (
        <div className="summaryDescription">
          <h2>Descripción de la publicación</h2>
          <div className="summatyDescriotionInner">
            {data.description !== ''
              ? data.description
              : 'No hay descripción disponible para esta propiedad.'}
          </div>
        </div>
      )}
    </div>
  );
}

PropertyInfo.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    swimmingPool: PropTypes.bool,
    parkings: PropTypes.number,
    propertyType: PropTypes.oneOf(['Casa', 'Depto']),
    floors: PropTypes.number,
    totalAreaTextSmall: PropTypes.string,
    fullBathrooms: PropTypes.number,
    coveredAreaTextSmall: PropTypes.string,
    bedRooms: PropTypes.string,
    totalAreaText: PropTypes.string,
    coveredAreaText: PropTypes.string,
    address: PropTypes.string,
    quality: PropTypes.number,
  }),
  mode: PropTypes.oneOf(['property', 'appraisal']),
};

PropertyInfo.defaultProps = {
  mode: 'property',
};

export default PropertyInfo;

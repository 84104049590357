import React from 'react';
import { Link } from 'react-router-dom';
import { TassAppLogo } from '../../assets';
import './style.less';

export function AccessDenied() {
  return (
    <div className="access-denied">
      <img src={TassAppLogo} alt="" />
      <h1>¡Aprovecha el potencial de Tassapp!</h1>
      <h4>
        Obtener esta información podría transformarse en <b>tu ventaja competitiva</b> para comprar
        o vender propiedades al mejor precio. Subscríbete con nosotros para obtener estos beneficios{' '}
        <Link to="/">
          <span>haciendo click aquí.</span>
        </Link>
      </h4>
    </div>
  );
}

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_INVESTMENT_DATA } from '../../../graphql/queries/properties';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import InvestmentSimulator from '../../RenderViews/InvestmentSimulator';
import { parseInvestmentSimulationData } from '../services';
import './style.less';

function SummaryInvestmentSimulator({ propertyId }) {
  const [data, setData] = useState();

  useQuery(GET_INVESTMENT_DATA, {
    variables: { id: propertyId },
    onCompleted: (data) =>
      setData(parseInvestmentSimulationData(data.getPublicationInvestmentView)),
  });

  if (!data)
    return (
      <div className="componentTitle">
        <h1>Simulación de inversión</h1>
        <Spin />
      </div>
    );

  return (
    data && (
      <>
        <div className="componentDivider" />
        <div className="componentTitle">
          <h1>Simulación de inversión</h1>
        </div>
        <div className="componentHeader">
          Con la siguiente herramienta podrás simular el dividendo de esta propiedad y compararlo
          con el arriendo estimado
        </div>
        <InvestmentSimulator data={data} />
      </>
    )
  );
}

SummaryInvestmentSimulator.propTypes = {
  propertyId: PropTypes.number,
};

export default SummaryInvestmentSimulator;

import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import './style.less';

function DefaultInfoCard({ icon, title, body, footer, border }) {
  return (
    <div className={`infoCardContainer ${border && 'border'}`}>
      <Icon component={icon} className="infoCardIcon" />
      <div className="infoContainer">
        <h3>{title}</h3>
        <h4>{body}</h4>
        {footer && <h5>{footer}</h5>}
      </div>
    </div>
  );
}

DefaultInfoCard.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  footer: PropTypes.string,
  border: PropTypes.bool,
};

DefaultInfoCard.defaultProps = {
  footer: '',
  border: true,
};

export default DefaultInfoCard;

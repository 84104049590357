import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListProperties from '../../RenderViews/ListProperties';
import ToggleFilter from '../../Filters/Toggle';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_SIMILAR_PUBLICATIONS_FROM_ATTRS } from '../../../graphql/queries/appraisal';
import { PROPERTIES_PREVIEW } from '../../../graphql/queries';
import { parseMarkersProperties, parsePropertyPreview } from '../../Properties/services';

function AppraisalSimilarProperties({ appraisalData }) {
  const [similarProperties, setSimilarProperties] = useState();
  const [similarPropertiesData, setsimilarPropertiesData] = useState();
  const [toggleValue, setToggleValue] = useState('sell');

  const [getExcelData] = useLazyQuery(PROPERTIES_PREVIEW, {
    fetchPolicy: 'cache-and-network',

    onCompleted: (data) => {
      const { parsedPropertiesPreview } = parsePropertyPreview(data.getPublicationPreviews);
      setsimilarPropertiesData(parsedPropertiesPreview);
    },
  });
  const { loading } = useQuery(GET_SIMILAR_PUBLICATIONS_FROM_ATTRS, {
    variables: { ...appraisalData, operation: toggleValue },
    onCompleted: (data) => {
      const similarPropertiesParsed = parseMarkersProperties(
        data.filterSimilarPublicationsFromAttributes,
      );
      setSimilarProperties(similarPropertiesParsed.parsedMarkersData);
      getExcelData({
        variables: {
          ids: similarPropertiesParsed.parsedMarkersData.map((x) => x.id),
        },
      });
    },
  });

  return (
    <>
      <div className="appraisalTitle">
        <h2>Propiedades comparables</h2>
      </div>
      <div className="appraisalSimilarFilters">
        <ToggleFilter
          defaultValue={'sell'}
          options={[
            { label: 'Venta', value: 'sell' },
            { label: 'Arriendo', value: 'rental' },
          ]}
          callback={setToggleValue}
        />
      </div>

      <ListProperties
        listProperties={similarProperties ? similarProperties : []}
        listPropertiesData={similarPropertiesData ? similarPropertiesData : []}
        property={appraisalData}
        loading={loading}
        openFullScreen={true}
      ></ListProperties>
    </>
  );
}

AppraisalSimilarProperties.propTypes = {
  appraisalData: PropTypes.shape({
    propertyTypeDB: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    coveredArea: PropTypes.number.isRequired,
    totalArea: PropTypes.number.isRequired,
  }),
};
export default AppraisalSimilarProperties;

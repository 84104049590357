import React from 'react';
import PropTypes from 'prop-types';
import './style.less';

export function LandingPage(props) {
  const notNullChildren = React.Children.toArray(props.children).filter(Boolean);
  return (
    <div
      className={
        notNullChildren.length ? 'landingPage rows-' + notNullChildren.length : 'landingPage'
      }
    >
      {notNullChildren}
    </div>
  );
}
LandingPage.propTypes = {
  children: PropTypes.node,
};

LandingPage.defaultProps = {
  children: null,
};

export function Row(props) {
  const notNullChildren = React.Children.toArray(props.children).filter(Boolean);
  return (
    <div
      className={
        notNullChildren.length ? 'landingPageRow cols-' + notNullChildren.length : 'landingPageRow'
      }
    >
      {notNullChildren}
    </div>
  );
}
Row.propTypes = {
  children: PropTypes.node,
};

Row.defaultProps = {
  children: null,
};

export function Title(props) {
  return <h1 className={'landingPageTitle ' + props.type}>{props.children}</h1>;
}
Title.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};
Title.defaultProps = {
  children: null,
  type: 'main',
};

export function Text(props) {
  return <p className={'landingPageText'}>{props.children}</p>;
}
Text.propTypes = {
  children: PropTypes.node,
};
Text.defaultProps = {
  children: null,
};

export function InputBar(props) {
  return <div className={'landingPageInputBar'}>{props.children}</div>;
}
InputBar.propTypes = {
  children: PropTypes.node,
};
InputBar.defaultProps = {
  children: null,
};
